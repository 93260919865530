import React, { Fragment, useState } from "react";
import map from "lodash/map";
import { isArray } from "@/util";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import { useAppSelector } from "@/redux/store";
import { Image } from "@/components/image";
import ListComponent from "./list-component";

const COLUMN_LIST = ["col1", "col2", "col3", "col4", "col5", "col6"];

const MobileMenuCategories = ({ pageChange, openLink }) => {
  const megaMenu = useAppSelector(state => state.megaMenuReducer.megaMenuList);
  const superPriceStore = useAppSelector(
    state => state.megaMenuReducer.staticContent
  );

  const [activeLevel, setActiveLevel] = useState(1);
  const [level2Data, setLevel2Data] = useState([]);
  const [level2Header, setLevel2Header] = useState("");
  const [level3Data, setLevel3Data] = useState([]);
  const [level3Header, setLevel3Header] = useState("");
  const [level3Title, setLevel3Title] = useState("");
  const [carousalList, setCarousalList] = useState([]);

  const handle2LevelList = menuList => {
    if (checkIfChildExists(menuList)) {
      const level2Data = createSingleList(menuList);
      setActiveLevel(2);
      setLevel2Data(level2Data);
      setLevel2Header(menuList.headerTitle);
      setCarousalList(menuList.catCarousalList);
      pageChange(2);
    } else {
      openLink(menuList.redirectionLink);
    }
  };

  const createSingleList = list => {
    const singleList = COLUMN_LIST.reduce((accumulator, item) => {
      if (isArray(list[item]) && list[item].length) {
        return [...accumulator, ...list[item]];
      }
      return accumulator;
    }, []);

    return singleList;
  };

  const checkIfChildExists = list =>
    COLUMN_LIST.some(item => isArray(list[item]) && list[item].length > 0);

  const innerPageListClick = item => {
    if (isArray(item.children) && Boolean(item.children.length)) {
      setActiveLevel(3);
      setLevel3Data(item.children);
      setLevel3Header(item.subTitle);
      setLevel3Title(item.groupBy);
      setCarousalList([]);
    } else {
      openLink(item.redirectUrl);
    }
    CommonAnalyticsEvent.navClick({
      clickText: item.subTitle,
      link: item.redirectUrl
    });
  };

  const backBtnClick = () => {
    setActiveLevel(activeLevel - 1);
    pageChange(activeLevel - 1);
  };

  let content = null;
  if (activeLevel === 1) {
    content = (
      <ul className="menu_categories_wrapper">
        {map(megaMenu, menuList => (
          <li key={menuList.uid} onClick={() => handle2LevelList(menuList)}>
            <span>{menuList.headerTitle}</span>
            <span className="menu_img">
              <Image src={menuList.hoverImageUrl} />
            </span>
          </li>
        ))}

        {superPriceStore?.showSps && (
          <li
            onClick={() =>
              handle2LevelList({
                ...superPriceStore,
                redirectionLink: superPriceStore?.spsRedirectUrl || ""
              })
            }
          >
            <span>{superPriceStore.spsTitle}</span>
            <span className="menu_img">
              <Image src={superPriceStore.imageUrl || ""} />
            </span>
          </li>
        )}
      </ul>
    );
  } else if (activeLevel === 2) {
    content = (
      <ListComponent
        carousalList={carousalList}
        data={level2Data}
        header={level2Header}
        handleClick={innerPageListClick}
        backBtnClick={backBtnClick}
        isLevel2
      />
    );
  } else if (activeLevel === 3) {
    content = (
      <ListComponent
        data={level3Data}
        header={level3Header}
        handleClick={innerPageListClick}
        backBtnClick={backBtnClick}
        page3Title={level3Title}
      />
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default MobileMenuCategories;
