import includes from "lodash/includes";
import replace from "lodash/replace";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import get from "lodash/get";
import split from "lodash/split";
import trim from "lodash/trim";

import { appSettings } from "../../config/app";
import { ENV_CONFIG } from "../../config/env";
import { COUNTRY_NAME_SEO, QUERY_STRING_CONSTANTS } from "@/constants";

const SITE_URL = ENV_CONFIG.baseURL;

const getProductImages = product => {
  if (!product) return "";
  const getImageUrl = imageUrl => appSettings.imageBaseURL + imageUrl;
  return product.images?.length
    ? [getImageUrl(product.captionImageURL), ...product.images.map(getImageUrl)]
    : product.captionImageURL;
};

const getSEOBeautifiedUrl = url => (url.endsWith("/") ? url : url + "/");

export const getSEOStructuredProduct = (product, location, currencyCode) => {
  const schemaUrl = "https://schema.org/";
  const parentCategory = product.parentCategory?.label
    ? product.parentCategory + ">"
    : "";
  const category = `${parentCategory}${product.category?.label}`;
  const productAvailability = product.pstock > 0 ? "InStock" : "OutOfStock"; //additional SEO product availability options are described on the site: https://schema.org/ItemAvailability
  return {
    "@context": schemaUrl,
    "@type": "Product",
    name: product.title?.label,
    image: getProductImages(product),
    brand: {
      "@type": "Brand",
      name: product.brandName?.label
    },
    category,
    offers: {
      "@type": "Offer",
      url: SITE_URL + location.pathname,
      priceCurrency: currencyCode,
      price: product.price.current,
      availability: schemaUrl + productAvailability
    }
  };
};

export const getSEOStructuredBreadcrumbs = breadCrumbsList => {
  const itemListElement = breadCrumbsList?.map((item, index) => {
    const url = SITE_URL + (item.url && getSEOBeautifiedUrl(item.url));
    return {
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@id": item.url ? url : item.name,
        name: item.name,
        ...(item.url && { url })
      }
    };
  });
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement
  };
};

export const getMetaTitleDescription = (metaData, history, settings) => {
  let title = get(metaData, "title", "");
  let description = get(metaData, "metaDescription", "");
  let meta = {};
  const countryCode = get(settings, "countryCode", "");
  const query = new URLSearchParams(history.location.search);
  const brand = query.get("brand");
  const promotion = query.get("promotion");
  const occasion = query.get("occasion");
  if (brand) {
    const brandCount = split(brand, ",").length;
    if (brandCount > 1) {
      title = replace(title, "BRAND_NAME_SEO", "");
      description = replace(description, "BRAND_NAME_SEO", "");
    }
    const modifiedBrand = startCase(toLower(brand));
    title = replace(title, "BRAND_NAME_SEO", modifiedBrand);
    description = replace(description, "BRAND_NAME_SEO", modifiedBrand);
  }
  if (promotion) {
    const promotionCount = split(promotion, ",").length;
    if (promotionCount > 1) {
      title = replace(title, "PROMOTION_NAME_POST_HYPHEN", "");
      description = replace(description, "PROMOTION_NAME", "");
    }
    const modifiedPromotionPostHyphen = startCase(toLower(promotion)) + " -";
    const modifiedPromotion = startCase(toLower(promotion)) + "!";
    title = replace(
      title,
      "PROMOTION_NAME_POST_HYPHEN",
      modifiedPromotionPostHyphen
    );
    description = replace(description, "PROMOTION_NAME", modifiedPromotion);
  }
  if (occasion) {
    const modifiedOccasion = startCase(toLower(occasion));
    title = replace(title, "OCCASION_SEO", modifiedOccasion);
    description = replace(description, "OCCASION_SEO", modifiedOccasion);
  }

  meta = { title, description };
  const seoTitleDescrp = formatTitleDescription(meta, countryCode);
  return seoTitleDescrp;
};

export const formatTitleDescription = (
  meta: any,
  countryCode: string
): { title: string; description: string } => {
  let metaTitle = get(meta, "title", "");
  let metaDescrp = get(meta, "description", "");
  let titleDescrp: { title: string; description: string } = {
    title: "",
    description: ""
  };
  QUERY_STRING_CONSTANTS.forEach(queryString => {
    if (includes(metaTitle, queryString)) {
      metaTitle = replace(replace(metaTitle, queryString, ""), /  +/g, " ");
    }
    if (includes(meta.description, queryString)) {
      metaDescrp = replace(replace(metaDescrp, queryString, ""), /  +/g, " ");
    }
  });
  metaDescrp = replace(metaDescrp, COUNTRY_NAME_SEO, countryCode);
  metaTitle = replace(trim(metaTitle), /  +/g, " ");
  metaDescrp = replace(trim(metaDescrp), /  +/g, " ");
  titleDescrp = { title: metaTitle, description: metaDescrp };
  return titleDescrp;
};
