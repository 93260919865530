import isFunction from "lodash/isFunction";
import get from "lodash/get";
import { isServer } from "../util";

class SyteAISubscriptionService {
  // All the subscriptions should be added to SyteEvents array before the syte.ai script is loaded!
  syteEvents = [];

  verifyCallback(callback) {
    return callback && isFunction(callback);
  }

  setProductClickedSubscription(callback) {
    if (this.verifyCallback(callback)) {
      this.syteEvents.push({ name: "productClicked", callback });
    }
  }

  setViewSimilarClickedSubscription(callback) {
    if (this.verifyCallback(callback)) {
      this.syteEvents.push({ name: "discoveryButtonClick", callback });
    }
  }
  applySubscriptions() {
    if (!isServer && this.syteEvents.length) {
      window.SyteEvents = this.syteEvents;
    }
  }
  // This method can be used to subscribe to Syte.ai events only after the Syte.ai script is loaded
  addListenerToLoadedSyte(eventName, callback) {
    const maySubscribe =
      !isServer &&
      get(window, "SyteApp.isReady") &&
      eventName &&
      this.verifyCallback(callback);

    if (maySubscribe) {
      window.SyteApp.subscribeEvent(eventName, callback);
    }
  }
}

const instance = new SyteAISubscriptionService();

export default instance;
