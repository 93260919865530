import {
  SET_BRANDS_DATA,
  SET_SEARCH_TEXT,
  RESET_BRANDS_DATA
} from "../constants";
import { Brand } from "../../services/brandsService";

const initialState: {
  brands: Brand[];
  searchText: string;
} = {
  brands: [],
  searchText: ""
};

const setBrandsData = (state, { brands }) => ({ ...state, brands });

const setSearchText = (state, { text }) => ({ ...state, searchText: text });

const resetBrandsReducer = state => ({ ...initialState });

const brandsReducer = (state = initialState, action): typeof initialState => {
  switch (action.type) {
    case SET_BRANDS_DATA:
      return setBrandsData(state, action);
    case SET_SEARCH_TEXT:
      return setSearchText(state, action);
    case RESET_BRANDS_DATA:
      return resetBrandsReducer(state);
    default:
      return state;
  }
};

export default brandsReducer;
