import { useMemo } from "react";
import { ProductsListingPageData } from "../types";

const useProductListingFilters = (listingPageData: ProductsListingPageData) => {
  const filters = useMemo(
    () => listingPageData?.facets?.filter(facet => facet.type),
    [listingPageData]
  );
  return filters;
};

export default useProductListingFilters;
