import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CartProduct } from "../../../../services/cartService";

import { useRouteSlug } from "../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { selectCdnImageSettings } from "../../../../util/selectors";

import { deleteCartData } from "../../../../redux/actions/cart.action";

import ContentImage from "../../../content/contentImage";
import PriceSlab from "../../../price/priceSlab";

const ProductItem: FC<{
  product: CartProduct;
  style?: React.CSSProperties;
}> = ({ product, style }) => {
  const routeSlug = useRouteSlug();

  const configCdnImagesSettings = useAppSelector(selectCdnImageSettings);

  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();

  const deleteProduct = item => {
    // TODO: uncomment when cart analytics is ready
    // AnalyticService.cart.trackRemoveFromCart({
    //   item,
    //   authReducer,
    //   commonSettings,
    //   color
    // });

    dispatch(deleteCartData(item.productId, item._id));
  };

  return (
    <div className="cart_item_outer" style={style}>
      <div className="cart_item">
        <div>
          <Link to={`/${routeSlug}/${product.url}`}>
            <ContentImage
              img={product?.captionImageURL}
              url={configCdnImagesSettings?.thumb}
              showDefaultImage
            />
          </Link>
        </div>
        <div className="cart_item_content">
          <Link
            className="product_detail_link"
            to={`/${routeSlug}/${product.url}`}
          >
            <div className="view_content">
              {product.brand?.label && (
                <h5 className="pdp_brand">{product.brand.label}</h5>
              )}
              {product.title?.label && (
                <h1 className="product_title">{product.title.label}</h1>
              )}

              {product.isAvailableInCountry && product.price && (
                <PriceSlab
                  product={product}
                  discountLabelVisible
                  retailPriceVisible={false}
                />
              )}
            </div>
          </Link>

          <div className="product_size_info">
            {product.isPriceAvailableInUserCountry && (
              <p className="quantity_in_cart">
                {t("header.quantity")}:<span>{product.quantity ?? 1}</span>
              </p>
            )}
            {product.isPriceAvailableInUserCountry && Boolean(product.size) && (
              <p className="size_in_cart">
                {t("size.size")}:
                <span title={product.size}>{product.size}</span>
              </p>
            )}

            {!product.isPriceAvailableInUserCountry && (
              <div className="error">{t("header.notAvailableIn")}</div>
            )}
          </div>
        </div>

        <div className="cross-icon" onClick={() => deleteProduct(product)} />
      </div>
    </div>
  );
};

export default ProductItem;
