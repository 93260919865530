import {
  SAVE_BREADCRUMB_DATA,
  RESET_BREADCRUMB_DATA,
  SET_HOME_BREADCRUMB_TRANSLATION
} from "../constants";
const initialState = {
  home: {
    homeName: "",
    homeUrl: "/"
  },
  category: {
    categoryName: "",
    categoryUrl: ""
  },
  subCategory: {
    subCategoryName: "",
    subCategoryUrl: ""
  },
  productList: {
    ar_productListName: "",
    en_productListName: "",
    productListUrl: ""
  },
  productListLvl1: {
    productListLvl1Name: "",
    productListLvl1Url: ""
  },
  productListLvl2: {
    productListLvl2Name: "",
    productListLvl2Url: ""
  },
  product: {
    productName: "",
    productUrl: ""
  },
  myAccount: {
    myAccountName: "",
    myAccountUrl: ""
  },
  needHelp: {
    needHelpName: "",
    needHelpUrl: ""
  },
  sendUsEmail: {
    sendUsEmailName: "",
    sendUsEmailUrl: ""
  },
  brands: {
    brandsName: "",
    brandsUrl: ""
  },
  brandsList: {
    brandsName: "",
    brandsUrl: ""
  },
  brandsListLvl1: {
    brandsName: "",
    brandsUrl: ""
  },
  trackShipment: {
    trackShipmentName: "",
    trackShipmentUrl: ""
  }
};
const saveBreadCrumb = (state, { navData }) => ({
  ...state,
  [navData.modelName]: navData.modelData
});

const resetBreadCrumb = () => ({ ...initialState });

const setHomeBreadcrumb = (state, action) => ({
  ...state,
  home: {
    ...state.home,
    homeName: action.translatedValue.home
  }
});

const breadcrumbReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BREADCRUMB_DATA:
      return saveBreadCrumb(state, action);
    case RESET_BREADCRUMB_DATA:
      return resetBreadCrumb();
    case SET_HOME_BREADCRUMB_TRANSLATION:
      return setHomeBreadcrumb(state, action);
    default:
      return state;
  }
};

export default breadcrumbReducer;
