export const CATALOG_SECTION_TYPES = {
  DYNAMIC_BANNERS: "dynamicBannerModule",
  CATALOG_CAROUSEL: "catalog-carousel",
  PRODUCT_CAROUSEL: "product-carousel",
  BANNER: "banner"
};

export const SCROLL_TYPE = {
  NONE: "none",
  SCROLL: "scroll"
};
