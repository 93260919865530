import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const SaleComingSoon: FC<{
  heading?: string;
}> = ({ heading }) => {
  const { t } = useTranslation("sale");

  return (
    <>
      {heading && <h2 className="coming-soon-heading">{heading}</h2>}

      <div className="coming-soon-section">
        <p>{t("comingSoon")}</p>
      </div>
    </>
  );
};
