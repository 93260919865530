import React, { useEffect } from "react";
import { addScriptToBody } from "../../util/dom";
import { LISA_IFRAME_URL, LISA_MODAL_SDK_URL } from "../../constants";

export const LisaFrame = () => {
  useEffect(() => {
    var lisa_modal_configuration = {
      enablePreload: false
      // removeElementSelectors: [
      //   // ...
      // ]
    };
    addScriptToBody(LISA_MODAL_SDK_URL);
  }, []);
  return (
    <div className="lisa-container">
      <div className="lisa--viewer-container lisa--modal">
        <iframe className="lisa--viewer-frame" src={LISA_IFRAME_URL} />
      </div>
    </div>
  );
};
