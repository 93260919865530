import { Cookies } from "react-cookie";
import { isServer, Store } from "@/redux/store";

import serverContextStorage from "./serverContextStorage";

class ContextStorage {
  private static clientData: StorageData;

  public static get cookies(): Cookies {
    return this.storage.cookies!;
  }

  public static get store(): Store {
    return this.storage.store!;
  }

  public static setClientStorageData(data: StorageData) {
    if (isServer)
      throw new Error("This method is only available on the client");
    this.clientData = data;
  }

  public static setServerStorageData(data: StorageData) {
    if (!isServer)
      throw new Error("This method is only available on the server");

    this.getServerContextStorage().store = data.store;
    this.getServerContextStorage().cookies = data.cookies;
  }

  private static get storage(): StorageData {
    return isServer
      ? this.getServerContextStorage()
      : this.getClientContextStorage();
  }

  private static getServerContextStorage(): StorageData {
    if (!isServer)
      throw new Error("This method is only available on the server");

    return serverContextStorage.getStore();
  }

  private static getClientContextStorage(): StorageData {
    if (isServer)
      throw new Error("This method is only available on the client");

    return this.clientData;
  }
}

type StorageData = {
  cookies: Cookies;
  store: Store;
};

export default ContextStorage;
