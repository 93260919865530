import {
  SET_LISA_MINIMIZED,
  SET_LISA_LIBRARY,
  SET_LISA_PLAYER_IS_ACTIVE
} from "../constants/lisa";

const initialState = {
  minimized: false,
  library: null,
  playerIsActive: false
};

const setLisaMinimized = (state, action) => ({
  ...state,
  minimized: action.payload
});

const setLisaPlayerIsActive = (state, action) => ({
  ...state,
  playerIsActive: action.payload
});

const lisaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LISA_MINIMIZED:
      return setLisaMinimized(state, action);
    case SET_LISA_PLAYER_IS_ACTIVE:
      return setLisaPlayerIsActive(state, action);
    default:
      return state;
  }
};

export default lisaReducer;
