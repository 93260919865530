import { pushGaProductClick } from "../../util/analytics";
import get from "lodash/get";
import {
  TODDLERS,
  CHILDREN,
  KIDS,
  QUICK_FILTER_FLOWS,
  STEPS_FOR_GA
} from "../../constants/quickFilter";
import { includesSubString } from "../../util";

class QuickFilterAnalyticService {
  trackQuickFilterClick = trackData => {
    const homePageBannerClick = get(trackData, "homePageBannerClick", true);
    const quickFilterData = get(trackData, "quickFilterData", {});
    const quickFilterStepPath = get(trackData, "quickFilterStepPath", []);
    const selectedCategories = get(trackData, "selectedCategories", []);
    const selectedSizes = get(trackData, "selectedSizes", []);

    const quickFilterClickData = this.getDataForGA(
      quickFilterData,
      quickFilterStepPath,
      selectedCategories,
      selectedSizes
    );

    if (homePageBannerClick) {
      pushGaProductClick.pushGa({
        event: "quickFilterClick",
        quickFilterClickData: {
          action: "Click - Banner",
          category: "QuickFilter",
          label: "HomePage"
        }
      });
    } else {
      pushGaProductClick.pushGa({
        event: "quickFilterClick",
        quickFilterClickData
      });
    }
  };

  getDataForGA = (
    quickFilterData,
    quickFilterStepPath,
    selectedCategories,
    selectedSizes,
    eventCategory = "QuickFilter",
    eventAction,
    eventLabel
  ) => {
    const titles = this.getPathTitlesArrayFromFilterData(
      quickFilterData,
      quickFilterStepPath,
      selectedCategories,
      selectedSizes,
      true
    );
    return {
      category: eventCategory,
      action:
        eventAction ||
        "Click - " +
          STEPS_FOR_GA[this.getQuickFilterFlow(quickFilterStepPath)][
            titles.length - 1
          ],
      label: eventLabel || "Homepage - " + titles.join(" - ")
    };
  };

  getPathTitlesArrayFromFilterData = (
    quickFilterData,
    quickFilterStepPath,
    selectedCategories,
    selectedSizes,
    withShortSizes
  ) => {
    const dataPath = quickFilterStepPath.map(item => [CHILDREN, item]);
    const titles = [];
    for (let i = 0; i < dataPath.length; i++) {
      titles.push(
        this.getQuickFilterTitle(
          quickFilterData,
          dataPath.slice(0, i + 1).flat()
        )
      );
    }

    selectedCategories.length && titles.push(selectedCategories.join("/"));
    if (selectedSizes.length) {
      let sizeTitles = selectedSizes.flat();
      if (withShortSizes) {
        sizeTitles = sizeTitles.map(item => item.split(" > ")[3].trim());
      }
      titles.push(sizeTitles.join("/"));
    }
    return titles;
  };

  getQuickFilterFlow = filterStepPath => {
    let mainFlow = QUICK_FILTER_FLOWS.adults;
    if (filterStepPath.includes(KIDS)) {
      mainFlow = includesSubString(filterStepPath.join(""), TODDLERS)
        ? QUICK_FILTER_FLOWS.toddlers
        : QUICK_FILTER_FLOWS.kids;
    }
    return mainFlow;
  };

  getQuickFilterTitle = (quickFilterData, dataPath) =>
    (
      get(quickFilterData, [...dataPath, "headerTitle"]) ||
      get(quickFilterData, [...dataPath, "subTitle"])
    ).trim();
}

export default QuickFilterAnalyticService;
