import isUndefined from "lodash/isUndefined";
import { SCROLL_TYPE } from "../../constants/catalog";

export const getAutoScrollConfig = autoScroll => {
  const autoPlayParams = {
    autoplay: {
      delay: autoScroll?.speed * 1000,
      disableOnInteraction: false
    }
  };

  return !isUndefined(autoScroll) && autoScroll?.type !== SCROLL_TYPE.NONE
    ? autoPlayParams
    : {};
};

export const getInfiniteLoopConfig = (isInfiniteLoopEnable, rowData) => {
  const slidesPerColumnConfig = isInfiniteLoopEnable
    ? {}
    : {
        grid: {
          rows: rowData?.itemsPerGroup
        }
      };

  const infiniteLoopParams = {
    loop: isInfiniteLoopEnable,
    ...slidesPerColumnConfig
  };

  return infiniteLoopParams;
};
