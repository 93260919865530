import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/redux/store";
import { selectCdnImageSettings } from "@/util/selectors";

const RecommendedProducts = ({ moveToProductDetail }) => {
  const { t } = useTranslation("userPreferences");
  const recommendedProducts = useAppSelector(state => state.search.products);
  const configCdnImagesSettings = useAppSelector(selectCdnImageSettings);

  return (
    <div className="search_recommended">
      <h5>{t("recommendedProducts")}</h5>
      <ul>
        {recommendedProducts.map(item => (
          <li key={item.id} onClick={() => moveToProductDetail(item)}>
            <p className="search_img">
              <img
                src={`${configCdnImagesSettings?.search}${item.captionImageURL}`}
                alt="recommended_product"
              />
            </p>
            <p className="search_data">
              {item.brand?.label}
              <span>{item.title?.label}</span>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecommendedProducts;
