import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import * as priceUtil from "../../../util/priceUtil";
import { useIsRTL } from "../../../hooks";

import { Product } from "../../../services/productService";

const DiscountLabel: FC<{
  product: Product;
  showOffText?: boolean;
  showDiscountPrefix?: boolean;
  discountPrefix?: string;
}> = ({
  product,
  showOffText = true,
  showDiscountPrefix = false,
  discountPrefix = "-"
}) => {
  const { t } = useTranslation("common");

  const isRTL = useIsRTL();

  const percentage = priceUtil.getDiscountPercent(product);

  const prefix = showDiscountPrefix ? discountPrefix : "";
  const percentageValue =
    !prefix && isRTL ? `%${percentage}${prefix}` : `${prefix}${percentage}%`;

  const showDiscount = Boolean(product.price?.old && percentage);
  if (!showDiscount) return null;

  return (
    <p className="item_discount">
      <span className="product-discount">
        {percentageValue} {showOffText && t("price.off")}
      </span>
    </p>
  );
};

export default DiscountLabel;
