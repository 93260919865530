import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { isDateInPast } from "../../../util/common";
import CustomCountdown from "../../customCountdown";

export const SaleMobileTimer: FC<{
  dateTo: string;
  dateFrom: string;
}> = ({ dateTo, dateFrom }) => {
  const alreadyStarted = isDateInPast(dateFrom);
  const timeToShow = alreadyStarted ? dateTo : dateFrom;
  const { t } = useTranslation("sale");

  return (
    <div className="mobile-timer">
      <h2 className="timer-text">
        {alreadyStarted ? t("endsIn") : t("startsIn")}
      </h2>
      <CustomCountdown
        date={timeToShow}
        type={1}
        content={{ backgroundColor: "black", color: "white" }}
        textStyles={{ top: 30, color: "black" }}
      />
    </div>
  );
};
