import React, { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";
import cn from "clsx";
import { BANNER_ROW_TYPE } from "../../../constants";
import DynamicVideo from "../dynamic-video";
import { isMobile } from "../../../util";
import { getRedirectionUrl, isLinkStartsFromHTTPS } from "../../../util/common";
import BannerIntersectionObserver from "../../banner/bannerIntersectionObserver";
import BannerImageComponent from "./banner-image";
import { BannerImage } from "../../../routes/catalogPage/types";
import { useLongLanguage, useRouteSlug } from "@/hooks";

type DynamicSingleBannerProps = {
  data: BannerImage;
  imageListLength: number;
  index: number;
  bannerModuleName: string;
  rowTitle: string;
  bannerPageTitle: string;
};

const stylesForSingleRow = (
  { hover, ...restStyles },
  { hovered, imageWidth, marginForImage, hoverValues }
) => ({
  ...restStyles,
  ...(!isMobile.any() && hovered && hoverValues && hover),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: `0 0 calc(${imageWidth}% - ${marginForImage}px)`
});

const DynamicSingleBanner: FC<DynamicSingleBannerProps> = ({
  data,
  imageListLength,
  index,
  bannerModuleName,
  rowTitle,
  bannerPageTitle
}) => {
  const language = useLongLanguage();
  const imageLinkRef = useRef(null);
  const routeSlug = useRouteSlug();
  const [hovered, setHovered] = useState(false);
  const { styles } = data;
  const languageSpecifiedData = data[language];
  if (!languageSpecifiedData) return null;
  const { redirectionLink, imageURL, ratio } = languageSpecifiedData;
  const imageWidth = 100 / imageListLength;
  const hoverValues = styles.hoverValues?.transform;
  const marginForImage = styles.marginLeft + styles.marginRight || 0;
  const isExternalLink =
    redirectionLink && isLinkStartsFromHTTPS(redirectionLink);
  const redirectionPath = isExternalLink
    ? redirectionLink
    : `/${routeSlug}${redirectionLink || "/"}`;
  const linkTarget = isExternalLink ? "_blank" : "_self";
  const dataForAnalytic = {
    bannerModuleName: bannerModuleName?.toUpperCase(),
    bannerRowName: rowTitle?.toUpperCase(),
    index: index + 1,
    imageURL: imageURL || languageSpecifiedData.videoUrl,
    pageName: bannerPageTitle,
    imageListData: data
  };

  if (data.type === BANNER_ROW_TYPE.VIDEO) {
    return (
      <DynamicVideo
        data={languageSpecifiedData}
        imageWidth={imageWidth}
        marginForImage={marginForImage}
        styles={styles}
        linkTarget={linkTarget}
        redirectionPath={redirectionPath}
        index={index}
        dataForAnalytic={dataForAnalytic}
      />
    );
  }

  return (
    <BannerIntersectionObserver data={dataForAnalytic}>
      <a
        className={cn({
          "disabled-link": !redirectionLink
        })}
        href={redirectionPath}
        target={linkTarget}
        style={stylesForSingleRow(styles, {
          hovered,
          imageWidth,
          marginForImage,
          hoverValues
        })}
        data-banner-link={redirectionLink}
        data-banner-index={index}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="banner-element">
          <div
            className="banner-child"
            ref={imageLinkRef}
            style={{ paddingBottom: `${ratio}%` }}
          >
            <BannerImageComponent
              imageURL={imageURL}
              onImageLoad={() =>
                (imageLinkRef.current.style.background = "none")
              }
            />
          </div>
        </div>
      </a>
    </BannerIntersectionObserver>
  );
};

export default DynamicSingleBanner;
