import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import hoistNonReactStatics from "hoist-non-react-statics";
import { isServer } from "../util";
import withSyteApiStatus from "./withSyteApiStatus";
import get from "lodash/get";
import Helmet from "react-helmet";
import { SyteAISubscriptionService } from "../services";
import AnalyticService from "../services/analytic-service";

const configureSyteSubscriptions = () => {
  SyteAISubscriptionService.setProductClickedSubscription(data => {
    AnalyticService.syte.trackSyteProductClick(data);
  });
  SyteAISubscriptionService.setViewSimilarClickedSubscription(data => {
    AnalyticService.syte.trackViewSimilarClick(data);
  });
  SyteAISubscriptionService.applySubscriptions();
};

const getLanguageAndCountryFromUrl = pathname => {
  try {
    return pathname
      .split("/")
      .filter(e => e)[0]
      .split("-");
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getLangCode = lang => (lang === "ar" ? "ar_AE" : "en_US");

const SyteAiScriptComponent = React.memo(
  withSyteApiStatus(
    ({ syteApiKey, syteAccountId, syteAppIsReady, sytePreloadCallback }) => {
      if (isServer) return null;

      const scriptBaseUrl = "//cdn.syteapi.com/assets/imajs/imajs.js";
      const location = useLocation();
      const [lang] = getLanguageAndCountryFromUrl(location.pathname);
      const languageCode = getLangCode(lang);

      const initSyteAi = () => {
        const { setLanguage } = window.SyteApp;
        const init = window.SyteApi?.init;

        setLanguage && setLanguage(languageCode);

        if (!syteAccountId || !syteApiKey) return;

        init &&
          init({
            accountid: syteAccountId,
            sig: syteApiKey
          });
      };

      const handleChangeClientState = (newState, addedTags) => {
        if (addedTags && addedTags.scriptTags) {
          const foundScript = addedTags.scriptTags.find(({ src }) =>
            src.includes(scriptBaseUrl)
          );

          if (foundScript) {
            foundScript.addEventListener("load", () => {
              document.dispatchEvent(
                new Event("syte_ai_is_loaded", { bubbles: true })
              );
              initSyteAi();
            });
          }
        }
      };
      useEffect(() => {
        if (!isServer && window.SyteApp) {
          initSyteAi();
        }
      }, [location, syteAppIsReady]);

      useEffect(() => {
        sytePreloadCallback();
      }, []);

      return (
        <Helmet onChangeClientState={handleChangeClientState}>
          <script
            src={`${scriptBaseUrl}?a=${syteAccountId}&sig=${syteApiKey}`}
            async
          />
        </Helmet>
      );
    }
  )
);

const withSyteAiFeeds = Component => {
  const EnhancedComponent = props => {
    const syteApiKey = useSelector(state =>
      get(state, "common.settings.syte_api_key")
    );
    const syteAccountId = useSelector(state =>
      get(state, "common.settings.syte_account_id")
    );

    return (
      <>
        {syteApiKey && (
          <SyteAiScriptComponent
            {...{
              syteApiKey,
              syteAccountId,
              sytePreloadCallback: configureSyteSubscriptions
            }}
          />
        )}
        <Component {...props} />
      </>
    );
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withSyteAiFeeds;
