import React, { FC } from "react";
import ReactPaginate from "react-paginate";
import cn from "clsx";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIsRTL, useRouteSlug } from "../../../hooks";
import "./style.scss";

type ProductListingPaginationProps = {
  pagesTotal: number;
  activePage: number;
  showPageCount: boolean;
  handlePageClick: (selectedItem: { selected: number }) => void;
};

const ProductListingPagination: FC<ProductListingPaginationProps> = ({
  pagesTotal,
  activePage,
  showPageCount,
  handlePageClick
}) => {
  const { t } = useTranslation("productListing");
  const history = useHistory();
  const routeSlug = useRouteSlug();
  const isRTL = useIsRTL();
  const resetFilters = () => {
    history.push(location.search ? location.pathname : `/${routeSlug}/`);
  };

  return (
    <>
      {showPageCount ? (
        <div className={cn("pagination_wrapper", { arabic: isRTL })}>
          <div className="paginate_display">
            <span className="page_count d-none-resp">
              {t("page")}{" "}
              <span>
                {" "}
                {isRTL
                  ? `${pagesTotal}/${activePage}`
                  : `${activePage}/${pagesTotal}`}
              </span>
            </span>

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagesTotal}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              // ReactPaginate starts counting from 0, so we need to subtract 1
              forcePage={activePage - 1}
              containerClassName={"pagination"}
              activeClassName={"active"}
              // hrefBuilder={page => getPageQuery(page)}
            />
          </div>
        </div>
      ) : (
        <div className="reset-filter noRecordForListing">
          <button
            className="round_btn form_black_btn btn"
            onClick={resetFilters}
          >
            {t("resetFilter")}
          </button>
        </div>
      )}
    </>
  );
};

export default ProductListingPagination;
