import React, { useEffect, useState } from "react";

const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsSmallScreen(window.matchMedia("(max-width: 812px)").matches);
    setIsRendered(true);
  }, []);
  return { isSmallScreen, isRendered };
};

export default useIsSmallScreen;
