import React, { FC, useMemo } from "react";

import ListWrapper from "./listWrapper";
import MenuBanner from "./menuBanner";
import { Menu, MenuBannerType } from "./categoryMenu";

const MenuListWrapper: FC<{
  col: Menu[] | MenuBannerType;
  headerTitles: { en_headerTitle?: string; headerTitle: string };
}> = ({ col, headerTitles }) => {
  const filteredColumn = useMemo(() => {
    return getFilteredColumn(col, headerTitles);
  }, [col]);

  if (!filteredColumn) return null;

  return Array.isArray(filteredColumn) ? (
    <ListWrapper col={filteredColumn} />
  ) : (
    <MenuBanner col={filteredColumn} />
  );
};

const getFilteredColumn = (column, headerTitles) => {
  if (!Array.isArray(column)) {
    return column;
  }
  return column.reduce((acc, item) => {
    if (!item.isDisabled) {
      let filteredChildren = item.children;
      if (Array.isArray(item.children)) {
        filteredChildren = item.children
          .filter(child => !child.isDisabled)
          .map(child => ({
            ...child,
            ...headerTitles,
            subTitle1: item.subTitle,
            en_subTitle1: item.en_subTitle
          }));
      }

      acc.push({ ...item, children: filteredChildren, ...headerTitles });
    }
    return acc;
  }, []);
};

export default MenuListWrapper;
