import { Impressions } from "@/routes/productListing/types";
import { Product } from "../../../productService";
import { WishListItem } from "../../types";

export interface RemoveFromWishlist {
  item: Product;
  wishListItem: WishListItem;
}

export interface ImpressionsPayload {
  items: Impressions[];
  listHeading: string;
}

export type FilterAutoAppliedPayload = any;

export type FilterPayload = {
  filter_by: string;
  filter_value: string;
  filter_by_category: string;
  // product list | search results
  filter_location: FILTER_LOCATION;
  filter_all_categories: string;
  // example: adidas | adidas originals
  filter_all_values: string;
};

export type SortPayload = {
  sort_by: string;
  sort_location: SORT_LOCATION;
};

export enum FILTER_LOCATION {
  PRODUCT_SEARCH = "product search",
  SEARCH_RESULTS = "search results"
}

export enum SORT_LOCATION {
  PRODUCT_LIST = "product list",
  SEARCH_RESULTS = "search results"
}

export interface AddProductToWishlist {
  item: Product;
  wishlistData?: { size: string };
}
