import { GET_MEGA_MENU, RESET_MEGA_MENU } from "../constants";
import get from "lodash/get";

const initialState = {
  megaMenuList: []
};

const getMegaMenu = (state, { content, language }) => {
  const data = get(content, "data.result.megaMenuList", []);
  const sortedData =
    (data &&
      data.sort((a, b) => {
        const keyA = a.pos,
          keyB = b.pos;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })) ||
    [];

  const spsFilter = [...sortedData];

  spsFilter &&
    spsFilter.sort((a, b) => {
      const keyA = a.isSPS,
        keyB = b.isSPS;
      if (language === "english") {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
      } else {
        if (keyA > keyB) return 1;
        if (keyA < keyB) return -1;
      }

      return 0;
    });

  return {
    ...state,
    megaMenuList: [...spsFilter],
    staticContent: get(content, "data.result.staticContent", {})
  };
};

const resetMegaMenu = () => ({ ...initialState });
const megaMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEGA_MENU:
      return getMegaMenu(state, action);
    case RESET_MEGA_MENU:
      return resetMegaMenu();
    default:
      return state;
  }
};
export default megaMenuReducer;
