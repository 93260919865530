import React from "react";
import FooterNewsletter from "./footerNewsletter";

const stylesForRow = (styles, width) => ({
  ...styles,
  maxWidth: width?.value,
  marginLeft: "auto",
  marginRight: "auto"
});

const NewsLetterWrapper = ({ bannerData }) => {
  const styles = bannerData.styles;

  return (
    <div style={stylesForRow(styles, bannerData.width)}>
      <FooterNewsletter />
    </div>
  );
};

export default NewsLetterWrapper;
