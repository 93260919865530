import React, { FC } from "react";
import { createSelector } from "@reduxjs/toolkit";
import shuffle from "lodash/shuffle";
import { isMobile } from "@/util";
import CatalogProductCarousel from "./catalogProductCarousel";
import { BannerRow } from "@/routes/catalogPage/types";
import { Column } from "@/components/dynamic/dynamicColumns/types";
import "./style.scss";

const ProductCarousel: FC<{
  content: BannerRow | Column;
  bannerModuleName: string;
  bannerPageTitle: string;
  isSaleCarousel: boolean;
}> = ({ content, bannerModuleName, bannerPageTitle, isSaleCarousel }) => {
  const getImageList = createSelector(
    data =>
      data && Array.isArray(data.imageList) && shuffle([...data.imageList]),
    imageList => imageList || []
  );

  const isNotAvailable =
    isMobile.any() && !content?.enabledOn?.includes("resp");

  if (isNotAvailable) {
    return null;
  }

  return (
    <CatalogProductCarousel
      getImageList={getImageList}
      content={content}
      bannerModuleName={bannerModuleName}
      bannerPageTitle={bannerPageTitle}
      isSaleCarousel={isSaleCarousel}
    />
  );
};

export default ProductCarousel;
