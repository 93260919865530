import isArray from "lodash/isArray";

import { BEConfig } from "../../config/env";
import { fetchWebApi } from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { updateCartList } from "./cart.action";
import { handleFetchError } from "../../util/errorHandler";
import { sameSizeItemsFormat } from "@/util/cart";
import { getVisitorId, languageFromPathName } from "../../util";
import {
  GET_WISHLISTS,
  SET_WISHLISTS_BASE_DATA,
  SET_WISHLIST_LENGTH,
  SET_WISHLIST_PAGINATION,
  UPDATE_WISHLISTS,
  EMPTY_WISHLIST_CART,
  SELECTED_SIZE,
  WISHLIST_BASE_DATA_LOADING,
  DELETE_WISHLIST_ITEM_LOADING
} from "../constants";
import WishlistService, {
  WishlistData,
  WishlistProductBaseData
} from "../../services/wishlistService";

import { AppDispatch } from "../store";

export const getWishList = wishList => ({
  type: GET_WISHLISTS,
  wishList
});
export const setWishListBaseData = wishListBaseData => ({
  type: SET_WISHLISTS_BASE_DATA,
  wishListBaseData
});
export const setWishListPagination = pagination => ({
  type: SET_WISHLIST_PAGINATION,
  pagination
});

export const setWishListLength = length => ({
  type: SET_WISHLIST_LENGTH,
  length
});

export const updateWishList = wishList => ({
  type: UPDATE_WISHLISTS,
  wishList
});

export const wishListBaseDataLoading = () => ({
  type: WISHLIST_BASE_DATA_LOADING
});

export const deleteWishListItemLoading = () => ({
  type: DELETE_WISHLIST_ITEM_LOADING
});

export const emptyWishListAndCart = () => ({ type: EMPTY_WISHLIST_CART });

export const updateWishlistItemSize = ({
  sizeValue,
  productId,
  sizeId,
  sizeType,
  _id,
  sizeTypeKey,
  isMultisize
}: {
  sizeValue: string;
  productId: number;
  sizeId: number;
  sizeType: string;
  _id: string;
  sizeTypeKey: string;
  isMultisize: boolean;
}) => ({
  type: SELECTED_SIZE,
  selectedSizeValue: sizeValue,
  productId,
  sizeId,
  sizeType,
  _id,
  sizeTypeKey,
  isMultisize
});

export const deleteWishListData =
  ({
    productId,
    wishlistItemId,
    page
  }: {
    productId: number;
    wishlistItemId: string;
    page?: number;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      const wishlistData = await WishlistService.removeProductFromWishlist({
        productId,
        wishlistItemId,
        page
      });

      const isPaginationFlow = page !== undefined;
      if (isPaginationFlow)
        dispatch(setWishlistData(wishlistData as WishlistData));

      return wishlistData;
    } catch (error) {
      console.error(error);

      return error;
    }
  };

export const getWishListBaseData = (language, loader = true) => {
  language = languageFromPathName(language);
  const {
    wishListApi: { baseURL, protocol, port, versionInfo, wishListPageHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${wishListPageHandle}/?q=list`;

  return (dispatch, getState) => {
    loader && dispatch(showLoadingIndicator());
    dispatch(wishListBaseDataLoading());

    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        loader && dispatch(hideLoadingIndicator());
        if (response.data && isArray(response.data)) {
          dispatch(setWishListBaseData(response.data));
          dispatch(setWishListLength(response.data.length));
        } else {
          dispatch(setWishListBaseData([]));
          dispatch(setWishListLength(0));
        }
        return response;
      })
      .catch(error => {
        loader && dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchWishlistData =
  ({ page }: { page?: number }) =>
  async (dispatch: AppDispatch) => {
    dispatch(showLoadingIndicator());

    try {
      const wishlistData = await WishlistService.getWishlist({ page });

      dispatch(setWishlistData(wishlistData));

      return wishlistData;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const setWishlistData =
  (wishlistData: WishlistData) => (dispatch: AppDispatch) => {
    const { totalProducts, ...pagination } = wishlistData.pagination;

    dispatch(getWishList(wishlistData.wishlist));
    dispatch(setWishListPagination(pagination));
    dispatch(setWishListLength(totalProducts));
  };

export const saveToWishlist =
  ({
    data,
    page,
    loader = true
  }: {
    data: {
      productId: number;
      size: string;
      sizeId: number;
      sizeType: string | number;
      _id?: string;
      sizeTypeKey?: string;
      isMultisize: boolean;
    };
    page?: number;
    loader?: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    if (loader) dispatch(showLoadingIndicator());

    try {
      const wishlistData = await WishlistService.saveToWishlist(data, { page });

      const isPaginationFlow = page !== undefined;
      if (isPaginationFlow)
        dispatch(setWishlistData(wishlistData as WishlistData));
      return wishlistData;
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      if (loader) dispatch(hideLoadingIndicator());
    }
  };

export const saveItemToWishlist =
  ({
    cartId,
    page,
    loader = true
  }: {
    cartId: string;
    page?: number;
    loader?: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    if (loader) dispatch(showLoadingIndicator());

    try {
      const wishlistData = await WishlistService.saveItemToWishlist(cartId, {
        page
      });

      const isPaginationFlow = page !== undefined;
      if (isPaginationFlow)
        dispatch(setWishlistData(wishlistData as WishlistData));
      return wishlistData;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error;
    } finally {
      if (loader) dispatch(hideLoadingIndicator());
    }
  };

export const moveFromWishlistToCart =
  (wishlistProduct: WishlistProductBaseData) =>
  async (dispatch: AppDispatch, getState) => {
    const sizeFormattedItem = sameSizeItemsFormat(
      wishlistProduct,
      getState().cartReducer.cart
    );
    const wishlistData = await WishlistService.moveToCart(wishlistProduct);
    if (wishlistData) {
      dispatch(updateCartList(sizeFormattedItem));
    }

    const isPaginationFlow = wishlistProduct.page !== undefined;
    if (isPaginationFlow)
      dispatch(setWishlistData(wishlistData as WishlistData));

    return wishlistData;
  };
