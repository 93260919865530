import GoogleAnalytics from "../../googleAnalytics";
import {
  ActionToAuthPage,
  AuthFormErrors,
  AuthMethod,
  EmailSignIn,
  SocialSignIn
} from "./types";

class AuthAnalyticsService {
  forgotPassword() {
    GoogleAnalytics.forgotPassword();
  }
  authenticationMethod(payload: AuthMethod) {
    GoogleAnalytics.authenticationMethod(payload);
  }
  actionToAuthPage(payload: ActionToAuthPage) {
    GoogleAnalytics.actionToAuthPage(payload);
  }
  emailSignIn(payload: EmailSignIn) {
    GoogleAnalytics.emailSignIn(payload);
  }
  socialSignIn(payload: SocialSignIn) {
    GoogleAnalytics.socialSignIn(payload);
  }
  signUp(payload) {
    GoogleAnalytics.signUp(payload);
  }
  loginError(payload) {
    GoogleAnalytics.loginError(payload);
  }
  signUpError(payload) {
    GoogleAnalytics.signUpError(payload);
  }
  loginFormError(payload: AuthFormErrors) {
    GoogleAnalytics.loginFormError(payload);
  }
  signupFormError(payload: AuthFormErrors) {
    GoogleAnalytics.signupFormError(payload);
  }
  logout() {
    GoogleAnalytics.logout();
  }
}

const AuthAnaltyicsEvent = new AuthAnalyticsService();
export default AuthAnaltyicsEvent;
