export const SIGNUP_API_CODE_NAMES = {
  EMAIL_ALREADY_EXISTS: "4001",
  MOBILE_VERIFIED_ANOTHER_ACCOUNT: "4002",
  MOBILE_EXISTS_ANOTHER_ACCOUNT: "4003",
  OTP_REQUIRED: "4004",
  MOBILE_REQUIRED: "4005"
};

export const SIGNUP_CODES_FOR_MODAL = {
  "4003": "4003",
  "4004": "4004",
  "4005": "4005"
};

export const SIGNUP_API_CODE_INDEX = {
  ALREADY_IN_USE: 0,
  VERIFY_OTP: 1,
  REATTACH_NUMBER: 2,
  VERIFY_NUMBER: 3
};

export const MODAL_STEPS = {
  "4005": SIGNUP_API_CODE_INDEX.VERIFY_NUMBER,
  "4004": SIGNUP_API_CODE_INDEX.VERIFY_OTP,
  "4003": SIGNUP_API_CODE_INDEX.ALREADY_IN_USE
};

export const SOCIAL_SIGNUP_MODES = {
  GOOGLE: "google",
  FACEBOOK: "facebook",
  APPLE: "apple"
};
