import React, { forwardRef, ReactNode } from "react";
import { Scrollbars } from "react-custom-scrollbars";

export const CustomScrollbars = forwardRef<
  unknown,
  { onScroll: Function; style: React.CSSProperties; children: ReactNode }
>(({ onScroll, style, children }, forwardedRef) => (
  <Scrollbars
    ref={scrollbarsRef => {
      if (scrollbarsRef) {
        // Weird ts issue, functionality works fine
        // @ts-ignore
        forwardedRef(scrollbarsRef.view);
      } else {
        // @ts-ignore
        forwardedRef(null);
      }
    }}
    style={{ ...style, overflow: "hidden" }}
    onScroll={onScroll}
    /* Since Scrollbars has a bug showing horizontal scrolling(sometimes) when
       it's not needed, and it's impossible to get horizontal scrolling here, we
       disable it */
    renderTrackHorizontal={() => <div />}
  >
    {children}
  </Scrollbars>
));

export default CustomScrollbars;
