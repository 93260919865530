import {
  HANDLE_GRID_CHANGE,
  TOGGLE_QUICKLOOK_VIEW,
  TOGGLE_APP_ONLY_ITEM_MODAL,
  SET_INITIAL_STATE,
  HANDLE_GENERATED_URL
} from "../constants";

const initialState = {
  showQuicklookModal: false,
  appOnlyItemModal: {
    showAppOnlyItemModal: false,
    product: null
  },
  sysGeneratedUrl: []
};

const handleGridChange = (state, { gridValue }) => ({ ...state, gridValue });

const toggleQuicklookView = (state, { showQuicklookModal }) => ({
  ...state,
  showQuicklookModal
});
const toggleAppOnlyItemModal = (state, { showAppOnlyItemModal, product }) => ({
  ...state,
  appOnlyItemModal: {
    showAppOnlyItemModal: showAppOnlyItemModal,
    product
  }
});

const setInitialState = state => ({
  ...initialState,
  sysGeneratedUrl: state.sysGeneratedUrl
});

const handleGeneratedUrls = (state, { data }) => ({
  ...state,
  sysGeneratedUrl: data
});

const productListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_GRID_CHANGE:
      return handleGridChange(state, action);
    case TOGGLE_QUICKLOOK_VIEW:
      return toggleQuicklookView(state, action);
    case TOGGLE_APP_ONLY_ITEM_MODAL:
      return toggleAppOnlyItemModal(state, action);
    case SET_INITIAL_STATE:
      return setInitialState(state);
    case HANDLE_GENERATED_URL:
      return handleGeneratedUrls(state, action);
    default:
      return state;
  }
};

export default productListingReducer;
