import React, { FC, HTMLAttributes, useEffect, useRef, useState } from "react";
import cn from "clsx";

const FilterOptionsContainer: FC<
  HTMLAttributes<HTMLDivElement> & { filterWrapperClassName: string }
> = ({ children, filterWrapperClassName }) => {
  const [openedClass, setOpenedClass] = useState("");
  const filterModalWrapperRef = useRef<HTMLDivElement | null>(null);

  const ensureElementWithinBounds = ref => {
    const elementRect = ref.current?.getBoundingClientRect();
    if (!elementRect) return;
    const screenRect = {
      left: 0,
      top: 0,
      right: window.innerWidth,
      bottom: window.innerHeight
    };

    const checkRightSide =
      elementRect.right > screenRect.right &&
      elementRect.left < screenRect.right;

    const checkLeftSide =
      elementRect.left < screenRect.left && elementRect.right > screenRect.left;

    if (checkRightSide || checkLeftSide) {
      setOpenedClass("open_filter_to_other_side");
    }
  };

  useEffect(() => {
    ensureElementWithinBounds(filterModalWrapperRef);
  }, []);
  return (
    <div
      ref={filterModalWrapperRef}
      id="web_filter"
      className={cn("filter_content", openedClass, filterWrapperClassName)}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </div>
  );
};

export default FilterOptionsContainer;
