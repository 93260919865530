import React, { FC } from "react";
import { Link } from "react-router-dom";
import cn from "clsx";

import { hideMegaMenu, highlight } from "../../util/dom";
import List from "./list";
import CommonAnalyticsEvent from "../../services/analytics/main/common";
import { useRouteSlug } from "@/hooks";
import { Menu } from "./categoryMenu";

const MenuList: FC<{ listItem: Menu & { isMarginClass: boolean } }> = ({
  listItem
}) => {
  const { subTitle, redirectUrl, bold, children, isMarginClass, color } =
    listItem;
  const routeSlug = useRouteSlug();
  const linkURL = `/${routeSlug}${redirectUrl}`;
  return (
    <div
      className={cn("subTitle-wrapper", {
        list: children && Boolean(children.length),
        "margin-bottom": isMarginClass
      })}
    >
      <p className="subTitle" onClick={highlight}>
        <Link to={linkURL}>
          <span
            style={{
              color: color?.value,
              fontFamily: `${
                !!bold
                  ? "'FuturaPT-Demi', Helvetica, Arial, sans-serif"
                  : "'Futura PT Book', Helvetica, Arial, sans-serif"
              }`
            }}
            onClick={evt => {
              hideMegaMenu(evt);
              CommonAnalyticsEvent.lvl1MenuClick({
                item: listItem,
                url: linkURL
              });
            }}
          >
            {subTitle}
          </span>
        </Link>
      </p>
      {Boolean(children && children.length) && (
        <ul>
          {children.map(item => (
            <List item={item} key={item.uid} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default MenuList;
