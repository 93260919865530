import { PAYMENT_CHANGE, REQUIRED_EN, RESET_SAVED_CARD } from "../constants";
import validator from "validator";
import cloneDeep from "lodash/cloneDeep";

const initialState = {
  cardHolderName: "",
  cardName: "",
  cardNumber: "",
  expiryMonth: "",
  expiryYear: "",
  cvv: "",
  payPalToken: null,
  errors: {
    cardHolderName: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    cardName: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    cardNumber: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    expiryMonth: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    expiryYear: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    },
    cvv: {
      valid: true,
      validWhen: false,
      check: "isEmpty",
      message: REQUIRED_EN
    }
  }
};

const handleTextChange = (state, action) => ({
  ...state,
  [action.name]: typeof action.value === "undefined" ? "" : action.value,
  errors: {
    ...state.errors,
    [action.name]: state.errors[action.name]
      ? {
          ...state.errors[action.name],
          touched: true,
          valid: state.errors[action.name].check
            ? validator[state.errors[action.name].check](
                action.value
                  ? action.name !== "cardNumber"
                    ? action.value.toString()
                    : action.value.length === 16
                    ? action.value.toString()
                    : ""
                  : "",
                state.errors[action.name].options
              ) === state.errors[action.name].validWhen &&
              (typeof action.status !== "undefined" ? action.status : true)
            : true
        }
      : { touched: true }
  }
});

const resetSavedCard = state => ({ ...initialState });

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_CHANGE:
      return handleTextChange(state, action);
    case RESET_SAVED_CARD:
      return resetSavedCard(state);
    default:
      return state;
  }
};

export default paymentReducer;
