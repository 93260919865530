const EVENTS = {
  // Page Events
  viewPage: "page_view",

  // PDP Events
  viewProduct: "view_item",
  addToCart: "add_to_cart",
  addToWishlist: "add_to_wishlist",
  productImagesInteraction: "image_interaction",
  shareProduct: "share_product",
  selectSize: "select_size",
  outOfStock: "out_of_stock",
  paymentMethodInfo: "payment_method_info",

  // Cart Events
  removeFromCart: "remove_from_cart",
  viewCart: "view_cart",

  // Checkout Events
  beginCheckout: "begin_checkout",
  addShippingInfo: "add_shipping_info",
  continueAsGuest: "continue_as_guest",
  pinYourLocation: "pin_your_location",
  addPaymentInfo: "add_payment_info",
  applyVoucher: "apply_voucher",
  purchase: "purchase",
  refund: "refund",
  paymentError: "payment_error",

  // Header Events
  viewMiniCart: "view_mini_cart",
  changeCountry: "country_preference",
  changeLanguage: "change_language",
  navTop: "nav_top",
  navClick: "nav_click",
  search: "search",
  emptySearch: "null_search",
  viewSearchResult: "view_search_results",
  searchInteraction: "search_interaction",
  accOptionSelected: "my_account_option_clicked",

  // Authentication Events
  forgotPassword: "forgot_password",
  signInMethod: "sign_in",
  signUpMethod: "join_now",
  actionToSignUp: "join_click",
  actionToSignIn: "sign_in_click",
  signIn: "login",
  signUp: "sign_up",
  loginError: "login_error",
  signUpError: "signup_error",
  logout: "logout_success",

  // Form Events
  formStart: "form_start",
  formComplete: "form_complete",
  errorOccured: "error_occured",
  formAbandonment: "form_abandonment",

  // OTP Events
  requestOTP: "otp_request",
  resendOTP: "resend_otp",
  verifiedOTP: "otp_verification",

  removeFromWishlist: "remove_from_wishlist",

  // PLP page
  selectItem: "select_item",
  viewItemList: "view_item_list",
  filterAutoApplied: "filter_auto_applied",
  filter: "filter",
  sort: "sort",
  brandStock: "brand_stock",

  // Banner Events
  viewBanner: "view_banner",
  selectBanner: "select_banner",
  viewPromotion: "view_promotion",
  selectPromotion: "select_promotion",

  // My Accound Events
  cancelOrderStart: "cancel_order_start",
  cancelOrderSuccess: "cancel_order_success",
  accountInfoSaved: "my_account_info_saved",
  trackOrder: "track_order",

  // Footer Events
  navFooter: "nav_footer",
  subscribeNewsletter: "newsletter_subscription",
  viewAllFaq: "view_all_faq",
  accordionExpand: "accordion_expand",
  accordionCollapse: "accordion_collapse",
  locateNearByStore: "locate_nearby_store",
  sizeChart: "size_chart",

  clickToAction: "cta_click",
  addNewAddress: "add_new_address",
  socialClick: "social_click"
};

export default EVENTS;
