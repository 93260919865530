import { SECTION_SLUGS } from "../../constants/sections";
import { BROWSER_STORAGE } from "../../redux/constants";

import { _countryStoreSelection } from "../../util";

import {
  getCountrySettings,
  setCommonData,
  getConfigurationSettings
} from "../../redux/actions/common.action";
import { getMegaMenuData } from "../../redux/actions/megaMenu.action";
import { getBannerSections } from "../../redux/actions/section.action";
import { fetchFooterData } from "../../redux/actions/footer.action";
import { getCartAndWishlistGeneralData } from "@/redux/actions/cart.action";
import { fetchLoyaltyTierToken } from "@/redux/actions/myAccount.action";

import { handleLoadTask } from "./helpers";
import { PageLoadStrategy } from "./types";

const loadCommonData: PageLoadStrategy = async data => {
  const state = data.store.getState();
  const isUserLoggedIn = state.authReducer.userLoggedIn;

  const sequentialTasks: PageLoadStrategy[] = [
    ...(isUserLoggedIn ? [loadLoyaltyPointsTierToken] : []),
    loadCountrySettings
  ];

  const parallelTasks: PageLoadStrategy[] = [
    fetchMegaMenu,
    loadHeaderSections,
    loadConfigurations,
    loadCartAndWishlistBriefData,
    loadFooter
  ];

  for (const task of sequentialTasks) {
    await handleLoadTask(task, data);
  }

  await Promise.all(parallelTasks.map(task => handleLoadTask(task, data)));
};

const loadCountrySettings: PageLoadStrategy = ({ store, url, language }) => {
  return store.dispatch(getCountrySettings(language, null, url));
};

const fetchMegaMenu: PageLoadStrategy = ({ store, url, cookies, language }) => {
  const { common } = store.getState();
  const userCountry = cookies[BROWSER_STORAGE.USER_COUNTRY];

  const { _country, storeId, _bflStoreId } = _countryStoreSelection(
    store,
    url,
    userCountry,
    common,
    setCommonData
  );

  return store.dispatch(
    getMegaMenuData(
      language,
      storeId || _bflStoreId,
      _country?.countryId,
      cookies
    )
  );
};

const loadHeaderSections: PageLoadStrategy = async ({ store }) => {
  await store.dispatch(getBannerSections(SECTION_SLUGS.HEADER));
};

const loadConfigurations: PageLoadStrategy = async ({ store }) => {
  await store.dispatch(getConfigurationSettings());
};

const loadFooter: PageLoadStrategy = async ({ store }) => {
  await store.dispatch(fetchFooterData());
};

const loadCartAndWishlistBriefData: PageLoadStrategy = async ({ store }) => {
  return store.dispatch(getCartAndWishlistGeneralData());
};

const loadLoyaltyPointsTierToken: PageLoadStrategy = async ({ store }) => {
  return store.dispatch(fetchLoyaltyTierToken());
};

export default loadCommonData;
