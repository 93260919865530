import GoogleAnalytics from "../../googleAnalytics";
import { CancelOrderStart, CancelOrderSuccess, TrackOrder } from "./types";

class MyAccountAnalyticsService {
  cancelOrderStart(payload: CancelOrderStart) {
    GoogleAnalytics.cancelOrderStart(payload);
  }
  cancelOrderSuccess(payload: CancelOrderSuccess) {
    GoogleAnalytics.cancelOrderSuccess(payload);
  }
  accountInfoSaved() {
    GoogleAnalytics.accountInfoSaved();
  }
  trackOrder(payload: TrackOrder) {
    GoogleAnalytics.trackOrder(payload);
  }
}

const AccountAnalyticsEvent = new MyAccountAnalyticsService();
export default AccountAnalyticsEvent;
