import React, { useEffect, useState } from "react";
import cn from "clsx";

import { LoaderTypes } from "./loaderTypes";
import { LOADER_TYPES } from "../../constants";

const Loader = ({
  type = LOADER_TYPES.RIPPLE,
  style = {},
  className = "",
  visible = false,
  hideAfter = 0,
  fullscreen = false,
  ...propsWithoutDefaultValues
}) => {
  const props = {
    type,
    style,
    className,
    visible,
    hideAfter,
    fullscreen,
    ...propsWithoutDefaultValues
  };
  const [isExpired, setIsExpiredStatus] = useState(false);

  useEffect(() => {
    let timerId;

    if (props.hideAfter > 0) {
      timerId = setTimeout(() => {
        setIsExpiredStatus(true);
      }, props.hideAfter);
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  });

  if (!props.visible || isExpired) {
    return null;
  }

  const LoaderComponent = LoaderTypes[props.type];

  return (
    <div className={cn("loader-wrapper", props.className)} style={props.style}>
      <LoaderComponent {...props} />
    </div>
  );
};

export default Loader;
