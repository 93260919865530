import React, { FC } from "react";
import cn from "clsx";
import get from "lodash/get";
import { BACKGROUND_TYPE, HOMEPAGE_SECTION_TYPES } from "@/constants";
import { getDeviceType, isServer } from "@/util";
import ProductCarousel from "../../product/productCarousel";
import { DynamicSlider } from "../dynamic-slider";
import CarouselWrapperRow from "../dynamic-carousel";
import DynamicModule from "../dynamic-module";
import { getPrevModeStorage } from "@/util/browserStorage";
import DynamicHTMLBanner from "../dynamic-html-banner";
import BannerClickAnalyticHandler from "../../banner/bannerClickAnalyticHandler";
import DynamicTab from "../dynamicTab/dynamicTab";
import DynamicList from "../dynamic-list";
import DynamicColumns from "../dynamicColumns";
import SingleBannerContainer from "../dynamicSingleBanner/singleBannerContainer";
import { useCountryShort, useIsRTL, useLongLanguage } from "@/hooks";
import { ContextStorage } from "@/libs/contextStorage";

import { BannerStyles, Content } from "@/routes/catalogPage/types";

import "./style.scss";

const BannerRow = ({
  rowData,
  bannerModuleName,
  bannerPageTitle,
  bannerSlug
}) => {
  const { imageList, styles, width } = rowData;

  const language = useLongLanguage();

  const rowStyles = {
    ...styles,
    width: width?.value || "100%",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    background: styles.backgroundColor || "none"
  };

  switch (rowData.bannerType) {
    case HOMEPAGE_SECTION_TYPES.DYNAMIC_CAROUSEL:
      return (
        <CarouselWrapperRow
          rowData={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
        />
      );

    case HOMEPAGE_SECTION_TYPES.SLIDER:
      return (
        <DynamicSlider
          section={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
        />
      );

    case HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL:
      return (
        <ProductCarousel
          content={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
          isSaleCarousel={false}
        />
      );

    case HOMEPAGE_SECTION_TYPES.CUSTOM:
      return <DynamicModule content={rowData} />;

    case HOMEPAGE_SECTION_TYPES.HTML:
      return (
        <DynamicHTMLBanner
          content={rowData}
          language={language}
          bannerSlug={bannerSlug}
        />
      );

    case HOMEPAGE_SECTION_TYPES.SINGLE:
      return (
        <SingleBannerContainer
          imageList={imageList}
          rowStyles={rowStyles}
          rowDataId={rowData.id}
          bannerModuleName={bannerModuleName}
          rowTitle={rowData.title}
          bannerPageTitle={bannerPageTitle}
        />
      );

    case HOMEPAGE_SECTION_TYPES.FLASH_SALE_CAROUSEL:
      return (
        <ProductCarousel
          content={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
          isSaleCarousel
        />
      );

    case HOMEPAGE_SECTION_TYPES.TAB:
      return <DynamicTab content={rowData} />;

    case HOMEPAGE_SECTION_TYPES.LIST:
      return <DynamicList content={rowData} rowStyles={rowStyles} />;

    case HOMEPAGE_SECTION_TYPES.COLUMN:
      return (
        <DynamicColumns
          rowData={rowData}
          bannerModuleName={bannerModuleName}
          bannerPageTitle={bannerPageTitle}
          rowStyles={rowStyles}
        />
      );

    default:
      return null;
  }
};

const getBackgroundForBanner = (styles: BannerStyles) => {
  switch (styles.backgroundType) {
    case BACKGROUND_TYPE.COLOR:
      return { background: styles.backgroundColor };

    case BACKGROUND_TYPE.GRADIENT:
      return { background: styles.backgroundGradient };
    case BACKGROUND_TYPE.IMAGE:
      return {
        backgroundImage: `url(${styles.backgroundImage})`
      };

    default:
      break;
  }
};

const DynamicBanner: FC<{ bannerData: Content; bannerPageTitle: string }> = ({
  bannerData,
  bannerPageTitle
}) => {
  // TODO: useUseSelector
  const deviceTypeSSR = get(
    ContextStorage.store.getState(),
    "common.deviceType",
    ""
  );
  const deviceType = deviceTypeSSR || getDeviceType();
  const { id, enable } = bannerData || {};
  const deviceSpecifiedData = bannerData[deviceType];
  if (!deviceSpecifiedData || !enable) return null;
  const { styles } = deviceSpecifiedData;
  const isPrevMode = getPrevModeStorage();
  const countrySlug = useCountryShort();
  const isRTL = useIsRTL();
  const background = getBackgroundForBanner(styles);
  const pageName = bannerPageTitle;
  const pageUrl = !isServer && window.location.href;
  const wrapperStyles = {
    ...styles,
    ...background
  };

  return (
    <div
      className={cn("dynamic_banner", {
        prevMode: isPrevMode,
        arabic: isRTL
      })}
      style={wrapperStyles}
    >
      {isPrevMode && (
        <div className="prev_mode_banner_name">{bannerData?.title || ""}</div>
      )}
      {deviceSpecifiedData.rows.map((rowData, index) => {
        const isFullBg = rowData.isFullBg;

        return (
          <BannerClickAnalyticHandler
            key={id + index}
            bannerModuleName={bannerData?.title}
            rowModuleName={rowData.title}
            countrySlug={countrySlug}
            pageName={pageName}
            pageUrl={pageUrl}
          >
            {isFullBg ? (
              <div
                style={{ background: rowData.styles.backgroundColor ?? "none" }}
              >
                <BannerRow
                  rowData={rowData}
                  bannerModuleName={bannerData?.title}
                  bannerPageTitle={pageName}
                  bannerSlug={bannerData?.slug}
                />
              </div>
            ) : (
              <BannerRow
                rowData={rowData}
                bannerModuleName={bannerData?.title}
                bannerPageTitle={pageName}
                bannerSlug={bannerData?.slug}
              />
            )}
          </BannerClickAnalyticHandler>
        );
      })}
    </div>
  );
};

const DynamicBannersWrapper: FC<{
  section: Content;
  bannerPageTitle?: string;
}> = ({ section, bannerPageTitle }) => (
  <DynamicBanner bannerData={section} bannerPageTitle={bannerPageTitle} />
);

export default DynamicBannersWrapper;
