import { pushGaProductClick, createObjForGA } from "../../util/analytics";
import get from "lodash/get";

class ProductAnalyticService {
  trackProductSearch = trackData => {
    const text = get(trackData, "text", "");
    const itemCount = get(trackData, "itemCount", "");
    const countryName = get(trackData, "countryName", "");
    pushGaProductClick.pushGa({
      event: "productSearch",
      search_keyword: text,
      item_count: itemCount,
      countryName
    });
  };

  trackProductImpressions = trackData => {
    const item = get(trackData, "_arr", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const page = get(trackData, "pageTitle", "");
    const category = get(trackData, "_category", "");
    const { ecommerce, outerObj } = createObjForGA.multipleItemsImpression(
      item,
      authReducer,
      commonSettings,
      page,
      category
    );
    pushGaProductClick.pushGaGTMProductImpressions(ecommerce, outerObj);
  };

  trackProductDetailImpressions = trackData => {
    const item = get(trackData, "item", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const recentlyViewed = get(trackData, "recentlyViewed", []);
    let impressionObject = createObjForGA.multipleItemsImpression(
      item,
      authReducer,
      commonSettings,
      "productDetails",
      "",
      recentlyViewed
    );
    const ecommerce = get(impressionObject, "ecommerce", {});
    const outerObj = get(impressionObject, "outerObj", {});
    const updatedRecentlyViewed = get(
      impressionObject,
      "updatedRecentlyViewed",
      {}
    );
    pushGaProductClick.pushGaGTMProductDetailsImpressions(
      ecommerce,
      outerObj,
      updatedRecentlyViewed
    );
  };

  trackProductListingDetailImpressions = trackData => {
    const item = get(trackData, "item", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const title = get(trackData, "title", "");
    const category = get(trackData, "category", "");
    const { ecommerce, outerObj } = createObjForGA.multipleItemsImpression(
      item,
      authReducer,
      commonSettings,
      title,
      category
    );
    pushGaProductClick.pushGaGTMProductClick1(ecommerce, outerObj);
  };

  trackMoveToProductDetail = trackData => {
    const item = get(trackData, "item", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce(
      item,
      authReducer,
      commonSettings
    );
    pushGaProductClick.pushGaProductClick1(ecommerce, outerObj);
    pushGaProductClick.pushGaGTMProductClick1(ecommerce, outerObj);
  };

  trackProductDetails = trackData => {
    const item = get(trackData, "response.data", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const productUrl = get(trackData, "location.pathname", "");
    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce(
      item,
      authReducer,
      commonSettings,
      "productDetails",
      null,
      productUrl
    );
    pushGaProductClick.pushGaProductClick2(ecommerce, outerObj);
    pushGaProductClick.pushGaProductClick2(ecommerce, {
      ...outerObj,
      page: "productView"
    });
  };

  trackProductPurchase = trackData => {
    const order = get(trackData, "order", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const checkout = get(trackData, "checkout", {});
    const orderItems = get(order, "items", []);
    const totalAmount = get(order, "transactionAmount", 0);
    const paymentMode = get(order, "paymentMode", []);
    const coupen = get(order, "discountType", "");
    const totalDiscount = get(order, "transactionDiscount", 0);
    const voucherCode = get(checkout, "voucherCode", "");
    const itemLength = get(order, "items.length", 0);
    const deliveryType = get(order, "deliveryType", "");

    const { ecommerce, outerObj, actionField } =
      createObjForGA.multipleNetCoreItems(
        orderItems,
        authReducer,
        commonSettings,
        "",
        order
      );

    pushGaProductClick.pushGaPurchase(
      ecommerce,
      {
        ...outerObj,
        total_amount: totalAmount,
        mode_of_payment: paymentMode,
        coupon_applied: coupen,
        coupon: coupen,
        total_discount: totalDiscount
      },
      {
        ...actionField,
        total_amount: totalAmount,
        mode_of_payment: paymentMode,
        coupon_applied: coupen,
        coupon: voucherCode,
        affiliation: "BFL Online Store",
        total_discount: totalAmount,
        total_items: itemLength,
        shipping_option: deliveryType,
        payment_type: paymentMode
      }
    );
  };

  trackContentListing = trackData => {
    const item = get(trackData, "item", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const title = get(trackData, "title", "");
    const category = get(trackData, "category", "");
    const productListCount = get(trackData, "productListCount", "");
    const { ecommerce, outerObj } = createObjForGA.multipleItemsImpression(
      item,
      authReducer,
      commonSettings,
      title,
      category,
      null,
      productListCount
    );
    pushGaProductClick.pushGaGTMProductImpressions(ecommerce, outerObj);
    pushGaProductClick.pushNTProductListing(ecommerce, outerObj);
  };

  trackProductQuickLook = ({ id, title, brandName }) => {
    pushGaProductClick.pushGa({
      event: "quickLook",
      productId: id,
      title,
      brandName
    });
  };
}

export default ProductAnalyticService;
