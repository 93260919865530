import { LISA_CLIENT_NAME, LISA_HUB_SDK_URL } from "../../constants";
import { addScriptToBody } from "../../util/dom";

const getLisaHubConfig = settings => ({
  alert: {
    countdown: {
      completed: "Starting soon",
      caption: "Going live in"
    },
    enabled: true,
    leadTime: 60 * 10, // 10 Minutes
    live: { caption: "Now live" },
    position: "bl", // 'bl' | 'br' | 'tl' | 'tr' | { top: 0, left: 0, right: 0, bottom: 0 }
    replay: { caption: "Watch again" },
    ...(settings?.alert && settings.alert)
  },
  client: LISA_CLIENT_NAME,
  language: settings.language,
  ...(settings?.on && { on: settings.on })
});

class LiSAHubService {
  isLoaded = false;
  sdk = null;

  init(settings) {
    window.LiSA = window.LiSA || {};
    if (!window.LiSA.sdk) {
      window.LiSA.sdk = { config: {} };
      this.script = addScriptToBody(LISA_HUB_SDK_URL, () => {
        this.isLoaded = true;
      });
      window.LiSA.sdk.config = getLisaHubConfig(settings);
      this.sdk = window.LiSA.sdk;
    }
  }
  updateSettings(settings) {
    //   settings:
    // {
    //   language?: LanguageCode;
    //   locale?: string;
    //   localisation?: LocalisationValue;
    //   store?: string;
    // }
    if (this.isLoaded) this.sdk.updateConfig(settings);
  }
}

const instance = new LiSAHubService();

export default instance;
