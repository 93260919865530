export enum FILTER_TYPES {
  CATEGORY = "category",
  PRICE = "price",
  SIZE = "size",
  MULTI_SELECT = "multiSelect",
  SINGLE_SELECT = "singleSelect"
}

export enum FILTER_QUERY_PARAMS {
  SORT = "sort",
  SIZE = "size"
}
