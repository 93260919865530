import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useLanguage } from "@/hooks";
import Loader from "../loader/loader";
import {
  deleteWishListData,
  saveToWishlist
} from "../../redux/actions/wishlist.action";
import {
  selectAuthReducer,
  selectCommonSettings,
  selectLisaMinimized
} from "../../util/selectors";
import {
  AnalyticService,
  LiSACommunicationService,
  LiSAService
} from "../../services";
import CartService, { Cart } from "../../services/cartService";
import {
  fetchCartData,
  getCartAndWishlistGeneralData
} from "../../redux/actions/cart.action";
import {
  setLisaMinimized,
  setLisaPlayerIsActive
} from "../../redux/actions/lisa.action";
import { toastMsg } from "@/util/toast";
import {
  getActionString,
  getItemDetails,
  getLisaMinimized,
  getRouteSlugStore,
  hasStringifiedData,
  parseItemDataFromString
} from "../../util/lisa";
import { commonLoaderStyle, LOADER_TYPES, TOAST_TYPES } from "../../constants";
import { isMobile, getWishListGeneralDataStore, isPresent } from "../../util";

export const lisaNavigate = ({ history, route, dispatch, library }) => {
  dispatch(setLisaMinimized(true));
  library.player.minimize();
  history.push(route);
};

export const LisaFrameConfigContainer = ({ customData, children }) => {
  const { t } = useTranslation("profile");
  const dispatch = useDispatch();
  const [lisaPlayer, setLisaPlayer] = useState(null);
  const [lisaLoading, setLisaLoading] = useState(false);
  const location = useLocation();
  const [initLocation] = useState(location.pathname);
  const history = useHistory();
  const authReducer = useSelector(selectAuthReducer);
  const commonSettings = useSelector(selectCommonSettings);
  const lisaMinimized = useSelector(selectLisaMinimized);
  const language = useLanguage();

  const getLisaSectionConfig = ({
    upcomingTitle,
    liveTitle,
    recordedTitle
  }) => [
    {
      limit: 3,
      order: -1,
      state: "live",
      tag: "LIVE",
      title: liveTitle || t("currentlyLive")
    },
    {
      limit: 5,
      order: 1,
      state: "preShow",
      tag: "UPCOMING",
      title: upcomingTitle || t("upcomingShows")
    },
    {
      limit: 10,
      order: -1,
      state: "replay",
      tag: "RECORDED",
      title: recordedTitle || t("discoverMoreLivestreams")
    }
  ];

  const saveItemToWishList = async item => {
    const response = await dispatch(saveToWishlist({ data: item }));
    AnalyticService.cart.trackAddingToWishlist({
      item,
      wishlistData: getWishListGeneralDataStore(),
      authReducer,
      commonSettings
    });

    dispatch(getCartAndWishlistGeneralData());
    return response;
  };

  const deleteFromWishList = async (productId, size = "") => {
    const wishListBaseData = getWishListGeneralDataStore();
    const wishListItem =
      !isEmpty(wishListBaseData) &&
      wishListBaseData.find(
        item => item.productId === productId && item.size === size
      );
    await dispatch(
      deleteWishListData({
        productId,
        wishlistItemId: (wishListItem && wishListItem._id) || ""
      })
    );
    dispatch(getCartAndWishlistGeneralData());

    AnalyticService.cart.trackDeleteWishlist({
      item: wishListItem,
      wishList: wishListBaseData,
      authReducer,
      commonSettings
    });
  };

  const lisaConfigure = () => {
    const { upcomingTitle, liveTitle, recordedTitle, ...otherData } =
      customData;
    LiSAService.libReInit({
      language: language,
      sections: getLisaSectionConfig({
        upcomingTitle,
        liveTitle,
        recordedTitle
      }),
      ...otherData,
      getPlayerSettings: library => {
        setLisaLoading(false);
        setLisaPlayer(library.player);
        return {
          [library.player.Event.PRODUCT_VIEW]: data => {
            const urlString = getActionString(data);
            const url = hasStringifiedData(urlString)
              ? parseItemDataFromString(urlString).url
              : urlString;
            const routeSlug = getRouteSlugStore();
            if (url) {
              const route = `/${routeSlug}${url}`;
              lisaNavigate({ dispatch, history, route, library });
            }
          },
          [library.player.Event.CART_VIEW]: url => {
            const routeSlug = getRouteSlugStore();
            const route = `/${routeSlug}${url}/`;
            lisaNavigate({ dispatch, history, route, library });
          },
          [library.player.Event.PRODUCT_ADD_TO_CART]: data => {
            const actionString = getActionString(data);
            const itemData = hasStringifiedData(actionString)
              ? parseItemDataFromString(actionString)
              : {};
            const [productId, sizeId] = [
              Number(itemData.productId || data.id),
              Number(itemData.sizeId || data.variantId || 0)
            ];
            const productData = [
              {
                productId,
                stockId: sizeId,
                quantity: 1
              }
            ];
            (async () => {
              try {
                const response = await CartService.repopulateCart({
                  productData,
                  countryId: commonSettings.countryId
                });
                if (response?.success) {
                  const cartData = (await dispatch(
                    fetchCartData(true)
                  )) as unknown as {
                    errors: any;
                    products: Cart;
                  };

                  const itemCount = cartData?.products?.find(
                    item =>
                      item.productId === productId &&
                      (sizeId ? sizeId === item.sizeId : true)
                  )?.quantity;

                  itemCount &&
                    LiSACommunicationService.sendFailedItemAdding(itemCount);
                  const isLisaMinimized = getLisaMinimized();
                  if (isLisaMinimized || isMobile.any()) {
                    toastMsg(TOAST_TYPES.SUCCESS, t("itemAddedInCart"), true);
                  }
                }
              } catch (e) {
                const errorMessage = `Something went wrong. The server said:${
                  e.response?.data?.message || e.message || "noting useful"
                }. The product is not added`;

                return toastMsg(TOAST_TYPES.ERROR, errorMessage, true);
              }
            })();
          },
          [library.player.Event.PRODUCT_GRANT_REACTION]: data => {
            const { isMultisize, productId, size, sizeId, sizeType } =
              getItemDetails(data);
            const itemData = {
              isMultisize,
              productId,
              sizeId,
              size: size || "",
              sizeType: sizeType || null
            };
            if (!isPresent(itemData)) {
              (async () => {
                const response = await saveItemToWishList(itemData);
                if (response && response instanceof Error) {
                  const errorMessage = `Something went wrong. The server said:${
                    //@ts-ignore
                    response.response?.data?.message ||
                    response.message ||
                    "noting useful"
                  }. The product is not added`;

                  return toastMsg(TOAST_TYPES.ERROR, errorMessage, true);
                }
                const isLisaMinimized = getLisaMinimized();
                if (isLisaMinimized || isMobile.any()) {
                  toastMsg(TOAST_TYPES.SUCCESS, t("itemAddedInWishlist"), true);
                }
              })();
            }
          },
          [library.player.Event.PRODUCT_REVOKE_REACTION]: data => {
            const itemData = {
              size: "",
              sizeType: null,
              ...getItemDetails(data)
            };
            if (isPresent(itemData)) {
              deleteFromWishList(itemData.productId, itemData.size);
              const isLisaMinimized = getLisaMinimized();
              if (isLisaMinimized || isMobile.any()) {
                toastMsg(TOAST_TYPES.ERROR, t("itemRemovedFromWishlist"), true);
              }
            }
          },
          [library.player.Event.BANNER_VIEW]: data => {
            const urlString = getActionString(data);
            const url = hasStringifiedData(urlString)
              ? parseItemDataFromString(urlString).url
              : urlString;
            const routeSlug = getRouteSlugStore();
            if (url) {
              const route = `/${routeSlug}${url}`;
              lisaNavigate({ dispatch, history, route, library });
            }
          },
          [library.player.Event.SHOW_START]: onShowStatusChange,
          [library.player.Event.SHOW_STOP]: onShowStatusChange,
          [library.player.Event.CLOSE]: () => {
            setTimeout(() => dispatch(setLisaPlayerIsActive(false)), 1000);
          }
        };
      },
      getBackdropSettings: library => {
        return {
          [library.player.library.backdrop.Event.CLICK]: () =>
            library.player.minimize()
        };
      }
    });
  };

  const onShowStatusChange = () => {
    const sameLocation = initLocation === window.location.pathname;
    sameLocation && reInitLisaWithDelay();
  };

  const reInitLisaWithDelay = () => {
    setTimeout(() => lisaConfigure(), 1000);
  };

  useEffect(() => {
    lisaMinimized && dispatch(setLisaMinimized(false));
    return () => {
      if (lisaPlayer) {
        dispatch(setLisaMinimized(true));
        dispatch(setLisaPlayerIsActive(true));
        lisaPlayer.minimize();
      }
    };
  }, [lisaPlayer]);

  useEffect(() => {
    setLisaLoading(true);
    lisaConfigure();
  }, []);

  return lisaLoading ? (
    <>
      <Loader
        type={LOADER_TYPES.DOTS}
        size={16}
        qty={3}
        visible
        style={commonLoaderStyle}
      />
      {children}
    </>
  ) : (
    children
  );
};
