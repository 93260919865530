import qs from "query-string";
import { AxiosResponse } from "axios";

import ApiService from "./apiService";

import { BEConfig } from "../config/env";

import { webapiGet, webapiPost } from "../webapis/core";

class LoyaltyPointsService extends ApiService {
  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }

  isArrayDataValid = array =>
    array.every(
      el => (el.data && el.data.success) || el.data.success === false
    );

  async getLoyaltyExpiryDetails(countryId, currencyCode, language) {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.loyaltyExpiryDetails
    }`;

    const queryParams = qs.stringify({
      countryId,
      currencyCode
    });

    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId(),
      language
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response.data;
  }

  async getLoyaltyEarnedPoints(countryId, currencyCode, language) {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.loyaltyEarnedPoints
    }`;

    const queryParams = qs.stringify({
      countryId,
      currencyCode
    });

    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId(),
      language
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response.data;
  }

  async registerLoyaltyProgram(bodyData) {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.registerLoyaltyProgramUser
    }`;

    const response = await webapiPost(this.getAccessToken(), apiURL, bodyData)
      .request;

    if (!response.data.success) throw new Error(JSON.stringify(response.data));

    return response.data;
  }

  async getLoyaltyDetails(language: Language) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.loyaltyDetails
    }`;

    const response: AxiosResponse<GetLoyaltyDetailsResponse> = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId(),
      language
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response.data.data;
  }

  async getLoyaltyPointsTable(language, pageNum, pageSize, countryId) {
    const apiURL = `${this.getBaseUrl()}${BEConfig.customerApi.loyaltyPoints}`;

    const queryParams = qs.stringify({
      pageSize,
      pageNum,
      countryId
    });

    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId(),
      language
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response.data;
  }

  async redeemLoyaltyPoints(bodyData) {
    const apiURL = `${this.getBaseUrl()}${BEConfig.customerApi.loyaltyPoints}`;

    const response = await webapiPost(this.getAccessToken(), apiURL, bodyData)
      .request;

    if (!response.data.success) throw new Error(response.data.message);

    return response;
  }

  async getWalletHistory(currencyCode, countryId, language, pageNum) {
    const queryParams = qs.stringify({
      currencyCode,
      countryId
    });
    const walletHistoryEndpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.wallet
    }?pageSize=4&pageNum=${pageNum}&${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      walletHistoryEndpoint,
      this.getVisitorId(),
      language
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response;
  }

  async getWalletBalance(currencyCode, countryId, language) {
    const queryParams = qs.stringify({
      currencyCode,
      countryId
    });
    const walletBalanceEndpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.walletBalance
    }?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      walletBalanceEndpoint,
      this.getVisitorId(),
      language
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response;
  }

  async getMyWalletDetails(currencyCode, countryId, language, pageNum) {
    const response = await Promise.all([
      this.getWalletHistory(currencyCode, countryId, language, pageNum),
      this.getWalletBalance(currencyCode, countryId, language)
    ]);

    if (!this.isArrayDataValid(response)) {
      // TODO: fix ts error
      // @ts-ignore
      throw new Error(response);
    }

    return response;
  }

  async getWalletExpiryDetails(countryId, currencyCode, language) {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.walletExpirationDetails
    }`;

    const queryParams = qs.stringify({
      countryId,
      currencyCode
    });

    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId(),
      language
    ).request;

    if (!response.data.success) throw new Error(response.data.message);

    return response.data;
  }

  async downloadInvoice(bodyData) {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.downloadLoyaltyInvoice
    }`;

    try {
      const { data: responseData } = await webapiPost(
        this.getAccessToken(),
        apiURL,
        bodyData
      ).request;

      if (responseData?.data && responseData?.code === 200) {
        const file = responseData.data.invStr;
        const linkSource = `data:application/pdf;base64,${file}`;
        const downloadLink = document.createElement("a");
        const fileName = `${bodyData.invoiceNo}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        return { ...responseData, status: true };
      } else {
        return { ...responseData, status: false };
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export type GetLoyaltyDetailsResponse = {
  success: boolean;
  message: string;
  data: LoyaltyDetailsData;
};

export type LoyaltyDetailsData = {
  // null - if we are not registered in the loyalty program
  loyaltyDetails: LoyaltyDetails | null;
  tierToken: string;
};

export type LoyaltyDetails = {
  cardNumber: string;
  memeberLevel: string;
  currentBalance: string;
  pointsValue: string;
  pointNeededForNextLevel: number;
  usedPointsToConvert: number;
  earnedAmountFromConvert: number;
  accuralPoints: number;
  currency: string;
  cutoffDate: Date;
};

const instance = new LoyaltyPointsService();

export default instance;
