import ApiService from "./apiService";
import { BEConfig } from "../config/env";
import { fetchWebApi } from "../webapis/apiResource";
import { AxiosResponse } from "axios";

class BrandsService extends ApiService {
  public async getBrandsData(): Promise<Brand[]> {
    const response = (await fetchWebApi(
      this.getAccessToken(),
      this.getBaseUrl(),
      this.getVisitorId(),
      this.getLanguage()
    )) as AxiosResponse<Brand[]>;

    return response.data;
  }

  private getBaseUrl() {
    const {
      productsApi: { baseURL, protocol, port, versionInfo, brandsPageHandle }
    } = BEConfig;

    return `${protocol}${baseURL}${port}${versionInfo}${brandsPageHandle}`;
  }
}

export type Brand = {
  category: CommonAlgoliaValue;
  brand: CommonAlgoliaValue;
};

export type CommonAlgoliaValue = {
  key: string;
  defaultValue: string;
  value: string;
};

const instance = new BrandsService();

export default instance;
