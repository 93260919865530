import React, { FC } from "react";
import LazyLoad from "react-lazy-load";
import Img from "react-image";

import defaultImageSrc from "../../images/default.jpg";
import defaultProductSrc from "@/images/product_default.gif";
import defaultLogoSrc from "../../images/default-logo.svg";

import { Props } from "./types";

export const Image: FC<Props> = ({
  lazyLoad = false,
  showDefaultImage,
  showNewLogoDefaultImage,
  loader,
  id,
  src,
  ...props
}) => {
  let imageSrc = defaultImageSrc;

  if (showDefaultImage) {
    imageSrc = defaultProductSrc;
  } else if (showNewLogoDefaultImage && id == "main_header_logo") {
    imageSrc = defaultLogoSrc;
  }

  const placeholder = <img src={imageSrc} alt="default" className="w-100" />;

  const imgContent = (
    <Img
      {...(props as any)}
      decode={false}
      loader={loader || placeholder}
      src={src}
    />
  );

  return (
    <>
      {src ? (
        lazyLoad ? (
          <LazyLoad
            debounce
            offsetTop={500}
            offsetBottom={500}
            className="lazy-load-wrapper"
          >
            {imgContent}
          </LazyLoad>
        ) : (
          imgContent
        )
      ) : (
        placeholder
      )}
    </>
  );
};
