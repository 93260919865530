import React, { useEffect, useState } from "react";
import find from "lodash/find";
import { useSelector } from "react-redux";
import { selectCustomerId, selectLanguageSlug } from "../../../util/selectors";
import { POST_MESSAGE } from "@/constants";

const DynamicGamification = ({ bannerData, generalData }) => {
  const [isIframeError, setIsIframeError] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(false);

  const src = (find(bannerData, { key: "iframeURL" }) || {}).value;

  if (!src) {
    return null;
  }
  const languageSlug = useSelector(selectLanguageSlug);
  const customerId = useSelector(selectCustomerId);

  const modifiedSrc = `${src}?lang=${languageSlug}&userId=${customerId}`;
  const styles = {
    ...generalData.styles,
    maxWidth: generalData.width?.value || "100%",
    margin: `${generalData.styles.marginTop}px auto ${generalData.styles.marginBottom}px`,
    height: "90vh"
  };

  useEffect(() => {
    const setMessageHandler = event => {
      if (event.data.type === POST_MESSAGE.IFRAME_ERROR) {
        setIsIframeError(true);
      }
    };
    window.addEventListener("message", setMessageHandler);

    return () => {
      window.removeEventListener("message", setMessageHandler);
    };
  }, []);

  useEffect(() => {
    const checkUrl = () => {
      try {
        new URL(modifiedSrc);
        setIsUrlValid(true);
      } catch (error) {
        setIsIframeError(true);
      }
    };

    checkUrl();
  }, [modifiedSrc]);

  return !isIframeError && isUrlValid ? (
    <div className="gamification_wrapper" style={styles}>
      <iframe
        width="100%"
        height="100%"
        src={modifiedSrc}
        style={{ border: "none", overflow: "none" }}
      />
    </div>
  ) : null;
};

export default DynamicGamification;
