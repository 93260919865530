export const GENERIC_SIZE_CHART = {
  clothing: "Clothing",
  imageBlock1: "Image Block 1",
  imageBlock2: "Image Block 2",
  imageBlock3: "Image Block 3"
};

export const GENERIC_SIZE_CHART_KEYS = {
  Clothing: "clothing",
  "Image Block 1": "imageBlock1",
  "Image Block 2": "imageBlock2",
  "Image Block 3": "imageBlock3"
};
