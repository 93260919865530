import React from "react";
import find from "lodash/find";
import StorylyStory from "./StorylyStory";

export const StorylyBanner = ({ bannerData, generalData }) => {
  const token = (find(bannerData, { key: "token" }) || {}).value;

  if (!token) return null;

  const { styles } = generalData;
  return (
    <div style={styles}>
      <StorylyStory token={token} />
    </div>
  );
};
