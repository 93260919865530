import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";

const useSelectedByQuery = () => {
  const { search } = useLocation();

  const selected = useMemo(() => {
    return Object.entries(qs.parse(search, { decode: false })).reduce(
      (acc, [key, value]) => {
        acc[key] = {
          queryParam: key,
          selectedOptions: (Array.isArray(value) ? value : [value]).map(
            option => ({
              key: option
            })
          )
        };
        return acc;
      },
      {}
    );
  }, [search]);

  return selected;
};

export default useSelectedByQuery;
