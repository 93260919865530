import React, { useEffect } from "react";
import cn from "clsx";
import { withSyteApiStatus } from "@/hocs";
import SyteCameraIcon from "@/images/syte-camera.svg";
import SyteCameraMobileIcon from "@/images/syte-camera-mobile.svg";
import "./style.scss";

const SyteSearchButton = withSyteApiStatus(
  ({
    className,
    mobile = false,
    children,
    onButtonRender,
    syteAppIsReady,
    ...otherProps
  }) => {
    useEffect(() => {
      if (syteAppIsReady) {
        onButtonRender && onButtonRender();
      }
    }, [syteAppIsReady]);

    return (
      syteAppIsReady && (
        <button
          className={cn(
            "--syte-start-camera-upload",
            "btn btn-link syte-search-btn",
            className
          )}
          data-camera-button-placement="search-bar"
          {...otherProps}
        >
          <img
            src={mobile ? SyteCameraMobileIcon : SyteCameraIcon}
            alt="search by image"
          />
          {children}
        </button>
      )
    );
  }
);

export default SyteSearchButton;
