import reduce from "lodash/reduce";
import { escapeRegExpSpecialCharacters } from "../regExp";

export const templateHtmlParser = (html, replaceKeysMap) =>
  reduce(
    replaceKeysMap,
    (acc, value, key) => {
      const regExp = new RegExp(escapeRegExpSpecialCharacters(key), "g");
      acc = acc.replace(regExp, value);
      return acc;
    },
    html
  );
