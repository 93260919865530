import ErrorPage from "../errorPage";
import ProductDetailPage from "../pdp";
import SignUp from "../signup";
import LogIn from "../login";
import ThankYou from "../thankYou";
import ForgotPassword from "../forgot-password";
import CatalogPage from "../catalogPage";
import Wishlist from "../wishlist";
import Cart from "../cart";
import Checkout from "../checkout";
import ShopByBrands from "../shopByBrands";
import OrderSuccess from "../orderSuccess";
import OrderSuccessV2 from "../orderSuccessV2";
import HelpDetails from "../help/helpDetailsIndex";
import Help from "../help";
import MyAccount from "../my-account";
import PaymentError from "../paymentError";
import PaymentErrorV2 from "../paymentErrorV2";
import SendUsEmail from "../sendUsEmail";
import SizeChart from "../sizeChart";
import StoreLocator from "../storeLocator";
import TrackOrder from "../trackOrder";
import RepopulateCart from "../rePopulateCart";
import PaymentProcessing from "../paymentProcessing";
import DynamicPage from "../dynamicPage";

export const routes = [
  {
    path: "/:language/error-page",
    component: ErrorPage
  },
  {
    exact: true,
    path: "/:language/sign-up",
    component: SignUp
  },
  {
    exact: true,
    path: "/:language/login",
    component: LogIn
  },
  {
    exact: true,
    path: "/:language/registration/thank-you",
    component: ThankYou
  },
  {
    exact: true,
    path: "/:language/forgot-password",
    component: ForgotPassword
  },
  {
    path: "/:language/:productName/:id/p",
    component: ProductDetailPage
  },
  {
    path: "/:language/category/:categoryName",
    component: CatalogPage
  },
  {
    exact: true,
    path: "/:language/wishlist",
    component: Wishlist
  },
  {
    exact: true,
    path: "/:language/my-cart",
    component: Cart
  },
  {
    path: "/:language/checkout",
    component: Checkout,
    exact: true
  },
  {
    path: "/:language/order-success/:orderId",
    component: OrderSuccess
  },
  {
    path: "/:language/order/success/",
    component: OrderSuccessV2
  },
  {
    path: "/:language/order/failed/",
    component: PaymentErrorV2
  },
  {
    exact: true,
    path: "/:language/need-help/tracking-shipping-and-delivery",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/loyalty-program",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/product-and-stock",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/payment-and-vouchers",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/order-issues",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help/general",
    component: HelpDetails
  },
  {
    exact: true,
    path: "/:language/need-help",
    component: Help
  },
  {
    exact: true,
    path: "/:language/shop-by-brands",
    component: ShopByBrands
  },
  {
    exact: true,
    path: "/:language/brands",
    component: ShopByBrands
  },
  {
    exact: true,
    path: "/:language/size-chart",
    component: SizeChart
  },
  {
    exact: true,
    path: "/:language/store-locator",
    component: StoreLocator
  },
  {
    exact: true,
    path: "/:language/my-account/account-overview",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-details",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-orders",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-address-book",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/my-wallet",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/loyalty-program",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/saved-cards",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/my-account/redeem-gift-card",
    component: MyAccount
  },
  {
    exact: true,
    path: "/:language/send-us-email",
    component: SendUsEmail
  },
  {
    path: "/:language/payment-error",
    component: PaymentError
  },
  {
    path: "/:language/payment-processing/",
    component: PaymentProcessing
  },
  {
    exact: true,
    path: "/:language/track-order",
    component: TrackOrder
  },
  {
    exact: false,
    path: "/:language/re-populate-cart",
    component: RepopulateCart
  },
  {
    path: "/:language",
    component: DynamicPage
  }
];
