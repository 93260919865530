import React, { FC } from "react";
import get from "lodash/get";
import filter from "lodash/filter";
import { Link } from "react-router-dom";
import { isDateInFuture, isDateInPast } from "../../util/common";
import { useRouteSlug } from "@/hooks";
import { MenuBannerType } from "./categoryMenu";

const MenuBanner: FC<{ col: MenuBannerType }> = ({ col }) => {
  const { type, images } = col;
  const schedulers = get(col, "schedulers", []);
  const enabledSchedulers = filter(schedulers, "enabled");
  const routeSlug = useRouteSlug();
  const activeScheduler = enabledSchedulers.find(
    scheduler => isDateInPast(scheduler.from) && isDateInFuture(scheduler.to)
  );

  const megaMenuImages = activeScheduler ? activeScheduler.images : images;
  return (
    !!images && (
      <div className="menuWrapper">
        {megaMenuImages.map(img => (
          <div
            className={`${type + img.className + ` order-` + img.pos}`}
            key={img.pos}
            onClick={e => {
              if (e) {
                const parent = e.currentTarget.offsetParent.parentElement;
                parent.parentElement.nextElementSibling &&
                  parent.parentElement.nextElementSibling.classList.remove(
                    "active"
                  );
                parent.classList.contains("active-li") &&
                  parent.classList.remove("active-li");
              }
            }}
          >
            <Link to={`/${routeSlug}${img.redirectionUrl}`}>
              <img src={img.imageUrl} alt={img.alt} loading="lazy" />
            </Link>
          </div>
        ))}
      </div>
    )
  );
};
export default MenuBanner;
