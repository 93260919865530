import React, { FC } from "react";
import cn from "clsx";
import ModalBFL from "../modal/modal";
import { useIsRTL } from "../../hooks";
import { DialogModalProps } from "./type";

const DialogModal: FC<DialogModalProps> = ({
  titleText,
  bodyText,
  confirmButtonText,
  cancelButtonText,
  showModal,
  onCancel,
  onConfirm
}) => {
  const isRTL = useIsRTL();

  return (
    <ModalBFL
      showModal={showModal}
      modalClass={cn("generic_modal dialog_modal", { arabic: isRTL })}
    >
      <div className="dialog_modal_content">
        {titleText && <h4>{titleText}</h4>}
        {bodyText && <p>{bodyText}</p>}
        <div className="action_btn">
          <button onClick={onCancel}>{cancelButtonText}</button>
          {confirmButtonText && (
            <button onClick={onConfirm}>{confirmButtonText}</button>
          )}
        </div>
      </div>
    </ModalBFL>
  );
};

export default DialogModal;
