import { useMemo } from "react";

const useSelectedByResponse = list => {
  const selected = useMemo(() => {
    return list.filter(item => item.isSelected);
  }, [list]);

  return selected;
};

export default useSelectedByResponse;
