import AuthAnaltyicsEvent from "../../services/analytics/main/authentication";
import CommonAnalyticsEvent from "../../services/analytics/main/common";

export const handleActionToSignPages = (toLogin: boolean = false) => {
  AuthAnaltyicsEvent.actionToAuthPage({
    isLogin: !!toLogin
  });
};

export const handleOtpValidationResponse = (isValidOTP = null) => {
  if (isValidOTP) {
    CommonAnalyticsEvent.validatedOTP();
  }
};
// export const getFormErrors = (errors, isFormReady) => {
//     const errorKeys = Object.keys(errors);
//     let errorStatus = errorKeys.some(key => errors[key] !== "");
//     if (errorStatus && ready) {
//       const errorsObj = errorKeys.reduce((acc, name) => {
//         acc[name] = errors[name];
//         return acc;
//       }, {});
//     }
// }
