import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import CustomCountdown from "@/components/customCountdown";
import CountdownBanner from "./countdownBanner";
import { CountDownContentType } from "../types";

type CountdownWrapperProps = {
  content: CountDownContentType;
  imageListLength: number;
  removeBannerWrapper: () => void;
  removeCountdown: (id: string) => void;
};

const CountdownWrapper: FC<CountdownWrapperProps> = ({
  content,
  removeBannerWrapper,
  imageListLength,
  removeCountdown
}) => {
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const { styles, date, styleType } = content;
  const countdownStyles = {
    ...styles,
    width: imageListLength <= 2 ? `${100 / imageListLength}%` : "50%",
    justifyContent: content.countdownOption
  };

  useEffect(() => {
    const countdownOver = moment().isAfter(content.date);
    setIsCountdownOver(countdownOver);
  }, []);

  const onCountdownCopmlete = () => {
    if (imageListLength !== 1) {
      removeCountdown(content.id);
    }
    setIsCountdownOver(true);
  };

  return (
    <div
      className="catalog_banner_section"
      style={countdownStyles}
      itemID={`countdown-${content.id}`}
    >
      <div
        id="bfl-countdown"
        className="bfl-countdown"
        data-date={date}
        data-type="0"
      >
        <CustomCountdown
          date={date}
          type={styleType}
          onCountdownComplete={onCountdownCopmlete}
          content={content}
        />
        <CountdownBanner content={content} isCountdownOver={isCountdownOver} />
      </div>
    </div>
  );
};

export default CountdownWrapper;
