import { BEConfig } from "../config/env";
import { getGuestAccessToken } from "../util/storeHelper";
import { webapiPost } from "../webapis/core";
import ApiService from "./apiService";
import { ContextStorage } from "@/libs/contextStorage";

class GuestService extends ApiService {
  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;

    return `${protocol}${baseURL}${port}${versionInfo}`;
  }

  async guestRegistration(body) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.guestUserHandle
    }`;

    const response = await webapiPost(
      getGuestAccessToken(ContextStorage.store.getState),
      endpoint,
      body
    ).request;
    return response;
  }

  async completeRegistration(body) {
    const endpoint = `${this.getBaseUrl()}${
      BEConfig.customerApi.guestUserHandle
    }/complete`;

    const response = await webapiPost(
      getGuestAccessToken(getState),
      endpoint,
      body
    ).request;
    return response;
  }
}

const instance = new GuestService();

export default instance;
