import { AxiosResponse } from "axios";
import ApiService from "./apiService";
import { BEConfig } from "../config/env";
import { webapiGet, webapiDelete, webapiPost } from "../webapis/core";
import { ContextStorage } from "@/libs/contextStorage";
import { selectCurrency } from "../util/selectors";
import { fetchCartData, saveToCart } from "../redux/actions/cart.action";

import { Product } from "./productService";
import { DELIVERY_TYPES } from "@/constants";

class CartService extends ApiService {
  async fetchCartData() {
    const response = (await webapiGet(
      this.getAccessToken(),
      this.getBaseUrl(),
      this.getVisitorId(),
      this.getLanguage()
    ).request) as AxiosResponse<{ errors: any; products: Cart }>;

    return response.data;
  }

  async deleteCartData(productId, id) {
    const endpoint = `${this.getBaseUrl()}/${productId}/${id}`;

    const response = await webapiDelete(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response;
  }

  async repopulateCart({ productData, countryId }) {
    const endpoint = `${this.getBaseUrl()}/repopulate`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { productData, countryId },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  getBaseUrl() {
    const { baseURL, protocol, port, cartPageHandle, versionInfo } =
      BEConfig.wishListApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}${cartPageHandle}`;

    return url;
  }

  getCurrency() {
    const state = ContextStorage.store.getState();
    return selectCurrency(state);
  }

  changeCartItemQuantity(quantity, item) {
    const body = { quantity, ...item };

    ContextStorage.store
      .dispatch(saveToCart(body, false))
      .then(resp => {
        if (resp && resp.data.success) {
          ContextStorage.store.dispatch(fetchCartData(true, false));
        }
      })
      .catch(console.error);
  }

  async getCartAndWishlistGeneralData() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.wishListApi;

    const endpoint = `${protocol}${baseURL}${port}${versionInfo}count`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response;
  }

  async getCartSummary({
    //DELIVERY_TYPES.DELIVERY_ADDRESS should be replaced by "" but only after the BE is updated to handle it
    deliveryType = DELIVERY_TYPES.DELIVERY_ADDRESS
  }: OrderSummaryRequestBody): Promise<OrderSummary> {
    const { baseURL, protocol, port, checkoutHandle, versionInfo } =
      BEConfig.checkoutApi;

    const endpoint = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}/summary`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { deliveryType },
      this.getVisitorId()
    ).request;

    return response.data;
  }

  validateCheckout = async (deliveryType: DELIVERY_TYPES) => {
    const { baseURL, protocol, port, checkoutHandle, versionInfo } =
      BEConfig.checkoutApi;
    const endpoint = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}/validate-checkout`;
    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      { deliveryType },
      this.getVisitorId()
    ).request;

    return response.data;
  };
}

export type CartProduct = Product & {
  _id: string;
  productId: number;
  quantity: number;
  size: string;
  sizeId: number;
  sizeType: string;
  visitorId?: string;
  errors: any;
  url?: string;
};

export type Cart = CartProduct[];

export type OrderSummaryRequestBody = {
  deliveryType: string;
};

export type PriceDetails = {
  currency: string;
  discountAmount: number;
  discountAmountLabel: string;
  shippingCharge: number;
  shippingChargeLabel: string;
  subTotal: number;
  subTotalLabel: string;
  totalPrice: number;
  totalPriceLabel: string;
  walletAmount: number;
  walletAmountLabel: string;
};

export type OrderSummary = {
  deliveryDetails: OrderSummaryDeliveryDetails[];
  priceDetails: PriceDetails;
};

export type OrderSummaryDeliveryDetails = {
  deliveryType: DeliveryType;
  paymentTypes: PaymentType[];
  orderAmount: number;
  messages: string[];
  freeShippingMessage: string;
  title: string;
  shippingCharge: number;
  freeShippingLimit: number;
};

export type DeliveryType = {
  key: DELIVERY_TYPES;
  label: string;
  defaultValue: string;
};

export type PaymentType = {
  paymentType: string;
  currencyCode: string;
  paymentGateway: string;
  tier: string;
  sortOrder: number;
  title: string;
  description: string;
  detailedDescription?: string;
};

const instance = new CartService();

export default instance;
