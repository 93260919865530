import get from "lodash/get";
import commaNumber from "comma-number";

export const formatPriceByDecimal = (price, decimalPlace = 2) => {
  let _price = Number(price) || 0;
  return _price % 1 === 0 ? _price : _price.toFixed(decimalPlace);
};

export const getDiscountPercent = item => {
  const itemPrice = item?.price;
  let discount = Math.round(
    ((itemPrice?.old - itemPrice?.current) / itemPrice?.old) * 100
  );
  switch (true) {
    case discount >= 26 && discount <= 29:
      discount = 30;
      break;
    case discount >= 46 && discount <= 49:
      discount = 50;
      break;
    case discount >= 71 && discount <= 74:
      discount = 75;
      break;
    default:
      break;
  }

  return discount;
};

export const convertDiscountPriceByRoundType = (amount, countrySettings) => {
  const roundType = get(countrySettings, "roundType", "");

  switch (roundType) {
    case "CEIL500":
      return Math.ceil(amount / 500) * 500;
    default:
      return amount;
  }
};

export const convertWithComma = price => commaNumber(price);

export const shapePriceWithComma = (price, decimalPlace = 2) => {
  const _price = formatPriceByDecimal(price, decimalPlace);

  try {
    return commaNumber(_price);
  } catch (error) {
    return _price;
  }
};

export const getItemDiscountPersentage = (item, language) => {
  const percentage = getDiscountPercent(item);
  const isArabic = language.includes("ar");
  const percentageDiscount = isArabic ? `%${percentage}-` : `-${percentage}%`;

  return { percentageDiscount, percentage };
};
