import React from "react";
import cn from "clsx";
import { Link } from "react-router-dom";
import { hideMegaMenu, highlight } from "../../util/dom";
import CommonAnalyticsEvent from "../../services/analytics/main/common";
import { useRouteSlug } from "@/hooks";

const List = ({ item }) => {
  const {
    subTitle,
    redirectUrl,
    color: { value },
    bold
  } = item;
  const routeSlug = useRouteSlug();
  const linkURL = `/${routeSlug}${redirectUrl}`;
  return (
    <li
      onClick={e => {
        const searchInput = document.querySelector(
          "#search_input"
        ) as HTMLInputElement;
        searchInput.value = "";
        highlight(e);
      }}
    >
      <Link to={linkURL}>
        <span
          className={cn(
            {
              "font-weight-bold": bold
            },
            "list-text"
          )}
          style={value && { color: value }}
          onClick={e => {
            hideMegaMenu(e);
            CommonAnalyticsEvent.lvl2MenuClick({
              item,
              url: linkURL
            });
          }}
        >
          {subTitle}
        </span>
      </Link>
    </li>
  );
};

export default List;
