import React from "react";
import { LisaFrameConfigContainer } from "./LisaFrameConfigContainer";
import { LisaLibrary } from "./LisaLibrary";
import "./styles.scss";

export const LisaBanner = ({ bannerData, generalData }) => {
  const { styles, width } = generalData;
  const containerStyle = {
    maxWidth: width?.value || "100%",
    marginLeft: "auto",
    marginRight: "auto",
    ...styles
  };
  const customData = bannerData.reduce((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
  return (
    <div style={containerStyle}>
      <LisaFrameConfigContainer customData={customData}>
        <LisaLibrary />
      </LisaFrameConfigContainer>
    </div>
  );
};
