import {
  STORE_LOCATOR_LIST,
  CHANGE_SELECTED_COUNTRY,
  CHANGE_SELECTED_SHOP
} from "../constants";

const initialState = {
  storeCountry: [],
  selectedCountry: "",
  selectedShop: {},
  locatorList: [],
  cityList: [],
  otherInfo: ""
};

const getSelectedShops = (locatorList, selectedCountry) => {
  return locatorList
    .filter(item => item.country === selectedCountry)
    .map(item => {
      item.shops.forEach(shop => {
        shop.en_country = item.en_country;
        shop.country = item.country;
      });
      return item;
    });
};

const storeLocatorList = (state, { locatorList, countryId, countryData }) => {
  const _storeCountry = new Set();
  let updatedLocatorList = locatorList.map(item => {
    let itemCountryId = item.countryId;
    const countryDetails =
      countryData &&
      countryData.find(item => {
        return item.countryId === itemCountryId;
      });

    item.country = (countryDetails && countryDetails.countryName) || "";
    return item;
  });

  updatedLocatorList.forEach((item, i) => _storeCountry.add(item.country));
  const _selectedShop =
    updatedLocatorList &&
    updatedLocatorList.filter(item => item.countryId === countryId);
  return {
    ...state,
    locatorList: updatedLocatorList,
    storeCountry: [..._storeCountry],
    selectedCountry:
      _selectedShop && _selectedShop.length && _selectedShop[0].country,
    selectedShop: _selectedShop &&
      _selectedShop.length &&
      _selectedShop[0].shops && {
        ..._selectedShop[0].shops[0],
        station_id: _selectedShop && _selectedShop[0].shops[0].id,
        countryName: _selectedShop && _selectedShop[0].country
      },
    cityList: getSelectedShops(
      updatedLocatorList,
      _selectedShop && _selectedShop.length && _selectedShop[0].country
    )
  };
};

const changeSelectedCountry = (state, { selectedCountry }) => ({
  ...state,
  selectedCountry,
  cityList: getSelectedShops(state.locatorList, selectedCountry)
});

const changeSelectedShop = (state, { selectedShop }) => ({
  ...state,
  selectedShop: { ...selectedShop, countryName: state.selectedCountry }
});

const storeLocatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_LOCATOR_LIST:
      return storeLocatorList(state, action);
    case CHANGE_SELECTED_COUNTRY:
      return changeSelectedCountry(state, action);
    case CHANGE_SELECTED_SHOP:
      return changeSelectedShop(state, action);
    default:
      return state;
  }
};

export default storeLocatorReducer;
