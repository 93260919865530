import cn from "clsx";
import React, { FC } from "react";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import { CustomBannerData } from "../types";
import "./index.scss";

type Props = {
  bannerData: CustomBannerData;
  generalData: any;
};

const FooterCustomerCare: FC<Props> = ({ bannerData, generalData }) => {
  const iconUrl = bannerData.find(data => data.key === "iconUrl")?.value;
  const email = bannerData.find(data => data.key === "email")?.value;
  const { styles } = generalData;

  const handleSocialClick = () => {
    CommonAnalyticsEvent.socialClick({
      socialPlatform: "customercare"
    });
  };

  return (
    <div className="footer_customer_care" style={styles}>
      <a
        className={cn("contact-email", "footer_contact_wrapper")}
        href={`mailto:${email}`}
        onClick={handleSocialClick}
      >
        <img
          src={iconUrl}
          className="contactEmail"
          alt="contact email icon"
          loading="lazy"
        />
        <span className="email" /> {email}
      </a>
    </div>
  );
};

export default FooterCustomerCare;
