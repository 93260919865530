import React, { useEffect, useMemo } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useStore } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import qs from "query-string";
import defaults from "lodash/defaults";

import { Cookies } from "react-cookie";

import { handleFetchError } from "@/util/errorHandler";

import ErrorLayout from "@/components/errorLayout";

import { isServer, useAppDispatch, useAppSelector } from "../../redux/store";
import {
  setIsServerLoadedReduxState,
  showLoaders,
  hideLoaders
} from "../../redux/actions/common.action";
import { useLanguage } from "../../hooks";
import { withClientOnlyRender } from "../../hocs";

import loadCommonData from "./loadCommonData";
import { usePageInitialProps } from "./context";
import { usePageTracking } from "./hooks";

import {
  ServerLoadedPage,
  InitializationConfig,
  PageLoadStrategyDataArgument,
  internalPageDataBridgeInitialProps
} from "./types";

const withPageInitialLoad = <Props = {}, InitialProps = {}>({
  page: PageComponent,
  pageName,
  strategy,
  ssr: ssrEnabled,
  loader: isLoaderInUse = true,
  defaultInitialProps = {},
  loaderConfig
}: InitializationConfig<Props, InitialProps>) => {
    // SSR TEMPORARY DISABLED
    ssrEnabled = false;

  const EnhancedComponent: ServerLoadedPage<Props & InitialProps> = props => {
    const { initialProps, setInitialProps } = usePageInitialProps<
      InitialProps & internalPageDataBridgeInitialProps
    >();

    const store = useStore();
    const params = useParams<Record<string, string>>();
    const location = useLocation();
    const language = useLanguage();

    const dispatch = useAppDispatch();

    const isServerLoadedReduxState = useAppSelector(
      state => state.common.isServerLoadedReduxState
    );

    usePageTracking({ name: pageName });

    useEffect(() => {
      (async () => {
         // SSR TEMPORARY DISABLED
        if (isServerLoadedReduxState && false) {
          isCommonDataLoadedOnWeb = true;
          return dispatch(setIsServerLoadedReduxState(false));
        }

        const shouldStartLoading = Boolean(
          !isCommonDataLoadedOnWeb || strategy
        );
        if (!shouldStartLoading) return;
        try {
          const data: PageLoadStrategyDataArgument = {
            store,
            params,
            query: location.search
              ? (qs.parse(location.search) as Record<string, string>)
              : {},
            cookies: cookies.getAll(),
            language,
            isServer,
            url: location.pathname
          };

          if (isLoaderInUse) dispatch(showLoaders(loaderConfig));

          if (!isCommonDataLoadedOnWeb) {
            await loadCommonData(data);

            isCommonDataLoadedOnWeb = true;
          }

          const initialProps = strategy && (await strategy(data));
          if (initialProps !== undefined) {
            setInitialProps(initialProps as InitialProps);
          }
        } catch (error) {
          handleFetchError(error, dispatch);
          setInitialProps({
            __PAGE_DATA_BRIDGE: {
              hasError: true
            }
          } as InitialProps);
        } finally {
          if (isLoaderInUse) dispatch(hideLoaders());
        }
      })();
    }, [location]);

    const { componentProps, bridgeProps } = useMemo(() => {
      const { __PAGE_DATA_BRIDGE: bridgeProps = {}, ...componentProps } =
        initialProps || {};

      if (!defaultInitialProps)
        return {
          componentProps,
          bridgeProps
        };

      return {
        componentProps: defaults(
          { ...(componentProps || {}) },
          defaultInitialProps
        ),
        bridgeProps
      };
    }, [initialProps]);

    const hasError = Boolean(bridgeProps.hasError);
    useEffect(() => {
      return () => {
        if (hasError) {
          setInitialProps({
            __PAGE_DATA_BRIDGE: {
              hasError: false
            }
          } as InitialProps);
        }
      };
    }, [location, hasError]);

    if (hasError) return <ErrorLayout />;
    return <PageComponent {...props} {...componentProps} />;
  };

  EnhancedComponent.ssrConfig = {
    initialLoad: async data => {
      try {
        // SSR TEMPORARY DISABLED
        return
        await loadCommonData(data);
        if (!ssrEnabled) return;

        return await strategy(data);
      } catch (error) {
        handleFetchError(error, data.store.dispatch);

        return {
          __PAGE_DATA_BRIDGE: {
            hasError: true
          }
        };
      }
    },
    ssr: ssrEnabled
  };

  hoistNonReactStatics(EnhancedComponent, PageComponent);

  return ssrEnabled
    ? EnhancedComponent
    : withClientOnlyRender(EnhancedComponent);
};

const cookies = new Cookies();

let isCommonDataLoadedOnWeb = false;

export default withPageInitialLoad;
