import React from "react";

const BannerLoaderImage = ({ loaderIcon }) => {
  return (
    <div className="loader-wrapper">
      <img src={loaderIcon} alt="loader-icon" className="loader-icon" />
    </div>
  );
};

export default BannerLoaderImage;
