import get from "lodash/get";
import isArray from "lodash/isArray";
import { isServer, languageFromPathName, getProductUrlName } from "./index";
import { appSettings } from "../config/app";

export const pushInfoToGa = obj => {
  if (!isServer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  }
};

export const pushGaProductClick = {
  pushGa: obj => {
    const requestObj = { ...obj };
    pushInfoToGa(requestObj);
  },
  pushGaProductClick1: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "productView",
      ecommerce: {
        currency,
        currencyCode: currency,
        click: {
          products: isArray(obj) ? obj : [obj]
        }
      },
      eventCallback: function () {
        // document.location = productObj.url;
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaGTMProductClick1: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const page = get(outerObj, "page", {});
    const requestObj = {
      ...outerObj,
      event: "productClick",
      ecommerce: {
        currency,
        currencyCode: currency,
        click: {
          actionField: { list: page },
          products: isArray(obj) ? obj : [obj]
        }
      },
      eventCallback: function () {
        // document.location = productObj.url;
      }
    };
    pushInfoToGa(requestObj);

    const requestObj2 = {
      ...outerObj,
      ecommerce: {
        currency,
        currencyCode: currency,
        detail: {
          actionField: { list: page },
          products: isArray(obj) ? obj : [obj]
        }
      },
      eventCallback: function () {
        // document.location = productObj.url;
      }
    };
    pushInfoToGa(requestObj2);
  },
  pushGaGTMProductImpressions: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "impressions",
      ecommerce: {
        currency,
        currencyCode: currency,
        impressions: isArray(obj) ? obj : [obj]
      },
      eventCallback: function () {
        // document.location = productObj.url;
      }
    };
    pushInfoToGa(requestObj);
  },
  pushNTProductListing: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "productListingPage",
      ecommerce: {
        currency,
        currencyCode: currency,
        listing: {
          products: isArray(obj) ? obj : [obj]
        }
      },
      eventCallback: function () {
        // document.location = productObj.url;
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaGTMProductDetailsImpressions: (
    obj,
    outerObj = {},
    updatedRecentlyViewed = []
  ) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "productDetailsImpressions",
      ecommerce: {
        currency,
        currencyCode: currency,
        impressions: updatedRecentlyViewed,
        detail: {
          actionField: {},
          products: isArray(obj) ? obj : [obj]
        }
      },
      eventCallback: function () {
        // document.location = productObj.url;
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaProductClick2: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const page = get(outerObj, "page", "productDetails");
    const requestObj = {
      ...outerObj,
      event: page,
      ecommerce: {
        currency,
        currencyCode: currency,
        detail: {
          // actionField: { list: actionField },
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaAddToCart: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "addToCart",
      ecommerce: {
        currency,
        currencyCode: currency,
        add: {
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaAddToWishList: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "addToWishList",
      ecommerce: {
        currency,
        currencyCode: currency,
        wishlistAdd: {
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaRemoveFromWishList: (obj, outerObj = {}) => {
    const requestObj = {
      ...outerObj,
      event: "removeFromWishList",
      ecommerce: {
        currency: get(outerObj, "currency", ""),
        currencyCode: get(outerObj, "currency", ""),
        wishlistRemove: {
          products: Array.isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaCancelOrder: (obj, outerObj = {}, actionField = {}) => {
    const requestObj = {
      ...outerObj,
      event: "orderCancellation",
      ecommerce: {
        currency: get(outerObj, "currency", ""),
        currencyCode: get(outerObj, "currency", ""),
        cancelledOrderId: get(actionField, "id", ""),
        cancelOrder: {
          products: Array.isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaSyteSearch: (syteSearchObj = {}) => {
    const requestObj = {
      ...syteSearchObj,
      event: "SyteCameraSearch"
    };
    pushInfoToGa(requestObj);
  },
  syteProductClick: (syteSearchObj = {}) => {
    const requestObj = {
      ...syteSearchObj,
      event: "SyteProductClick"
    };
    pushInfoToGa(requestObj);
  },
  pushGaRemoveFromCart: (obj, outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "removeFromCart",
      ecommerce: {
        currency,
        currencyCode: currency,
        remove: {
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaCheckout: (obj, outerObj = {}, actionField = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "checkout",
      ecommerce: {
        currency,
        currencyCode: currency,
        checkout: {
          actionField: actionField,
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaCheckoutOptions: (productArray, outerObj = {}, actionField = {}) => {
    const requestObj = {
      ...outerObj,
      event: "checkoutOption",
      ecommerce: {
        checkout_option: {
          actionField
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushNTShippingAddress: (outerObj = {}, actionField = {}) => {
    const requestObj = {
      ...outerObj,
      event: "shippingAddress"
    };
    pushInfoToGa(requestObj);
  },
  pushNTCartPage: (obj = [], outerObj = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "cartPage",
      ecommerce: {
        currency,
        currencyCode: currency,
        cart: {
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushNTPurchase: (obj, outerObj = {}, actionField = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "productPurchase",
      ecommerce: {
        currency,
        currencyCode: currency,
        purchase: {
          actionField,
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  },
  pushGaPurchase: (obj, outerObj = {}, actionField = {}) => {
    const currency = get(outerObj, "currency", "");
    const requestObj = {
      ...outerObj,
      event: "productPurchase",
      ecommerce: {
        currency,
        currencyCode: currency,
        purchase: {
          actionField,
          products: isArray(obj) ? obj : [obj]
        }
      }
    };
    pushInfoToGa(requestObj);
  }
};

export const createObjForGASyte = {
  syteSearchHeader: () => {
    const syteSearchObj = {
      syteSearch: {
        label: "Header"
      }
    };
    return syteSearchObj;
  },
  syteSearchProductPage: item => {
    const productName =
      get(item, "brandName", "") + " " + get(item, "title", "");
    const productId = get(item, "id", "");
    const syteSearchObj = {
      syteSearch: {
        label: "Product Page",
        productName,
        productId
      }
    };
    return syteSearchObj;
  },
  syteSearchListPage: (item, pageTitle) => {
    const productName =
      get(item, "brandName", "") + " " + get(item, "title", "");
    const productId = get(item, "id", "");
    const syteSearchObj = {
      syteSearch: {
        label: "Product List",
        productName,
        listName: pageTitle,
        productId
      }
    };
    return syteSearchObj;
  },
  syteProductClick: data => {
    const syteSearchObj = {
      syteProductClick: data
    };
    return syteSearchObj;
  }
};

export const createObjForGA = {
  outerAndEcommerce: (
    item,
    authReducer,
    commonSettings,
    page,
    order,
    productUrl,
    color
  ) => {
    let relativeImages = [];
    try {
      if (item.size && item && item.colors && item.colors.colorSizeOptions) {
        item.colors.colorSizeOptions.find(e => e.sizeLabel === item.size);
      }
      if (
        item.images &&
        item.images.productImageURLs &&
        item.images.productImageURLs.length
      ) {
        item.images.productImageURLs.map(mapItem => {
          relativeImages.push({
            image: `${appSettings.imageBaseURL}${mapItem.image}`
          });
          return null;
        });
      } else {
        if (item.captionImageURL) {
          relativeImages.push({
            image: `${appSettings.imageBaseURL}${item.captionImageURL}`
          });
        }
      }
    } catch (error) {
      //
    }
    const lang = languageFromPathName(document && document.location.pathname);
    const productImageUrl = get(item, "captionImageURL", "");
    const productTitle = get(item, "title", "");
    const brandId = get(item, "brandId", "");
    const brandName = get(item, "brandName", "");
    const oldPrice = get(item, "oldPrice", 0);
    const productSize = get(item, "size", "");
    const productQuantity = get(item, "quantity", "");
    const paymentMode = get(item, "paymentMode", []);
    const voucherCode = get(item, "voucherCode", []);
    const transactionDiscount = get(item, "transactionDiscount", 0);
    const country = get(item, "address.country", "");
    const city = get(item, "address.city", "");
    const area = get(item, "address.area", "");
    const addressLine1 = get(item, "address.addressLine1", "");
    const addressLine2 = get(item, "address.addressLine2", "");
    const position = get(item, "position", 0);
    const email = get(authReducer, "email", "");
    const currency = get(commonSettings, "currencyCode", "");
    const countryId = get(commonSettings, "countryId", "");
    const countryName = get(commonSettings, "countryName", "");
    const orderId = get(order, "orderId", "");
    const transactionAmount = get(order, "transactionAmount", 0);
    const transactionShippingCharge = get(
      order,
      "transactionShippingCharge",
      0
    );
    const transactionCodFee = get(order, "transactionCodFee", 0);
    const totalAmount =
      transactionAmount + transactionShippingCharge + transactionCodFee;
    const currentPrice = +(
      item.currentPrice ||
      item.transactionPrice ||
      item.price ||
      0
    );
    const ecommerce = {
      product_code: item.pId || item.productId || item.id || "",
      code: item.pId || item.productId || item.id || "",
      product_name: productTitle,
      name: productTitle,
      brandId,
      brand: brandName,
      description:
        item["en_productDetails"] ||
        item["ar_productDetails"] ||
        item["productDetails"] ||
        "",
      parent_category_name:
        item["en_parentCategory"] ||
        item["ar_parentCategory"] ||
        item["parentCategory"] ||
        "",
      category_name:
        item["en_catname"] || item["ar_catname"] || item["catname"] || "",
      sub_category_name:
        item["en_subcatname"] ||
        item["ar_subcatname"] ||
        item["subcatname"] ||
        "",
      price_before_discount: +oldPrice,
      price_after_discount: currentPrice,
      color: color
        ? [color]
        : (item.colors && item.colors.colorSizeOptions) || [],
      size: productSize,
      prqt: productQuantity,
      quantity: productQuantity,
      prid: item.pId || item.productId || item.id || "",
      id: item.pId || item.productId || item.id || "",
      shipping: transactionShippingCharge,
      mode_of_payment: paymentMode,
      coupon_applied: voucherCode,
      total_amount:
        (item.orderSubTotal || item.transactionAmount) +
          item.transactionShippingCharge +
          (item.transactionCodFee || 0) || 0,
      total_discount: transactionDiscount,
      country,
      city,
      area,
      address1: addressLine1,
      address2: addressLine2,
      price: currentPrice,
      category: `${item.en_parentCategory || item.parentCategory}/${
        item.en_catname || item.catname
      }/${item.en_subcatname || item.subcatname}`,
      // variant: variant ? [variant] : (item.colors && item.colors.colorSizeOptions || []),
      variant: "",
      position,
      product_image:
        (item.image &&
          `${appSettings && appSettings.imageBaseURL}${item.image}`) ||
        (relativeImages && relativeImages) || [
          { image: item.captionImageURL }
        ] ||
        [],
      product_url:
        (productUrl && `${document.location.origin}${productUrl}`) ||
        `${document.location.origin}/${
          commonSettings.language || lang
        }-${commonSettings.countrySHORT.toLowerCase()}/${item.title.key}/${
          item.pId
        }/p/` ||
        "",
      productImageUrl,
      countryName: get(commonSettings, "countryName", "")
    };
    const outerObj = {
      page: page || "",
      email,
      currency,
      currencyCode: currency,
      countryId,
      countryName
    };
    const actionField = {
      id: orderId,
      transactionAmount,
      totalAmount
    };
    return { ecommerce, outerObj, actionField };
  },
  multipleItems: (items = [], authReducer, commonSettings, page, order) => {
    const ecommerce = items.map(item => {
      let color;
      if (item.size && item && item.colors && item.colors.colorSizeOptions) {
        color = item.colors.colorSizeOptions.find(
          e => e.sizeLabel === item.size
        );
      }
      const quantity = get(item, "quantity", "");
      const title = get(item, "title", "");
      const brandId = get(item, "brandId", "");
      const brandName = get(item, "brandName", "");
      const productDetails = get(item, "productDetails", "");
      const oldPrice = get(item, "oldPrice", 0);
      const position = get(item, "position", 0);
      const currentPrice = +(
        item.currentPrice ||
        item.transactionPrice ||
        item.price ||
        0
      );
      return {
        quantity,
        product_code: item.pId || item.productId || item.id || "",
        code: item.pId || item.productId || item.id || "",
        product_name: title,
        name: title,
        brandId,
        brand: brandName,
        description: productDetails,
        sub_category_name:
          item["en_subcatname"] ||
          item["ar_subcatname"] ||
          item["subcatname"] ||
          "",
        price_before_discount: +oldPrice,
        price_after_discount: currentPrice,
        color: color
          ? [color]
          : (item.colors && item.colors.colorSizeOptions) || [],
        size: (item.colors && item.colors.colorSizeOptions) || [],
        id: item.pId || item.productId || item.id || "",
        price: currentPrice,
        category: `${item.en_parentCategory}/${item.en_catname}/${item.en_subcatname}`,
        // variant: item.colors && item.colors.colorSizeOptions || [],
        variant: "",
        position
      };
    });
    const email = get(authReducer, "email", "");
    const currency = get(commonSettings, "currencyCode", "");
    const countryId = get(commonSettings, "countryId", "");
    const countryName = get(commonSettings, "countryName", "");
    const orderId = get(order, "orderId", "");
    const transactionAmount = get(order, "transactionAmount", 0);
    const transactionShippingCharge = get(
      order,
      "transactionShippingCharge",
      0
    );
    const transactionCodFee = get(order, "transactionCodFee", 0);
    const totalAmount =
      transactionAmount + transactionShippingCharge + transactionCodFee;
    const outerObj = {
      page: page || "",
      email,
      currency,
      currencyCode: currency,
      countryId,
      countryName
    };
    const actionField = {
      id: orderId,
      transactionAmount,
      totalAmount
    };
    return { ecommerce, outerObj, actionField };
  },
  multipleNetCoreItems: (
    items = [],
    authReducer,
    commonSettings,
    page,
    order
  ) => {
    const ecommerce = items.map(item => {
      let color;
      let checkoutImage = "";
      if (item.size && item && item.colors && item.colors.colorSizeOptions) {
        color = item.colors.colorSizeOptions.find(
          e => e.sizeLabel === item.size
        );
      }
      if (item.captionImageURL) {
        checkoutImage = `${appSettings.imageBaseURL}${item.captionImageURL}`;
      }
      const pathName = get(document, "location.pathname", "");
      const lang = languageFromPathName(pathName);
      const quantity = get(item, "quantity", "");
      const title = get(item, "title", "");
      const brandId = get(item, "brandId", "");
      const brandName = get(item, "brandName", "");
      const oldPrice = get(item, "oldPrice", 0);
      const colorSizeOptions = get(item, "colors.colorSizeOptions");
      const size = get(item, "size", "");
      const position = get(item, "position", 0);
      const countryName = get(commonSettings, "countryName", "");
      const parentCategory = item.en_parentCategory
        ? item.en_parentCategory
        : item.parentCategory;
      const subcatname = item.en_subcatname
        ? item.en_subcatname
        : item.subcatname;
      const catname = item.en_catname ? item.en_catname : item.catname;
      const newCategory = `${parentCategory}/${catname}/${subcatname}`;
      const productImageUrl = get(item, "captionImageURL", "");
      const currentPrice = +(
        item.currentPrice ||
        item.transactionPrice ||
        item.price ||
        0
      );
      return {
        quantity,
        prqty: quantity,
        product_code: item.pId || item.productId || item.id || "",
        code: item.pId || item.productId || item.id || "",
        product_name: title,
        product_image:
          (item.image &&
            `${appSettings && appSettings.imageBaseURL}${item.image}`) ||
          checkoutImage ||
          "",
        name: title,
        brandId,
        brand: brandName,
        description:
          item["en_productDetails"] ||
          item["ar_productDetails"] ||
          item["productDetails"] ||
          "",
        parent_category_name:
          item["en_parentCategory"] ||
          item["ar_parentCategory"] ||
          item["parentCategory"] ||
          "",
        category_name:
          item["en_catname"] || item["ar_catname"] || item["catname"] || "",
        sub_category_name:
          item["en_subcatname"] ||
          item["ar_subcatname"] ||
          item["subcatname"] ||
          "",
        price_before_discount: +oldPrice,
        price_after_discount: currentPrice,
        color: color ? [color] : colorSizeOptions,
        size: colorSizeOptions || size || [],
        id: item.pId || item.productId || item.id || "",
        prid: item.pId || item.productId || item.id || "",
        price: currentPrice,
        category: newCategory,
        variant: color
          ? [color]
          : (item.colors && item.colors.colorSizeOptions) || [],
        product_url:
          `${document.location.origin}/${lang}-${
            commonSettings.countrySHORT &&
            commonSettings.countrySHORT.toLowerCase()
          }/${item.title.key}/${item.productId}/p/` || "",
        variant: "",
        position,
        countryName,
        productImageUrl
      };
    });
    const email = get(authReducer, "email", "");
    const currency = get(commonSettings, "currencyCode", "");
    const countryId = get(commonSettings, "countryId", "");
    const countryName = get(commonSettings, "countryName", "");
    const orderId = get(order, "orderId", "");
    const transactionAmount = get(order, "transactionAmount", 0);
    const transactionShippingCharge = get(
      order,
      "transactionShippingCharge",
      0
    );
    const transactionCodFee = get(order, "transactionCodFee", 0);
    const totalAmount =
      transactionAmount + transactionShippingCharge + transactionCodFee;
    const outerObj = {
      page: page || "",
      email,
      currency,
      currencyCode: currency,
      countryId,
      countryName
    };
    const actionField = {
      id: orderId,
      transactionAmount,
      revenue: transactionAmount,
      totalAmount,
      shipping: transactionShippingCharge
    };
    return { ecommerce, outerObj, actionField };
  },
  multipleNetCoreItemsNew: (items = [], authReducer, commonSettings, page) => {
    const ecommerce = items.map(item => {
      let color;
      if (item.size && item && item.colors && item.colors.colorSizeOptions) {
        color = item.colors.colorSizeOptions.find(
          e => e.sizeLabel === item.size
        );
      }
      const quantity = get(item, "quantity", "");
      const title = get(item, "title", "");
      const brandId = get(item, "brandId", "");
      const brandName = get(item, "brandName", "");
      const oldPrice = get(item, "oldPrice", 0);
      const colorSizeOptions = get(item, "colors.colorSizeOptions", []);
      const position = get(item, "position", 0);
      const currentPrice = +(
        item.currentPrice ||
        item.transactionPrice ||
        item.price ||
        0
      );
      return {
        quantity,
        prqty: quantity,
        product_code: item.pId || item.productId || item.id || "",
        code: item.pId || item.productId || item.id || "",
        product_name: title,
        product_image:
          (item.image &&
            `${appSettings && appSettings.imageBaseURL}${item.image}`) ||
          "",
        name: title,
        brandId,
        brand: brandName,
        description: item.productDetails || item.title,
        sub_category_name:
          item["en_subcatname"] ||
          item["ar_subcatname"] ||
          item["subcatname"] ||
          "",
        price_before_discount: +oldPrice,
        price_after_discount: currentPrice,
        color: color ? [color] : colorSizeOptions,
        size: colorSizeOptions,
        id: item.pId || item.productId || item.id || "",
        prid: item.pId || item.productId || item.id || "",
        price: currentPrice,
        category: `${item.en_parentCategory}/${item.en_catname}/${item.en_subcatname}`,
        // variant: color ? [color] : (item.colors && item.colors.colorSizeOptions || []),
        variant: "",
        position
      };
    });
    const email = get(authReducer, "email", "");
    const currency = get(commonSettings, "currencyCode", "");
    const countryId = get(commonSettings, "countryId", "");
    const countryName = get(commonSettings, "countryName", "");
    const outerObj = {
      page: page || "",
      email,
      currency,
      currencyCode: currency,
      countryId,
      countryName
    };
    return { ecommerce, outerObj };
  },
  multipleItemsImpression: (
    item,
    authReducer,
    commonSettings,
    page,
    category = "",
    recentlyViewed,
    productListCount
  ) => {
    let ecommerce = null;
    let updatedRecentlyViewed = null;
    if (isArray(item)) {
      ecommerce = item.map(item => {
        let color;
        if (item.size && item && item.colors && item.colors.colorSizeOptions) {
          color = item.colors.colorSizeOptions.find(
            e => e.sizeLabel === item.size
          );
        }
        const title = get(item, "title", "");
        const brandName = get(item, "brandName", "");
        const position = get(item, "position", 0);
        const colorSizeOptions = get(item, "colors.colorSizeOptions", []);
        const currentPrice = +(
          item.currentPrice ||
          item.transactionPrice ||
          item.price ||
          0
        );
        return {
          name: title,
          id: item.pId || item.productId || item.id || "",
          price: currentPrice,
          brand: brandName,
          category: `${item.en_parentCategory}/${item.en_catname}/${item.en_subcatname}`,
          parentCategory:
            item["en_parentCategory"] ||
            item["ar_parentCategory"] ||
            item["parentCategory"] ||
            category,
          // variant: item.colors && item.colors.colorSizeOptions || [],
          categoryName:
            item["en_catname"] || item["ar_catname"] || item["catname"] || "",
          subCategoryName:
            item["en_subcatname"] ||
            item["ar_subcatname"] ||
            item["subcatname"] ||
            "",
          variant: "",
          position,
          list: page,
          color: color ? [color] : colorSizeOptions
        };
      });
    } else {
      let color;
      if (item.size && item && item.colors && item.colors.colorSizeOptions) {
        color = item.colors.colorSizeOptions.find(
          e => e.sizeLabel === item.size
        );
      }
      const title = get(item, "title", "");
      const brandName = get(item, "brandName", "");
      const position = get(item, "position", 0);
      const colorSizeOptions = get(item, "colors.colorSizeOptions", []);
      updatedRecentlyViewed =
        recentlyViewed &&
        recentlyViewed.map(item => {
          item.captionImageURL = `${appSettings.imageBaseURL}${item.captionImageURL}`;
          return item;
        });
      const currentPrice = +(
        item.currentPrice ||
        item.transactionPrice ||
        item.price ||
        0
      );
      ecommerce = {
        name: title,
        id: item.pId || item.productId || item.id || "",
        price: currentPrice,
        brand: brandName,
        category: `${item.en_parentCategory}/${item.en_catname}/${item.en_subcatname}`,
        variant: colorSizeOptions,
        position,
        list: page,
        color: color ? [color] : colorSizeOptions
      };
    }

    const _baseUrl = get(document, "location.origin");
    const _listUrl = get(window, "location.pathname");
    const email = get(authReducer, "email", "");
    const currency = get(commonSettings, "currencyCode", "");
    const countryId = get(commonSettings, "countryId", "");
    const countryName = get(commonSettings, "countryName", "");
    const outerObj = {
      page: page || "",
      email,
      currency,
      currencyCode: currency,
      countryId,
      countryName,
      productListLink: `${_baseUrl}${_listUrl}`,
      productListCount
    };

    return { ecommerce, outerObj, updatedRecentlyViewed };
  }
};
