import React, { FC, useMemo } from "react";
import cn from "clsx";
import { useCurrency } from "../../../hooks";

import { Product } from "../../../services/productService";

const PriceLabel: FC<{ product: Product }> = ({ product }) => {
  const currencyCode = useCurrency();

  const formattedPrice = useMemo(
    () => product.price?.currentPriceLabel || 0,
    [product]
  );

  const oldPrice = product.price?.old;
  const currentPrice = product.price?.current;
  const hasDiscount = Boolean(oldPrice && currentPrice !== oldPrice);

  return (
    <p className="item_price">
      {currencyCode && (
        <span className={cn("price", { red: hasDiscount })}>
          {`${currencyCode} ${formattedPrice}`}
        </span>
      )}

      {hasDiscount && (
        <span className="old_price">
          {`${currencyCode} ${product.price?.oldPriceLabel}`}
        </span>
      )}
    </p>
  );
};

export default PriceLabel;
