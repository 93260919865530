import React, { FC } from "react";
import cn from "clsx";

import { useIsRTL } from "../../../hooks";
import { Product } from "../../../services/productService";

import PriceLabel from "./priceLabel";
import DiscountLabel from "./discountLabel";
import RRPSection from "./RrpSection";

import "./style.scss";

const PriceSlab: FC<{
  product: Product;
  discountLabelVisible?: boolean;
  priceLabelVisible?: boolean;
  retailPriceVisible?: boolean;
}> = ({
  product,
  discountLabelVisible = true,
  priceLabelVisible = true,
  retailPriceVisible = true
}) => {
  const isRTL = useIsRTL();

  return (
    <div className={cn("price_slab", { arabic: isRTL })}>
      {priceLabelVisible && <PriceLabel product={product} />}
      {discountLabelVisible && <DiscountLabel product={product} />}
      {retailPriceVisible && <RRPSection product={product} />}
    </div>
  );
};

export default PriceSlab;
