import React, { useEffect, useRef } from "react";
import cn from "clsx";
import { isTouchScreen } from "../../../util";
import useSwiperScript from "@/hooks/useSwiperScript";
import { useIsRTL } from "@/hooks";
import "./style.scss";

const ProductSizes = ({ sizes, gridSize }) => {
  if (!sizes?.length) return null;

  const swiperRef = useRef(null);
  const loadedScriptStatus = useSwiperScript();
  const isArabic = useIsRTL();

  useEffect(() => {
    if (
      swiperRef.current &&
      sizes.length &&
      (loadedScriptStatus || window.Swiper)
    ) {
      //@ts-ignore
      const swiper = new window.Swiper(swiperRef.current, swiperParams);
      isArabic && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [swiperRef, sizes, loadedScriptStatus]);

  const swiperParams = {
    dots: false,
    slidesPerView: "auto",
    grabCursor: true,
    slidesPerGroup: sizes.length,
    ...(!isTouchScreen() && {
      spaceBetween: 8,
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next"
      }
    })
  };

  return (
    <div className="product-size-wrapper">
      <ul
        className={cn("products_size_list", {
          smaller_list: gridSize > 4
        })}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="swiper" ref={swiperRef}>
          <div className="swiper-wrapper">
            {sizes.map((size, index) => (
              <li className="swiper-slide" key={size.sizeId || index}>
                {size.sizeShortLabel || size.size}
              </li>
            ))}
          </div>
        </div>
      </ul>
    </div>
  );
};

export default ProductSizes;
