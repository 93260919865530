import React, { FC } from "react";
import moment from "moment";
import truncate from "lodash/truncate";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../../util";
import { isDateInPast } from "../../../util/common";
import CustomCountdown from "../../customCountdown";
import FlashImage from "./assets/images/flash.gif";

interface SaleTabData {
  title: string;
  from: number;
  to: number;
  id: number;
  slug: string;
  mainTab: boolean;
  productsCount: number;
  heading: string;
}

const SaleTabContent: FC<{
  data: SaleTabData;
  isFlashImage: boolean;
  productsLength: number;
  dateTo: string;
  dateFrom: string;
}> = ({ data, isFlashImage, productsLength, dateTo, dateFrom }) => {
  const isMobileView = isMobile.any();
  const showTimer = Boolean(!isMobileView && productsLength);
  const alreadyStarted = isDateInPast(dateFrom);
  const timeToShow = alreadyStarted ? dateTo : dateFrom;
  const { t } = useTranslation("sale");

  return (
    <div className="sale-tab tabs-wrapper">
      {!isMobileView && isFlashImage && (
        <div className="flash">
          <img src={FlashImage} alt="flash-image" className="flash-image" />
        </div>
      )}
      <div className="sale-live">
        <h2>
          {truncate(data?.heading, {
            length: isMobileView ? 14 : 17,
            omission: "..."
          })}
        </h2>
      </div>
      {showTimer && (
        <div className="timer">
          <div>
            <h2 className="timer-title">
              {alreadyStarted ? t("endsIn") : t("startsIn")}{" "}
              <span className="full-date">
                (
                <span className="date">
                  {moment(timeToShow).format("MMM DD")}
                </span>{" "}
                <span className="time">
                  {moment(timeToShow).format("hh:mm A")}
                </span>
                )
              </span>
            </h2>
          </div>
          <CustomCountdown
            date={timeToShow}
            type={1}
            content={{ backgroundColor: "black", color: "white" }}
            textStyles={{ top: 30, color: "black" }}
          />
        </div>
      )}
    </div>
  );
};

export default SaleTabContent;
