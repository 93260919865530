import qs from "query-string";

import ApiService from "./apiService";

import { BEConfig } from "../config/env";

import { webapiDelete, webapiGet, webapiPost } from "../webapis/core";

class CustomerService extends ApiService {
  async getOrderList({ pageSize = 5, pageNum = 1, months = 6 } = {}) {
    const apiURL = `${this.getBaseUrl()}${BEConfig.customerApi.myOrders}`;
    const queryParams = qs.stringify({
      pageSize,
      pageNum,
      months
    });
    const endpoint = `${apiURL}?${queryParams}`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    if (!Array.isArray(response.data) && !response.data.success)
      throw new Error(response.data.message);

    return response.data;
  }

  async getCreditCardList() {
    const endpoint = `${this.getBaseUrl()}user/credit-card/`;

    const response = await webapiGet(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async addCreditCard(data) {
    const endpoint = `${this.getBaseUrl()}user/credit-card/`;

    const response = await webapiPost(
      this.getAccessToken(),
      endpoint,
      data,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  async deleteCreditCard(id) {
    const endpoint = `${this.getBaseUrl()}user/credit-card/${id}`;

    const response = await webapiDelete(
      this.getAccessToken(),
      endpoint,
      this.getVisitorId()
    ).request;

    return response.data;
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new CustomerService();

export default instance;
