import { isServer } from "../util";

class StorageService {
  gridSizeKey = "GRID_SIZE";
  ordersHistoryKey = "ORDERS_HISTORY_FROM";
  otpTimestamp = "OTP_TIMESTAMP";

  setGridSize(size) {
    localStorage.setItem(this.gridSizeKey, size);

    return size;
  }

  getGridSize() {
    const defaultValue = 4;

    const value = !isServer && parseInt(localStorage.getItem(this.gridSizeKey));

    return !value || isNaN(value) ? defaultValue : value;
  }

  setOrdersHistoryFrom(value) {
    localStorage.setItem(this.ordersHistoryKey, value);

    return value;
  }

  getOrdersHistoryFrom() {
    return !isServer && localStorage.getItem(this.ordersHistoryKey);
  }

  removeOrdersHistoryFrom() {
    localStorage.removeItem(this.ordersHistoryKey);
    return this.ordersHistoryKey;
  }
}

const instance = new StorageService();

export default instance;
