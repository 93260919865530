export const productCarouselSwiperConfig = {
  dots: true,
  slidesPerView: 5,
  rebuildOnUpdate: true,
  freeMode: true,
  slidesPerGroup: 5,
  spaceBetween: 15,
  breakpoints: {
    1024: {
      slidesPerView: 6.5,
      slidesPerGroup: 5,
      spaceBetween: 15
    },
    800: {
      slidesPerView: 3.6,
      slidesPerGroup: 4,
      spaceBetween: 8
    },
    600: {
      slidesPerView: 4.2,
      slidesPerGroup: 3,
      spaceBetween: 8
    },

    425: {
      slidesPerView: 2.3,
      slidesPerGroup: 2,
      spaceBetween: 8
    },
    320: {
      slidesPerView: 2.3,
      slidesPerGroup: 2,
      spaceBetween: 8
    }
  }
};
