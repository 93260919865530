import React, { FC, ReactNode, useCallback, useState } from "react";
import cn from "clsx";
import { useOutsideEventDetector } from "@/hooks";
import FilterOptionsContainer from "@/routes/productListing/productListingFiltersWeb/filterOptionsContainer";

type FilterElementProps = {
  className?: string;
  children: ReactNode;
  filterLabel?: string;
  filterSelectedCount?: string | number;
  filterWrapperClassName?: string;
  setSearchValue?: (value: string) => void;
};

const FilterElement: FC<FilterElementProps> = ({
  className,
  children,
  filterLabel,
  filterSelectedCount,
  filterWrapperClassName,
  setSearchValue
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const clickOutsideDetectorRef =
    useOutsideEventDetector<HTMLHeadingElement>(() => {
      setSearchValue && setSearchValue("");
      setIsOpen(false);
    }, ["click", "touchstart", "scroll"]);
  const onClickHandler = useCallback(e => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  return (
    <h4
      className={cn(
        className,
        "filter_title",
        isOpen ? "filter_open" : "filter_close_inner"
      )}
      onClick={onClickHandler}
      ref={clickOutsideDetectorRef}
    >
      <span>
        {!!filterLabel && filterLabel}
        {!!filterSelectedCount && <strong>[{filterSelectedCount}]</strong>}
      </span>
      {isOpen && (
        <FilterOptionsContainer filterWrapperClassName={filterWrapperClassName}>
          {children}
        </FilterOptionsContainer>
      )}
    </h4>
  );
};

export default FilterElement;
