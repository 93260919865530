import { createSelector } from "@reduxjs/toolkit";
import get from "lodash/get";

import { LANGUAGE, LANGUAGE_ROUTE_KEY } from "../../constants";
import { SECTION_HEADER_TYPES } from "@/constants/sections";

export const selectCommon = store => store.common;
export const selectLanguageSlug = store => store.common.language;

export const selectHomePageApiErrorData = store =>
  store.homepage.APIErrorResponse;
export const selectHomePageApiErrorMessage = createSelector(
  selectHomePageApiErrorData,
  error => error.message
);
export const selectHomePageApiErrorCode = createSelector(
  selectHomePageApiErrorData,
  error => error.code
);

export const selectRegistrationDataStatus = store =>
  store.homepage.registrationDataStatus;
export const selectRegistrationDataCode = createSelector(
  selectRegistrationDataStatus,
  data => data.code
);
export const selectSignUpLoginToken = store => store.homepage.signUpLoginToken;
export const selectSocialPhoneCheckToken = store =>
  store.homepage.socialPhoneCheckToken;
export const selectSocialLoginFlow = store => store.homepage.socialLoginFlow;
export const selectLastRegistrationStatusCode = store =>
  store.homepage.lastRegistrationStatusCode;
export const selectRegistrationError = store =>
  store.homepage.registrationError;
export const selectAddressOtpFlow = store => store.homepage.addressOtpFlow;
export const selectAddressOtpError = createSelector(
  selectAddressOtpFlow,
  otpFlow => otpFlow.error
);

export const selectCommonSettings = store => store.common.settings;
export const selectShowLoader = store => store.common.showLoader;
export const selectPageLoaderEnabled = createSelector(
  selectCommon,
  commonState => commonState.pageLoader.visible
);

export const selectUpdateError = store => store.myAccountReducer.updateError;
export const selectPhoneUpdateFlowData = store =>
  store.myAccountReducer.phoneUpdateFlowData;

export const selectLoyaltyStatics = store =>
  store.myAccountReducer.loyaltyPoints.LoyaltyStatics;

export const selectLoyaltyDetails = createSelector(
  selectLoyaltyStatics,
  loyaltyStatics => loyaltyStatics.loyaltyDetails
);

export const selectLoyaltyProgramCurrentBalance = createSelector(
  selectLoyaltyDetails,
  loyaltyDetails => loyaltyDetails?.currentBalance
);
export const selectLoyaltyProgramMonetaryValue = createSelector(
  selectLoyaltyDetails,
  loyaltyDetails => loyaltyDetails?.pointsValue
);

export const selectWalletData = store => store.myAccountReducer.wallet;

export const selectLanguage = createSelector(
  selectLanguageSlug,
  languageSlug => {
    console.warn("selectLanguage is deprecated. Use useLongLanguage instead");
    return languageSlug === LANGUAGE_ROUTE_KEY.arabic
      ? LANGUAGE.arabic
      : LANGUAGE.english;
  }
);

export const selectIsArabic = createSelector(selectLanguage, language => {
  console.warn("selectIsArabic is deprecated. Use useIsRTL instead");

  return language.includes(LANGUAGE_ROUTE_KEY.arabic);
});

export const selectCountrySlug = createSelector(
  selectCommonSettings,
  settings => {
    console.warn(
      "selectCountrySlug is deprecated. Use useCountryShort instead"
    );
    return get(settings, "countrySHORT", "ae").toLowerCase();
  }
);

export const selectCountryId = createSelector(
  selectCommonSettings,
  settings => {
    console.warn("selectCountryId is deprecated. Use useCountryId instead");
    return get(settings, "countryId");
  }
);

export const selectRouteSlug = createSelector(
  selectLanguageSlug,
  selectCountrySlug,
  (language, country) => {
    console.warn("selectRouteSlug is deprecated. Use useRouteSlug instead");
    return `${language}-${country}`;
  }
);

export const selectCurrency = createSelector(selectCommonSettings, settings => {
  console.warn("selectCurrency is deprecated. Use useCurrency instead");
  return get(settings, "currencyCode", "AED");
});

export const selectWishListBaseData = state =>
  state.wishListReducer.wishListBaseData;

export const selectLisaReducer = state => state.lisaReducer;
export const selectLisaMinimized = createSelector(
  selectLisaReducer,
  lisaReducer => lisaReducer.minimized
);
export const selectLisaPlayerIsActive = createSelector(
  selectLisaReducer,
  lisaReducer => lisaReducer.playerIsActive
);

export const selectAuthReducer = store => store.authReducer;
export const selectAddressReducer = store => store.addressReducer;

export const selectAccessToken = createSelector(
  selectAuthReducer,
  authReducer => authReducer.accessToken
);

export const selectViewGridValue = store =>
  Number(store.productListingReducer.gridValue);

export const selectShowAppOnlyItemModalState = store =>
  store.productListingReducer.appOnlyItemModal;

export const selectSectionsReducer = store => store.sectionReducer;

export const selectHeaderBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.headerSections
);

export const selectCartBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.cartSections
);

export const selectOrderSuccessBannerSections = createSelector(
  selectSectionsReducer,
  sectionReducer => sectionReducer.orderSuccessSections
);

export const selectCdnImageSettings = store =>
  store.common.configSettings?.cdnImages;

export const selectMessagesFromConfigs = store =>
  store.common.configSettings?.messages;

export const selectCheckoutValidationMsg = store =>
  store.common.configSettings?.checkoutValidationMsg;

export const selectSyteSettings = store => store.common.configSettings?.syte;

export const selectWishListGeneralProducts = store =>
  store.cartReducer.cartAndWishlistGeneralData?.wishList?.products;

export const selectWishListGeneralCount = store =>
  store.cartReducer.cartAndWishlistGeneralData?.wishList?.count;

export const selectCartGeneralCount = store =>
  store.cartReducer.cartAndWishlistGeneralData?.cart?.count;

export const selectCheckoutReducer = store => store.checkoutReducer;
export const selectCheckoutData = createSelector(
  selectCheckoutReducer,
  checkoutReducer => checkoutReducer.checkoutData
);
export const selectPaymentStatusResponse = createSelector(
  selectCheckoutReducer,
  checkoutReducer => checkoutReducer.paymentStatusResponse
);

export const selectCartReducer = state => state.cartReducer;

export const selectCartData = createSelector(
  selectCartReducer,
  cartReducer => cartReducer.cart
);

export const selectWishList = state => state.wishListReducer.wishList;

export const selectOrderFetchingLoader = store =>
  store.myAccountReducer.showMyOrdersLoader;

export const selectDeviceType = store => store.common.deviceType;

export const selectIsCheckedLoyaltyProgram = store =>
  store.common.isCheckedLoyaltyProgram;

export const selectTabbyPaymentError = store =>
  store?.common?.configSettings?.messages?.tabbyPaymentError;

const selectPage = store => store.page;

export const selectWishListAndCartGeneralLoaded = createSelector(
  selectCartReducer,
  cart => cart.wishListAndCartGeneralLoaded
);

export const selectDeleteWishListItemLoaded = createSelector(
  selectCartReducer,
  cart => cart.deleteWishListItemLoaded
);

export const selectPageMeta = createSelector(selectPage, page => page.meta);

export const selectCountryCode = createSelector(
  selectCommonSettings,
  settings => get(settings, "countryCode", "")
);

export const selectCountryName = createSelector(
  selectCommonSettings,
  settings => get(settings, "countryName", "")
);

export const selectCountryShort = createSelector(
  selectCommonSettings,
  settings => {
    console.warn(
      "selectCountryShort is deprecated. Use useCountryShort instead"
    );
    return get(settings, "countrySHORT", "");
  }
);

export const selectPageAnalitic = createSelector(
  selectPage,
  page => page.analyticData
);

export const selectCustomerId = state => state.authReducer.customerId;

export const selectSettings = store => store.common.configSettings;

export const selectOTPLoginFlowBFL = store => store.homepage.otpLoginFlowBFL;

export const selectCdnDomain = createSelector(
  selectSettings,
  settings => settings?.cdnDomain
);

export const selectStaticAssets = createSelector(
  selectSettings,
  settings => settings?.staticAssets
);

export const selectSubmoduleURL = createSelector(
  selectCdnDomain,
  domain => domain?.submodule
);

export const selectStandardAddress = createSelector(
  selectCheckoutReducer,
  checkoutReducer => checkoutReducer.standardDeliveryAddress
);

export const selectAddressBook = createSelector(
  selectAuthReducer,
  authReducer => authReducer.addressBook
);

export const selectLoggedInStatus = createSelector(
  selectAuthReducer,
  authReducer => authReducer.userLoggedIn
);

export const selectCurrentAddressBook = createSelector(
  selectCommonSettings,
  selectAddressBook,
  (commonSettings, addressBook) =>
    addressBook.filter(
      address => address.countryId === commonSettings.countryId
    )
);

export const selectOrderAddress = createSelector(
  selectCheckoutData,
  checkoutData => checkoutData.address
);

export const selectCommonSettingsState = createSelector(
  selectCommonSettings,
  commonSettings => commonSettings.state
);

export const selectCheckoutInfoText = createSelector(
  selectSettings,
  settings => settings.checkoutValidationMsg
);

export const selectIsPickUpFormValid = createSelector(
  selectCheckoutReducer,
  checkoutReducer => checkoutReducer.isPickUpFormValid
);

export const selectIsJoinedLoyaltyProgram = createSelector(
  selectCommon,
  settings => settings.isJoinedToLoyaltyProgram
);

export const selectLoyaltyPoints = createSelector(
  selectCommon,
  settings => settings.loyaltyPoints
);

export const selectAppDownloadPhone = state =>
  state.myAccountReducer.appDownloadPhone;

export const selectCountryData = createSelector(
  selectCommon,
  settings => settings.countryData
);

export const selectMobileLocalNumberLength = createSelector(
  selectCommonSettings,
  settings => settings.mobileLocalNumberLength
);

export const selectMobileCountryCode = createSelector(
  selectCommonSettings,
  settings => settings.mobileCountryCode
);

export const selectMobileLocalCode = createSelector(
  selectCommonSettings,
  settings => settings.mobileLocalCode
);

export const selectCheckout = store => store.checkout;

export const selectCheckoutDataForValidationMessage = createSelector(
  selectCheckout,
  selectCheckoutValidationMsg,
  (checkout, validationMsg) => {
    const { summary, addresses, stepValidationDetails } = checkout;
    return {
      summary,
      addresses,
      validationMsg,
      stepValidationDetails
    };
  }
);

export const isDecimalPlacesCountry = createSelector(
  selectCommonSettings,
  settings => settings.decimalPlaces === 2
);

export const headerBannerSectionsSelector = createSelector(
  selectHeaderBannerSections,
  headerBannerSections => {
    const {
      [SECTION_HEADER_TYPES.HEADER_BOTTOM]: headerBottomSections,
      [SECTION_HEADER_TYPES.HEADER_SHOP_BY_STORE]: headerShopByStoreSections
    } = headerBannerSections;

    return { headerBottomSections, headerShopByStoreSections };
  }
);
