export const QUICK_FILTER_DATA_MAP_KEYS = {
  MAIN_CATEGORY: "MAIN_CATEGORY",
  KID_CATEGORY: "KID_CATEGORY",
  PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  SIZE_CATEGORY: "SIZE_CATEGORY",
  STEP_DATA_FIELD: "STEP_DATA_FIELD",
  KID_SHOES_FIELD: "KID_SHOES_FIELD"
};

//hardcoded params for quickFilters data parsing

export const TODDLERS_N_BABIES = "الأطفال الصغار والرضع";
export const ARABIC_CLOTHING = "ألبسة";
export const QUICK_FILTER_KIDS = "اطفال";
export const ARABIC_SHOES = "الأحذية";
export const KIDS_FOOTWEAR = "أحذية الأطفال";
const GENERAL_SIZE = "المقاس الإجمالي";
const WAIST_SIZE = "مقاس الخصر";
const BAND_SIZE = "ربطة";
const CUP_SIZE = "كوب";
const SHOP_BY_AGE = "تسوق حسب العمر";
const INTERNATIONAL_SIZE = "المقاسات العالمية";
const SIZE_RANGE = "حجم المدى";
const WAIST = "وسط";
const AGE = "عمر";
const US = "أميريكي";
const EU = "أوروبي";
const UK = "المملكة المتحدة";
export const ARABIC_COLLECTION = "مجمو";
export const ARABIC_ALL = "جميع المنتجات";
export const ARABIC_ALL_OPTION_VARIANTS = ["مجموعة", "عرض الكل"];
export const ARABIC_SHOP_ALL_PREFIX = " تسوق جميع المنتجات";
export const ARABIC_SHOP_ALL = {
  clothing: "تسوق جميع الملابس",
  shoes: "تسوق جميع الأحذية"
};
export const ARABIC_GIRLS_IDEAL_FOR_PARAM = "الفتيات";
export const ARABIC_BOYS_IDEAL_FOR_PARAM = "أولاد";
export const ARABIC_TODDLERS_HIERARCHY_FOOTWEAR = "حذاء";
//------------------------------------------------------------

export const ARABIC_ADULTS_CLOTHES_FORMATS = {
  [GENERAL_SIZE]: [INTERNATIONAL_SIZE, SIZE_RANGE],
  [WAIST_SIZE]: [WAIST],
  [BAND_SIZE]: [BAND_SIZE],
  [CUP_SIZE]: [CUP_SIZE]
};
export const ARABIC_KIDS_CLOTHES_FORMATS = {
  [SHOP_BY_AGE]: [AGE]
};

export const ARABIC_SHOES_FORMATS = {
  [EU]: [EU],
  [US]: [US],
  [UK]: [UK]
};

export const ARABIC_REQUEST_PATH_MAP = {
  "/men/clothing/": "رجالي > ملابس",
  "/men/shoes/": "رجالي > أحذية",
  "/women/clothing/": "نساء > ملابس",
  "/women/shoes/": "نساء > أحذية",
  "/kids/boys/": "أولاد > فتيان",
  "/kids/girls/": "أولاد > فتيات",
  "/kids/toddlers-and-babies/": "أولاد > الأطفال الصغار والرضع",
  "/clothing/": "أولاد > الأطفال الصغار والرضع"
};
