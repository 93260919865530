import React, { FC, useEffect, useRef } from "react";
import cn from "clsx";
import { useIsRTL } from "@/hooks";
import useSwiperScript from "@/hooks/useSwiperScript";
import { ProductSizeVariant } from "@/services/productService";
import { isTouchScreen } from "@/util";

import { SwiperOptions } from "swiper/types";
import "./style.scss";

type Props = {
  displaySizes: ProductSizeVariant[];
  isShortLabel?: boolean;
  isMobileView?: boolean;
};
export const SwiperProductSize: FC<Props> = ({
  displaySizes,
  isMobileView = false,
  isShortLabel = true
}) => {
  if (!(displaySizes && displaySizes.length)) return null;

  const swiperRef = useRef(null);
  const loadedScriptStatus = useSwiperScript();
  const isArabic = useIsRTL();

  useEffect(() => {
    if (
      swiperRef.current &&
      displaySizes.length &&
      (loadedScriptStatus || window.Swiper)
    ) {
      const swiper = new window.Swiper(swiperRef.current, swiperParams);
      isArabic && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [swiperRef, displaySizes, loadedScriptStatus]);

  const swiperParams: SwiperOptions = {
    slidesPerView: "auto",
    freeMode: true,
    grabCursor: true,
    slidesPerGroup: displaySizes.length,
    ...(!isTouchScreen() && {
      spaceBetween: 8
    }),
    allowSlideNext: true,
    allowSlidePrev: true
  };

  return (
    <ul
      className={cn("size_list", {
        smaller_list: isMobileView
      })}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="swiper" ref={swiperRef}>
        <div className="swiper-wrapper">
          {displaySizes.map((size, index) => (
            <li className="swiper-slide" key={size.sizeId || index}>
              {isShortLabel ? size.sizeShortLabel || size.size : size.size}
            </li>
          ))}
        </div>
      </div>
    </ul>
  );
};
