import {
  MY_ACCOUNT_DETAIL,
  HANDLE_USER_DETAILS,
  SET_MY_ORDERS,
  SET_USER_LOYALTY_POINTS,
  SET_LOYALTY_TABLE,
  SET_CANCEL_ORDER,
  REQUIRED_EN,
  NUMBER_EN,
  SET_WALLET,
  UPDATE_WALLET,
  RESET_CANCEL_ORDER,
  ADD_TO_WALLET,
  TRACK_ORDER,
  CLEAR_TRACK_ORDER,
  SET_WALLET_DATA,
  SET_USER_WALLET_EXPIRY_DATA,
  FETCH_SOCIAL_ACCOUNT,
  TOGGLE_ADD_NEW_CARD_TAB,
  HANDLE_APP_DOWNLOAD_DETAILS,
  RESET_MY_ACCOUNT_DETAIL,
  SET_LOYALTY_EXPIRY_DATA,
  SET_LOYALTY_EARNED_POINTS,
  RESET_LOYALTY_PROGRAM_INFO,
  SET_UPDATE_ERROR,
  SET_PHONE_UPDATE_FLOW_DATA,
  SHOW_MY_ORDERS_LOADER,
  HIDE_MY_ORDERS_LOADER,
  SET_IS_LOADING_LOYALTY_POINTS,
  SET_LOYALTY_POINTS_TIER,
  SET_WALLET_AMOUNT
} from "../constants";
import validator from "validator";

const initialState = {
  myDetails: {
    info: {
      firstname: "",
      lastname: "",
      gender: "",
      phone: "",
      email: "",
      nationality: 1,
      countryId: "",
      birthday: ""
    },
    errors: {
      firstname: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      }
    }
  },
  myDetailsReset: {
    info: {
      firstname: "",
      lastname: "",
      gender: "",
      phone: "",
      email: "",
      nationality: 1,
      countryId: "",
      birthday: ""
    },
    errors: {
      firstname: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      phone: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: NUMBER_EN
      }
    }
  },
  appDownloadPhone: {
    info: {
      phone: ""
    },
    errors: {
      phone: {
        valid: true,
        validWhen: false,
        check: "",
        message: NUMBER_EN
      }
    }
  },
  myOrders: {},
  OrderDetail: {
    cancelOrder: {
      orderId: "",
      reason: "",
      email: "",
      comment: "",
      quantity: "",
      orderItems: [
        {
          productId: "",
          quantity: 0,
          colorid: 0,
          sizeid: 0
        }
      ]
    },
    errors: {
      comment: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      reason: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      },
      quantity: {
        valid: true,
        validWhen: false,
        check: "isEmpty",
        message: REQUIRED_EN
      }
    }
  },
  loyaltyPoints: {
    LoyaltyStatics: {},
    LoyaltyTable: {},
    expiryDetails: {},
    loyaltyEarnedPoints: []
  },
  wallet: {},
  walletExpiryDetails: {},
  fullWalletHistoryLoaded: false,
  addToWallet: {
    voucherCode: "",
    currencyCode: "",
    apiErrorResponse: ""
  },
  socialAccounts: {},
  trackOrder: {},
  addNewCardTab: false,
  updateError: {},
  phoneUpdateFlowData: {},
  showMyOrdersLoader: false,
  isLoadingLoyaltyPoints: false,
  tierToken: ""
};
const trackOrder = (state, { apiResponse }) => ({
  ...state,
  trackOrder: apiResponse
});
const clearTrackOrder = state => ({
  ...state,
  trackOrder: {}
});
const myAccountDetails = (state, { info }) => ({
  ...state,
  myDetails: {
    ...state.myDetails,
    info: {
      ...state.myDetails.info,
      firstname: info.firstname,
      lastname: info.lastname,
      gender: info.gender,
      phone: info.phone,
      email: info.email,
      birthday: info.birthday
    }
  }
});

const resetMyAccountDetails = state => ({
  ...state,
  myDetails: { ...state.myDetailsReset },
  myOrders: initialState.myOrders,
  tierToken: ""
});

const addToWallet = (state, { name, value }) => ({
  ...state,
  addToWallet: {
    ...state.addToWallet,
    [name]: value
  }
});
const setCancelOrder = (state, { name, value }) => ({
  ...state,
  OrderDetail: {
    ...state.OrderDetail,
    cancelOrder: {
      ...state.OrderDetail.cancelOrder,
      [name]: value
    },
    errors: {
      ...state.OrderDetail.errors,
      [name]: {
        ...state.OrderDetail.errors[name],
        touched: true,
        valid:
          state.OrderDetail.errors[name] && state.OrderDetail.errors[name].check
            ? validator[state.OrderDetail.errors[name].check](value) ===
              state.OrderDetail.errors[name].validWhen
            : true
      }
    }
  }
});
const resetCancelOrder = state => ({
  ...state,
  OrderDetail: initialState.OrderDetail
});
const setUserInfo = (state, { name, value, status }) => ({
  ...state,
  myDetails: {
    ...state.myDetails,
    info: {
      ...state.myDetails.info,
      [name]: value
    },
    errors: {
      ...state.myDetails.errors,
      [name]: {
        ...state.myDetails.errors[name],
        valid:
          state.myDetails.errors[name] && state.myDetails.errors[name].check
            ? validator[state.myDetails.errors[name].check](value) ===
                state.myDetails.errors[name].validWhen &&
              (typeof status !== "undefined" ? status : true) &&
              value.trim()
            : true
      }
    }
  }
});

const setAppDownloadPhoneInfo = (state, { name, value, status }) => ({
  ...state,
  appDownloadPhone: {
    ...state.appDownloadPhone,
    info: {
      ...state.appDownloadPhone.info,
      [name]: value
    },
    errors: {
      ...state.appDownloadPhone.errors,
      [name]: {
        ...state.appDownloadPhone.errors[name],
        valid:
          state.appDownloadPhone.errors[name] &&
          state.appDownloadPhone.errors[name].check
            ? validator[state.appDownloadPhone.errors[name].check](value) ===
                state.appDownloadPhone.errors[name].validWhen &&
              (typeof status !== "undefined" ? status : true)
            : typeof status !== "undefined"
            ? status
            : true
      }
    }
  }
});

const setMyOrders = (state, { apiResponse }) => ({
  ...state,
  myOrders: { ...apiResponse }
});

const setWallet = (state, { apiResponse }) => ({
  ...state,
  wallet: apiResponse,
  fullWalletHistoryLoaded: !Boolean(
    apiResponse.walletHistoryDetails && apiResponse.walletHistoryDetails.length
  )
});

const updateWallet = (state, { apiResponse }) => {
  const { walletHistoryDetails } = apiResponse;

  return {
    ...state,
    wallet: {
      ...state.wallet,
      walletHistoryDetails: [
        ...state.wallet.walletHistoryDetails,
        ...walletHistoryDetails
      ]
    },
    ...(!Boolean(walletHistoryDetails.length) && {
      fullWalletHistoryLoaded: true
    })
  };
};

const setWalletAmount = (state, { data }) => ({
  ...state,
  wallet: {
    ...state.wallet,
    walletAmount: data
  }
});

const resetLoyaltyProgramInfo = state => ({
  ...state,
  loyaltyPoints: initialState.loyaltyPoints,
  wallet: initialState.wallet,
  fullWalletHistoryLoaded: initialState.fullWalletHistoryLoaded
});

const socialAccounts = (state, { apiResponse }) => ({
  ...state,
  socialAccounts: apiResponse
});
const setLoyaltyPoints = (state, { apiResponse }) => ({
  ...state,
  loyaltyPoints: {
    ...state.loyaltyPoints,
    LoyaltyStatics: apiResponse
  }
});
const setLoyaltyTable = (state, { apiResponse, pageNum }) => {
  if (pageNum > 1) {
    return {
      ...state,
      loyaltyPoints: {
        ...state.loyaltyPoints,
        LoyaltyTable: {
          ...state.loyaltyPoints.LoyaltyTable,
          ...apiResponse,
          loyaltyPoints: [
            ...state.loyaltyPoints.LoyaltyTable.loyaltyPoints,
            ...apiResponse.loyaltyPoints
          ]
        }
      }
    };
  } else {
    return {
      ...state,
      loyaltyPoints: {
        ...state.loyaltyPoints,
        LoyaltyTable: { ...apiResponse }
      }
    };
  }
};

const setWalletExpiryData = (state, action) => ({
  ...state,
  walletExpiryDetails: action.payload
});

const setLoyaltyExpiryData = (state, action) => ({
  ...state,
  loyaltyPoints: { ...state.loyaltyPoints, expiryDetails: action.payload }
});

const setLoyaltyEarnedPoints = (state, action) => ({
  ...state,
  loyaltyPoints: { ...state.loyaltyPoints, loyaltyEarnedPoints: action.payload }
});

const setWalletData = state => ({ ...state, wallet: [] });

const toggleAddNewCardTab = (state, { value }) => ({
  ...state,
  addNewCardTab: value
});

const setUpdateError = (state, { payload }) => ({
  ...state,
  updateError: payload
});

const setPhoneUpdateFlowData = (state, { payload }) => ({
  ...state,
  phoneUpdateFlowData: payload
    ? { ...state.phoneUpdateFlowData, ...payload }
    : initialState.phoneUpdateFlowData
});

const showMyOrdersLoader = state => ({
  ...state,
  showMyOrdersLoader: true
});

const hideMyOrdersLoader = state => ({
  ...state,
  showMyOrdersLoader: false
});
const setIsLoadingLoyaltyPoints = (state, { value }) => ({
  ...state,
  isLoadingLoyaltyPoints: value
});

const setLoyaltyPointsTierToken = (state, { tierToken }) => ({
  ...state,
  tierToken
});

const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_ACCOUNT_DETAIL:
      return myAccountDetails(state, action);
    case RESET_MY_ACCOUNT_DETAIL:
      return resetMyAccountDetails(state);
    case HANDLE_USER_DETAILS:
      return setUserInfo(state, action);
    case HANDLE_APP_DOWNLOAD_DETAILS:
      return setAppDownloadPhoneInfo(state, action);
    case SET_MY_ORDERS:
      return setMyOrders(state, action);
    case SET_USER_LOYALTY_POINTS:
      return setLoyaltyPoints(state, action);
    case SET_LOYALTY_POINTS_TIER:
      return setLoyaltyPointsTierToken(state, action);
    case SET_LOYALTY_TABLE:
      return setLoyaltyTable(state, action);
    case SET_CANCEL_ORDER:
      return setCancelOrder(state, action);
    case SET_WALLET:
      return setWallet(state, action);
    case UPDATE_WALLET:
      return updateWallet(state, action);
    case RESET_LOYALTY_PROGRAM_INFO:
      return resetLoyaltyProgramInfo(state);
    case RESET_CANCEL_ORDER:
      return resetCancelOrder(state);
    case ADD_TO_WALLET:
      return addToWallet(state, action);
    case TRACK_ORDER:
      return trackOrder(state, action);
    case CLEAR_TRACK_ORDER:
      return clearTrackOrder(state, action);
    case FETCH_SOCIAL_ACCOUNT:
      return socialAccounts(state, action);
    case TOGGLE_ADD_NEW_CARD_TAB:
      return toggleAddNewCardTab(state, action);
    case SET_WALLET_DATA:
      return setWalletData(state, action);
    case SET_USER_WALLET_EXPIRY_DATA:
      return setWalletExpiryData(state, action);
    case SET_LOYALTY_EXPIRY_DATA:
      return setLoyaltyExpiryData(state, action);
    case SET_LOYALTY_EARNED_POINTS:
      return setLoyaltyEarnedPoints(state, action);
    case SET_UPDATE_ERROR:
      return setUpdateError(state, action);
    case SET_PHONE_UPDATE_FLOW_DATA:
      return setPhoneUpdateFlowData(state, action);
    case SHOW_MY_ORDERS_LOADER:
      return showMyOrdersLoader(state);
    case HIDE_MY_ORDERS_LOADER:
      return hideMyOrdersLoader(state);
    case SET_IS_LOADING_LOYALTY_POINTS:
      return setIsLoadingLoyaltyPoints(state, action);
    case SET_WALLET_AMOUNT:
      return setWalletAmount(state, action);
    default:
      return state;
  }
};

export default myAccountReducer;
