import get from "lodash/get";
import { getVisitorId } from "@/util";
import { pushGaProductClick } from "../../util/analytics";

class CommonAnalyticService {
  trackSessionStart = customerId => {
    pushGaProductClick.pushGa({
      event: "sessionStart",
      sessionStartCustomerId: customerId
    });
  };

  trackPageBrowse = url => {
    pushGaProductClick.pushGa({
      event: "pageBrowse",
      URL: url
    });
  };

  trackNewsletterSubscription = trackData => {
    const email = get(trackData, "email", "");
    const countryCode = get(trackData, "countryCode", "");
    const countryName = get(trackData, "countryName", "");
    const customerId = get(trackData, "customerId", "");
    const userType = get(trackData, "userType", "");

    pushGaProductClick.pushGa({
      event: "newsletterSubscription",
      newsletterSubscription: {
        email,
        country: countryCode,
        countryName,
        customerId,
        userType
      }
    });
  };

  trackLeadSubmitted = trackData => {
    const email = get(trackData, "email", "");
    const totalAmount = get(trackData, "totalAmount", "");
    pushGaProductClick.pushGa({
      event: "leadSubmitted",
      email,
      total_amount: totalAmount
    });
  };

  trackHomePageStatus = email => {
    pushGaProductClick.pushGa({
      event: "homePage",
      email
    });
  };

  trackHomePageEvent = () => {
    pushGaProductClick.pushGa({
      event: "homePage",
      email: ""
    });
  };

  trackViewCategory = catalogueCategory => {
    pushGaProductClick.pushGa({
      event: "View Category",
      catalogueCategory
    });
  };

  trackViewSubCategory = trackData => {
    const parentCategory = get(trackData, "parentCategory", "");
    const category = get(trackData, "category", "");
    const subCategory = get(trackData, "subCategory", "");
    pushGaProductClick.pushGa({
      event: "View Subcategory",
      parentCategory,
      category,
      subCategory
    });
  };

  trackPageType = ({ url, type }) => {
    pushGaProductClick.pushGa({
      event: "pageType",
      url,
      type
    });
  };

  trackCountryChange = ({ countryId, customerId, language, countryName }) => {
    const userId = customerId ? { customerId } : { visitorId: getVisitorId() };
    pushGaProductClick.pushGa({
      event: "countryChange",
      countryId,
      ...userId,
      language,
      countryName
    });
  };
}

export default CommonAnalyticService;
