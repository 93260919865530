import React, { FC, useEffect, useRef } from "react";
import useSwiperScript from "@/hooks/useSwiperScript";
import { useIsRTL } from "../../../hooks";
import { AssistiveFilterOption } from "../types";
import "./style.scss";

type AssistiveFiltersSectionProps = {
  staticFilter: AssistiveFilterOption;
  filters: AssistiveFilterOption[] | null;
  onFilterClick: (filter: AssistiveFilterOption) => void;
};

const swiperParams = {
  dots: false,
  slidesPerView: 4,
  rebuildOnUpdate: true,
  spaceBetween: 14,
  breakpoints: {
    800: {
      slidesPerView: "auto"
    },
    600: {
      slidesPerView: "auto"
    },
    320: {
      slidesPerView: "auto",
      spaceBetween: 5
    }
  }
};

const AssistiveFiltersSection: FC<AssistiveFiltersSectionProps> = ({
  staticFilter,
  filters,
  onFilterClick
}) => {
  const isRTL = useIsRTL();

  const swiperRef = useRef(null);

  const loadedScriptStatus = useSwiperScript();

  useEffect(() => {
    if (swiperRef.current && (loadedScriptStatus || window.Swiper)) {
      //@ts-ignore
      const swiper = new window.Swiper(swiperRef.current, swiperParams);
      isRTL && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [swiperRef, loadedScriptStatus]);

  if (!filters || filters.length === 0) return null;

  const filterElements = filters.map(filter => {
    return (
      <div
        key={filter.defaultValue}
        className="assistive_filter_item swiper-slide"
        onClick={() => onFilterClick(filter)}
      >
        {filter.label}
      </div>
    );
  });

  const staticFilterElement = staticFilter && (
    <div
      key={staticFilter.defaultValue}
      className="assistive_filter_item swiper-slide static_slide"
      onClick={() => onFilterClick(staticFilter)}
    >
      {staticFilter.label}
    </div>
  );

  return (
    <div className="assistive_filters_wrapper">
      <div className="container chooseCategory_wrapper">
        <div className="assistiveFiltersResp">
          {staticFilterElement}
          <div className="swiper" ref={swiperRef}>
            <div className="swiper-wrapper">{filterElements}</div>
          </div>
        </div>
        <div className="assistive_content chooseCategory">
          {staticFilterElement}
          {filterElements}
        </div>
      </div>
    </div>
  );
};

export default AssistiveFiltersSection;
