import GoogleAnalytics from "../../googleAnalytics";
import {
  AddPaymentInfoProps,
  AddShippingInfoProps,
  ApplyVoucherProps,
  BeginCheckoutProps,
  PurchaseEcommerceProps,
  RefundProps
} from "./types";

class CheckoutAnalyticsService {
  beginCheckout(ecommerceData: BeginCheckoutProps) {
    GoogleAnalytics.beginCheckout(ecommerceData);
  }

  addShippingInfo(ecommerceData: AddShippingInfoProps) {
    GoogleAnalytics.addShippingInfo(ecommerceData);
  }

  continueAsGuest() {
    GoogleAnalytics.continueAsGuest("logged out");
  }

  addNewAddress() {
    GoogleAnalytics.addAddressCTA();
  }

  pinYourLocation(location: string) {
    GoogleAnalytics.pinYourLocation({ status: "success", location });
  }

  addPaymentInfo(ecommerceData: AddPaymentInfoProps) {
    GoogleAnalytics.addPaymentInfo(ecommerceData);
  }

  applyVoucher(voucherData: ApplyVoucherProps) {
    GoogleAnalytics.applyVoucher(voucherData);
  }

  purchase(ecommerce: PurchaseEcommerceProps) {
    GoogleAnalytics.purchase(ecommerce);
  }

  refund(ecommerce: RefundProps) {
    GoogleAnalytics.refund(ecommerce);
  }

  trackAddressGuest(payload) {
    GoogleAnalytics.trackAddressGuest(payload);
  }
}

const CheckoutAnalyticsEvent = new CheckoutAnalyticsService();
export default CheckoutAnalyticsEvent;
