import React, { FC, useEffect, useRef } from "react";
import isEmpty from "lodash/isEmpty";
import CarouselItem from "./CarouselItem";

import { isMobile, isServer } from "../../../util";
import {
  getAutoScrollConfig,
  getInfiniteLoopConfig
} from "../../../util/carousel";
import { getReorderedArray } from "../../../util/dynamic-banner";
import useSwiperScript from "@/hooks/useSwiperScript";
import { useIsRTL, useLongLanguage } from "@/hooks";
import { BULLET_TYPES, CONTROLLER_TYPES } from "../../../constants";
import { BannerRow } from "@/routes/catalogPage/types";
import "./style.scss";

const getElement = (el, className) => el && el.querySelector(className);

const CarouselWrapperRow: FC<{
  rowData: BannerRow;
  bannerModuleName: string;
  bannerPageTitle: string;
}> = ({ rowData, bannerModuleName, bannerPageTitle }) => {
  const {
    autoScroll,
    width,
    styles,
    imageList = [],
    indicatorStyles,
    controllerStyles,
    controllerType
  } = rowData;

  const language = useLongLanguage();
  const carouselRef = useRef(null);
  const swiperRef = useRef(null);
  const loadedScriptStatus = useSwiperScript();
  const isArabic = useIsRTL();

  const activeBullet =
    !isServer &&
    carouselRef?.current?.querySelector(`.slider-bullet-active${rowData.id}`);

  const isIndicatorTypeScroll = rowData.indicatorType === BULLET_TYPES.SCROLL;
  const isIndicatorTypeNone = rowData.indicatorType === BULLET_TYPES.NONE;
  const isControlTypeNone = controllerType === CONTROLLER_TYPES.NONE;

  const setControllerStyles = () => {
    const carouselWrapper = !isServer && carouselRef?.current;

    const nextButton = getElement(carouselWrapper, ".swiper-button-next");
    const prevButton = getElement(carouselWrapper, ".swiper-button-prev");

    if (nextButton && prevButton && controllerStyles) {
      nextButton.style.background = controllerStyles.backgroundControllerColor;
      prevButton.style.background = controllerStyles.backgroundControllerColor;
    }
  };

  const setActiveBulletColor = () => {
    const activeBullet =
      !isServer &&
      carouselRef?.current?.querySelector(`.slider-bullet-active${rowData.id}`);
    if (activeBullet) {
      activeBullet.style.background =
        rowData.indicatorStyles?.indicatorColor || "none";
    }
  };

  if (isIndicatorTypeNone) {
    const carouselWrapper =
      !isServer && document.querySelector(`#carousel-${rowData.id}`);

    if (carouselWrapper) {
      carouselWrapper.classList.add("without-bullets");
    }
  }

  useEffect(() => {
    const isBulletType = !isIndicatorTypeScroll || isIndicatorTypeNone;

    if (isBulletType) {
      onSlideChange();
    }
  }, [activeBullet, imageList.length]);

  useEffect(() => {
    if (isIndicatorTypeScroll && (loadedScriptStatus || window.Swiper)) {
      setIndicatorColor();
    }
    if (!isControlTypeNone && !isMobile.any()) {
      setControllerStyles();
    }
  }, [loadedScriptStatus]);

  const onSlideChange = () => {
    const allBullets =
      !isServer &&
      carouselRef?.current?.querySelectorAll(`.slider-bullet-${rowData.id}`);

    if (allBullets) {
      allBullets.forEach(bullet => {
        bullet.style.background =
          indicatorStyles?.backgroundIndicatorColor || "none";
      });
      setActiveBulletColor();
    }
  };

  const bullets = {
    pagination: {
      el: ".swiper-pagination",
      renderBullet: (index, className) => {
        return `<span class='${className} ${rowData.indicatorType} slider-bullet-${rowData.id}'></span>`;
      },
      bulletActiveClass: `swiper-pagination-bullet-active slider-bullet-active${rowData.id}`,
      clickable: true
    },
    on: {
      slideChange: onSlideChange
    }
  };

  const indicator = {
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
      draggable: true
    }
  };

  const paginationForSwiper = isIndicatorTypeScroll ? indicator : bullets;

  const setIndicatorColor = () => {
    const carouselID =
      !isServer && document.getElementById(`carousel-${rowData.id}`);

    const swiperScrollbar = getElement(carouselID, ".swiper-scrollbar");
    const swiperScrollbarDrag = getElement(
      carouselID,
      ".swiper-scrollbar-drag"
    );

    if (swiperScrollbar && swiperScrollbarDrag) {
      swiperScrollbar.style.background =
        indicatorStyles?.backgroundIndicatorColor || "none";
      swiperScrollbarDrag.style.background =
        indicatorStyles?.indicatorColor || "none";
    }
  };

  const navigation = {
    prevEl: ".swiper-button-prev",
    nextEl: ".swiper-button-next"
  };

  const autoScrollConfig = getAutoScrollConfig(autoScroll);

  const isInfiniteLoopEnable =
    rowData.itemsPerGroup <= 1 && !isEmpty(autoScrollConfig);
  const swiperParams = {
    ...autoScrollConfig,
    ...params,
    ...getInfiniteLoopConfig(isInfiniteLoopEnable, rowData),
    ...(controllerType &&
      !isControlTypeNone &&
      !isMobile.any() && { navigation }),
    slidesPerView: rowData.visibleImages,
    ...(rowData.indicatorType && !isIndicatorTypeNone && paginationForSwiper),
    freeMode: true
  };

  const rowStyles = {
    ...styles,
    maxWidth: width?.value || "100%",
    marginLeft: "auto",
    marginRight: "auto"
  };

  const imagesData = isMobile.any()
    ? imageList.filter(el => el[language]?.enabledOn?.includes("resp"))
    : imageList;

  const reorderedArray =
    rowData.itemsPerGroup > 1
      ? getReorderedArray(imagesData, rowData.itemsPerGroup)
      : imagesData;

  const isNotAvailable = isMobile.any() && !rowData.enabledOn?.includes("resp");

  if (isNotAvailable) {
    return null;
  }
  useEffect(() => {
    if (swiperRef.current && (loadedScriptStatus || window.Swiper)) {
      const swiper = new window.Swiper(swiperRef.current, swiperParams);
      isArabic && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [swiperRef, loadedScriptStatus]);

  const isNavigationEnabled = controllerType && !isControlTypeNone;

  const imageWidth = 100 / rowData.visibleImages;
  const carouselItems = reorderedArray.map((imageContent, index) => {
    const dataForAnalytic = {
      bannerModuleName: bannerModuleName?.toUpperCase(),
      index: index + 1,
      bannerRowTitle: rowData.title?.toUpperCase(),
      imageUrl: imageContent[language]?.imageURL,
      pageName: bannerPageTitle,
      imageListData: imageContent
    };
    return (
      <div
        key={index}
        className="swiper-slide"
        style={{ maxWidth: `${imageWidth}%`, height: "auto" }}
      >
        <CarouselItem
          imageContent={imageContent}
          language={language}
          imageWidth={imageWidth}
          dataForAnalytic={dataForAnalytic}
          itemIndex={index}
        />
      </div>
    );
  });

  return (
    <div
      style={rowStyles}
      className="dynamic-carousel-wrapper"
      id={`carousel-${rowData.id}`}
      ref={carouselRef}
    >
      <div className="swiper" ref={swiperRef}>
        <div className="swiper-wrapper">{carouselItems}</div>
        {isNavigationEnabled && (
          <>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </>
        )}

        <div className="swiper-pagination"></div>
        {isIndicatorTypeScroll && (
          <div className="swiper-scrollbar">
            <div className="swiper-scrollbar-drag"></div>
          </div>
        )}
      </div>
    </div>
  );
};

const params = {
  dots: true,
  rebuildOnUpdate: true,
  spaceBetween: 0
};

export default CarouselWrapperRow;
