import get from "lodash/get";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import {
  TOGGLE_QUICK_FILTER_MODAL,
  SET_QUICK_FILTER_STEP,
  SET_QUICK_FILTER_DATA,
  UPDATE_QUICK_FILTER_DATA,
  SET_QUICK_FILTER_STEP_PATH,
  SET_QUICK_FILTER_STEP_DATA,
  RESET_QUICK_FILTER,
  SET_CATEGORY,
  RESET_CATEGORIES,
  SET_SIZE,
  APPLY_SELECTED_CATEGORIES,
  SET_QUICK_FILTER_PRODUCTS,
  RESET_QUICK_FILTER_PRODUCTS,
  PARSE_PRODUCTS_SIZES,
  RESET_QUICK_FILTER_SELECTION
} from "../constants";
import { addDataFieldToPath } from "../../util/quickFilter";
import {
  getSizeKeys,
  getMappedSizeObject,
  getSortedLvl0SizeObject,
  getLvl0FilterArray,
  getFilteredSizeObject,
  getArrayFromSizeObject
} from "../../util/sizes";
import {
  ADULTS_CLOTHES_FORMATS,
  KIDS_CLOTHES_FORMATS,
  SHOES_FORMATS,
  QUICK_FILTER_SHOES,
  KIDS,
  FOOTWEAR,
  ADULTS_FLOW_STEPS_AMOUNT,
  KIDS_FLOW_STEPS_AMOUNT,
  TODDLERS_FLOW_STEPS_AMOUNT,
  TODDLERS
} from "../../constants/quickFilter";
import { includesSubString } from "../../util";

const initialState = {
  showQuickFilterModal: false,
  quickFilterData: {},
  quickFilterStep: 0,
  quickFilterStepPath: [],
  quickFilterStepData: null,
  selectedCategories: [],
  selectedSizeFilter: { lvl0: "", lvl1: "" },
  selectedLvl0Data: "",
  activeSlideKeyLvl1: "",
  sizeOption: "",
  selectedSizes: [],
  maxStepBySelectedCategory: 3,
  quickFilterProducts: {},
  groupedAvailableSizes: {}
};

const toggleQuickFilerModal = state => ({
  ...state,
  showQuickFilterModal: !state.showQuickFilterModal
});

const setQuickFilterData = (state, action) => ({
  ...state,
  quickFilterData: action.payload,
  quickFilterStepData: action.payload
});

const updateQuickFilterData = (state, action) => {
  const { childrenObject, updatePath } = action.payload;
  const { quickFilterData } = state;
  const structuredUpdatePath = addDataFieldToPath(updatePath);
  const updatedData = cloneDeep(quickFilterData);
  const updatedChildren = {
    ...get(updatedData, structuredUpdatePath, {}),
    ...childrenObject
  };
  set(updatedData, structuredUpdatePath, updatedChildren);
  return {
    ...state,
    quickFilterData: updatedData
  };
};

const setQuickFilterStep = (state, action) => ({
  ...state,
  quickFilterStep: action.payload
});

const setQuickFilterStepPath = (state, action) => {
  const {
    quickFilterStepPath,
    quickFilterData,
    quickFilterStep,
    selectedCategories,
    selectedSizes,
    maxStepBySelectedCategory,
    groupedAvailableSizes
  } = state;
  const path = action.payload;
  let updatedPath = quickFilterStepPath;
  let stepData = quickFilterData;
  const stepWithMultiSelection = maxStepBySelectedCategory - 1;
  const isSingleChoiceStep = quickFilterStep < stepWithMultiSelection;

  if (
    !maxStepBySelectedCategory ||
    quickFilterStep !== maxStepBySelectedCategory
  ) {
    updatedPath = [...quickFilterStepPath, path];
    if (path === -1) {
      updatedPath = quickFilterStepPath.slice(
        0,
        quickFilterStepPath.length - 1
      );
    }

    stepData = get(
      quickFilterData,
      addDataFieldToPath(updatedPath),
      quickFilterData
    );
  } else {
    stepData = get(
      quickFilterData,
      addDataFieldToPath(updatedPath),
      quickFilterData
    );
  }
  const nextStep =
    !isSingleChoiceStep && path === -1
      ? quickFilterStep - 1
      : updatedPath.length;
  const updatedCategories =
    quickFilterStep === stepWithMultiSelection ||
    quickFilterStep === maxStepBySelectedCategory
      ? [...initialState.selectedCategories]
      : [...selectedCategories];
  const updatedSizes =
    quickFilterStep === maxStepBySelectedCategory
      ? [...initialState.selectedSizes]
      : [...selectedSizes];
  const updatedAvailableSizes =
    quickFilterStep === maxStepBySelectedCategory
      ? { ...initialState.groupedAvailableSizes }
      : { ...groupedAvailableSizes };

  let maxStep = ADULTS_FLOW_STEPS_AMOUNT;
  if (updatedPath.includes(KIDS)) {
    maxStep = updatedPath.join("").includes(TODDLERS)
      ? TODDLERS_FLOW_STEPS_AMOUNT
      : KIDS_FLOW_STEPS_AMOUNT;
  }

  return {
    ...state,
    quickFilterStep: nextStep,
    selectedCategories: updatedCategories,
    selectedSizes: updatedSizes,
    quickFilterStepData: stepData,
    quickFilterStepPath: updatedPath,
    maxStepBySelectedCategory: maxStep,
    groupedAvailableSizes: updatedAvailableSizes
  };
};

const setQuickFilterStepData = (state, action) => ({
  ...state,
  quickFilterStepData: { ...state.quickFilterStepData, ...action.payload }
});

const setCategory = (state, action) => {
  const selectedCategory = action.payload;
  const updatedSelection = state.selectedCategories.includes(selectedCategory)
    ? state.selectedCategories.filter(category => category !== selectedCategory)
    : [...state.selectedCategories, selectedCategory];
  return {
    ...state,
    selectedCategories: updatedSelection
  };
};

const resetCategories = state => ({
  ...state,
  selectedCategories: initialState.selectedCategories
});

const applySelectedCategories = (state, action) => {
  const { nextStep } = action.payload;

  return {
    ...state,
    quickFilterStep: nextStep
  };
};

const setSize = (state, action) => {
  const sizeURL = action.payload;
  const { selectedSizes } = state;
  const updatedSelection = selectedSizes.includes(sizeURL)
    ? selectedSizes.filter(availableSizeURL => availableSizeURL !== sizeURL)
    : [...selectedSizes, sizeURL];
  return {
    ...state,
    selectedSizes: updatedSelection
  };
};

const setQuickFilterProducts = (state, { content, facetName }) => {
  const updatedFacets = content.facets;
  const specificFacet = facetName && {
    [facetName]: {
      ...updatedFacets[facetName]
    }
  };
  return {
    ...state,
    quickFilterProducts: {
      ...content,
      url: content.url || (state.products && state.products.url) || "",
      facets: {
        ...updatedFacets,
        ...specificFacet
      }
    }
  };
};

const resetQuickFilterProducts = state => ({
  ...state,
  quickFilterProducts: initialState.quickFilterProducts
});

const parseProductsSizes = (state, action) => {
  const sizeFacetObj = action.payload;
  const { quickFilterStepPath } = state;

  const sizeKeys = getSizeKeys(sizeFacetObj);
  const mappedSizeObject = getSortedLvl0SizeObject(
    getMappedSizeObject(sizeFacetObj, sizeKeys)
  );

  const lvl0Array = getLvl0FilterArray(mappedSizeObject);

  const joinedPath = quickFilterStepPath.join("");
  const shoesFlow =
    includesSubString(joinedPath, QUICK_FILTER_SHOES) ||
    includesSubString(joinedPath, FOOTWEAR);
  const clothesFormats = includesSubString(joinedPath, KIDS)
    ? KIDS_CLOTHES_FORMATS
    : ADULTS_CLOTHES_FORMATS;
  const shoesFormats = shoesFlow && SHOES_FORMATS;

  const sizesFormats = {
    ...(!shoesFlow && clothesFormats),
    ...(shoesFormats && shoesFormats)
  };

  const groupedAvailableSizes = Object.entries(sizesFormats).reduce(
    (acc, [title, formats]) => {
      const sizesObj = getFilteredSizeObject(
        lvl0Array,
        mappedSizeObject,
        formats
      );

      if (!isEmpty(sizesObj)) {
        acc[title] = orderBy(
          getArrayFromSizeObject(sizesObj),
          ["sortOrderlvl2"],
          ["asc"]
        );
      }
      return acc;
    },
    {}
  );

  return {
    ...state,
    groupedAvailableSizes
  };
};

const resetQuickFilterSelection = state => {
  return {
    ...initialState,
    quickFilterData: state.quickFilterData,
    quickFilterStepData: state.quickFilterData
  };
};

const resetQuickFilter = () => ({ ...initialState });

const quickFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_QUICK_FILTER_MODAL:
      return toggleQuickFilerModal(state, action);
    case SET_QUICK_FILTER_DATA:
      return setQuickFilterData(state, action);
    case UPDATE_QUICK_FILTER_DATA:
      return updateQuickFilterData(state, action);
    case SET_QUICK_FILTER_STEP_PATH:
      return setQuickFilterStepPath(state, action);
    case SET_QUICK_FILTER_STEP:
      return setQuickFilterStep(state, action);
    case SET_QUICK_FILTER_STEP_DATA:
      return setQuickFilterStepData(state, action);
    case SET_CATEGORY:
      return setCategory(state, action);
    case RESET_CATEGORIES:
      return resetCategories(state);
    case APPLY_SELECTED_CATEGORIES:
      return applySelectedCategories(state, action);
    case SET_QUICK_FILTER_PRODUCTS:
      return setQuickFilterProducts(state, action);
    case RESET_QUICK_FILTER_PRODUCTS:
      return resetQuickFilterProducts(state);
    case SET_SIZE:
      return setSize(state, action);
    case PARSE_PRODUCTS_SIZES:
      return parseProductsSizes(state, action);
    case RESET_QUICK_FILTER_SELECTION:
      return resetQuickFilterSelection(state);
    case RESET_QUICK_FILTER:
      return resetQuickFilter();
    default:
      return state;
  }
};

export default quickFilterReducer;
