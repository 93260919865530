import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CheckoutService } from "@/services";
import {
  OrderSummaryDeliveryDetails,
  PriceDetails
} from "@/services/cartService";
import { DELIVERY_TYPES, TOAST_TYPES } from "@/constants";
import { handleFetchError } from "@/util/errorHandler";
import { toastMsg } from "@/util/toast";

export type CheckoutState = {
  validation: {
    checkout:
      | {
          _id: string;
          orderItems: {
            cartId: string;
            productId: number;
            sizeId: number;
            quantity: number;
            promoType: string;
            transactionPrice: number;
            transactionOldPrice: number;
            upc: string;
            onlineStock: {
              countryId: number;
              locationCode: string;
              qty: number;
            }[];
            sizeStock: number;
          }[];
          paymentMode: [];
          deliveryType: string;
          customerId: number;
          visitorId: string;
        }
      | EmptyObject;
    success?: boolean;
  };
  summary:
    | {
        _id: string;
        address?: {
          addressId: number;
          firstname: string;
          lastname: string;
          addressLine1: string;
          zipcode?: string;
          area: string;
          cityId: string;
          countryId: number;
          phone: string;
          default: boolean;
          areaId: number;
          collectionPointId: string;
          country: string;
          city: string;
          deliveryType: DELIVERY_TYPES;
          isMobileVerified: boolean;
          email?: string;
        };
        customerId: number;
        visitorId: string;
        countryId: number;
        deliveryType: DELIVERY_TYPES;
        isGuestUser: boolean;
        step1Completed: boolean;
        step2Completed: boolean;
        step3Completed: boolean;
        priceDetails: PriceDetails & {
          currency: string;
          discountAmount: number;
          discountAmountLabel: string;
          shippingCharge: number;
          shippingChargeLabel: string;
          subTotal: number;
          subTotalLabel: string;
          totalPrice: number;
          totalPriceLabel: string;
          walletAmount: number;
          walletAmountLabel: string;
          codFee?: number;
        };
        deliveryDetails: OrderSummaryDeliveryDetails[];
        walletInfo: { useMyWallet: boolean; userWalletAmount: number };
        paymentType: string;
        voucher: Voucher;
        paymentSystem: {
          id: string;
          type: "MONEYHASH";
          paymentType: "Payin" | "Payout";
          enabled: boolean;
        };
        paymentGateway: string;
      }
    | EmptyObject;
  ccDetails: any;
  stepValidationDetails: {
    isSelectedStorePickUp: boolean;
    isNameAndPhoneNumberProvided: boolean;
  };
  addresses:
    | {
        success: boolean;
        message: string;
        addressDetails: [
          {
            addressId: number;
            firstname: string;
            lastname: string;
            addressLine1: string;
            zipcode: string;
            area: string;
            cityId: string;
            countryId: number;
            phone: string;
            default: boolean;
            areaId: number;
            collectionPointId: string;
            country: string;
            city: string;
            deliveryType: DELIVERY_TYPES;
            isMobileVerified: boolean;
          }
        ];
      }
    | EmptyObject;
  voucherData:
    | {
        success: boolean;
        message: string;
        voucherDetails: [Voucher];
      }
    | EmptyObject;
};

export type Voucher = {
  binNo: string;
  countryId: number;
  currencyCode: string;
  disc_value: { percent: number; max_discount: number };
  discount_limit: number;
  discount_type: string;
  expiryDate: string | Date;
  isBankSpecific: boolean;
  remarks: string;
  voucherCode: string;
  voucherNo: string;
  voucherText: {
    date: string;
    message: string;
  };
};

export type Address = {
  deliveryType: DELIVERY_TYPES;
  collectionPointId: number;
  firstname: string;
  lastname: string;
  addressLine1: string;
  phone: string;
  default: boolean;
  cityId: string;
  areaId: string;
  zipCode: string;
  area: string;
};

export const updateCheckout = createAsyncThunk(
  "checkout/updateCheckout",
  async (
    { checkoutId, changes }: { checkoutId: string; changes: any },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await CheckoutService.updateCheckoutData({
        checkoutId,
        changes
      });
      return response;
    } catch (error) {
      if (error.response && !error.response.data?.success) {
        toastMsg(
          TOAST_TYPES.ERROR,
          error.response.data?.message ||
            "Something went wrong during the checkout data changing",
          true
        );
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const setUseMyWallet = createAsyncThunk(
  "checkout/setUseMyWallet",
  async (
    data: { useMyWallet: boolean; walletAmount: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await CheckoutService.setUseMyWallet(data);
      return response;
    } catch (error) {
      toastMsg(
        TOAST_TYPES.ERROR,
        error?.response?.data?.message ||
          "Something went wrong during the wallet selection",
        true
      );

      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSavedAddresses = createAsyncThunk(
  "checkout/getSavedAddresses",
  async (
    {
      deliveryType
    }: {
      deliveryType: DELIVERY_TYPES;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await CheckoutService.getSavedAddresses(deliveryType);

      return response;
    } catch (error) {
      toastMsg(
        TOAST_TYPES.ERROR,
        error?.response?.data?.message ||
          "Something went wrong during the addresses getting",
        true
      );

      return rejectWithValue(error?.response?.data);
    }
  }
);

export const saveAddress = createAsyncThunk(
  "checkout/saveAddress",
  async (address: Address, { dispatch }) => {
    try {
      const response = await CheckoutService.saveAddress(address);
      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    }
  }
);

export const updateAddress = createAsyncThunk(
  "checkout/updateAddress",
  async ({ updatedAddress }: { updatedAddress: Address }, { dispatch }) => {
    try {
      const response = await CheckoutService.updateAddress(updatedAddress);

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    }
  }
);

export const getVoucherList = createAsyncThunk(
  "checkout/getVoucherList",
  async (
    data: { currencyCode: string; countryId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await CheckoutService.getVoucherList(data);

      return response;
    } catch (error) {
      toastMsg(
        TOAST_TYPES.ERROR,
        error?.response?.data?.message ||
          "Something went wrong during the vouchers getting",
        true
      );

      return rejectWithValue(error?.response?.data);
    }
  }
);

export const applyVoucher = createAsyncThunk(
  "checkout/applyVoucher",
  async ({ voucherCode, binNo }: { voucherCode: string; binNo?: string }) => {
    try {
      const response = await CheckoutService.applyVoucher({
        voucherCode,
        binNo
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeVoucher = createAsyncThunk(
  "checkout/removeVoucher",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await CheckoutService.removeVoucher();
      return response.data;
    } catch (error) {
      toastMsg(
        TOAST_TYPES.ERROR,
        error?.response?.data?.message ||
          "Something went wrong during the voucher removing",
        true
      );

      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeAddress = createAsyncThunk(
  "checkout/removeAddress",
  async (
    { addressId }: { addressId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await CheckoutService.removeAddress(addressId);
      return response;
    } catch (error) {
      toastMsg(
        TOAST_TYPES.ERROR,
        error?.response?.data?.message ||
          "Something went wrong during the address removing",
        true
      );

      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: CheckoutState = {
  validation: { checkout: {} },
  summary: {},
  addresses: {},
  ccDetails: {
    selectedCardCvv: "",
    selectedCardId: "",
    binCode: "",
    checkoutComFormValidationStatus: false,
    isVoucherFlowCompleted: false
  },
  stepValidationDetails: {
    isSelectedStorePickUp: false,
    isNameAndPhoneNumberProvided: false
  },
  voucherData: {}
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setValidationData(state, action) {
      state.validation = action.payload;
    },
    resetValidationData(state) {
      state.validation = initialState.validation;
    },
    setOrderSummary(state, action) {
      state.summary = action.payload;
    },
    resetOrderSummary(state) {
      state.summary = initialState.summary;
    },
    resetPriceDetails(state) {
      state.summary.priceDetails = initialState.summary.priceDetails;
    },
    resetAddresses(state) {
      state.addresses = initialState.addresses;
    },
    setSelectedCardCvv(state, action) {
      state.ccDetails.selectedCardCvv = action.payload;
    },
    setSelectedCardId(state, action) {
      state.ccDetails.selectedCardId = action.payload;
    },
    setBinCode(state, action) {
      state.ccDetails.binCode = action.payload;
    },
    setIsSelectedStorePickUp(state, action) {
      state.stepValidationDetails.isSelectedStorePickUp = action.payload;
    },
    setIsNameAndPhoneNumberProvided(state, action) {
      state.stepValidationDetails.isNameAndPhoneNumberProvided = action.payload;
    },
    setVoucherList(state, action) {
      state.voucherData = action.payload;
    },
    setCheckoutComFormValidationStatus(state, action) {
      state.ccDetails.checkoutComFormValidationStatus = action.payload;
    },
    setIsVoucherFlowCompleted(state, action) {
      state.ccDetails.isVoucherFlowCompleted = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(updateCheckout.fulfilled, (state, action) => {
      state.summary = action.payload;
    });
    builder.addCase(applyVoucher.fulfilled, (state, action) => {
      if (!action.payload.hasOwnProperty("success"))
        state.summary = action.payload;
    });
    builder.addCase(removeVoucher.fulfilled, (state, action) => {
      if (!action.payload.hasOwnProperty("success"))
        state.summary = action.payload;
    });
    builder.addCase(setUseMyWallet.fulfilled, (state, action) => {
      state.summary = action.payload;
    });
    builder.addCase(getSavedAddresses.fulfilled, (state, action) => {
      state.addresses = action.payload;
    });
    builder.addCase(saveAddress.fulfilled, (state, action) => {
      state.addresses = action.payload;
    });
    // builder.addCase(applyVoucher.fulfilled, (state, action) => {
    //   state.summary.voucher = action.payload?.voucher;
    // });
  }
});

export default checkoutSlice;
