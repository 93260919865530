export const TOGGLE_QUICK_FILTER_MODAL = "TOGGLE_QUICK_FILTER_MODAL";
export const SET_QUICK_FILTER_DATA = "SET_QUICK_FILTER_DATA";
export const UPDATE_QUICK_FILTER_DATA = "UPDATE_QUICK_FILTER_DATA";
export const SET_QUICK_FILTER_STEP = "SET_QUICK_FILTER_STEP";
export const SET_QUICK_FILTER_STEP_PATH = "SET_QUICK_FILTER_STEP_PATH";
export const SET_QUICK_FILTER_STEP_DATA = "SET_QUICK_FILTER_STEP_DATA";
export const SET_CATEGORY = "SET_CATEGORY";
export const RESET_CATEGORIES = "RESET_CATEGORIES";
export const APPLY_SELECTED_CATEGORIES = "APPLY_SELECTED_CATEGORIES";
export const SET_QUICK_FILTER_PRODUCTS = "SET_QUICK_FILTER_PRODUCTS";
export const RESET_QUICK_FILTER_PRODUCTS = "RESET_QUICK_FILTER_PRODUCTS";
export const SET_SIZE = "SET_SIZE";
export const PARSE_PRODUCTS_SIZES = "PARSE_PRODUCTS_SIZES";
export const RESET_QUICK_FILTER = "RESET_QUICK_FILTER";
export const RESET_QUICK_FILTER_SELECTION = "RESET_QUICK_FILTER_SELECTION";
