import React, { FC } from "react";
import MenuListWrapper from "./menuListWrapper";
import "./style.scss";

const CategoryMenu: FC<{
  menuList: MenuList;
}> = ({ menuList }) => {
  const { col1, col2, col3, col4, col5, col6, headerTitle, en_headerTitle } =
    menuList;
  const listWrapper = [col1, col2, col3];
  const bannerWrapper = [col4, col5, col6];
  const headerTitles = {
    headerTitle,
    en_headerTitle
  };

  // List Wrapper
  const listWrap = listWrapper.map((col, index) => (
    <MenuListWrapper col={col} key={index} headerTitles={headerTitles} />
  ));

  // Banner Wrapper
  const indexOfImagesContainer = bannerWrapper.findIndex(
    col => col && !!Array.isArray((col as MenuBannerType).images)
  );

  const lastBannerSelected =
    indexOfImagesContainer > -1
      ? bannerWrapper.slice(0, indexOfImagesContainer + 1)
      : bannerWrapper;

  const promotionCategory = lastBannerSelected.map((col, index) => (
    <MenuListWrapper col={col} key={index} headerTitles={headerTitles} />
  ));

  return (
    <>
      <div className="category-menu-bg category-menu-bg-toggle" />
      <div className="category-menu category-menu-bg-toggle">
        <section className="container padding0">
          <div className="list-wrapper">{listWrap}</div>
          <div className="promotion-category">{promotionCategory}</div>
        </section>
      </div>
    </>
  );
};

export type Menu = {
  subTitle: string;
  redirectUrl: string;
  bold: boolean;
  isPublish: boolean;
  color: {
    id: number;
    value: string;
  };
  children: Menu[];
  imageUrl: string;
  uid: number;
  groupBy?: string;
  isDisabled?: boolean;
};
export type MenuBannerType = {
  imgType: number;
  type: string;
  images: any[];
};

type MenuList = {
  col1: Menu[];
  col2: Menu[];
  col3: Menu[];
  col4: Menu[] | MenuBannerType;
  col5: Menu[] | MenuBannerType;
  col6: Menu[] | MenuBannerType;
  en_headerTitle?: string;
  headerTitle: string;
  imageUrl: string;
  pos: string;
  isSPS: boolean;
  isEnabledSPS: boolean;
  hoverImageUrl: string;
  redirectionLink: string;
  catCarousalList: any[];
  isPublish: boolean;
  uid: string;
  isPublished: boolean;
};

export default CategoryMenu;
