import { useState } from "react";
import { FilterOption, GeneralFacet } from "../types";

const useMultiselectFilter = (
  filter: Omit<GeneralFacet & { data: FilterOption[] }, "type" | "sortOrder">,
  onSelection?: (...args) => void
) => {
  const { data: filterOptions, ...restFilterData } = filter;
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);
  const onFilterOptionClick = ({ option }, event) => {
    event.stopPropagation();
    let updatedOptions = [];
    if (
      selectedOptions.some(selectedOption => selectedOption.key === option.key)
    ) {
      updatedOptions = selectedOptions.filter(
        selectedOption => selectedOption.key !== option.key
      );
    } else {
      updatedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);

    onSelection &&
      onSelection({
        ...restFilterData,
        selectedOptions: updatedOptions
      });
  };

  const clearSelectedOptions = () => {
    setSelectedOptions([]);
    onSelection &&
      onSelection({
        ...restFilterData,
        selectedOptions: []
      });
  };

  return {
    filterOptions,
    selectedOptions,
    onFilterOptionClick,
    clearSelectedOptions,
    setSelectedOptions
  };
};

export default useMultiselectFilter;
