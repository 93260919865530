import React from "react";
import spinnerImage from "./images/ripple.svg";
import "./css/Ripple.scss";
import "./css/common-loader.scss";

const Ripple = ({ height = 150, width = 150, color = "green", fullscreen }) =>
  (fullscreen ? buildFullScreenHtml : buildInlineScreenHtml)({
    height,
    width,
    color
  });

const buildFullScreenHtml = props => (
  <div>
    <div className="bfl-loader-wrapper" />
    <div className="loader-container">
      <img
        alt="load"
        src={spinnerImage}
        style={{ height: props.height, width: props.width }}
      />
    </div>
  </div>
);

const buildInlineScreenHtml = props => (
  <div className="inline-loader-container">
    <img
      alt="load"
      src={spinnerImage}
      style={{ height: props.height, width: props.width }}
    />
  </div>
);

export default Ripple;
