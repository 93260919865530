import React, { FC } from "react";

export const ApplyButton: FC<{
  onButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonText: string | React.ReactNode;
  wrapperStyles?: React.CSSProperties;
}> = ({ onButtonClick, buttonText, wrapperStyles }) => {
  return (
    <div className="apply_button_wrapper" style={wrapperStyles}>
      <button className="apply_button" onClick={onButtonClick}>
        {buttonText}
      </button>
    </div>
  );
};
