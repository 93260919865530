import React from "react";
import BounceLoader from "../../bounce-loader";
import "./css/Ring.scss";
import "./css/common-loader.scss";

const Dots = ({
  size = 16,
  qty = 3,
  background = "rgba(0,0,0,0.70)",
  bounceLoaderColor = "#f8d000",
  fullscreen
}) =>
  (fullscreen ? buildFullScreenHtml : buildInlineScreenHtml)({
    size,
    qty,
    background,
    bounceLoaderColor
  });

const buildFullScreenHtml = props => (
  <div className="bfl-loader-wrapper" style={{ background: props.background }}>
    <div className="loader-container">
      <BounceLoader
        color={props.bounceLoaderColor}
        size={props.size}
        qty={props.qty}
      />
    </div>
  </div>
);

const buildInlineScreenHtml = props => (
  <div className="inline-loader-container">
    <BounceLoader
      color={props.bounceLoaderColor}
      size={props.size}
      qty={props.qty}
    />
  </div>
);

export default Dots;
