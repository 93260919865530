import {
  CREATE_USER,
  SESSION_TERMINATED,
  AUTHENTICATE_USER,
  API_ERROR,
  RESET_API_ERROR,
  API_NOTIFICATION_MSG,
  RESET_NOTIFICATION_MSG,
  IS_HOME_PAGE,
  HOME_PAGE_ERROR,
  SET_REGISTRATION_DATA_STATUS,
  RESET_REGISTRATION_DATA_STATUS,
  SET_SIGNUP_LOGIN_TOKEN,
  RESET_SIGNUP_LOGIN_TOKEN,
  SET_SOCIAL_LOGIN_FLOW,
  RESET_SOCIAL_LOGIN_FLOW,
  SET_SOCIAL_PHONE_CHECK_TOKEN,
  RESET_SOCIAL_PHONE_CHECK_TOKEN,
  SET_ANALYTICS_DATA,
  SET_LAST_REGISTRATION_STATUS_CODE,
  SET_REGISTRATION_ERROR,
  SET_ADDRESS_OTP_ERROR,
  SET_ADDRESS_OTP_FLOW,
  SET_OTP_LOGIN_FLOW_BFL
} from "../constants";

const initialState = {
  isHomePage: false,
  isHomePageError: false,
  signUp: {
    nationality: 1
  },
  APIErrorResponse: {
    success: true,
    message: ""
  },
  forgotPassword: {
    APINotificationMsg: ""
  },
  homepageState: {
    storeId: "",
    countryId: "",
    sections: []
  },
  registrationDataStatus: {},
  signUpLoginToken: "",
  socialPhoneCheckToken: "",
  socialLoginFlow: true,
  otpLoginFlowBFL: false,
  analyticsData: {},
  lastRegistrationStatusCode: "",
  registrationError: "",
  addressOtpFlow: { error: "" }
};

const setAPIErrorResponse = (state, action) => ({
  ...state,
  APIErrorResponse: action.content
});
const resetAPIErrorResponse = (state, action) => ({
  ...state,
  APIErrorResponse: initialState.APIErrorResponse
});
const setAPINotificationMsg = (state, action) => ({
  ...state,
  forgotPassword: {
    APINotificationMsg: action.message
  }
});
const resetAPINotificationMsg = state => ({
  ...state,
  forgotPassword: {
    ...state.forgotPassword,
    APINotificationMsg: initialState.forgotPassword.APINotificationMsg
  }
});

const createUser = state => ({ ...state });

const authenticateUser = state => ({ ...state });

const homePageError = (state, action) => ({
  ...state,
  isHomePageError: action.isError
});

const isHomePage = (state, { value }) => ({ ...state, isHomePage: value });

const setRegistrationDataStatus = (state, { payload }) => ({
  ...state,
  registrationDataStatus: payload
});

const resetRegistrationDataStatus = state => ({
  ...state,
  registrationDataStatus: initialState.registrationDataStatus
});

const setSignUpLoginToken = (state, { payload }) => ({
  ...state,
  signUpLoginToken: payload
});

const resetSignUpLoginToken = state => ({
  ...state,
  registrationDataStatus: initialState.signUpLoginToken
});

const setSocialLoginFlow = (state, { payload }) => ({
  ...state,
  socialLoginFlow: payload
});
const resetSocialLoginFlow = state => ({
  ...state,
  socialLoginFlow: initialState.socialLoginFlow
});

const setSocialPhoneCheckToken = (state, { payload }) => ({
  ...state,
  socialPhoneCheckToken: payload
});

const resetSocialPhoneCheckToken = state => ({
  ...state,
  socialPhoneCheckToken: initialState.socialPhoneCheckToken
});

export const setAnalyticsData = (state, { payload }) => ({
  ...state,
  analyticsData: payload
});

export const setLastRegistrationStatusCode = (state, { payload }) => ({
  ...state,
  lastRegistrationStatusCode: payload
});

export const setRegistrationError = (state, { payload }) => ({
  ...state,
  registrationError: payload
});

const setAddressOtpError = (state, action) => ({
  ...state,
  addressOtpFlow: { ...state.addressOtpFlow, error: action.payload }
});

const setAddressOtpFlow = (state, action) => ({
  ...state,
  addressOtpFlow: { ...state.addressOtpFlow, ...action.payload }
});

const setOTPLoginFlowBFL = (state, action) => ({
  ...state,
  otpLoginFlowBFL: action.payload
});

// const clearBannersSectionsData = state => {
//   return {
//     ...state,
//     homepageState: {
//       ...state.homepageState,
//       sections: []
//     }
//   };
// };

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_TERMINATED:
      return state;
    case CREATE_USER:
      return createUser(state, action);
    case AUTHENTICATE_USER:
      return authenticateUser(state, action);
    case HOME_PAGE_ERROR:
      return homePageError(state, action);
    case API_ERROR:
      return setAPIErrorResponse(state, action);
    case RESET_API_ERROR:
      return resetAPIErrorResponse(state);
    case API_NOTIFICATION_MSG:
      return setAPINotificationMsg(state, action);
    case RESET_NOTIFICATION_MSG:
      return resetAPINotificationMsg(state);
    case IS_HOME_PAGE:
      return isHomePage(state, action);
    case SET_REGISTRATION_DATA_STATUS:
      return setRegistrationDataStatus(state, action);
    case RESET_REGISTRATION_DATA_STATUS:
      return resetRegistrationDataStatus(state);
    case SET_SIGNUP_LOGIN_TOKEN:
      return setSignUpLoginToken(state, action);
    case RESET_SIGNUP_LOGIN_TOKEN:
      return resetSignUpLoginToken(state);
    case SET_SOCIAL_LOGIN_FLOW:
      return setSocialLoginFlow(state, action);
    case RESET_SOCIAL_LOGIN_FLOW:
      return resetSocialLoginFlow(state);
    case SET_SOCIAL_PHONE_CHECK_TOKEN:
      return setSocialPhoneCheckToken(state, action);
    case RESET_SOCIAL_PHONE_CHECK_TOKEN:
      return resetSocialPhoneCheckToken(state);
    case SET_ANALYTICS_DATA:
      return setAnalyticsData(state, action);
    case SET_LAST_REGISTRATION_STATUS_CODE:
      return setLastRegistrationStatusCode(state, action);
    case SET_REGISTRATION_ERROR:
      return setRegistrationError(state, action);
    case SET_ADDRESS_OTP_ERROR:
      return setAddressOtpError(state, action);
    case SET_ADDRESS_OTP_FLOW:
      return setAddressOtpFlow(state, action);
    case SET_OTP_LOGIN_FLOW_BFL:
      return setOTPLoginFlowBFL(state, action);

    default:
      return state;
  }
};

export default homepageReducer;
