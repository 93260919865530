import React, { FC } from "react";
import map from "lodash/map";
import { Image } from "@/components/image";
import { CategoryItem } from "./types";

const GroupCategories: FC<{
  title: string;
  dataList: CategoryItem[];
  handleClick: (item: CategoryItem) => void;
}> = ({ title, dataList, handleClick }) => (
  <div className="group_by_categories_wrapper">
    {title && <h3>{title}</h3>}
    <ul className="categories_list">
      {map(dataList, item => (
        <li key={item.uid} onClick={() => handleClick(item)}>
          <span className="image_wrapper">
            <Image src={item.imageUrl || ""} />
          </span>
          <span className="item_sub_title">{item.subTitle}</span>
        </li>
      ))}
    </ul>
  </div>
);

export default GroupCategories;
