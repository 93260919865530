import {
  LISA_CLIENT_NAME,
  LISA_LIBRARY_HOST_NODE,
  LISA_LIBRARY_PLAYER_ID,
  LISA_LIBRARY_SDK_URL
} from "../../constants";
import { getDeviceType, isMobile } from "../../util";
import { addScriptToBody } from "../../util/dom";

const lisaListTemplate = `{{#each sections}}
<div class="lisa__section{{#with variations}}{{#each .}} lisa__section--{{.}}{{/each}}{{/with}} ${getDeviceType()}">
  {{#title}}
    <div class="lisa__section-title">{{.}}</div>
  {{/title}}
  {{#with items}}
    <ul class="lisa__section-listing">
      {{#each .}}
        <li class="lisa__show{{#with variations}}{{#each .}} lisa__show--{{.}}{{/each}}{{/with}}" data-lisa-show-id="{{id}}">
          {{#asset}}
            <div class="lisa__show-assets">
              <div class="lisa__show-asset {{#orientation}}lisa__show-asset--{{.}}{{/orientation}}">
                <img class="lisa__show-asset--lazy" data-src="{{url}}" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="{{../../title}}" />
                <noscript>
                  <img src="{{url}}" alt="{{../../title}}" />
                </noscript>
              </div>
            </div>
          {{/asset}}
          <div class="lisa__show-data">
            {{#tag}}
              <span class="lisa__show-tag">
                <span>{{.}}</span>
              </span>
            {{/tag}}
            {{#title}}
              <strong class="lisa__show-title">{{.}}</strong>
            {{/title}}
            {{#description}}
              <div class="lisa__show-description">{{{.}}}</div>
            {{/description}}
            {{#date}}
              <span class="lisa__show-date">{{.}}</span>
            {{/date}}
          </div>
          <a class="lisa__show-cta-play">
            <svg>
              <circle cx="41" cy="41" r="40"></circle>
              <polygon points="32,25 32,58 60,42"></polygon>
            </svg>
          </a>
        </li>
      {{/each}}
    </ul>
  {{/with}}
</div>
{{/each}}`;

const getPrivilegedShow = shows => {
  const { live, upcoming, conducted } = shows.reduce(
    (acc, show) => {
      if (show.live) {
        acc.live.push(show);
      } else if (show.conducted) {
        acc.conducted.push(show);
      } else {
        acc.upcoming.push(show);
      }
      return acc;
    },
    { live: [], conducted: [], upcoming: [] }
  );

  return (live[0] || upcoming[0] || conducted.pop() || {}).id;
};

class LiSAService {
  library = null;
  script = null;
  isLoaded = false;
  format = isMobile.any() ? "auto" : "landscape";

  getLisaLibConfig = settings => ({
    // autoplay?: type Autoplay = boolean | ((sources: ComposedShow[], library: Library) => string | undefined),
    autoplay: getPrivilegedShow,
    // channel?: string;
    client: LISA_CLIENT_NAME,
    // data?: {
    //   asset?: (source: ComposedShow, preSelected?: Asset) => Asset | undefined;
    //   date?: ((source: ComposedShow, preSelected?: Date) => string) | string | undefined;
    //   description?: (source: ComposedShow, preSelected?: string) => string | undefined;
    //   title?: (source: ComposedShow, preSelected?: string) => string | undefined;
    // },
    debug: process.env.REACT_APP_ENV === "development",
    hostNode: `#${LISA_LIBRARY_HOST_NODE}`,
    language: settings.language, //LanguageCode;
    layout: {
      view: settings?.view?.trim?.().toLowerCase() || "grid"
    },
    // layout?: type LayoutConfig = {
    //   assetOrientation?: Orientation;
    //   breakpoints?: number[];
    //   view?: 'grid' | 'list' | 'slider';
    // },
    on: {
      // {
      //   error?: (err: Error | string) => void;
      //   ready?: (library: Library) => void;
      // }
      ready: library => {
        if (settings?.on?.ready) {
          settings.on.ready(library);
        }
        const playerSettings = settings?.getPlayerSettings?.(library);
        if (playerSettings) {
          for (let prop in playerSettings) {
            library.player.on(prop, playerSettings[prop]);
          }
        }
        const backdropSettings = settings?.getBackdropSettings?.(library);
        if (backdropSettings) {
          for (let prop in backdropSettings) {
            library.backdrop.on(prop, backdropSettings[prop]);
          }
        }
      }
    },
    player: {
      format: this.format,
      // format?: 'auto' | Orientation;
      host: `#${LISA_LIBRARY_PLAYER_ID}`, //'overlay' | string;
      backdropBehaviour: "ignore",
      minimizeOnClose: true
    },
    // querystring?: string;
    // quickView?: QuickViewConfig;
    ...(settings.sections && { sections: settings.sections }),
    // sections: [
    //   {
    //     limit: 10,
    //     order: -1,
    //     state: "replay",
    //     tag: "RECORDED",
    //     title: "Recent shows"
    //   },
    //   {
    //     limit: 3,
    //     order: -1,
    //     state: "live",
    //     tag: "LIVE",
    //     title: "Currently live"
    //   },
    //   {
    //     limit: 5,
    //     order: 1,
    //     state: "preShow",
    //     tag: "UPCOMING",
    //     title: "Upcoming shows"
    //   }
    // ],
    // store?: string;
    // template?: string;
    template: lisaListTemplate
  });

  libInit = settings => {
    window.LiSA = window.LiSA || {};
    if (!window.LiSA.library) {
      window.LiSA.library = { configs: [] };
      this.script = addScriptToBody(LISA_LIBRARY_SDK_URL, () => {
        this.isLoaded = true;
      });
      const config = this.getLisaLibConfig(settings);
      window.LiSA.library.configs.push(config);
      this.library = window.LiSA.library;
    }
  };

  libDestroy = () => {
    if (this.isLoaded && this.library) this.library.destroy();
  };

  libReInit = settings => {
    this.libDestroy();
    if (this.isLoaded && this.library.reInit) {
      const config = this.getLisaLibConfig(settings);
      window.LiSA.library.configs = [config];
      this.library.reInit();
      return;
    }
    this.libInit(settings);
  };
}

const instance = new LiSAService();

export default instance;
