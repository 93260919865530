import {
  SET_NEWS_LETTER_SUBSCRIPTION_DATA,
  RESET_NEWS_LETTER_SUBSCRIPTION_DATA,
  SET_FOOTER_DATA
} from "../constants";

const initialState = {
  newsletterSubscription: {
    email: ""
  },
  content: {}
};

const setFooter = (state, { content }) => ({
  ...state,
  content: content
});

const setNewsletterSubscription = (state, action) => {
  return {
    ...state,
    newsletterSubscription: action.newsletter
  };
};

const resetNewsletterSubscription = state => {
  return {
    ...state,
    newsletterSubscription: initialState.newsletterSubscription
  };
};

const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FOOTER_DATA:
      return setFooter(state, action);
    case SET_NEWS_LETTER_SUBSCRIPTION_DATA:
      return setNewsletterSubscription(state, action);
    case RESET_NEWS_LETTER_SUBSCRIPTION_DATA:
      return resetNewsletterSubscription(state);
    default:
      return state;
  }
};
export default footerReducer;
