import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BEConfig } from "@/config/env";
import { Product } from "@/services/productService";
import { getVisitorId } from "@/util";
import { handleFetchError } from "@/util/errorHandler";
import { getAccessToken } from "@/util/storeHelper";
import { fetchWebApi } from "@/webapis/apiResource";
import { ContextStorage } from "@/libs/contextStorage";

type QueryItem = {
  query: {
    value: string;
    matchLevel: string;
    matchedWords: string[];
  };
};
type SearchState = {
  suggestions: any;
  trending: QueryItem[];
  products: Product[];
};
type SearchArgs = {
  language: string;
  value?: string;
};

const initialState: SearchState = {
  suggestions: [],
  trending: [],
  products: []
};

export const getSearchSuggestions = createAsyncThunk(
  "suggestions/fetchAll",
  async (data: SearchArgs) => {
    const { value, language } = data;
    const { protocol, baseURL, port, versionInfo, productSuggestions } =
      BEConfig.productsApi;
    const apiURL = `${protocol}${baseURL}${port}${versionInfo}${productSuggestions}?searchText=${value}`;

    try {
      const response = await fetchWebApi(
        getAccessToken(ContextStorage.store.getState()),
        apiURL,
        getVisitorId(),
        language
      );

      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export const getSearchTrending = createAsyncThunk(
  "trending/fetchAll",
  async (data: SearchArgs) => {
    // TODO: move to service
    const { language } = data;
    const { protocol, baseURL, port, versionInfo, trendingProducts } =
      BEConfig.productsApi;
    const apiURL = `${protocol}${baseURL}${port}${versionInfo}${trendingProducts}`;
    const state = ContextStorage.store.getState();

    try {
      const response = await fetchWebApi(
        getAccessToken(state),
        apiURL,
        getVisitorId(),
        language
      );

      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchSuggestions(state, action) {
      state.suggestions = action.payload.suggestions;
      state.products = action.payload.products;
    },
    setSearchTrending(state, action) {
      state.trending = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getSearchSuggestions.fulfilled, (state, action) => {
      state.products = action.payload.products.hits;
      state.suggestions = action.payload.suggestions;
    });
    builder.addCase(getSearchSuggestions.rejected, (state, action) => {
      const error = action.error;
      //@ts-ignore
      handleFetchError(error, ContextStorage.store.dispatch);
    });

    builder.addCase(getSearchTrending.fulfilled, (state, action) => {
      state.trending = action.payload.trending;
    });
    builder.addCase(getSearchTrending.rejected, (state, action) => {
      const error = action.error;
      //@ts-ignore
      handleFetchError(error, ContextStorage.store.dispatch);
    });
  }
});

export default searchSlice;
