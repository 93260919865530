import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";
import FilterMultiSelectWeb from "./filterMultiSelectWeb";
import { FILTER_TYPES } from "../constants";
import FilterSingleSelectWeb from "./filterSingleSelectWeb";
import FilterRangeWeb from "./filterRangeWeb";
import FilterCategoryWeb from "./filterCategoryWeb";
import FilterSizesWeb from "./filterSizesWeb";
import {
  CategoryFacet,
  Facet,
  GeneralFacet,
  MultiSelectFacet,
  PriceFacet,
  SingleSelectFacet,
  SizeFacet
} from "../types";

type FilterProps = {
  filter: Omit<Facet, "sortOrder">;
};

const FilterContainer: FC<FilterProps> = ({ filter }) => {
  if (isEmpty(filter?.data)) throw new Error();

  switch (filter.type) {
    case FILTER_TYPES.MULTI_SELECT:
      return (
        <FilterMultiSelectWeb
          filter={filter as GeneralFacet & MultiSelectFacet}
        />
      );
    case FILTER_TYPES.CATEGORY:
      return (
        <FilterCategoryWeb filter={filter as GeneralFacet & CategoryFacet} />
      );
    case FILTER_TYPES.PRICE:
      return <FilterRangeWeb filter={filter as GeneralFacet & PriceFacet} />;
    case FILTER_TYPES.SIZE:
      return <FilterSizesWeb filter={filter as GeneralFacet & SizeFacet} />;
    case FILTER_TYPES.SINGLE_SELECT:
      return (
        <FilterSingleSelectWeb
          filter={filter as GeneralFacet & SingleSelectFacet}
        />
      );
    default:
      return null;
  }
};

export default FilterContainer;
