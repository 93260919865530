import React, { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useRouteSlug } from "@/hooks";
import { useAppSelector } from "@/redux/store";

import { selectCartGeneralCount } from "@/util/selectors";
import "./style.scss";

const CartIconMobile = () => {
  const routeSlug = useRouteSlug();

  const currency = useAppSelector(state => state.common.settings?.currencyCode);

  const cartGeneralCount = useAppSelector(selectCartGeneralCount);

  const history = useHistory();

  const moveToCart = useCallback(() => {
    history.push(`/${routeSlug}/my-cart/`);
  }, [currency, routeSlug, history]);

  return (
    <div className="cart">
      <div>
        <span className="cart_icon" onClick={moveToCart} />
        {Boolean(cartGeneralCount) && (
          <>
            <span className="items_in_bucket" onClick={moveToCart}>
              {cartGeneralCount >= 100 ? "99+" : cartGeneralCount}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(CartIconMobile);
