import React, { DetailedHTMLProps, FC, Fragment, HTMLAttributes } from "react";
import Countdown from "react-countdown";
import "./style.scss";
import { CountDownContentType } from "../dynamic/dynamicSingleBanner/types";

type CustomCountdownProps = {
  date: string | number | Date;
  type: string | number;
  onCountdownComplete?: () => void;
  content: CountDownContentType;
  children?: string;
  textStyles?:
    | DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
    | React.CSSProperties;
};

const CustomCountdown: FC<CustomCountdownProps> = ({
  date,
  type,
  children,
  onCountdownComplete,
  content,
  textStyles
}) => {
  const renderer = ({ completed, formatted }) => {
    if (completed) {
      return null;
    }

    const { days, hours, minutes, seconds } = formatted;

    const renderSection = (number, name, type) => {
      const style = {
        ...(content?.backgroundColor && {
          backgroundColor: content?.backgroundColor
        })
      };

      return (
        <div className="bfl-countdown_section" {...(type === 1 && { style })}>
          {[...number].map((numString, index) => (
            <span
              key={`${name}_${index}`}
              className="bfl-countdown_box"
              style={{
                ...(type !== 1 && style),
                ...(content?.color && { color: content?.color })
              }}
            >
              {numString}
            </span>
          ))}
          {name && (
            <span
              className="bfl-countdown_text"
              style={{ ...(textStyles && textStyles) }}
            >
              {name === "days" ? `day${Number(number) === 1 ? "" : "s"}` : name}
            </span>
          )}
        </div>
      );
    };

    const getRenderSections = type => {
      const style = {
        ...(content?.backgroundColor && {
          color: content?.backgroundColor
        })
      };
      const dots = (
        <span className="colon-dots" style={style}>
          :
        </span>
      );
      return (
        <>
          {days !== "00" && (
            <>
              {renderSection(days, "days", type)} {dots}
            </>
          )}
          {renderSection(hours, "hours", type)} {dots}
          {renderSection(minutes, "minutes", type)} {dots}
          {renderSection(seconds, "seconds", type)}
        </>
      );
    };

    switch (type) {
      case 1:
        return (
          <Fragment>
            {children}
            <div className="bfl-countdown_wrapper-1">
              {getRenderSections(1)}
            </div>
          </Fragment>
        );

      case 2:
      default:
        return (
          <Fragment>
            {children}
            <div className="bfl-countdown_wrapper-2">
              {getRenderSections(2)}
            </div>
          </Fragment>
        );
    }
  };

  return (
    <Countdown
      date={date}
      renderer={props => renderer(props)}
      onComplete={onCountdownComplete}
    />
  );
};

export default CustomCountdown;
