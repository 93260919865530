import { createSlice } from "@reduxjs/toolkit";
import { PLP_CMS_DATA_SLUGS } from "@/constants";
import { CatalogDataProps, Content } from "@/routes/catalogPage/types";
import {
  MetaData,
  ProductsListingPageData
} from "@/routes/productListing/types";

export type BreadCrumbData = {
  defaultValue: string;
  link: string;
  value: string;
};

type CatalogPageData = CatalogDataProps & {
  variants?: any[];
  externalAssets?: {
    css: string[];
  };
};

type DynamicPageState = {
  data: {
    cms: (Content & { slug?: PLP_CMS_DATA_SLUGS })[];
    breadcrumbs: BreadCrumbData[];
    data: ProductsListingPageData | CatalogPageData;
    meta: MetaData;
    type: string;
    analytics?: any;
    url: string;
    isBrandPage?: boolean;
    countryId: number;
    language: string;
  };
};

const initialState: DynamicPageState = {
  data: {
    cms: null,
    breadcrumbs: null,
    data: null,
    meta: null,
    type: "",
    url: "",
    countryId: null,
    language: ""
  }
};

const dynamicPageSlice = createSlice({
  name: "dynamicPage",
  initialState,
  reducers: {
    setDynamicPageData(state, action) {
      state.data = action.payload;
    },
    resetDynamicPageData(state) {
      state.data = initialState.data;
    }
  }
});

export default dynamicPageSlice;
