import loadable from "@loadable/component";

const UserPreferences = loadable(
  () =>
    import(
      /* webpackChunkName: "userPreferences component" */ "./userPreferences"
    )
);

export default UserPreferences;
