import React from "react";
import {
  LISA_LIBRARY_HOST_NODE,
  LISA_LIBRARY_PLAYER_ID
} from "../../constants";

export const LisaLibrary = () => (
  <>
    <div id={LISA_LIBRARY_PLAYER_ID} />
    <div id={LISA_LIBRARY_HOST_NODE} className="container mb-5" />
  </>
);
