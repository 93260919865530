import Loadable from "@loadable/component";

const ProductDetailsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "product_details_page" */ "./productDetailsContainer"
    )
);

export default ProductDetailsPage;
