import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { Range } from "react-input-range";
import isEqual from "lodash/isEqual";
import cn from "clsx";
import { SelectedFiltersMobContext } from ".";
import { useIsRTL } from "@/hooks";
import RangeSelector from "@/components/rangeSelector";
import FilterWrapperElement from "@/routes/productListing/productListingFiltersMob/filterWrapperElement";
import { ApplyButton } from "@/routes/productListing/productListingFiltersMob/applyButton";
import { FilterRangePropsType } from "../types";

const FilterRangeMob: FC<FilterRangePropsType> = ({
  filter,
  setToggledFilter,
  toggledFilter
}) => {
  const filterData = filter?.data;
  const filterCurrency = filterData?.currency;

  const [rangeValue, setRangeValue] = useState(filterData?.selectedRange);
  const { onSelection } = useContext(SelectedFiltersMobContext);
  const isRTL = useIsRTL();
  const { t } = useTranslation("productListing");

  const onRangeComplete = async () => {
    const [minAttr, maxAttr] = filter.queryParam.split("&");
    const [minKey] = minAttr.split("=");
    const [maxKey] = maxAttr.split("=");

    if (!isEqual(filterData?.selectedRange, rangeValue)) {
      await onSelection([
        {
          ...filter,
          queryParam: minKey,
          selectedOptions: [{ key: rangeValue.min }]
        },
        {
          ...filter,
          queryParam: maxKey,
          selectedOptions: [{ key: rangeValue.max }]
        }
      ]);
    }

    setToggledFilter(null);
  };

  const rangeData = {
    min: filterData.range.min,
    max: filterData.range.max,
    selectedMin: filterData.selectedRange.min,
    selectedMax: filterData.selectedRange.max
  };

  const onFilterClearClickHandler = () => {
    const [minAttr, maxAttr] = filter.queryParam.split("&");
    const [minKey] = minAttr.split("=");
    const [maxKey] = maxAttr.split("=");

    const needToSendData =
      rangeData.min !== rangeData.selectedMin ||
      rangeData.max !== rangeData.selectedMax;

    if (needToSendData) {
      onSelection([
        {
          queryParam: minKey,
          selectedOptions: []
        },
        {
          queryParam: maxKey,
          selectedOptions: []
        }
      ]);
    }

    setRangeValue({ min: rangeData.min, max: rangeData.max });
  };

  const onBackButtonClick = e => {
    e.stopPropagation();
    setRangeValue(filterData.selectedRange);
    setToggledFilter({});
  };

  const isCurrentToggledFilter =
    toggledFilter && toggledFilter.queryParam === filter.queryParam;

  const labelText = `(${filterCurrency} ${filterData.selectedRange.min}-${filterData.selectedRange.max})`;

  return (
    <h4
      className={cn(
        "filter_title",
        isCurrentToggledFilter ? "filter_open" : "filter_close_inner"
      )}
      onClick={() => {
        setToggledFilter(filter);
      }}
    >
      <span>
        {filter.label}
        <strong>{labelText}</strong>
      </span>

      {isCurrentToggledFilter && (
        <div
          className={"filter_content openLeft"}
          onClick={e => e.stopPropagation()}
        >
          <FilterWrapperElement
            iconClassName="mobile_back_icon"
            onIconClick={onBackButtonClick}
            filterTitle={filter.label}
            onClearBtnClick={onFilterClearClickHandler}
            isHeaderVisible={isCurrentToggledFilter}
          >
            <Scrollbars autoHeight autoHeightMin={55} autoHeightMax={240}>
              <div className="range_container">
                <div className="range_dragger_container">
                  <p className={cn({ arabic: isRTL })}>
                    <span>{t("range")}</span> {filterCurrency} {rangeValue.min}{" "}
                    - {filterCurrency} {rangeValue.max}
                  </p>
                  <div className="range_dragger">
                    <RangeSelector
                      minValue={rangeData.min}
                      maxValue={rangeData.max}
                      value={rangeValue}
                      changeRange={setRangeValue as (value: Range) => void}
                    />
                  </div>
                </div>
              </div>
            </Scrollbars>
            <ApplyButton
              onButtonClick={onRangeComplete}
              buttonText={t("apply")}
            />
          </FilterWrapperElement>
        </div>
      )}
    </h4>
  );
};

export default FilterRangeMob;
