import isEqual from "lodash/isEqual";
import { isMobileSafari } from "react-device-detect";

export const locationKeyChanged = (location, anotherLocation) => {
  const { key, ...restLocationProps } = location;
  const { key: anotherKey, ...restAnotherLocationProps } = anotherLocation;
  return (
    isEqual(restLocationProps, restAnotherLocationProps) && key !== anotherKey
  );
};

export const pretendClick = url => {
  if (!url) return;
  const link = document.createElement("a");
  link.setAttribute("href", url);
  !isMobileSafari && link.setAttribute("target", "_blank");
  link.click();
};

export const getOppositeLanguageUrl = url => {
  if (!url) return "";
  if (url.includes("/ar-")) {
    return url.replace("/ar-", "/en-");
  } else {
    return url.replace("/en-", "/ar-");
  }
};
