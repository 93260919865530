import React, { FC, InputHTMLAttributes } from "react";
import cn from "clsx";

import "./style.scss";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  isChecked: boolean;
};

const CircleRadio: FC<Props> = ({ isChecked, ...args }) => (
  <div className="circle-radio">
    <input
      className={cn({ checked: isChecked })}
      type="radio"
      name="circle-radio"
      value="circle-radio"
      checked={isChecked}
      readOnly
      {...args}
    />
  </div>
);

export default CircleRadio;
