import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { LiSAHubService } from "../../services";
import {
  getLanguageSlugStore,
  getLiveFloatingButtonUrl,
  getPositionStyle,
  getRouteSlugStore
} from "../../util/lisa";
import { selectLanguageSlug } from "../../util/selectors";
import { showLoadingIndicator } from "../../redux/actions/common.action";
import { useRouteSlug } from "@/hooks";

export const LisaHub = ({ customProps }) => {
  const history = useHistory();
  const routeSlug = useRouteSlug();
  const languageSlug = useSelector(selectLanguageSlug);
  const positionStyle = getPositionStyle(customProps);
  const dispatch = useDispatch();
  const { url, ...otherProps } = customProps;

  useEffect(() => {
    LiSAHubService.init({
      alert: {
        position: "tl",
        ...otherProps,
        ...(positionStyle && { position: positionStyle })
      },
      language: languageSlug,
      on: {
        ready: hub => {
          hub.on(hub.Event.ALERT_READY, alert => {
            alert.on(alert.Event.CLICKED, show => {
              const routeSlug = getRouteSlugStore();
              const customUrl = getLiveFloatingButtonUrl(url, routeSlug);
              const route = qs.stringifyUrl({
                url: customUrl,
                query: {
                  lisa_autoplay: show.id,
                  language: getLanguageSlugStore()
                }
              });
              dispatch(showLoadingIndicator());
              alert.hide();
              history.push(route);
            });
          });
        }
      }
    });
  }, [routeSlug]);

  useEffect(() => {
    LiSAHubService.updateSettings({
      language: languageSlug
    });
  }, [languageSlug]);
  return null;
};
