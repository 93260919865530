import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { isDateInPast } from "../../../util/common";
import PriceSlab from "@/components/price/priceSlab";
import { Product } from "@/services/productService";

export const SaleProductPriceBlock: FC<{
  product: Product;
  isMainTab: boolean;
  dateFrom: string;
}> = ({ product, isMainTab, dateFrom }) => {
  const isBuyNowBtn =
    isDateInPast(dateFrom) && Boolean(product?.pstock) && isMainTab;
  const { t } = useTranslation("sale");

  return (
    <div className="price-block">
      {product.price && (
        <PriceSlab
          product={product}
          retailPriceVisible
          discountLabelVisible={false}
          priceLabelVisible
        />
      )}

      {isBuyNowBtn && <button className="buy-now">{t("buyNow")}</button>}
    </div>
  );
};
