import React from "react";
import { isMobile } from "@/util";
import DynamicAccordion from "../dynamicAccordion";
import DynamicBullet from "../dynamic-bullet";

const LIST_TYPES = {
  ACCORDION: "Accordion",
  BULLET: "Bullet"
};

const DynamicList = ({ content, rowStyles }) => {
  const { listType } = content;

  const isNotAvailableForResp =
    isMobile.any() && !content?.enabledOn?.includes("resp");

  if (isNotAvailableForResp) {
    return null;
  }

  switch (listType) {
    case LIST_TYPES.ACCORDION:
      return (
        <DynamicAccordion accordion={content.accordion} rowStyles={rowStyles} />
      );

    case LIST_TYPES.BULLET:
      return <DynamicBullet content={content} rowStyles={rowStyles} />;

    default:
      return null;
  }
};

export default DynamicList;
