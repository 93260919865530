import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import useFormActivityHandler from "../../../services/analytics/hooks/useFormActivityHandler";
import { useIsRTL } from "@/hooks";
import { emailValidationRegExp } from "../../../constants";

const NewsletterForm = ({ onSubmit }) => {
  const { t } = useTranslation("footer");
  const isRTL = useIsRTL();

  const formikState = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().trim().matches(emailValidationRegExp).required()
    }),
    onSubmit: (values, formikHelpers) => {
      onSubmit(values);
      formikHelpers.resetForm();
    }
  });
  const { handleSubmit, touched, errors, values, handleChange, submitCount } =
    formikState;

  useFormActivityHandler({
    formikState,
    formName: "newsletter form",
    lastFocusedFormField: "email"
  });

  const isInputInvalid = valueKey =>
    Boolean(touched[valueKey] && errors && errors[valueKey]);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="news-letter-form news-letter-banner"
        id="mc-embedded-subscribe-form"
      >
        <input
          type="text"
          name="email"
          dir={isRTL ? "rtl" : "ltr"}
          placeholder={t("enterYourEmail")}
          value={values.email}
          onChange={handleChange}
          aria-label="Email"
        />

        <button className="news-letter-btn" value="Subscribe" type="submit">
          <span className="arrow_right" />
        </button>
      </form>

      {isInputInvalid("email") && !isEmpty(values.email) && (
        <span className="error">{t("enterValidEmail")}</span>
      )}

      {Boolean(submitCount && isEmpty(values.email)) && (
        <span className="error">{t("enterEmail")}</span>
      )}
    </>
  );
};

export default NewsletterForm;
