import { getVisitorId } from "@/util";
import { ContextStorage } from "@/libs/contextStorage";
import { selectLanguageSlug } from "../util/selectors";

import {
  getGuestUserStorage,
  getPrevModeStorage
} from "../util/browserStorage";
import { BROWSER_STORAGE } from "../redux/constants";

class ApiService {
  protected getAccessToken(cookies?) {
    const state = ContextStorage.store.getState();

    return (
      state.authReducer.accessToken ||
      state.authReducer.accessGuestToken ||
      cookies?.[BROWSER_STORAGE.ACCESS_TOKEN] ||
      getGuestUserStorage()
    );
  }

  protected getVisitorId() {
    return getVisitorId();
  }

  protected getLanguage() {
    const state = ContextStorage.store.getState();

    const language = selectLanguageSlug(state);

    return language;
  }

  protected getIsPreviewStatus(...args) {
    return getPrevModeStorage(...args);
  }
}

export default ApiService;
