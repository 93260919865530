import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import ShopByStoreList from "../shopByStoreList";

const BrandPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("common");

  return (
    <div className="brand_stores">
      <div
        className={cn("store_menu_wrapper", { open_stores: isOpen })}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <span className="hamburger_menu" />
        <span>{t("header.shopByStores")}</span>
        <div className="brandMenuWrapper">{isOpen && <ShopByStoreList />}</div>
      </div>
      <div className={cn("store_overlay", { active: isOpen })}>&nbsp;</div>
    </div>
  );
};

export default BrandPopup;
