import { Cookie } from "universal-cookie";
import { Store } from "@/redux/store";

import { AsyncLocalStorage } from "async_hooks";

const serverContextStorage = new AsyncLocalStorage<{
  cookies: Cookie;
  store: Store;
}>();

export default serverContextStorage;
