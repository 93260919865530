import React, { FC } from "react";
import cn from "clsx";
import { Link } from "react-router-dom";
import { useRouteSlug } from "@/hooks";
import { isLinkStartsFromHTTPS } from "@/util/common";
import PaymentIcons from "../../footer/paymentIcons";
import FooterNewsletter from "../../footer/newsLetter/footerNewsletter";
import { useAppSelector } from "@/redux/store";
import { selectLoggedInStatus } from "@/util/selectors";
import { Menu, MenuType } from "./types";

type Props = {
  className?: string;
  columnItem: Menu;
  // This prop needed for event when we should create nav tree like ‘men > shoes > boots’
  parentsText?: string[];
};

const ColumnItem: FC<Props> = ({
  className = "dynamic_column_item",
  columnItem,
  parentsText
}) => {
  const isUserLoggedIn = useAppSelector(selectLoggedInStatus);

  // TODO: Check if logic actually works after api implementation
  if (columnItem?.authorization === isUserLoggedIn) {
    return null;
  }

  return (
    <div className={className}>
      {columnItem.menuType === MenuType.TEXT ? (
        <ColumnText columnItem={columnItem} parentsText={parentsText} />
      ) : (
        <div style={columnItem.styles}>
          <ColumnCustom
            moduleType={columnItem.moduleType}
            columnItem={columnItem}
          />
        </div>
      )}

      {columnItem?.children?.map(childrenItem => {
        return (
          <ColumnItem
            className="dynamic_column_children_item"
            columnItem={childrenItem}
            key={childrenItem.id}
            parentsText={
              parentsText
                ? [...parentsText, columnItem.subTitle]
                : [columnItem.subTitle]
            }
          />
        );
      })}
    </div>
  );
};

const ColumnText: FC<{ columnItem: Menu; parentsText?: string[] }> = ({
  columnItem,
  parentsText
}) => {
  const routeSlug = useRouteSlug();

  const style = {
    ...columnItem.styles,
    fontWeight: columnItem.fontStyle,
    color: columnItem.textColor
  };

  // Margin top/bottom and textAlign doesn't work on text element, so we add it to outer div
  const outerStyles = {
    marginBottom: columnItem.styles?.marginBottom,
    marginTop: columnItem.styles?.marginTop,
    textAlign: columnItem.textAlign
  };

  if (columnItem.redirectionUrl) {
    const redirectionUrl = isLinkStartsFromHTTPS(columnItem.redirectionUrl)
      ? columnItem.redirectionUrl
      : `/${routeSlug}${columnItem.redirectionUrl}`;

    const navTree = parentsText
      ? `${parentsText.join(" > ")} > ${columnItem.subTitle}`
      : columnItem.subTitle;

    return (
      <div
        style={outerStyles}
        className={cn("sub_title", columnItem.menuStyle)}
      >
        <Link to={redirectionUrl}>
          <ColumnImage columnItem={columnItem} isLeftImage={true} />
          <span style={style} data-nav_tree={navTree}>
            {columnItem.subTitle}
          </span>
          <ColumnImage columnItem={columnItem} isLeftImage={false} />
        </Link>
      </div>
    );
  }

  return (
    <div style={outerStyles} className={cn("sub_title", columnItem.menuStyle)}>
      <ColumnImage columnItem={columnItem} isLeftImage={true} />
      <span style={style}>{columnItem.subTitle}</span>
      <ColumnImage columnItem={columnItem} isLeftImage={false} />
    </div>
  );
};

const ColumnCustom: FC<{ moduleType: string; columnItem: Menu }> = ({
  moduleType,
  columnItem
}) => {
  switch (moduleType) {
    case "paymentIcons":
      return <PaymentIcons footerWeb />;

    case "newsLetter":
      return <FooterNewsletter data={columnItem} />;

    default:
      return null;
  }
};

const ColumnImage: FC<{ columnItem: Menu; isLeftImage: boolean }> = ({
  columnItem,
  isLeftImage
}) => {
  const imagePosition = isLeftImage ? "leftImage" : "rightImage";

  if (!columnItem[imagePosition]?.imageURL) {
    return null;
  }

  return (
    <img
      src={columnItem[imagePosition].imageURL}
      width={columnItem[imagePosition].width}
      style={{ aspectRatio: columnItem[imagePosition].ratio }}
      alt="column icon"
    />
  );
};

export default ColumnItem;
