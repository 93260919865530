import React from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import { useIsRTL } from "@/hooks";
import AlertCircleIcon from "@/images/alert-circle.svg";
import "./style.scss";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation("errorPage");
  const isRTL = useIsRTL();

  switch (error.name) {
    case "ChunkLoadError":
      return (
        <div className={cn("error_fallback", { arabic: isRTL })}>
          <div className="error_fallback_content">
            <img
              className="alert_icon"
              src={AlertCircleIcon}
              alt="alert-circle"
            />
            <p className="error_fallback_message">{t("chunkLoadingFailed")}</p>
            <button className="round_btn btn" onClick={resetErrorBoundary}>
              {t("retry")}
            </button>
          </div>
        </div>
      );
    default:
      throw error;
  }
};

export default ErrorFallback;
