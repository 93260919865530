import React, { ComponentType, FC } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useRouteSlug } from "@/hooks";
import { useAppSelector } from "@/redux/store";
import { selectWishListGeneralCount } from "@/util/selectors";

import BrandPopup from "./brandPopup";
import AccountPopup from "./accountPopup";

const ProfileButtons: FC<{ CartPopup: ComponentType }> = ({ CartPopup }) => {
  const routeSlug = useRouteSlug();
  const wishlistCount = useAppSelector(selectWishListGeneralCount);

  const location = useLocation();

  return (
    <div className="profile_options">
      <BrandPopup />

      <AccountPopup />

      <Link
        to={{
          pathname: `/${routeSlug}/wishlist/`,
          state: { prevLocation: location.pathname + location.search }
        }}
        className="favorite"
      >
        <span className="favorite_icon" />
        {Boolean(wishlistCount) && (
          <span className="items_in_bucket">{wishlistCount}</span>
        )}
      </Link>

      <CartPopup />
    </div>
  );
};

export default ProfileButtons;
