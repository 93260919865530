import filter from "lodash/filter";

import { BEConfig } from "../../config/env";
import { CustomerService } from "../../services";

import {
  fetchWebApi,
  putDashboardWebApi,
  deleteWebApi,
  postDashboardWebApi
} from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import {
  getVisitorId,
  languageFromPathName,
  validateProduct
} from "../../util";
import { retryCall } from "../../util/common";

import {
  SET_CHECKOUT_DATA,
  CHANGE_CHECKOUT_DATA,
  SET_ADDRESS_DATA,
  CLEAR_CHECKOUT_DATA,
  SET_CHECKOUT_ORDER,
  SET_MAP_FILTER,
  MAP_DELIVERY_ADDRESS_CHECKOUT,
  SAVE_CARD_FOR_FUTURE,
  SELECTED_SAVED_CARD_DATA,
  POPULATE_VOUCHER_CODES,
  REMOVE_APPLIED_VOUCHER,
  M_SELECTED_SAVED_CARD_DATA,
  CLEAR_GUEST_EMAIL,
  SET_PAYMENT_DATA,
  CHANGE_MAP_ADDRESS_PHONE,
  RESET_MAP_DELIVERY_ADDRESS,
  SET_STANDARD_DELIVERY_ADDRESS,
  RESET_STANDARD_DELIVERY_ADDRESS,
  SET_MAP_DELIVERY_ADDRESS,
  RESET_CARD_LIST,
  INSTORE_DELIVERY_ADDRESS_CHECKOUT,
  SET_INSTORE_PICKUP_LIST,
  RESET_INSTORE_DELIVERY_ADDRESS,
  SET_CHECKOUT_REQUEST_DATA,
  SET_INSTORE_DELIVERY_ADDRESS,
  SET_CHECKOUT_COM_FORM_VALIDATION_STATUS,
  SET_CREDIT_CARDS,
  RESET_CREDIT_CARDS,
  SET_SELECTED_CARD_CVV,
  CLEAR_SELECTED_CARD_CVV,
  CLEAR_BIN_CODE,
  SET_BIN_CODE,
  SHOW_CHECKOUT_SUMMARY_LOADER,
  HIDE_CHECKOUT_SUMMARY_LOADER,
  SET_SUMMARY_RECALCULATION_IN_PROGRESS,
  RESET_PAYMENT_METHOD,
  SET_PAYMENT_STATUS_RESPONSE,
  SET_IS_PICK_UP_FORM_VALID,
  RESET_IS_PICK_UP_FORM_VALID
} from "../constants";
import { VOUCHER } from "../../constants";
import { getPrevModeStorage } from "../../util/browserStorage";
import { normalizeCartValues } from "../../util/cart";
import * as priceUtil from "../../util/priceUtil";

export const setCheckoutData = checkoutData => ({
  type: SET_CHECKOUT_DATA,
  checkoutData
});

export const setPaymentData = () => ({ type: SET_PAYMENT_DATA });
export const changeCheckoutData = (name, value) => ({
  type: CHANGE_CHECKOUT_DATA,
  name,
  value
});

export const showCheckoutSummaryLoadingIndicator = () => ({
  type: SHOW_CHECKOUT_SUMMARY_LOADER
});

export const hideCheckoutSummaryLoadingIndicator = () => ({
  type: HIDE_CHECKOUT_SUMMARY_LOADER
});

export const clearGuestEmail = () => ({ type: CLEAR_GUEST_EMAIL });

export const clearCheckoutData = () => ({ type: CLEAR_CHECKOUT_DATA });

export const removeAppliedVoucher = () => (dispatch, getState) => {
  const state = getState();
  const { selectedPayment } = state.checkoutReducer.checkoutData;
  dispatch(
    changeCheckoutData(
      "selectedPayment",
      (selectedPayment || []).filter(item => item !== VOUCHER)
    )
  );
  dispatch({ type: REMOVE_APPLIED_VOUCHER });
};

export const setBinCode = binNo => ({ type: SET_BIN_CODE, binNo });

export const clearBinCode = () => ({ type: CLEAR_BIN_CODE });

export const setCheckoutOrder = apiResponse => ({
  type: SET_CHECKOUT_ORDER,
  apiResponse
});
export const setMapFilter = apiResponse => ({
  type: SET_MAP_FILTER,
  apiResponse
});

export const setAddress = address => ({ type: SET_ADDRESS_DATA, address });

export const mapDeliveryAddressCheckout = address => ({
  type: MAP_DELIVERY_ADDRESS_CHECKOUT,
  address
});

export const setCheckoutRequestData = checkoutRequestData => ({
  type: SET_CHECKOUT_REQUEST_DATA,
  checkoutRequestData
});

export const inStoreDeliveryAddressCheckout = address => ({
  type: INSTORE_DELIVERY_ADDRESS_CHECKOUT,
  address
});

export const setInStorePickupData = storeList => ({
  type: SET_INSTORE_PICKUP_LIST,
  storeList
});

export const saveCardForFuture = value => ({
  type: SAVE_CARD_FOR_FUTURE,
  value
});

export const resetCardList = () => ({ type: RESET_CARD_LIST });

export const handleSelectedSavedCard = selectedData => ({
  type: SELECTED_SAVED_CARD_DATA,
  selectedData
});

export const handleMSelectedSavedCard = selectedData => ({
  type: M_SELECTED_SAVED_CARD_DATA,
  selectedData
});

export const populateVoucherCodes = VoucherCodes => ({
  type: POPULATE_VOUCHER_CODES,
  VoucherCodes
});

export const setSummaryRecalculationInProgress = () => dispatch => {
  dispatch({ type: SET_SUMMARY_RECALCULATION_IN_PROGRESS, payload: true });
  dispatch(showCheckoutSummaryLoadingIndicator());
};
export const resetSummaryRecalculationInProgress = () => dispatch => {
  dispatch({ type: SET_SUMMARY_RECALCULATION_IN_PROGRESS, payload: false });
  dispatch(hideCheckoutSummaryLoadingIndicator());
};

export const resetPaymentMethod = () => ({
  type: RESET_PAYMENT_METHOD
});

export const checkoutCart = (
  bodyData,
  history,
  language,
  stopRedirect,
  country,
  cart
) => {
  language = languageFromPathName(language);
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, checkoutHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;
  let product = {};

  return async (dispatch, getState) => {
    const { countryId } = getState().common.settings;
    const updatedBody = { ...bodyData, countryId };
    const { checkoutData } = getState().checkoutReducer;
    const { settings } = getState().common;
    let data =
      updatedBody || (Object.keys(checkoutData).length && checkoutData);

    if (data) {
      let { orderItems } = data;
      orderItems = orderItems.map(order => {
        return {
          ...order,
          transactionPrice: priceUtil.formatPriceByDecimal(
            order.transactionPrice,
            settings.decimalPlaces
          )
        };
      });
      data = { ...data, orderItems: [...orderItems] };
    }

    dispatch(showLoadingIndicator());

    try {
      const response = await retryCall({
        command: () =>
          postDashboardWebApi(
            getAccessToken(getState),
            apiURL,
            data,
            getVisitorId(),
            language
          ),
        retry: 3,
        delay: 5000
      });

      const newOrderItems = response.data.orderItems.map(item => {
        return normalizeCartValues({ item, attrNamePstock: "currentStock" });
      });
      const newRespData = { ...response.data, orderItems: newOrderItems };

      if (response.status === 200 && newRespData && newRespData.success) {
        dispatch(setCheckoutData(newRespData));
        cart &&
          cart.length &&
          cart.forEach(orderItem => {
            product = validateProduct(newRespData, orderItem);
          });
        if (
          history &&
          language &&
          product &&
          !product._OutOfStock &&
          !product._priceChange &&
          product._isAvailableCountry &&
          newRespData._id
        ) {
          !stopRedirect &&
            history.push(
              `/${language}-${country || "ae"}/checkout/${newRespData._id}/`
            );
        } else {
          if (!history.location.pathname.endsWith("my-cart")) {
            history.push(`/${language}-${country || "ae"}/my-cart/`);
          }
        }
      }

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);

      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };
};

export const updateCheckout = () => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, checkoutHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}`;
  return (dispatch, getState) => {
    const { checkoutData } = getState().checkoutReducer;
    return putDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      checkoutData,
      getVisitorId()
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateDbCheckoutData = (name, value) => dispatch => {
  dispatch(changeCheckoutData(name, value));
  // dispatch(updateCheckout());
};

export const fetchCheckoutData = (id, history, language, country) => {
  language = languageFromPathName(language);
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, checkoutHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${checkoutHandle}/${id}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (
          response.status === 200 &&
          response.data &&
          response.data.orderItems &&
          response.data.orderItems.length
        ) {
          const selectedPayment = filter(
            response.data?.selectedPayment,
            payment => payment !== VOUCHER
          );

          const responseData = {
            ...response.data,
            discount: 0,
            selectedPayment,
            voucherCode: "",
            voucherNo: ""
          };
          dispatch(setCheckoutData(responseData));
          switch (response.data.deliveryType) {
            case "SELF_PICKUP":
              dispatch(setMapDeliveryAddress(response.data.address));
              break;
            case "DELIVERY_ADDRESS":
              dispatch(setStandardDeliveryAddress(response.data.address));
              break;
            case "STORE_PICKUP":
              dispatch(setInstoreDeliveryAddress(response.data.address));
              break;
            default:
          }
          response.data.address && dispatch(setAddress(response.data.address));
          if (response.data.orderPlaced) {
            history.push(`/${language}-${country || "ae"}/my-cart`);
          }
        } else {
          history.push(`/${language}-${country || "ae"}/my-cart`);
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const deleteCheckoutItem = (id, _id) => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, checkoutItemHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${checkoutItemHandle}/${id}/${_id}`;
  return (dispatch, getState) => {
    return deleteWebApi(getAccessToken(getState), apiURL, getVisitorId())
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const applyVoucher = (bodyData, isGuestLogin) => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, applyVoucherHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${applyVoucherHandle}/?guest=${isGuestLogin}`;
  return (dispatch, getState) => {
    const { countryId } = getState().common.settings;
    const updatedBody = { ...bodyData, countryId };
    return postDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      updatedBody,
      getVisitorId()
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      })
      .finally(() => {
        dispatch(resetSummaryRecalculationInProgress());
      });
  };
};

export const voucherList = bodyData => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, getVouchersCode }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${getVouchersCode}`;
  return (dispatch, getState) => {
    const { countryId } = getState().common.settings;
    const { language } = getState().common;
    const updatedBody = { ...bodyData, countryId };
    dispatch(showLoadingIndicator());
    return postDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      updatedBody,
      getVisitorId(),
      language
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data && response.data.success) {
          dispatch(
            populateVoucherCodes(response && response.data.voucherDetails)
          );
        } else {
          dispatch(populateVoucherCodes([]));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchMapFilterData = (lat, lng, lang = "en", country = "UAE") => {
  const { mapCollectionList } = BEConfig;
  const apiURL = `${mapCollectionList}?&lat=${lat}&lon=${lng}&appKey=3782&lang=${lang}&country=${country} `;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(null, apiURL, null, null, null, null, true)
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200) {
          dispatch(setMapFilter(response.data.data));
        } else {
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const fetchInStorePickupData = (language, countryId) => {
  language = languageFromPathName(language);
  const apiURL = `${BEConfig.commonApi.protocol}${BEConfig.commonApi.baseURL}${BEConfig.commonApi.port}${BEConfig.commonApi.versionInfo}${BEConfig.commonApi.common}/${BEConfig.commonApi.shops}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      getAccessToken(getState),
      apiURL,
      getVisitorId(),
      language,
      null,
      null,
      null,
      getPrevModeStorage()
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.data && response.status === 200) {
          dispatch(setInStorePickupData(response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const updateCheckoutById = (id, body) => {
  const {
    checkoutApi: { baseURL, protocol, port, versionInfo, updateCheckoutById }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${updateCheckoutById}/${id}`;
  return (dispatch, getState) => {
    return putDashboardWebApi(
      getAccessToken(getState),
      apiURL,
      body,
      getVisitorId()
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const handleIntlInputTextChange = (
  name,
  value,
  deliveryType,
  status
) => ({
  type: CHANGE_MAP_ADDRESS_PHONE,
  name,
  value,
  deliveryType,
  status
});
export const resetMapDeliveryAddress = () => ({
  type: RESET_MAP_DELIVERY_ADDRESS
});
export const resetInStoreDeliveryAddress = () => ({
  type: RESET_INSTORE_DELIVERY_ADDRESS
});
export const setStandardDeliveryAddress = address => ({
  type: SET_STANDARD_DELIVERY_ADDRESS,
  address
});
export const resetStandardDeliveryAddress = () => ({
  type: RESET_STANDARD_DELIVERY_ADDRESS
});
export const setMapDeliveryAddress = address => ({
  type: SET_MAP_DELIVERY_ADDRESS,
  address
});

export const setInstoreDeliveryAddress = address => ({
  type: SET_INSTORE_DELIVERY_ADDRESS,
  address
});

export const setCheckoutComFormValidationStatus = status => ({
  type: SET_CHECKOUT_COM_FORM_VALIDATION_STATUS,
  payload: status
});

export const setCreditCards = data => ({
  type: SET_CREDIT_CARDS,
  payload: data
});
export const resetCreditCards = () => ({
  type: RESET_CREDIT_CARDS
});
export const loadCreditCards = () => async dispatch => {
  try {
    const data = await CustomerService.getCreditCardList();

    dispatch(setCreditCards(data));
  } catch (err) {
    console.error(err);
  }
};

export const setSelectedCardCvv = cvv => ({
  type: SET_SELECTED_CARD_CVV,
  payload: cvv
});

export const clearSelectedCardCvv = () => ({ type: CLEAR_SELECTED_CARD_CVV });

export const deleteCreditCard = id => async dispatch => {
  try {
    await CustomerService.deleteCreditCard(id);

    dispatch(loadCreditCards());
  } catch (err) {
    console.error(err);
  }
};

export const setPaymentStatusResponse = data => ({
  type: SET_PAYMENT_STATUS_RESPONSE,
  payload: data
});

export const setIsPickUpFormValid = (formName, isFormValid) => ({
  type: SET_IS_PICK_UP_FORM_VALID,
  formName,
  isFormValid
});

export const resetIsPickUpFormValid = () => ({
  type: RESET_IS_PICK_UP_FORM_VALID
});
