import React, { FC, useContext, useEffect, useState } from "react";
import cn from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import FilterOptionItem from "@/routes/productListing/productListingFiltersWeb/filterOptionItem";
import useSingleSelectFilter from "@/routes/productListing/hooks/useSingleSelectFilter";
import { SelectedFiltersContext } from "../saleWebFilteringSection";
import useSelectedByResponse from "@/routes/productListing/hooks/useSelectedByResponse";
import FilterElement from "./filterElement";
import { GeneralFacet, SingleSelectFacet } from "../types";

type FilterSingleSelectProps = {
  filter: Omit<GeneralFacet & SingleSelectFacet, "sortOrder">;
  withDefaultSelection?: boolean;
};

const FilterSingleSelectWeb: FC<FilterSingleSelectProps> = ({
  filter,
  withDefaultSelection = true
}) => {
  const {
    onSelection,
    updateSelectedFilters,
    selectedFilters,
    setSelectedFilters
  } = useContext(SelectedFiltersContext);
  const [forceRerenderKey, setForceRerenderKey] = useState(false);
  const {
    filterOptions,
    selectedOption,
    onFilterOptionClick,
    setSelectedOption
  } = useSingleSelectFilter(filter, onSelection);
  const selectedByResponse = useSelectedByResponse(filterOptions)[0];

  useEffect(() => {
    if (selectedByResponse) {
      updateSelectedFilters({
        type: filter.type,
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions: [selectedByResponse]
      });
    }
  }, []);

  useEffect(() => {
    setSelectedOption(selectedByResponse);
    if (!selectedByResponse && selectedFilters[filter.queryParam]) {
      setSelectedFilters(currentFilters => {
        const { [filter.queryParam]: toRemove, ...newFilters } = currentFilters;
        return newFilters;
      });
    }
  }, [filter]);

  return (
    <>
      <FilterElement
        className={cn({
          items_selected: !!selectedOption
        })}
        filterLabel={filter.label}
        filterWrapperClassName="mxw-300"
        key={`${forceRerenderKey}`}
      >
        <Scrollbars autoHeight autoHeightMin={55} autoHeightMax={240}>
          <div>
            <ul className={cn("hierarchical_categories2_item")}>
              {filterOptions.map((option, index) => (
                <FilterOptionItem
                  key={option.key}
                  option={option}
                  isSelected={
                    option.isSelected ||
                    (!selectedOption && withDefaultSelection && index === 0)
                  }
                  onSelection={() => {
                    onFilterOptionClick({ option }, event);
                    setForceRerenderKey(!forceRerenderKey);
                  }}
                />
              ))}
            </ul>
          </div>
        </Scrollbars>
      </FilterElement>
    </>
  );
};

export default FilterSingleSelectWeb;
