import React, { FC } from "react";
import { useIsRTL } from "@/hooks";
import { Image } from "@/components/image";
import { Icon, IconType } from "../types";

const FilterOptionIcon: FC<{ icon: Icon }> = ({ icon }) => {
  const isRTL = useIsRTL();

  switch (icon.type) {
    case IconType.COLOR:
      return (
        <span
          className="color"
          style={{
            backgroundColor: icon.value,
            margin: isRTL ? "0 0 0 10px" : "0 10px 0 0"
          }}
        />
      );
    case IconType.IMAGE:
      return (
        <div
          className="filterOptionIconWrapper"
          style={{
            margin: isRTL ? "0 0 0 10px" : "0 10px 0 0"
          }}
        >
          <Image className="store_img" src={icon.value} alt="" />
        </div>
      );
    default:
      return null;
  }
};

export default FilterOptionIcon;
