import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import hoistNonReactStatics from "hoist-non-react-statics";
import AnalyticService from "../services/analytic-service";

const withAnalyticPageBrowseTracking = Component => {
  const WrappedComponent = props => {
    const location = useLocation();

    // TODO: Remove this hoc after page data bridge is implemented everywhere
    console.error("Deprecated: use page data bridge instead");

    useEffect(() => {
      const url = location.pathname;
      AnalyticService.common.trackPageBrowse(url);
    }, [location]);

    return <Component {...props} />;
  };

  hoistNonReactStatics(WrappedComponent, Component);

  return WrappedComponent;
};

export default withAnalyticPageBrowseTracking;
