import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import { useDispatch } from "react-redux";
import {
  fetchSubscribeEmail,
  resetNewsletterSubscriptionData
} from "../../../redux/actions/footer.action";
import ThanksForSubscribingModal from "../../modal/thank-for-subscribing";
import { useAppSelector } from "../../../redux/store";
import NewsletterForm from "./newsletterForm";
import { useCountryId } from "@/hooks";
import { Menu } from "@/components/dynamic/dynamicColumns/types";

const FooterNewsletter: React.FC<{
  data?: Menu;
}> = ({ data }) => {
  const { t } = useTranslation("footer");
  const [isThankYouModalVisible, setIsThankYouModalVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const userLoggedIn = useAppSelector(state => state.authReducer.userLoggedIn);
  const countryId = useCountryId();
  const dispatch = useDispatch();

  const onSubscribe = ({ email }) => {
    const bodyData = { email, countryId };
    dispatch(fetchSubscribeEmail(bodyData));
    setModalOpen(true);
  };

  const handleModal = () => setModalOpen(false);

  useEffect(() => {
    setIsThankYouModalVisible(modalOpen);

    return () => {
      dispatch(resetNewsletterSubscriptionData);
    };
  }, [modalOpen]);

  if (data?.authorization && userLoggedIn) return null;

  return (
    <>
      {isThankYouModalVisible && (
        <ThanksForSubscribingModal
          openSubsModal={modalOpen}
          closeModal={handleModal}
          message={t("thankYouSubscribingMessage")}
          messageHeading={t("thankYouForSubs")}
        />
      )}
      <section className={cn("newsletter-col", { signedIn: !userLoggedIn })}>
        <NewsletterForm onSubmit={onSubscribe} />
      </section>
    </>
  );
};

export default FooterNewsletter;
