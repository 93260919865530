import React, { FC } from "react";
import Modal from "react-modal";
import cn from "clsx";
import { useIsRTL } from "@/hooks";

const ModalBfl: FC<{
  showModal: boolean;
  closeModal?: () => void;
  modalClass?: string;
  overlayClassName?: string;
  modalCustomStyle?: any;
  children?: any;
  zIndex?: number;
}> = ({
  showModal,
  closeModal,
  modalClass,
  overlayClassName,
  modalCustomStyle,
  children,
  zIndex
}) => {
  const isRTL = useIsRTL();

  const customStyles = {
    overlay: {
      zIndex: zIndex || "15"
    }
  };

  return (
    <div className="modal_wrapper">
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        className={cn("Modal", modalClass, { arabic: isRTL })}
        overlayClassName={cn("overlay", overlayClassName)}
        contentLabel="Example Modal"
        style={{ ...customStyles, ...modalCustomStyle }}
        closeTimeoutMS={200}
        shouldCloseOnOverlayClick={true}
      >
        {children}
      </Modal>
    </div>
  );
};

Modal.setAppElement("#root");

export default ModalBfl;
