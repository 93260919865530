import React, { useMemo, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import { CircleFlag } from "react-circle-flags";
import { getCountryList } from "@/redux/actions/common.action";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { selectCommonSettings } from "@/util/selectors";
import { useIsRTL, useLanguage } from "@/hooks";
import ModalBfl from "../../../modal/modal";
import UserPreferences from "../../../userPreferences";
import "./index.scss";

type Props = {
  generalData: any;
};

const FooterCountrySelection: FC<Props> = ({ generalData }) => {
  const [showUserPreferenceModal, setShowUserPreferenceModal] = useState(false);
  const { t, i18n, ready } = useTranslation(["userPreferences", "footer"]);
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const settings = useAppSelector(selectCommonSettings);
  const isRtl = useIsRTL();
  const { styles } = generalData;

  const userPreferencesTranslation = useMemo(() => {
    return i18n.getResourceBundle(language, "userPreferences");
  }, [i18n, language, ready]);

  const closeModal = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation();
    setShowUserPreferenceModal(false);
  };

  const openModal = (e?: React.MouseEvent<HTMLElement>) => {
    e && e.stopPropagation();
    dispatch(getCountryList(language));
    setShowUserPreferenceModal(true);
  };

  const countryShortCode = settings?.countrySHORT;

  return (
    <section className="country_selector_dropdown" style={styles}>
      <div className="currency_change" onClick={openModal}>
        <span className="shopping_from">{t("footer:shoppingFrom")}</span>
        {countryShortCode && (
          <span>
            <CircleFlag
              className="country_image"
              countryCode={countryShortCode.toLowerCase()}
              width={18}
            />
          </span>
        )}
        {settings?.countryCode && (
          <span className="country_currency">
            <span>{settings.countryCode}</span>
            {" | "}
            <span>{settings?.currencyCode}</span>
          </span>
        )}
        <span className="arrow-down" />
        <ModalBfl
          modalClass={cn("generic_modal", "country_selection_modal", {
            arabic: isRtl
          })}
          showModal={showUserPreferenceModal}
          overlayClassName="countryOverlay"
          closeModal={closeModal}
          zIndex={150}
        >
          <button className="cross_btn" onClick={closeModal}>
            &times;
          </button>
          {showUserPreferenceModal && (
            <UserPreferences
              // TODO: remove @ts-ignore and userPreferencesTranslation after refactoring UserPreferences
              // @ts-ignore
              userPreferencesTranslation={userPreferencesTranslation}
              closeModal={closeModal}
            />
          )}
        </ModalBfl>
      </div>
    </section>
  );
};

export default FooterCountrySelection;
