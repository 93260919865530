import { pushGaProductClick, createObjForGA } from "../../util/analytics";
import get from "lodash/get";

class CartAnalyticService {
  trackAddingToWishlist = trackData => {
    const item = get(trackData, "item", {});
    const wishlistData = get(trackData, "wishlistData", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce(
      { ...item, ...wishlistData },
      authReducer,
      commonSettings
    );
    pushGaProductClick.pushGaAddToWishList(ecommerce, outerObj);
  };

  trackDeleteWishlist = trackData => {
    const item = get(trackData, "item", {});
    const wishList = get(trackData, "wishList", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce(
      { ...item, ...wishList },
      authReducer,
      commonSettings
    );
    pushGaProductClick.pushGaRemoveFromWishList(ecommerce, outerObj);
  };

  trackAddToCart = trackData => {
    const item = get(trackData, "item", {});
    const cartData = get(trackData, "cartData", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const color = get(trackData, "color", {});

    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce(
      { ...item, ...cartData },
      authReducer,
      commonSettings,
      null,
      null,
      null,
      color
    );
    pushGaProductClick.pushGaAddToCart(ecommerce, outerObj);
  };

  trackRemoveFromCart = trackData => {
    const item = get(trackData, "item", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const color = get(trackData, "color", {});
    const { ecommerce, outerObj } = createObjForGA.outerAndEcommerce(
      item,
      authReducer,
      commonSettings,
      null,
      null,
      null,
      color
    );
    pushGaProductClick.pushGaRemoveFromCart(ecommerce, outerObj);
  };

  trackFetchingCartData = trackData => {
    const data = get(trackData, "cartData", []);
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const { ecommerce, outerObj } = createObjForGA.multipleNetCoreItemsNew(
      data,
      authReducer,
      commonSettings
    );
    pushGaProductClick.pushNTCartPage(ecommerce, outerObj);
  };

  trackRemoveFromCartInCheckout = trackData => {
    const item = get(trackData, "item", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const productId = get(item, "trackData.productId", "");
    const title = get(item, "trackData.title", "");
    const currentPrice = get(item, "trackData.currentPrice", "");
    const brandName = get(item, "trackData.brandName", "");
    const brandId = get(item, "trackData.brandId", "");
    const email = get(authReducer, "email", "");
    const currency = get(authReducer, "currency", "");
    const countryId = get(authReducer, "countryId", "");
    const countryName = get(commonSettings, "countryName", "");
    pushGaProductClick.pushGaRemoveFromCart(
      {
        id: productId,
        product_code: productId,
        code: productId,
        name: title,
        product_name: title,
        price: currentPrice,
        brandName,
        brand: brandName,
        brandId
      },
      {
        email,
        currency,
        countryId,
        countryName
      }
    );
  };
}

export default CartAnalyticService;
