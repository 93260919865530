import React, { FC } from "react";
import cn from "clsx";
import * as priceUtil from "../../../util/priceUtil";
import { Product } from "@/services/productService";
import SmallFlashImage from "../../../images/small-flash.svg";

export const SalePersentageBlock: FC<{ product: Product }> = ({ product }) => {
  const percentage = product.price && priceUtil.getDiscountPercent(product);
  const percentageValue = `${percentage}%`;

  const showDiscount =
    product.price?.current && Boolean(percentage) && isFinite(percentage);

  if (!showDiscount) return null;

  return (
    <div
      className={cn("sale-image-block", {
        "unavailable-product": !product.pstock
      })}
    >
      <img src={SmallFlashImage} alt="small-flash-image" />
      <p>{percentageValue}</p>
    </div>
  );
};
