export const getSplittedSections = (sections, cmsTypes, sectionTypes) =>
  sections.reduce((acc, item) => {
    if (cmsTypes[item.sectionSlug]) {
      acc[sectionTypes[item.sectionSlug]] = item.content.map(contentEl => ({
        ...contentEl,
        sectionTitle: item.title
      }));
    }
    return acc;
  }, {});
