import React, { FC, useMemo } from "react";
import cn from "clsx";
import { useIsRTL, useLongLanguage } from "@/hooks";
import { isMobile } from "@/util";
import { HOMEPAGE_SECTION_TYPES } from "@/constants";
import DynamicModule from "@/components/dynamic/dynamic-module";
import SingleBannerContainer from "@/components/dynamic/dynamicSingleBanner/singleBannerContainer";
import ColumnItem from "./columnItem";
import { DynamicColumnsProps, DynamicColumnProps } from "./types";
import DynamicHTMLBanner from "../dynamic-html-banner";
import { DynamicSlider } from "../dynamic-slider";
import ProductCarousel from "@/components/product/productCarousel";
import "./style.scss";

const DynamicColumns = ({
  rowData,
  rowStyles,
  bannerModuleName,
  bannerPageTitle
}: DynamicColumnsProps) => {
  const columns = rowData.columns;
  const isRTL = useIsRTL();

  if (!columns) {
    console.error("DynamicColumns: columns is not defined in rowData", rowData);
  }

  const isNotAvailableForResp =
    isMobile.any() && !rowData?.enabledOn?.includes("resp");

  if (isNotAvailableForResp) {
    return null;
  }

  return (
    <div
      className={cn("dynamic_columns_wrapper", {
        arabic: isRTL
      })}
      style={rowStyles}
    >
      {columns?.map((column, id) => {
        return (
          <DynamicColumn
            key={column.name + id}
            column={column}
            bannerModuleName={bannerModuleName}
            bannerPageTitle={bannerPageTitle}
            rowDataId={rowData.id}
            rowTitle={rowData.title}
          />
        );
      })}
    </div>
  );
};

const DynamicColumn: FC<DynamicColumnProps> = ({
  column,
  bannerModuleName,
  bannerPageTitle,
  rowDataId,
  rowTitle
}) => {
  const language = useLongLanguage();

  const style = useMemo(() => {
    const style: React.CSSProperties = column.styles
      ? { ...column.styles }
      : {};
    if (column.width) {
      style.width = `${column.width}%`;
    }
    return style;
  }, [column]);

  switch (column.columnType) {
    case HOMEPAGE_SECTION_TYPES.TREE:
      return (
        <div className="dynamic_column" style={style}>
          {column?.menu?.map(columnItem => {
            return <ColumnItem columnItem={columnItem} key={columnItem.id} />;
          })}
        </div>
      );

    case HOMEPAGE_SECTION_TYPES.HTML:
      return (
        <div className="dynamic_column" style={style}>
          <DynamicHTMLBanner
            content={column}
            language={language}
            bannerSlug={rowDataId}
          />
        </div>
      );

    case HOMEPAGE_SECTION_TYPES.SINGLE:
      return (
        <div className="dynamic_column" style={style}>
          <SingleBannerContainer
            imageList={column.imageList}
            rowDataId={rowDataId}
            bannerModuleName={bannerModuleName}
            rowTitle={rowTitle}
            bannerPageTitle={bannerPageTitle}
          />
        </div>
      );

    case HOMEPAGE_SECTION_TYPES.CUSTOM:
      const content = {
        custom: column.custom,
        enable: true,
        // styles is an empty obj to prevent errors in some dynamic modules that try to use it
        styles: {},
        enabledOn: ["resp", "app"]
      };
      return (
        <div className="dynamic_column" style={style}>
          <DynamicModule content={content} />
        </div>
      );

    case HOMEPAGE_SECTION_TYPES.SLIDER:
      return (
        <div className="dynamic_column" style={style}>
          <DynamicSlider
            section={column}
            bannerModuleName={bannerModuleName}
            bannerPageTitle={bannerPageTitle}
          />
        </div>
      );

    case HOMEPAGE_SECTION_TYPES.PRODUCT_CAROUSEL:
      return (
        <div className="dynamic_column" style={style}>
          <ProductCarousel
            content={column}
            bannerModuleName={bannerModuleName}
            bannerPageTitle={bannerPageTitle}
            isSaleCarousel={false}
          />
        </div>
      );

    default:
      return null;
  }
};

export default DynamicColumns;
