import { BEConfig } from "../../config/env";
import { DEFAULT_STORE_ID, LOADER_TYPES } from "../../constants";
import {
  CLEAR_BANNERS_SECTIONS,
  CLEAR_REDIRECTION_DATA,
  HOME_PAGE_SECTION_DATA,
  REDIRECTION_DATA,
  SET_ANALYTICS_DATA,
  SET_PAGE_TYPE,
  SET_STORE_LIST
} from "../../constants/page";
import CatalogService from "../../services/catalogService";
import { getPrevModeStorage } from "../../util/browserStorage";
import { handleFetchError } from "../../util/errorHandler";
import { fetchWebApi } from "../../webapis/apiResource";
import { dynamicPageSlice, seoSlice } from "../features";
import {
  hideLoadingIndicator,
  hidePageLoader,
  showLoadingIndicator,
  showPageLoader
} from "./common.action";

export const homePageSectionData = (sectionName, content) => ({
  type: HOME_PAGE_SECTION_DATA,
  sectionName,
  content
});

export const clearBannersSectionsData = () => ({
  type: CLEAR_BANNERS_SECTIONS
});

export const fetchHomepageSection = (
  language,
  countryId,
  storeId,
  section,
  myCookies
) => {
  language = language.includes("ar") ? "arabic" : "english";
  const apiURL = `${BEConfig.catalogApi.protocol}${BEConfig.catalogApi.baseURL}${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${BEConfig.catalogApi.homePageHandle}${BEConfig.catalogApi.getSection}?countryId=${countryId}&storeId=${storeId}&language=${language}&section=${section}`;
  return dispatch => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(
      null,
      apiURL,
      null,
      null,
      null,
      null,
      null,
      getPrevModeStorage(myCookies)
    )
      .then(response => {
        dispatch(hideLoadingIndicator());
        if (response.status === 200 && response.data) {
          dispatch(homePageSectionData(section, response.data));
        }
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};

export const redirectionData = data => ({ type: REDIRECTION_DATA, data });

export const clearRedirectionData = data => ({
  type: CLEAR_REDIRECTION_DATA,
  data
});

// Temporary solution for shopByStore
export const setStoreList = (lang, data) => {
  const storeList = {
    enable: true,
    [lang]: {
      mainTitle: "Shop by Store",
      storeList: data
    },
    type: "shopByStore"
  };

  return {
    type: SET_STORE_LIST,
    storeList
  };
};

export const setAnalyticsData = analyticData => ({
  type: SET_ANALYTICS_DATA,
  analyticData
});

export const setPageType = pageType => ({
  type: SET_PAGE_TYPE,
  pageType
});

export const getPageApi =
  ({
    url,
    language,
    countryId,
    storeId,
    ipcountry,
    cookies
  }: {
    url: string;
    language: string;
    countryId: AnyNumber;
    storeId?: AnyNumber;
    ipcountry?: string;
    cookies?: any;
  }) =>
  async dispatch => {
    const pageData = await CatalogService.getPageData(
      {
        url,
        countryId,
        language,
        storeId: storeId || DEFAULT_STORE_ID,
        cookies
      },
      { ipcountry }
    );

    dispatch(seoSlice.actions.setMetaData(pageData?.meta));
    dispatch(
      setAnalyticsData({
        variants: pageData?.data?.variants,
        analytics: pageData?.analytics
      })
    );
    dispatch(dynamicPageSlice.actions.setDynamicPageData(pageData));

    return pageData;
  };
