import React, { useMemo, FC } from "react";
import isEmpty from "lodash/isEmpty";
import cn from "clsx";
import { useTranslation } from "react-i18next";

import { getOptionalByValue } from "../../../util";
import { Product } from "../../../services/productService";

import { useIsRTL, useCurrency } from "../../../hooks";

type RrpData = {
  rrp: string;
  percentage: string;
};

const RRPSection: FC<{ product: Product }> = ({ product }) => {
  const { t } = useTranslation("common");

  const isRTL = useIsRTL();
  const currency = useCurrency();

  const rrpData = useMemo<RrpData>(() => {
    if (!product.price?.rrp || product.price?.rrp?.price <= 0) return;

    return {
      ...getOptionalByValue({
        value: product.price.rrp.discountPercentage,
        keyName: "percentage"
      }),
      ...getOptionalByValue({
        value: product.price.rrp.priceLabel,
        keyName: "rrp"
      })
    } as any;
  }, [product]);

  if (isEmpty(rrpData)) return null;

  return (
    <div className="rrp_section">
      {rrpData.rrp && (
        <span className={cn("rrp_value", "rrp_value_inline")}>
          <span className="rrp_text">{`${t("price.rrp")}:`}</span>
          {currency} {rrpData.rrp}
        </span>
      )}
      {rrpData.percentage && (
        <span className="rrp_percentage">
          {`(${isRTL ? `%${rrpData.percentage}` : `${rrpData.percentage}%`} ${t(
            "price.off"
          )})`}
        </span>
      )}
    </div>
  );
};

export default RRPSection;
