import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback
} from "react";
import { DESKTOP, MOBILE, TABLET, STORYLY_EVENTS } from "../../constants";
import { StorylyService } from "../../services";
import { getDeviceType } from "../../util";

import StarIcon from "../../images/star.svg";

const storylyStyleProps = {
  storyGroupTextColor: "#000000", // "HEX/RGBA"
  storyGroupTextVisibility: true, // true/false
  storyGroupTextFont: "Futura PT", // "FONT"
  storyGroupTextSize: "16", //"PIXEL"
  storyGroupTextWeight: "450", // e.g. normal/bold/bolder/lighter/100/200/300/400/500/600/700/800/900
  storyGroupTextLineHeight: "1", // e.g. 1/1.5/2/2.5/3/4/5/6/7/8/9/10
  storyGroupIconBorderColorSeen: ["#dfdfdf", "#eaebeb"], //["HEX/RGBA", "HEX/RGBA"]
  storyGroupIconBorderColorNotSeen: ["#f23b6b", "#ffde48"], //["HEX/RGBA", "HEX/RGBA"],
  //storyBorderGapSize: "2", // "PIXEL",
  //storyBorderGapColor: "", // "HEX/RGBA",
  storyGroupPinIcon: StarIcon, // "URL"
  storyGroupPinBackgroundColor: "#FED100", // e.g. #FFCB00
  storyGroupPinBorderColor: "rgba(0,0,0,0)", // e.g. #FFCB00
  //storyGroupSize: "110x110", // "PIXELxPIXEL" e.g. 50x50
  storyGroupBorderSize: 5, // "PIXEL" e.g. 4
  storyGroupBorderRadius: 60, // "PIXEL" e.g. 10
  storyGroupMarginHorizontal: 0, // "PIXEL" e.g. 10
  storyGroupAlign: "center", //"left/center/right"
  scrollIndicator: true // e.g. true/false
  // scrollIndicatorOptions: {
  //   indicatorWidth: 12, // e.g. 32
  //   indicatorHeight: 20, // e.g. 32
  //   indicatorBorderRadius: 12, // e.g. 16
  //   indicatorBorderColor: "#D8D8D8", // e.g. #D8D8D8
  //   indicatorBoxBackgroundColor: "#FFFFFF", // e.g. #FFFFFF
  //   indicatorDefaultIconsColor: "#000000", // e.g. #000000
  //   indicatorBoxShadow: "CSS",
  //   customLeftIndicator: URL, // URL extensions must be either .jpeg or .png.
  //   customRightIndicator: URL, // URL extensions must be either .jpeg or .png.
  //   leftIndicatorMargin: 5, // e.g. 8
  //   rightIndicatorMargin: 5 // e.g. 8
  // },
  // storyTextFont: "Times New Roman" // "FONT"
};

const storylyResponsiveProps = {
  [DESKTOP]: { storyGroupSize: "90x90" },
  [TABLET]: { storyGroupSize: "96x96" },
  [MOBILE]: {
    storyGroupSize: "85x85"
  }
};

const getResponsiveStorylyProps = () => storylyResponsiveProps[getDeviceType()];

const StorylyStory = ({ token, storylyProps }) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const storylyRef = useRef(null);

  useEffect(() => {
    const loadSDK = async () => {
      await StorylyService.loadSdk();
      setSdkLoaded(StorylyService.isSdkLoaded);
    };
    loadSDK();
  }, []);

  useLayoutEffect(
    () => {
      if (sdkLoaded) {
        storylyRef.current.init({
          token,
          events: {
            [STORYLY_EVENTS.isReady]: () => {
              console.log("Storyly is ready");
            }
          },
          props: {
            ...storylyStyleProps,
            ...getResponsiveStorylyProps(),
            ...(storylyProps && storylyProps)
          }
        });
      }
    },
    [sdkLoaded]
  );

  const setStorylyProps = useCallback(() => {
    // method may be called after storylyRef.current.init()
    storylyRef.current.setProperties({
      storyGroupAlign: "left",
      storyGroupSize: "90x90"
    });
  }, []);

  return <storyly-web ref={storylyRef} />;
};

export default StorylyStory;
