import React, { FC } from "react";
import { useLanguage } from "@/hooks";
import { CustomBannerData } from "../types";
import "./index.scss";

type Props = {
  bannerData: CustomBannerData;
  generalData: any;
};

const FooterHelpline: FC<Props> = ({ bannerData, generalData }) => {
  const language = useLanguage();
  const { styles } = generalData;
  const iconUrl = bannerData.find(data => data.key === "iconUrl")?.value;
  const contact = bannerData.find(data => data.key === "contact")?.value;
  const infoText = bannerData.find(
    data => data.key === `${language}_infoText`
  )?.value;

  return (
    <div className="footer_helpline" style={styles}>
      <a href={`tel:${contact}`} className="footer_contact_wrapper">
        <img
          src={iconUrl}
          className="contactPhone"
          alt="contact phone icon"
          loading="lazy"
        />
        <p className="direction-ltr contact-phone">{contact}</p>
        <p className="contact-timing">{infoText} </p>
      </a>
    </div>
  );
};

export default FooterHelpline;
