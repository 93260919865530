export const GRID_BY_SCREEN = {
  LARGE: 5,
  MEDIUM: 4,
  SMALL: 3,
  X_SMALL: 2
};

export const SALE_SREEN = {
  LARGE_WIDTH: 1600,
  MEDIUM_WIDTH: 993,
  SMALL_WIDTH: 539
};
