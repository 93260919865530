import { FormikProps, FormikValues } from "formik";
import { useEffect, useRef, useState } from "react";
import CommonAnalyticsEvent from "../main/common";

type Props = {
  formName: string;
  lastFocusedFormField: string;
  formikState: FormikProps<FormikValues>;
  onErrors?: (errors: Record<string, string>) => void;
  onFormStart?: () => void;
};
const useFormActivityHandler: React.FC<Props> = ({
  formName,
  lastFocusedFormField = "",
  formikState,
  onErrors,
  onFormStart
}) => {
  const [ready, setReady] = useState(false);
  const { errors, dirty, isSubmitting, isValid, submitCount } = formikState;
  const prevRef = useRef({
    errors,
    dirty,
    submitCount: 0,
    lastFocusedFormField
  });

  const lastFocusedFieldRef = useRef({
    lastFocusedFormField
  });

  lastFocusedFieldRef.current.lastFocusedFormField = lastFocusedFormField;

  useEffect(() => {
    const { submitCount: prevCount, dirty: prevDirty } = prevRef.current;

    if (prevCount !== submitCount) {
      setReady(true);
    }

    if (prevDirty !== dirty && dirty) {
      CommonAnalyticsEvent.formStart(formName);
      onFormStart && onFormStart();
    }

    prevRef.current.submitCount = submitCount;
    prevRef.current.dirty = dirty;
  }, [submitCount, dirty]);

  useEffect(() => {
    const errorKeys = Object.keys(errors);
    const errorStatus = errorKeys.some(key => errors[key] !== "");

    if (errorStatus && ready) {
      const errorsObj = errorKeys.reduce((acc, name) => {
        acc[name] = errors[name];
        return acc;
      }, {});

      CommonAnalyticsEvent.formError(errorsObj);
      onErrors && onErrors(errorsObj);
      setReady(false);
    }
  }, [errors, ready]);

  useEffect(() => {
    return () => {
      if (
        prevRef.current.submitCount === 0 &&
        lastFocusedFieldRef.current.lastFocusedFormField
      ) {
        CommonAnalyticsEvent.formAbandonment({
          name: formName,
          inputName: lastFocusedFieldRef.current.lastFocusedFormField
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isValid && isSubmitting && dirty) {
      CommonAnalyticsEvent.formComplete(formName);
    }
  }, [isValid, isSubmitting, dirty]);

  return null;
};

export default useFormActivityHandler;
