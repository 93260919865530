import { GET_NEED_HELP } from "../constants";

const initialState = {
  needHelpContent: {
    orderIssues: {
      content: [
        {
          title: "",
          longTitle: "",
          answer: "",
          onCoverFlag: false,
          faqFlag: false
        }
      ],
      meta: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    loyaltyProgram: {
      content: [
        {
          title: "",
          longTitle: "",
          answer: "",
          onCoverFlag: false,
          faqFlag: false
        }
      ],
      meta: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    paymentAndVouchers: {
      content: [
        {
          title: "",
          longTitle: "",
          answer: "",
          onCoverFlag: false,
          faqFlag: false
        }
      ],
      meta: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    trackingShippingAndDelivery: {
      content: [
        {
          title: "",
          longTitle: "",
          answer: "",
          onCoverFlag: false,
          faqFlag: false
        }
      ],
      meta: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    productAndStock: {
      content: [
        {
          title: "",
          longTitle: "",
          answer: "",
          onCoverFlag: false,
          faqFlag: false
        }
      ],
      meta: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    },
    general: {
      content: [
        {
          title: "",
          longTitle: "",
          answer: "",
          onCoverFlag: false,
          faqFlag: false
        }
      ],
      meta: {
        title: "",
        metaKeyword: "",
        metaDescription: "",
        headingH1: "",
        headingH2: ""
      }
    }
  }
};

const getNeedHelp = (state, { result }) => ({
  ...state,
  needHelpContent: {
    ...result
  }
});

const needHelpReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEED_HELP:
      return getNeedHelp(state, action);
    default:
      return state;
  }
};
export default needHelpReducer;
