import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cn from "clsx";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@/redux/store";
import {
  addAnimate,
  isMobile,
  isTabletView,
  getWishListGeneralDataStore,
  isPresent
} from "../../../util";
import {
  selectDeleteWishListItemLoaded,
  selectWishListAndCartGeneralLoaded,
  selectWishListGeneralProducts
} from "../../../util/selectors";
import {
  saveToWishlist,
  deleteWishListData
} from "../../../redux/actions/wishlist.action";
import { getCartAndWishlistGeneralData } from "../../../redux/actions/cart.action";
import { API_ERROR_MESSAGES } from "../../../constants/api";
import PlpAnalytics from "../../../services/analytics/main/plp";
import DialogModal from "@/components/dialogModal";
import { Product } from "@/services/productService/types";

type SaleWishListProps = {
  product: Product;
  iconRef?: React.LegacyRef<HTMLDivElement> | null;
};

type WishlistDataItemType = {
  isMultisize: boolean;
  productId: number;
  size: string;
  sizeId: number;
  sizeType: null | string;
  updatedDate: string;
  visitorId: string;
  _id?: string;
};

export const SaleWishList = ({
  product,
  iconRef = null
}: SaleWishListProps) => {
  const { t } = useTranslation("productDetails");
  const item = { ...product, productId: product.id };
  const [wishlistAnimationClass, setWishlistAnimationClass] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const prevPropsRef = useRef(null);
  const isMobileView = isMobile.any();
  const wishListBaseData = useSelector(selectWishListGeneralProducts);

  const wishListAndCartBaseDataLoaded = useSelector(
    selectWishListAndCartGeneralLoaded
  );
  const deleteWishListItemLoaded = useSelector(selectDeleteWishListItemLoaded);

  const dispatch = useAppDispatch();

  const addAnimateForWishlist = (
    item: Product,
    isClicked: boolean,
    wishListBaseData: [WishlistDataItemType],
    wishlistAnimationClass: string,
    selectedSize: string,
    isDetailPage: boolean
  ) => {
    const value = addAnimate(
      item,
      isClicked,
      wishListBaseData,
      wishlistAnimationClass,
      selectedSize,
      isDetailPage
    );
    setWishlistAnimationClass(value);
  };

  const removeAnimationClass = () =>
    setTimeout(() => {
      setWishlistAnimationClass("");
    }, 700);

  useEffect(() => {
    const checkOnWishListAndCartBaseData =
      !prevPropsRef?.current?.wishListAndCartBaseDataLoaded &&
      wishListAndCartBaseDataLoaded;
    const checkOnDeleteWishListItem =
      !prevPropsRef?.current?.deleteWishListItemLoaded &&
      deleteWishListItemLoaded;

    if (
      wishlistAnimationClass &&
      (checkOnWishListAndCartBaseData || checkOnDeleteWishListItem)
    ) {
      removeAnimationClass();
    }

    prevPropsRef.current = {
      wishListAndCartBaseDataLoaded,
      deleteWishListItemLoaded
    };
  }, [
    wishlistAnimationClass,
    deleteWishListItemLoaded,
    wishListAndCartBaseDataLoaded
  ]);

  const onWishlistIconClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!wishlistAnimationClass) {
      delayedWishlistHandler(item);
    }
  };

  const toggleSaveToWishList = product => {
    addAnimateForWishlist(
      product,
      true,
      wishListBaseData,
      wishlistAnimationClass,
      "",
      false
    );

    if (!isPresent(product)) {
      saveItemToWishList(product);
    } else {
      deleteFromWishList(product.id);
    }
  };

  const delayedWishlistHandler = useCallback(
    debounce(val => {
      toggleSaveToWishList(val);
    }, 500),
    [toggleSaveToWishList]
  );

  const delayedHandleNetworkError = useCallback(
    debounce(message => {
      setWishlistAnimationClass("");
      setAlertMessage(message);
    }, 1000),
    [setWishlistAnimationClass]
  );

  const checkNetworkError = response => {
    const isError = response && response instanceof Error;
    const errorMessage = response?.response?.data?.message || response?.message;
    if (isError && errorMessage) {
      delayedHandleNetworkError(errorMessage);
    }
  };

  const saveItemToWishList = async item => {
    const response = await dispatch(
      saveToWishlist({
        data: {
          size: "",
          sizeId: item.sizeId || 0,
          sizeType: null,
          productId: Number(item.id),
          isMultisize: item.isMultisize
        }
      })
    );

    PlpAnalytics.addToWishlist({ item });
    checkNetworkError(response);
    dispatch(getCartAndWishlistGeneralData());
  };

  const deleteFromWishList = async (productId, size = "") => {
    const wishListBaseData = getWishListGeneralDataStore();
    const wishListItem =
      !isEmpty(wishListBaseData) &&
      wishListBaseData.find(
        item => item.productId === productId && item.size === size
      );

    const response = await dispatch(
      deleteWishListData({
        productId,
        wishlistItemId: wishListItem?._id || ""
      })
    );
    PlpAnalytics.removeFromWishlist({
      item,
      wishListItem
    });
    checkNetworkError(response);
    dispatch(getCartAndWishlistGeneralData());
  };

  const isItemPresentInWishlist = isPresent(item);

  return (
    <>
      <div
        className="wishlist_icon_wrapper"
        onClick={onWishlistIconClick}
        ref={iconRef}
      >
        <span
          className={cn(
            "wishlist-icon",
            {
              "mobile-tablet-view": isMobileView || isTabletView()
            },
            wishlistAnimationClass,
            {
              wishlistIcon: !wishlistAnimationClass && isItemPresentInWishlist,
              wishlistedIcon:
                !wishlistAnimationClass && !isItemPresentInWishlist
            }
          )}
        />
      </div>
      <DialogModal
        showModal={Boolean(alertMessage)}
        titleText={t("alert")}
        bodyText={alertMessage}
        confirmButtonText={t("Ok")}
        onConfirm={e => {
          e.stopPropagation();
          setAlertMessage("");
        }}
      />
    </>
  );
};
