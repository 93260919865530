import React, { FC, useMemo, useCallback } from "react";
import map from "lodash/map";
import { useAppSelector } from "@/redux/store";

import { DESKTOP } from "@/constants";
import { getDeviceType } from "@/util";

import CommonAnalyticsEvent from "@/services/analytics/main/common";

import RespFooter from "@/components/footer/respFooter";
import View from "@/components/common/view";
import DynamicBannersWrapper from "@/components/dynamic/dynamicBanner";

// Styles here mostly outdated, but we can't safely delete it now because some of html content in CMS uses this classes
import "./style.scss";

const Footer: FC<{
  hideInMobile: boolean;
  footerSpace: boolean;
}> = ({ hideInMobile, footerSpace }) => {
  const footerContent = useAppSelector(state => state.footerReducer.content);
  const deviceTypeSSR = useAppSelector(state => state.common.deviceType);

  const deviceType = useMemo(() => {
    return deviceTypeSSR || getDeviceType();
  }, [deviceTypeSSR]);

  const handleFooterClicks = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const element = event.target as Element;
      // Handle click on span with text
      if (element.tagName === "SPAN" && element.parentElement.tagName === "A") {
        const navTree = element.getAttribute("data-nav_tree");
        if (!navTree) return;

        CommonAnalyticsEvent.footerClick({
          href: element.parentElement.getAttribute("href"),
          navTree
        });
      }

      // Handle click on icon near to text
      if (element.tagName === "IMG" && element.parentElement.tagName === "A") {
        // Span with text contained in same parent element
        const spanElement = element.parentElement.querySelector("span");
        const navTree = spanElement?.getAttribute("data-nav_tree");
        if (!navTree) return;

        CommonAnalyticsEvent.footerClick({
          href: element.parentElement.getAttribute("href"),
          navTree
        });
      }
    },
    []
  );

  if (deviceType === DESKTOP) {
    return (
      <View.desktop element="footer" onClick={handleFooterClicks}>
        {map(footerContent, (section, key) => (
          <DynamicBannersWrapper
            section={section}
            key={key}
            bannerPageTitle={section.sectionTitle}
          />
        ))}
      </View.desktop>
    );
  }

  return (
    <View.mobile element="footer" onClick={handleFooterClicks}>
      <RespFooter hideInMobile={hideInMobile} footerSpace={footerSpace} />
    </View.mobile>
  );
};

export default Footer;
