import React, { useEffect } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useSelector } from "react-redux";
import { selectSettings } from "../util/selectors";
import { getOppositeLanguageUrl } from "../util/routing";
import { useLongLanguage } from "@/hooks";

const DEFAULT_LANGUAGE = "DEFAULT_LANGUAGE";
const languageRegex = /(\/[a-z]{2})(-[a-z]{2}\/)/;

const withDefaultLanguageHandling = Component => {
  const EnhancedComponent = props => {
    const language = useLongLanguage();
    const configs = useSelector(selectSettings);
    const defaultLanguage = configs?.default?.langauge;
    const countryId = configs?.countryId;

    useEffect(() => {
      if (defaultLanguage) {
        const isNeedToChangeLang = language !== defaultLanguage;
        const languageWasSwitched =
          window.location.href.replace(languageRegex, "/$2") ===
          document.referrer.replace(languageRegex, "/$2");
        const isAlreadySet = localStorage.getItem(
          `${DEFAULT_LANGUAGE}_${countryId}`
        );
        if (isNeedToChangeLang && !isAlreadySet) {
          localStorage.setItem(`${DEFAULT_LANGUAGE}_${countryId}`, true);

          if (!languageWasSwitched) {
            window.location.href = getOppositeLanguageUrl(window.location.href);
          }
        }
      }
    }, [defaultLanguage, countryId]);

    return <Component {...props} />;
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withDefaultLanguageHandling;
