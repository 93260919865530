import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { BottomSheet } from "react-spring-bottom-sheet";
import cn from "clsx";
import { useIsRTL } from "@/hooks";
import CircleRadio from "@/components/circleRadio";
import SlideBottomToTop from "@/components/slide-bottom-to-top";
import "react-spring-bottom-sheet/dist/style.css";
import "./style.scss";

const BottomDropdown = ({
  isOpen,
  onClose,
  data,
  onOptionClick,
  selectedOption,
  title
}) => {
  const isRTL = useIsRTL();
  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      className={cn({ arabic: isRTL })}
      scrollLocking
      blocking
    >
      <div className="bottom_dropdown_wrapper">
        <p className="dropdown_title">{title}</p>
        <span tabIndex={0} className="mCrossIcon" onClick={onClose} />
        <Scrollbars autoHeight autoHeightMin={120} autoHeightMax={320}>
          <SlideBottomToTop>
            <ul className="dropdown_list" onClick={e => e.preventDefault()}>
              {data.map((option, index) => (
                <li
                  key={index}
                  onClick={e => {
                    onOptionClick({ option }, e);
                    onClose(e);
                  }}
                >
                  <div className="circle-radio-wrapper">
                    <CircleRadio
                      isChecked={
                        option.isSelected || (!selectedOption && index === 0)
                      }
                      name={option.key}
                    />
                  </div>
                  {option.label}
                </li>
              ))}
            </ul>
          </SlideBottomToTop>
        </Scrollbars>
      </div>
    </BottomSheet>
  );
};

export default BottomDropdown;
