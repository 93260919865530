import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import {
  GET_CART_LIST,
  UPDATE_CART_LIST,
  DELETE_CART_LIST,
  SET_CHECKOUT_DATA,
  SET_SAVING_INDICATOR
} from "../constants";
import { normalizeCartValues } from "../../util/cart";
import {
  CLEAR_CART_AND_WISHLIST_GENERAL,
  DELETE_WISHLIST_ITEM_LOADING,
  GET_CART_SUMMARY,
  SET_CART_AND_WISHLIST_GENERAL,
  UPDATE_ITEM_QUANTITY,
  WISHLIST_GENERAL_LOADING
} from "../constants";
import { itemsInCart } from "../../util";

import { Cart, OrderSummary } from "../../services/cartService";

type WishlistProduct = {
  _id: string;
  productId: number;
  size: string;
  sizeType: string | null;
  updatedDate?: string;
};

type InitialState = Partial<{
  cart: Cart;
  isSaving: boolean;
  cartAndWishlistGeneralData: {
    cart: { count: number };
    wishList: { count: number; products: WishlistProduct[] };
  };
  wishListAndCartGeneralLoaded: boolean;
  deleteWishListItemLoaded: boolean;
  cartSummary: OrderSummary | null;
}>;

const initialState: InitialState = {
  cart: [],
  isSaving: false,
  cartAndWishlistGeneralData: {
    cart: { count: 0 },
    wishList: { count: 0, products: null }
  },
  wishListAndCartGeneralLoaded: false,
  deleteWishListItemLoaded: false,
  cartSummary: null
};

const getUpdatedGeneralCartCount = (state, cart) => ({
  cartAndWishlistGeneralData: {
    ...state.cartAndWishlistGeneralData,
    cart: { ...state.cartAndWishlistGeneralData.cart, count: itemsInCart(cart) }
  }
});

const getCartList = (state, { cart, isProductMerged }) => ({
  ...state,
  cart: (cart || []).map(item => {
    const normalizedItem = normalizeCartValues({ item });
    const prevItem = state.cart.find(el => {
      return el._id === get(normalizedItem, "_id");
    });
    const prevValue =
      prevItem && !isProductMerged ? { prevValue: prevItem.prevValue } : {};

    return {
      ...normalizedItem,
      ...prevValue,
      isProductMerged
    };
  }),
  ...getUpdatedGeneralCartCount(state, cart)
});

const updateCartList = (state, { cart }) => ({
  ...state,
  cart: [cart, ...state.cart],
  ...getUpdatedGeneralCartCount(state, [cart, ...state.cart])
});

const deleteCartList = (state, { _id }) => {
  const _cart = state.cart.filter(o => o._id !== _id);
  return {
    ...state,
    cart: _cart,
    ...getUpdatedGeneralCartCount(state, _cart)
  };
};

const updateItemQuantity = (state, { productId, quantity }) => {
  const clonedCart = cloneDeep(state.cart);
  const updatedItem = clonedCart.find(item => item._id === productId);

  if (updatedItem) {
    updatedItem.quantity = quantity;
  }

  return {
    ...state,
    cart: [...clonedCart]
  };
};

const removePrevValueFromItem = (state, action) => {
  const newArr = state.cart.map(item => {
    const orderItem = action.checkoutData.orderItems.find(
      val => item.productId === val.productId && item.size === val.size
    );
    return orderItem?.currentStock > 0
      ? {
          ...item,
          prevValue: item.quantity > orderItem.currentStock ? item.quantity : {}
        }
      : item;
  });

  return {
    ...state,
    cart: newArr,
    ...getUpdatedGeneralCartCount(state, newArr)
  };
};

const getCartSummary = (state, payload) => {
  return {
    ...state,
    cartSummary: payload
  };
};

const setSavingIndicator = (state, { value }) => ({
  ...state,
  isSaving: value
});

export const wishListGeneralLoading = state => ({
  ...state,
  wishListAndCartGeneralLoaded: false
});

const deleteWishListGeneralLoading = state => ({
  ...state,
  deleteWishListItemLoaded: false
});

const setCartAndWishlistGeneralData = (
  state,
  { cartAndWishlistGeneralData }
) => ({
  ...state,
  cartAndWishlistGeneralData,
  wishListAndCartGeneralLoaded: true
});

const removeCartAndWishlistGeneral = state => ({
  ...state,
  cartAndWishlistGeneralData: initialState.cartAndWishlistGeneralData,
  cart: []
});

const cartReducer = (
  state: InitialState = initialState,
  action
): InitialState => {
  switch (action.type) {
    case GET_CART_LIST:
      return getCartList(state, action);
    case UPDATE_CART_LIST:
      return updateCartList(state, action);
    case DELETE_CART_LIST:
      return deleteCartList(state, action);
    case SET_CHECKOUT_DATA:
      return removePrevValueFromItem(state, action);
    case SET_SAVING_INDICATOR:
      return setSavingIndicator(state, action);
    case SET_CART_AND_WISHLIST_GENERAL:
      return setCartAndWishlistGeneralData(state, action);
    case CLEAR_CART_AND_WISHLIST_GENERAL:
      return removeCartAndWishlistGeneral(state);
    case WISHLIST_GENERAL_LOADING:
      return wishListGeneralLoading(state);
    case DELETE_WISHLIST_ITEM_LOADING:
      return deleteWishListGeneralLoading(state);
    case UPDATE_ITEM_QUANTITY:
      return updateItemQuantity(state, action);
    case GET_CART_SUMMARY:
      return getCartSummary(state, action);

    default:
      return state;
  }
};

export default cartReducer;
