import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import cn from "clsx";
import { useTranslation } from "react-i18next";
import "./style.scss";

const DEFAULT_MIN_HEIGHT = 150;

const ShowMoreHtmlWrapper = ({ children, showMoreContentHeight }) => {
  const { t } = useTranslation("dynamicModules");
  const [showMoreStatus, setShowMoreStatus] = useState(false);
  const [isShowMoreAllowed, setIsShowMoreAllowed] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    /** children[0] is always - div with classname top_banner_wrapper from DynamicHTMLBanner, we need this div because
     *  maxHeight doesn't affect his clientHeight, so we can calculate properly isShowMoreAllowed */
    const clientHeight = ref?.current?.children?.[0].clientHeight;
    const minClientHeight = showMoreContentHeight ?? DEFAULT_MIN_HEIGHT;
    setIsShowMoreAllowed(clientHeight > minClientHeight);
  }, []);

  const maxHeight = useMemo(() => {
    if (!isShowMoreAllowed || showMoreStatus) {
      return "fit-content";
    }
    return showMoreContentHeight ? `${showMoreContentHeight}px` : undefined;
  }, [showMoreContentHeight, isShowMoreAllowed, showMoreStatus]);

  return (
    <div className="show_more_html_wrapper">
      <div
        className={cn("html_text", {
          html_text_full: showMoreStatus || !isShowMoreAllowed
        })}
        style={{
          maxHeight,
          /** On first render of children we can't be sure if we need to add showMore button or no, so to prevent
           *  instant disappearing of this button if it's not needed we show children after measuring clientHeight */
          visibility: isShowMoreAllowed === null ? "hidden" : "visible"
        }}
        ref={ref}
      >
        {children}
      </div>
      {isShowMoreAllowed && (
        <Fragment>
          {showMoreStatus ? (
            <div className={cn("show_button", "show_less_button")}>
              <a onClick={() => setShowMoreStatus(false)}>{t("showLess")}</a>
            </div>
          ) : (
            <div className={cn("show_button", "show_more_button")}>
              <a onClick={() => setShowMoreStatus(true)}>{t("showMore")}</a>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ShowMoreHtmlWrapper;
