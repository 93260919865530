import { STORYLY_SDK_URL } from "../constants";

class StorylyService {
  _isSdkLoaded = false;

  get isSdkLoaded() {
    return this._isSdkLoaded;
  }

  loadSdk() {
    if (this._isSdkLoaded) return Promise.resolve();

    return new Promise((res, rej) => {
      const script = document.createElement("script");
      script.src = STORYLY_SDK_URL;
      script.async = true;
      script.setAttribute("custom-element", "storyly-web");

      script.onload = () => {
        this._isSdkLoaded = true;

        res();
      };
      script.onerror = rej;

      document.body.appendChild(script);
    });
  }
}

const instance = new StorylyService();

export default instance;
