import axios, { AxiosResponse } from "axios";
import { BEConfig } from "../config/env";
import {
  postDashboardWebApi,
  putDashboardWebApi
} from "../webapis/apiResource";
import ApiService from "./apiService";

type UserGetAuthData = {
  email: string;
  password: string;
};
type UserAuthData = UserGetAuthData & {
  token: string;
};
type UserUpdatePasswordData = UserAuthData & {
  oldPassword: string;
};

// TODO: check is this class in use
// it was in project files before correct implementation of typescript

class UserDetailsService extends ApiService {
  updateCurrentPassword = (
    bodyData: UserUpdatePasswordData
  ): Promise<AxiosResponse<any>> => {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.resetCurrentPasswordHandle
    }`;
    return putDashboardWebApi(this.getAccessToken(), apiURL, bodyData);
  };

  getUserAuthData = (
    email: string,
    password: string
  ): Promise<AxiosResponse<any>> => {
    const authPath = BEConfig.customerApi.loginUserHandle;
    const apiURL = `${this.getBaseUrl()}${authPath}`;
    return postDashboardWebApi(this.getAccessToken(), apiURL, {
      email,
      password
    });
  };

  getGoogleUserData = (accessToken: string): Promise<AxiosResponse<any>> => {
    const apiURL = BEConfig.googleApi.userInfo;
    return axios.get(apiURL, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
  };

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new UserDetailsService();

export default instance;
