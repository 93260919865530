import React, { FC, useMemo } from "react";
import map from "lodash/map";
import { useLanguage } from "@/hooks";
import { Image } from "@/components/image";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import { CustomBannerData } from "../types";
import "./index.scss";

type Props = {
  bannerData: CustomBannerData;
  generalData: any;
};

type JSONData = {
  en_title: string;
  ar_title: string;
  socialMediaLinks: {
    redirectLink: string;
    iconUrl: string;
    socialPlatform: string;
  }[];
};

const FooterConnectWithUs: FC<Props> = ({ bannerData, generalData }) => {
  const language = useLanguage();
  const { styles } = generalData;
  const { socialMediaLinks, title } = useMemo(() => {
    const json = bannerData.find(data => data.key === "json")?.value;
    const parsed = json && (JSON.parse(json) as JSONData);

    const title = parsed?.[`${language}_title`] ?? "";
    const socialMediaLinks = parsed?.socialMediaLinks ?? [];

    return { title, socialMediaLinks };
  }, [language, bannerData]);

  const handleSocialClick = (socialPlatform?: string) => {
    if (socialPlatform) {
      CommonAnalyticsEvent.socialClick({
        socialPlatform
      });
    }
  };

  return (
    <div className="footer_connect_with_us" style={styles}>
      <p className="head">{title}</p>
      <p className="connect">
        {map(socialMediaLinks, (socialMediaLink, index) => (
          <span key={index}>
            <a
              href={socialMediaLink.redirectLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleSocialClick(socialMediaLink.socialPlatform)}
            >
              <Image src={socialMediaLink.iconUrl} alt="social media link" />
            </a>
          </span>
        ))}
      </p>
    </div>
  );
};

export default FooterConnectWithUs;
