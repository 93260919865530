import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { isEmpty } from "lodash";
import useBodyScrollability from "@/hooks/useBodyScrollability";
import { FILTER_QUERY_PARAMS } from "@/routes/productListing/constants";
import PlpAnalytics from "@/services/analytics/main/plp";
import { SORT_LOCATION } from "@/services/analytics/main/plp/types";
import { handleFilterEvent } from "@/routes/productListing/analyticsHelper";
import FilterSidebar from "./filtersSidebar";
import FiltersPanel from "./filtersPanel";
import {
  Facet,
  FilterOption,
  FlashSaleFiltersProps,
  SelectedFiltersType
} from "../types";
import "./style.scss";
import { SalePageDataContext } from "../../SaleWrapper";
import { SaleService } from "@/services";

const FlashSaleFiltersMob: FC<FlashSaleFiltersProps> = ({ tabDetails }) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>(
    {}
  );

  const { pageData, saleData, setSaleData, setSelectedFiltersQuery } =
    useContext(SalePageDataContext);

  useEffect(() => {
    if (saleData && isEmpty(saleData.selectedFilters)) {
      setSelectedFilters({});
      setSelectedFiltersQuery({});
    }
  }, [saleData]);

  const bannerData = pageData.data;
  const pageContentValue = bannerData.slug;

  const { enableBodyScroll, disableBodyScroll } = useBodyScrollability();

  useEffect(() => {
    if (!isFiltersOpen) {
      enableBodyScroll();
    }
  }, [isFiltersOpen]);

  const getUpdatedQuery = useCallback(
    (updatedSelection: SelectedFiltersType) => {
      const queryObject = Object.values(updatedSelection).reduce(
        (acc, filter) => {
          acc[filter.queryParam] = filter.selectedOptions.map(
            option => option.key
          );
          return acc;
        },
        {}
      );

      return queryObject;
    },
    [selectedFilters]
  );

  const updateSelectedFilters = useCallback(
    selected => {
      let updated: {
        [key: string]: Facet & { selectedOptions: FilterOption[] };
      } = { ...selectedFilters };
      if (selected) {
        if (Array.isArray(selected)) {
          selected.forEach(item => {
            updated[item.queryParam] = item;
          });
        } else {
          updated[selected.queryParam] = selected;
        }
      }

      setSelectedFilters(selected => ({ ...selected, ...updated }));

      return updated;
    },
    [selectedFilters]
  );

  const onFilterChange = useCallback(
    async (query?: {}) => {
      setSelectedFiltersQuery(query);
      const { data } = await SaleService.getSaleSpecificTabData(
        tabDetails.slug,
        pageContentValue,
        query
      );
      setSaleData(data);
    },
    [tabDetails]
  );

  const onSelection = useCallback(
    selected => {
      const updatedSelection = updateSelectedFilters(selected);

      const query = getUpdatedQuery(updatedSelection);

      onFilterChange(query);

      if (selected.queryParam === FILTER_QUERY_PARAMS.SORT) {
        PlpAnalytics.sort({
          sort_by: selected?.selectedOptions?.[0]?.defaultValue?.toLowerCase(),
          sort_location: SORT_LOCATION.PRODUCT_LIST
        });
      } else {
        handleFilterEvent(selected, updatedSelection);
      }
    },
    [updateSelectedFilters, getUpdatedQuery, onFilterChange]
  );

  if (!saleData.facets) return null;

  const onFiltersOpen = () => {
    setIsFiltersOpen(true);
    disableBodyScroll();
  };

  const onFiltersClose = () => setIsFiltersOpen(false);

  if (saleData && isEmpty(saleData?.facets) && isFiltersOpen) {
    onFiltersClose();
  }

  return (
    <div className="flash_sale_filters_wrapper_resp">
      <SelectedFiltersMobContext.Provider
        value={{
          onSelection,
          selectedFilters,
          setSelectedFilters,
          updateSelectedFilters
        }}
      >
        <FiltersPanel
          itemsTotal={saleData.nbHits}
          filters={saleData.facets}
          onFiltersOpen={onFiltersOpen}
          appliedFilterCount={saleData.appliedFilterCount}
        />
        <FilterSidebar
          filters={saleData.facets}
          isFiltersOpen={isFiltersOpen}
          onFiltersClose={onFiltersClose}
          itemsTotal={saleData.nbHits}
        />
      </SelectedFiltersMobContext.Provider>
    </div>
  );
};

export const SelectedFiltersMobContext = createContext<{
  selectedFilters: {
    [key: string]: Facet & { selectedOptions: FilterOption[] };
  };
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<{
      [key: string]: Facet & {
        selectedOptions: FilterOption[];
      };
    }>
  >;
  updateSelectedFilters: (selected: any) => {
    [key: string]: Facet & {
      selectedOptions: FilterOption[];
    };
  };
  onSelection: (...args) => void;
}>(null);

export default FlashSaleFiltersMob;
