import { isEmpty } from "lodash";
import { AssistiveFilter, ProductsListingPageData } from "../types";

const useAssistiveFilters = (
  productListingData: ProductsListingPageData
): AssistiveFilter => {
  const assistiveFilter = productListingData?.assistiveFilter;
  if (!assistiveFilter || isEmpty(assistiveFilter.list)) return null;

  return assistiveFilter;
};

export default useAssistiveFilters;
