import { useIsRTL } from "@/hooks";
import cn from "clsx";
import React, { FC, useState } from "react";
import SyteTooltip from "../syteTooltip";
import SyteSearchButton from "./syteSearchButton";
import "./style.scss";

const SyteSearchWithTooltip: FC<{
  mobile: boolean;
  handleClick: () => void;
  className?: string;
}> = ({ mobile, className, handleClick }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const hideTooltip = () => setShowTooltip(false);

  const isRTL = useIsRTL();

  return (
    <div
      className={cn("syte-search-w-tooltip", {
        "d-inline-block responsive": mobile,
        className,
        arabic: isRTL
      })}
    >
      <SyteSearchButton
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={hideTooltip}
        mobile={mobile}
        onClick={handleClick}
      />
      <div className="position-relative">
        {showTooltip && <SyteTooltip onCrossClick={hideTooltip} />}
      </div>
    </div>
  );
};

export default SyteSearchWithTooltip;
