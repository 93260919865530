import { BROWSER, DESKTOP, LANGUAGE_ROUTE_KEY, MOBILE } from "../../constants";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  CHANGE_LANGUAGE,
  COMMON_DATA,
  SET_STATES_DATA,
  SET_GENERAL_INFO,
  SUBSCRIBE_NOW,
  SET_LOYALITY_POINTS,
  SET_LOYALTY_PROGRAM_STATUS,
  SHOW_ERROR_PAGE,
  HIDE_ERROR_PAGE,
  USER_ORIGIN_COUNTRY,
  CHANGE_IS_HOME_PAGE_REDUX,
  HIDE_PAGE_LOADER,
  SHOW_PAGE_LOADER,
  SET_PAYPAL_TOKEN,
  SET_SIZE_TYPE,
  SET_COUNTRIES_MOBILE_DETAILS,
  SET_SELECTED_COUNTRY_MOBILE_DETAILS,
  SET_DEVICE_TYPE,
  SET_CONFIG_SETTINGS,
  RESET_CONFIG_SETTINGS,
  SET_IS_CHECKED_LOYALTY_PROGRAM,
  SET_IS_SERVER_LOADED_REDUX_STATE,
  SET_IS_HEADER_FIXED,
  SET_COUNTRY_ID
} from "../constants";

import { languageFromPathName, isServer } from "../../util";
import { getDeviceInfo } from "../../util/common";

const deviceInfo = getDeviceInfo();
const initialState = {
  language: LANGUAGE_ROUTE_KEY.english,
  showLoader: true,
  apiCount: 0,
  loyalityPoints: 0,
  generalInfo: {
    deviceInfo: deviceInfo,
    appVersion: "1.0"
  },
  subscribeNow: {
    email: ""
  },
  settings: {},
  countryData: [],
  ipCountryData: {},
  errorPage: {
    is404: false,
    is500: false,
    is503: false
  },
  userOriginCountryData: {},
  isHomePageRedux: false,
  pageLoader: {
    visible: false,
    callCount: 0
  },
  selectedSizeType: "",
  countriesMobileDetails: [],
  selectedCountryMobileDetails: {},
  isJoinedToLoyaltyProgram: false,
  deviceType: "",
  configSettings: {
    cdnDomain: null,
    cdnImages: null,
    default: null,
    syte: null,
    customerSupportDetails: null
  },
  isCheckedLoyaltyProgram: false,
  isServerLoadedReduxState: isServer,
  isHeaderFixed: false
};

const showErrorPage = (state, { key, value }) => ({
  ...state,
  errorPage: { ...state.errorPage, [key]: value }
});

const hideErrorPage = state => ({
  ...state,
  errorPage: {
    ...state.errorPage,
    is404: false,
    is500: false,
    is503: false
  }
});

const subscribeNow = (state, { email }) => ({
  ...state,
  subscribeNow: {
    ...state.subscribeNow,
    email: email
  }
});

const showLoader = state => ({
  ...state,
  showLoader: true,
  apiCount: state.apiCount + 1
});

const hideLoader = state => {
  const data = {
    ...state,
    apiCount: state.apiCount - 1
  };
  if (state.apiCount - 1 < 1) {
    data.showLoader = false;
    data.apiCount = 0;
  }
  return data;
};

const setCommonData = (state, action) => ({ ...state, ...action });

const setStatesData = (state, action) => ({
  ...state,
  settings: { ...state.settings, state: action.state }
});

const setLoyaltyPoints = (state, { loyaltyPoints }) => ({
  ...state,
  loyaltyPoints
});

const setLoyaltyProgramStatus = (state, { isJoinedToLoyaltyProgram }) => ({
  ...state,
  isJoinedToLoyaltyProgram
});

const setGeneralInfo = (state, action) => ({
  ...state,
  generalInfo: {
    ...state.generalInfo,
    ...action.generalInfo
  }
});

const userOriginCountry = (state, action) => ({
  ...state,
  userOriginCountryData: action.userOriginCountry
});

const changeLanguage = (state, action) => ({
  ...state,
  language: languageFromPathName(action.name)
});

const changeIsHomePageRedux = (state, action) => ({
  ...state,
  isHomePageRedux: action.isHomePage
});

const showPageLoader = (state, action) => {
  return {
    ...state,
    pageLoader: {
      visible: true,
      callCount: state.pageLoader.callCount + 1,
      ...action.payload
    }
  };
};
const hidePageLoader = (state, action) => {
  const callCount = state.pageLoader.callCount - 1;
  if (callCount < 0) {
    console.error("Hiding loader without setting it visible previously");
  }
  return {
    ...state,
    pageLoader: {
      visible: callCount > 0,
      callCount: callCount > 0 ? callCount : 0,
      ...action.payload
    }
  };
};
const setPayPalToken = (state, payPalToken) => ({ ...state, payPalToken });

const setSizeType = (state, action) => ({
  ...state,
  selectedSizeType: action.payload
});
const setCountriesMobileDetails = (state, action) => ({
  ...state,
  countriesMobileDetails: action.payload
});
const setSelectedCountryMobileDetails = (state, action) => ({
  ...state,
  selectedCountryMobileDetails: action.payload
});

const setDeviceType = (state, action) => {
  const deviceType = action.payload === BROWSER ? DESKTOP : action.payload;

  return {
    ...state,
    deviceType
  };
};

const setConfigSettings = (state, { configs }) => ({
  ...state,
  configSettings: configs
});

const resetConfigSettings = state => ({
  ...state,
  configSettings: initialState.configSettings
});

const setIsCheckedLoyaltyProgram = (state, { isCheckedLoyaltyProgram }) => ({
  ...state,
  isCheckedLoyaltyProgram
});

export const setIsServerLoadedReduxState = (state, { payload }) => ({
  ...state,
  isServerLoadedReduxState: payload
});

const setIsHeaderFixed = (state, { isHeaderFixed }) => ({
  ...state,
  isHeaderFixed
});

const setCountryId = (state, action) => ({
  ...state,
  settings: { ...state.settings, countryId: action.payload }
});

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return showLoader(state);
    case HIDE_LOADER:
      return hideLoader(state);
    case CHANGE_LANGUAGE:
      return changeLanguage(state, action);
    case COMMON_DATA:
      return setCommonData(state, action);
    case SET_GENERAL_INFO:
      return setGeneralInfo(state, action);
    case SUBSCRIBE_NOW:
      return subscribeNow(state, action);
    case SET_STATES_DATA:
      return setStatesData(state, action);
    case SET_LOYALITY_POINTS:
      return setLoyaltyPoints(state, action);
    case SHOW_ERROR_PAGE:
      return showErrorPage(state, action);
    case HIDE_ERROR_PAGE:
      return hideErrorPage(state);
    case USER_ORIGIN_COUNTRY:
      return userOriginCountry(state, action);
    case CHANGE_IS_HOME_PAGE_REDUX:
      return changeIsHomePageRedux(state, action);
    case SHOW_PAGE_LOADER:
      return showPageLoader(state, action);
    case HIDE_PAGE_LOADER:
      return hidePageLoader(state, action);
    case SET_PAYPAL_TOKEN:
      return setPayPalToken(state, action.payload);
    case SET_SIZE_TYPE:
      return setSizeType(state, action);
    case SET_COUNTRIES_MOBILE_DETAILS:
      return setCountriesMobileDetails(state, action);
    case SET_SELECTED_COUNTRY_MOBILE_DETAILS:
      return setSelectedCountryMobileDetails(state, action);
    case SET_LOYALTY_PROGRAM_STATUS:
      return setLoyaltyProgramStatus(state, action);
    case SET_DEVICE_TYPE:
      return setDeviceType(state, action);
    case SET_CONFIG_SETTINGS:
      return setConfigSettings(state, action);
    case RESET_CONFIG_SETTINGS:
      return resetConfigSettings(state);
    case SET_IS_CHECKED_LOYALTY_PROGRAM:
      return setIsCheckedLoyaltyProgram(state, action);
    case SET_IS_SERVER_LOADED_REDUX_STATE:
      return setIsServerLoadedReduxState(state, action);
    case SET_IS_HEADER_FIXED:
      return setIsHeaderFixed(state, action);
    case SET_COUNTRY_ID:
      return setCountryId(state, action);

    default:
      return state;
  }
};

export default commonReducer;
