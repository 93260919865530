import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import { useHistory } from "react-router-dom";
import { useIsRTL, useLanguage, useRouteSlug } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { selectAuthReducer } from "@/util/selectors";
import { handleActionToSignPages } from "@/util/analyticsEvents";
import { getCountryList } from "@/redux/actions/common.action";
import { resetAPIError } from "@/redux/actions/homepage.action";
import MobileMenuCategories from "./mobile-menu-categories";
import AccountSettings from "./menu-account-settings";
import ShopByStoreList from "../shopByStoreList";
import "./style.scss";

const MobileMenu = ({ closeMenu, setOpenUserPreferenceModal }) => {
  const [showMainMenu, setShowMainMenu] = useState(true);
  const [showLanguage, setShowLanguage] = useState(false);
  const slug = useRouteSlug();
  const history = useHistory();
  const language = useLanguage();
  const isRTL = useIsRTL();
  const { t } = useTranslation("megaMenu");
  const authLinkRef = useRef(null);
  const { userLoggedIn, firstname, lastname } =
    useAppSelector(selectAuthReducer);
  const dispatch = useAppDispatch();

  const handleMobileCategory = page => {
    setShowMainMenu(page === 1);
  };

  const toggleLanguageList = value => {
    setShowLanguage(value);
  };

  const openLink = redirectionLink => {
    let redirectionUrl = redirectionLink;
    if (
      redirectionLink &&
      redirectionLink[redirectionLink.length - 1] !== "/"
    ) {
      redirectionUrl = redirectionLink + "/";
    }
    history.push(`/${slug}${redirectionUrl}`);
    closeMenu();
  };

  const openModal = e => {
    e?.stopPropagation();
    dispatch(getCountryList(language));
    setOpenUserPreferenceModal(true);
  };

  const handleSignMethod = toLogin => {
    handleActionToSignPages(toLogin);
    if (authLinkRef.current !== toLogin) {
      authLinkRef.current = toLogin;
      dispatch(resetAPIError());
    }
    openLink(toLogin ? "/login/" : "/sign-up");
  };

  return (
    <div
      className={cn("mega_menu_wrapper", {
        arabic: isRTL
      })}
    >
      {showMainMenu && !showLanguage && (
        <div
          className={cn("profile_desc", {
            logged_user: userLoggedIn
          })}
        >
          {userLoggedIn ? (
            <h3>
              {t("hi")}, {firstname} {lastname}
            </h3>
          ) : (
            <div className="user_profile">
              <p className="login">
                <span onClick={() => handleSignMethod(true)}>
                  {t("signIn")}
                </span>
                <span className="vertical_separator" />
                <span onClick={() => handleSignMethod(false)}>
                  {t("signUp")}
                </span>
              </p>
            </div>
          )}
        </div>
      )}
      {!showLanguage && (
        <MobileMenuCategories
          pageChange={handleMobileCategory}
          openLink={openLink}
        />
      )}
      {showMainMenu && !showLanguage && (
        <div className="mm_stores">
          <h3>{t("shopByStore")}</h3>
          <ShopByStoreList closeMenu={closeMenu} />
        </div>
      )}
      {(showMainMenu || showLanguage) && (
        <AccountSettings
          openLink={openLink}
          openCountryModal={openModal}
          closeMenu={closeMenu}
          onToggleLanguageList={toggleLanguageList}
        />
      )}
    </div>
  );
};

export default MobileMenu;
