import { CSSProperties } from "react";

export type DynamicColumnsProps = {
  rowData: { columns: Column[]; id: string; title: string } & Record<
    string,
    any
  >;
  rowStyles: CSSProperties;
  bannerPageTitle?: string;
  bannerModuleName: string;
};

export type DynamicColumnProps = {
  column: Column;
  bannerModuleName: string;
  bannerPageTitle?: string;
  rowDataId: string;
  rowTitle: string;
};

export type Column = {
  name: string;
  columnType: "tree" | "HTML" | "Single" | "Custom" | "Slider";
  width?: {
    type: string;
    value: string;
  };
  id: string;
  menu?: Menu[];
  html?: HTML;
  enabledOn: string[];
  autoPlay?: string;
  indicatorStyles?: {
    indicatorColor: string;
    backgroundIndicatorColor: string;
  };
  controllerStyles?: {
    backgroundControllerColor: string;
  };
  controllerType?: string;
  indicatorType?: string;
  styles?: Styles;
  widthType?: string;
  // for columnType - "custom"
  custom?: Custom[];
  // for columnType - Single
  imageList?: any;
  title?: string;
  autoScroll?: boolean;
  bannerType?: string;
  isShowMoreButtonVisible?: boolean;
  showMoreContentHeight?: number;
};

type Styles = {
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;
  marginTop: number;
  marginBottom: number;
  backgroundColor?: string;
  backgroundType?: string;
};

type HTML = {
  english: string;
  arabic: string;
};

export type Menu = {
  subTitle: string;
  redirectionUrl?: string;
  fontStyle: FontStyle;
  textAlign: TextAlign;
  textColor: string;
  menuType: MenuType;
  moduleType?: string;
  expanded: boolean;
  id: string;
  children?: Menu[];
  styles?: any;
  leftImage?: TImage;
  rightImage?: TImage;
  menuStyle: "none" | "style1" | "style2" | "style3";
  // TODO: After api updates check actual name of prop
  authorization?: boolean;
};

export type FontStyle = "bold" | "normal";

export type TImage = {
  imageURL: string;
  ratio: number;
  width: number;
};

export enum MenuType {
  TEXT = "text",
  CUSTOM = "custom"
}

export type TextAlign = "left" | "right" | "center";

export type Custom = {
  key: string;
  value: string;
};

export type English = {
  title: string;
  searchUrl: string;
  html?: string;
};
