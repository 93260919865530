import React from "react";
import { LIVE_FLOATING_BUTTON_TYPES } from "../../constants";
import { LisaHub } from "../lisa/LisaHub";
import LiveAnimation from "../live-animation";
import { useRouteSlug } from "@/hooks";

export const LiveFloatingBanner = ({ bannerData, generalData }) => {
  const routeSlug = useRouteSlug();
  const { styles } = generalData;
  const bannedDataObj = bannerData.reduce((acc, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});
  const { type, ...customProps } = bannedDataObj;
  let componentToShow = null;

  switch (type) {
    case LIVE_FLOATING_BUTTON_TYPES.BFL:
      componentToShow = (
        <LiveAnimation
          videoPageRoute={`/${routeSlug}${customProps.url}`}
          customProps={customProps}
        />
      );
      break;
    case LIVE_FLOATING_BUTTON_TYPES.LISA:
      componentToShow = <LisaHub customProps={customProps} />;
      break;
    default:
      break;
  }

  return <div style={styles}>{componentToShow}</div>;
};
