import {
  SET_LISA_MINIMIZED,
  SET_LISA_PLAYER_IS_ACTIVE
} from "../constants/lisa";

export const setLisaMinimized = minimized => {
  return {
    type: SET_LISA_MINIMIZED,
    payload: minimized
  };
};

export const setLisaPlayerIsActive = isActive => {
  return {
    type: SET_LISA_PLAYER_IS_ACTIVE,
    payload: isActive
  };
};
