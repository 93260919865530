import Loadable from "@loadable/component";

const PaymentProcessingPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "payment_processing_page" */ "./paymentProcessing"
    )
);

export default PaymentProcessingPage;
