import React, { FC, useEffect } from "react";
import { withSSR } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { useAppSelector, useAppDispatch } from "./redux/store";
import {
  tokenGenerator,
  showPrevMode,
  tokenGeneratorCheckoutGuest,
  setEmail
} from "./redux/actions/authConfig.action";

import { DEFAULT_PAGE_LOADER_CONFIG } from "./constants";
import { UserAgentManager } from "./util";
import {
  getUserStorage,
  getPrevModeStorage,
  getGuestUserStorage,
  getUserEmail
} from "./util/browserStorage";

import RoutesHandler from "./routes/routeManagement/routesHandler";
import Notifications from "./components/notifications";
import Loader from "./components/loader";
import "react-toastify/dist/ReactToastify.css";
import "./css/main.scss";

const App: FC<{ userAgent: string }> = ({ userAgent }) => {
  const dispatch = useAppDispatch();

  const pageLoaderParams = useAppSelector(state => state.common.pageLoader);

  useEffect(function initAuth() {
    const accessToken = getUserStorage();
    const guestAccessToken = getGuestUserStorage();
    const isPreviewMode = getPrevModeStorage();
    const userStoredEmail = getUserEmail();

    if (accessToken) {
      dispatch(tokenGenerator(accessToken));
    }
    if (guestAccessToken) {
      dispatch(tokenGeneratorCheckoutGuest(guestAccessToken));
    }
    if (isPreviewMode) {
      dispatch(showPrevMode(true));
    }
    if (userStoredEmail) {
      dispatch(setEmail(userStoredEmail));
    }
  }, []);

  UserAgentManager.setView(
    typeof window !== "undefined" && window.__user_agent__
      ? window.__user_agent__
      : userAgent
  );

  return (
    <div id="app">
      {/* Blank listener to trigger Outside click in Ipad */}
      <div id="content" onClick={() => {}}>
        {/* <Notifications /> */}
        <ToastContainer />

        <Loader
          {...{ ...DEFAULT_PAGE_LOADER_CONFIG, ...pageLoaderParams }}
          width={35}
          height={35}
        />

        <RoutesHandler />
      </div>
    </div>
  );
};

export default withSSR()(App);
