import React from "react";
import find from "lodash/find";
import { DYNAMIC_MODULE_NAMES } from "@/constants";
import { isMobile } from "@/util";
import FooterCountrySelection from "@/components/dynamic/dynamic-module/footerCountrySelection/footerCountrySelection";
import { StorylyBanner } from "../../storyly";
import { LisaBanner } from "../../lisa";
// import QuickFilters from "../../quick-filter";
import { LiveFloatingBanner } from "../../live-floating-button";
import NewsLetterWrapper from "../../footer/newsLetter/NewsLetterWrapper";
import DynamicGamification from "../dynamic-gamefication";
import { SaleWrapper } from "../dynamic-sale";
import DynamicDownloadMobileApp from "../dynamicDownloadMobileApp";
import FooterHelpline from "./footerHelpline";
import FooterCustomerCare from "./footerCustomerCare";
import FooterWhatsapp from "./footerWhatsapp";
import FooterConnectWithUs from "./footerConnectWithUs";

const DynamicModule = ({ content }) => {
  const { enable, custom, ...otherData } = content;

  const isNotAvailable =
    isMobile.any() && !content?.enabledOn?.includes("resp");
  if (!enable || !custom || isNotAvailable) return null;

  const moduleName = (find(custom, { key: "moduleName" }) || {}).value;

  switch (moduleName) {
    case DYNAMIC_MODULE_NAMES.STORYLY:
      return <StorylyBanner bannerData={custom} generalData={otherData} />;

    // NOTE: For now we just hide QuickFilters from UI
    // case DYNAMIC_MODULE_NAMES.QUICK_FILTER:
    //   return <QuickFilters bannerData={custom} />;
    case DYNAMIC_MODULE_NAMES.LISA:
      return <LisaBanner bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.LIVE_FLOATING_BUTTON:
      return <LiveFloatingBanner bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.NEWS_LETTER:
      return <NewsLetterWrapper bannerData={otherData} />;
    case DYNAMIC_MODULE_NAMES.BRAME_GAMIFICATION:
      return (
        <DynamicGamification bannerData={custom} generalData={otherData} />
      );
    case DYNAMIC_MODULE_NAMES.DOWNLOAD_MOBILE_APP:
      return <DynamicDownloadMobileApp bannerData={custom} />;
    case DYNAMIC_MODULE_NAMES.FOOTER_COUNTRY_SELECTION:
      return <FooterCountrySelection generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.FOOTER_HELPLINE:
      return <FooterHelpline bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.FOOTER_CUSTOMER_CARE:
      return <FooterCustomerCare bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.FOOTER_WHATSAPP:
      return <FooterWhatsapp bannerData={custom} generalData={otherData} />;
    case DYNAMIC_MODULE_NAMES.FOOTER_CONNECT_WITH_US:
      return (
        <FooterConnectWithUs bannerData={custom} generalData={otherData} />
      );
    default:
      return null;
  }
};

export default DynamicModule;
