import { pushGaProductClick, createObjForGA } from "../../util/analytics";
import get from "lodash/get";

class OrderAnalyticService {
  trackMulipleOrderCancellation = trackData => {
    const orderItems = get(
      trackData,
      "orderDetails.cancelOrder.orderItems",
      []
    );
    const cancelOrder = get(trackData, "orderDetails.cancelOrder", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const {
      ecommerce,
      outerObj,
      actionField
    } = createObjForGA.multipleNetCoreItems(
      orderItems,
      authReducer,
      commonSettings,
      "",
      cancelOrder
    );
    pushGaProductClick.pushGaCancelOrder(ecommerce, outerObj, actionField);
  };

  trackSingleOrderCancellation = trackData => {
    const orderItem = get(trackData, "orderItem", {});
    const cancelOrder = get(trackData, "orderDetails.cancelOrder", {});
    const authReducer = get(trackData, "authReducer", {});
    const commonSettings = get(trackData, "commonSettings", {});
    const {
      ecommerce,
      outerObj,
      actionField
    } = createObjForGA.outerAndEcommerce(
      orderItem,
      authReducer,
      commonSettings,
      "",
      cancelOrder
    );
    pushGaProductClick.pushGaCancelOrder(ecommerce, outerObj, actionField);
  };
}

export default OrderAnalyticService;
