import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  STORE_TOKEN,
  SAVE_USER_DETAILS,
  SET_ADDRESS_BOOK,
  SET_DEFAULT_ADDRESS,
  SHOW_PREVIEW,
  SET_PREV_PATH,
  STORE_TOKEN_CHECKOUT_GUEST,
  RESET_TOKEN_CHECKOUT_GUEST,
  SET_EMAIL
} from "../constants";

const initialState = {
  userName: "",
  customerId: "",
  userLoggedIn: false,
  accessToken: "",
  accessGuestToken: "",
  addressBook: [],
  showPreview: false,
  BFLUser: false
};

const saveUserDetails = (state, { data }) => ({
  ...state,
  userName: data.message,
  accessToken: data.token || data.accessToken,
  customerId: data.customerId,
  userLoggedIn: true,
  prevPath: "",
  BFLUser: data.BFLUser
});

const storeToken = (state, { token }) => ({
  ...state,
  accessToken: token,
  userLoggedIn: true
});
const storeTokenCheckoutGuest = (state, { token }) => ({
  ...state,
  accessGuestToken: token,
  userLoggedIn: false
});
const resetTokenCheckoutGuest = state => ({ ...state, accessGuestToken: "" });

const setPreviewMode = (state, { value }) => ({ ...state, showPreview: value });

const setUserInfo = (state, { userProfile: { fname, ...rest } }) => ({
  ...state,
  userName: fname,
  ...rest
});

const setAddressBook = (state, { addressBook = [] }) => ({
  ...state,
  addressBook
});

const setPrevPath = (state, { prevPath }) => ({ ...state, prevPath });

const setDefaultAddress = (state, { name, value, index }) => ({
  ...state,
  addressBook: [
    ...state.addressBook.slice(0, index),
    {
      ...state.addressBook[index],
      [name]: value
    },
    ...state.addressBook.slice(index + 1)
  ]
});

const setEmail = (state, action) => ({
  ...state,
  email: action.payload
});

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return saveUserDetails(state, action);
    case STORE_TOKEN:
      return storeToken(state, action);
    case STORE_TOKEN_CHECKOUT_GUEST:
      return storeTokenCheckoutGuest(state, action);
    case RESET_TOKEN_CHECKOUT_GUEST:
      return resetTokenCheckoutGuest(state);
    case SAVE_USER_DETAILS:
      return setUserInfo(state, action);
    case SET_ADDRESS_BOOK:
      return setAddressBook(state, action);
    case SET_DEFAULT_ADDRESS:
      return setDefaultAddress(state, action);
    case SHOW_PREVIEW:
      return setPreviewMode(state, action);
    case SET_PREV_PATH:
      return setPrevPath(state, action);
    case SET_EMAIL:
      return setEmail(state, action);
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default userProfileReducer;
