import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import SyteTooltipCloseIcon from "@/images/syte-tooltip-cross.svg";
import "./style.scss";

const SyteTooltip: FC<{
  onCrossClick: () => void;
  showCrossIcon?: boolean;
}> = ({ onCrossClick, showCrossIcon }) => {
  const { t } = useTranslation("userPreferences");

  return (
    <div className="syte-discovery-tooltip">
      <div className="close-icon-container d-flex pt-2 px-2">
        {showCrossIcon && (
          <img
            src={SyteTooltipCloseIcon}
            alt="close tooltip"
            onClick={onCrossClick}
          />
        )}
      </div>
      <div className="tooltip-content px-3">
        <p className="mb-0">{t("new")}</p>
        <p className="mb-2">{t("visualSearch")}</p>
        <p>{t("takePhotoOrUpload")}</p>
      </div>
    </div>
  );
};

export default SyteTooltip;
