import { FILTER_LOCATION } from "@/services/analytics/main/plp/types";
import PlpAnalytics from "@/services/analytics/main/plp";
import {
  FILTER_QUERY_PARAMS,
  FILTER_TYPES
} from "@/routes/productListing/constants";
import { SelectedFiltersType } from "./types";

export const handleFilterEvent = (
  selected,
  updatedSelection: SelectedFiltersType
) => {
  if (selected?.[0]?.type === FILTER_TYPES.PRICE) {
    return;
  }

  const filterValue = getFilterValue(selected);

  // If filter value falsy than we only removed filter(filters if it's mobile)
  if (!filterValue) {
    return;
  }

  const filterForAnalytics = {
    filter_by: getFilterBy(selected),
    filter_value: filterValue,
    filter_by_category: getFilterByCategory(selected),
    filter_location: FILTER_LOCATION.PRODUCT_SEARCH,
    filter_all_categories: getFilterAllCategories(updatedSelection),
    filter_all_values: getFilterAllValues(updatedSelection)
  };

  PlpAnalytics.filter(filterForAnalytics);
};

const getFilterBy = selected => {
  if (Array.isArray(selected)) {
    if (selected[0]?.type === FILTER_TYPES.CATEGORY) {
      // Last element of category filter contains actual value
      return selected.at(-1)?.defaultLabel?.toLowerCase();
    }
  }

  return selected?.defaultLabel?.toLowerCase();
};

const getFilterValue = selected => {
  if (Array.isArray(selected)) {
    if (selected?.[0]?.type === FILTER_TYPES.CATEGORY) {
      // Last element of category filter contains actual value
      const selectedOption = selected.at(-1)?.selectedOptions?.[0];
      return (
        selectedOption?.extraLabel?.toLowerCase() ??
        selectedOption?.defaultValue?.toLowerCase()
      );
    }
    return;
  }

  return getNewSelectedValues(selected?.selectedOptions);
};

const getFilterByCategory = selected => {
  if (Array.isArray(selected)) {
    if (selected[0].type === FILTER_TYPES.CATEGORY) {
      // Last element of category filter contains actual value
      return selected
        .at(-1)
        ?.selectedOptions?.map(
          selectedOption =>
            selectedOption?.extraLabel?.toLowerCase() ??
            selectedOption?.defaultValue?.toLowerCase()
        )
        ?.join("|");
    }
  }

  return selected?.selectedOptions
    ?.map(selectedOption => selectedOption?.defaultValue?.toLowerCase())
    ?.join("|");
};

const getNewSelectedValues = selectedOptions => {
  return selectedOptions
    ?.reduce((newValues, selectedOption) => {
      /** In current implementation isSelected === false means that it is newly selected item/items because already
       *  selected items have isSelected === true */
      if (selectedOption.isSelected === false) {
        newValues.push(selectedOption?.defaultValue?.toLowerCase());
      }
      return newValues;
    }, [])
    ?.join("|");
};

const getFilterAllCategories = (updatedSelection: SelectedFiltersType) => {
  const selectedValues = Object.values(updatedSelection);
  const selectedCategories = [];

  for (const selectedValue of selectedValues) {
    if (
      selectedValue.type === FILTER_TYPES.PRICE ||
      selectedValue.queryParam === FILTER_QUERY_PARAMS.SORT ||
      selectedValue.selectedOptions?.length === 0
    ) {
      continue;
    }
    selectedCategories.push(selectedValue?.defaultLabel?.toLowerCase());
  }

  return selectedCategories.join("|");
};

const getFilterAllValues = (updatedSelection: SelectedFiltersType) => {
  const selectedValues = Object.values(updatedSelection);

  return selectedValues
    .reduce((selectedValues, selectedValue) => {
      if (
        selectedValue.type === FILTER_TYPES.PRICE ||
        selectedValue.queryParam === FILTER_QUERY_PARAMS.SORT
      ) {
        return selectedValues;
      }

      selectedValue?.selectedOptions.forEach(selectedOption => {
        const value = selectedOption?.defaultValue?.toLowerCase();
        selectedValues.push(value);
      });
      return selectedValues;
    }, [])
    ?.join("|");
};
