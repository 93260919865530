import React, { FC, useContext, useEffect, useRef, useState } from "react";
import cn from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import isEmpty from "lodash/isEmpty";
import FilterOptionItem from "@/routes/productListing/productListingFiltersWeb/filterOptionItem";
import { SelectedFiltersContext } from "../saleWebFilteringSection";
import FilterElement from "./filterElement";
import { getDeepSelectedCategory } from "@/utils";
import { CategoryFilterOption, GeneralFacet, CategoryFacet } from "../types";

type FilterCategoryProps = {
  filter: Omit<GeneralFacet & CategoryFacet, "sortOrder">;
};
type FilterCategoryOptionProps = {
  filterContent: CategoryFilterOption;
  onSelection: (...args) => void;
};

const FilterCategoryWeb: FC<FilterCategoryProps> = ({ filter }) => {
  const { data: filterOptions } = filter;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const {
    onSelection,
    updateSelectedFilters,
    selectedFilters,
    setSelectedFilters
  } = useContext(SelectedFiltersContext);

  const onFilterOptionClick = ({ option, isLeaf = false }, event) => {
    event?.stopPropagation();

    const permissionToClick =
      isLeaf ||
      !selectedOptions.length ||
      selectedOptions[0].key !== option.key;

    if (permissionToClick) {
      let options = [];

      if (isLeaf) {
        const filteredOptions = selectedOptions.filter(
          selectedOption => selectedOption.queryParam === option.queryParam
        );
        const alreadyAdded = filteredOptions.find(
          selectedOption => selectedOption.key === option.key
        );

        if (alreadyAdded) {
          options = filteredOptions.filter(item => item.key !== option.key);
        } else {
          options = [...filteredOptions, option];
        }
      }

      setSelectedOptions(isLeaf ? options : [option]);

      onSelection([
        ...(selectedOptions.length > 0
          ? [
              {
                type: filter.type,
                queryParam: selectedOptions[0].queryParam,
                defaultLabel: filter.defaultLabel,
                selectedOptions: []
              }
            ]
          : []),
        {
          type: filter.type,
          queryParam: option.queryParam,
          defaultLabel: filter.defaultLabel,
          selectedOptions: isLeaf ? options : [option]
        }
      ]);
    }
  };

  useEffect(() => {
    const selectedOption = getDeepSelectedCategory(filter);
    if (!isEmpty(selectedOption)) {
      const updatedFilter = selectedOption.map(item => {
        return {
          type: filter.type,
          label: item.label,
          defaultLabel: filter.defaultLabel,
          queryParam: item.queryParam,
          selectedOptions: [item]
        };
      });

      updateSelectedFilters(updatedFilter);
    }
  }, []);

  useEffect(() => {
    const selectedOptionByResponse = getDeepSelectedCategory(filter);
    setSelectedOptions(selectedOptionByResponse);
    if (
      isEmpty(selectedOptionByResponse) &&
      selectedFilters[selectedOptions?.queryParam]
    ) {
      setSelectedFilters(currentFilters => {
        const { [selectedOptions.queryParam]: toRemove, ...newFilters } =
          currentFilters;
        return newFilters;
      });
    }
  }, [filter]);

  return (
    <FilterElement
      className={cn({
        items_selected: !!selectedOptions.length
      })}
      filterLabel={filter.label}
      filterWrapperClassName="categories mxw-300"
    >
      <Scrollbars autoHeight autoHeightMin={55} autoHeightMax={240}>
        <div>
          <ul>
            {filterOptions.map(option => (
              <RootFilterContent
                key={option.key}
                filterContent={option}
                onSelection={onFilterOptionClick}
              />
            ))}
          </ul>
        </div>
      </Scrollbars>
    </FilterElement>
  );
};

const LeafComponent: FC<FilterCategoryOptionProps> = ({
  filterContent,
  onSelection
}) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (filterContent.isSelected) {
      ref.current.scrollIntoView({ block: "end" });
    }
  }, []);

  return (
    <FilterOptionItem
      key={filterContent.key}
      option={filterContent}
      isSelected={filterContent.isSelected}
      onSelection={onSelection}
      containerRef={ref}
    />
  );
};

const ExpandableComponent: FC<FilterCategoryOptionProps> = ({
  filterContent,
  onSelection
}) => {
  const [isOpen, setIsOpen] = useState(filterContent.isSelected);
  const [selected, setSelected] = useState(false);
  const toggleOpen = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const onExtraLabelClick = event => {
    onSelection(
      {
        option: {
          ...filterContent,
          selectedOptions: [filterContent]
        }
      },
      event
    );
  };

  useEffect(() => {
    const selectionState =
      filterContent.isSelected &&
      filterContent.children.every(item => !item.isSelected);
    setSelected(selectionState);
  }, [filterContent]);

  return (
    <li>
      <h5
        className={cn("cursor_pointer", "category_name", "d-flex", {
          selected: filterContent.isSelected,
          expanded: isOpen
        })}
        onClick={toggleOpen}
      >
        <div className="expendable_icon" />
        {filterContent.label}
      </h5>
      <ul className={cn({ "d-none": !isOpen })}>
        <li
          className={cn("category_extra_label cursor_pointer", {
            selected
          })}
          onClick={onExtraLabelClick}
        >
          {filterContent.extraLabel}
        </li>
        {filterContent.children.map(option => (
          <RootFilterContent
            key={option.key}
            filterContent={option}
            onSelection={onSelection}
          />
        ))}
      </ul>
    </li>
  );
};

const RootFilterContent: FC<FilterCategoryOptionProps> = ({
  filterContent,
  onSelection
}) => {
  const isLeaf = isEmpty(filterContent.children);

  if (isLeaf) {
    return (
      <LeafComponent filterContent={filterContent} onSelection={onSelection} />
    );
  }
  return (
    <ExpandableComponent
      filterContent={filterContent}
      onSelection={onSelection}
    />
  );
};

export default FilterCategoryWeb;
