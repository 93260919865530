import { BEConfig } from "../../config/env";
import { putDashboardWebApi } from "../../webapis/apiResource";
import { getAccessToken } from "../../util/storeHelper";
import CartService, {
  OrderSummaryRequestBody
} from "../../services/cartService";

import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { handleFetchError } from "../../util/errorHandler";
import {
  GET_CART_LIST,
  UPDATE_CART_LIST,
  DELETE_CART_LIST,
  SET_SAVING_INDICATOR,
  GET_CART_SUMMARY
} from "../constants";
import { getVisitorId, languageFromPathName } from "../../util";
import { sameSizeItemsFormat } from "../../util/cart";
import {
  CLEAR_CART_AND_WISHLIST_GENERAL,
  DELETE_WISHLIST_ITEM_LOADING,
  SET_CART_AND_WISHLIST_GENERAL,
  UPDATE_ITEM_QUANTITY,
  WISHLIST_GENERAL_LOADING
} from "../constants";
export const getCartList = (cart, isProductMerged) => ({
  type: GET_CART_LIST,
  cart,
  isProductMerged
});

export const updateCartList = cart => ({ type: UPDATE_CART_LIST, cart });

export const setSavingIndicator = value => ({
  type: SET_SAVING_INDICATOR,
  value
});

export const deleteCartList = (productId, _id) => ({
  type: DELETE_CART_LIST,
  productId,
  _id
});

export const updateItemQuantity = (productId, quantity) => ({
  type: UPDATE_ITEM_QUANTITY,
  productId,
  quantity
});

export const deleteCartData =
  (productId, id = "", showLoader = true) =>
  async dispatch => {
    if (showLoader) dispatch(showLoadingIndicator());

    try {
      const response = await CartService.deleteCartData(productId, id);
      dispatch(deleteCartList(productId, id));

      return response;
    } catch (error) {
      handleFetchError(error, dispatch);
      return error.response;
    } finally {
      if (showLoader) dispatch(hideLoadingIndicator());
    }
  };

export const deletePurchasedCartData = (orderItems, language) => {
  return async dispatch => {
    language = languageFromPathName(language);

    const cartData = await CartService.fetchCartData();

    let listForDeleting = [];
    if (cartData?.products?.length) {
      listForDeleting = cartData.products.filter(
        el => orderItems && orderItems.find(item => item.pId === el.productId)
      );
      listForDeleting.forEach(item =>
        dispatch(deleteCartData(item.productId, item._id))
      );
    }
  };
};

export const fetchCartData =
  (showLoader = true, isProductMerged = false) =>
  async dispatch => {
    if (showLoader) dispatch(showLoadingIndicator());

    try {
      // TODO: refactor
      const cartData = await CartService.fetchCartData();
      dispatch(getCartList(cartData.products, false));

      return cartData;
    } catch (error) {
      handleFetchError(error, dispatch);

      return error.response;
    } finally {
      dispatch(hideLoadingIndicator());
    }
  };

export const saveToCart = (
  item,
  updateReducer = true,
  showLoader = false,
  withUpdatedProduct = false
) => {
  const {
    wishListApi: { baseURL, protocol, port, versionInfo, cartPageHandle }
  } = BEConfig;
  const apiURL = `${protocol}${baseURL}${port}${versionInfo}${cartPageHandle}`;
  return (dispatch, getState) => {
    const sizeFormattedItem = sameSizeItemsFormat(
      item,
      getState().cartReducer.cart
    );
    dispatch(setSavingIndicator(true));

    if (showLoader) dispatch(showLoadingIndicator());
    const token = getAccessToken(getState);
    return putDashboardWebApi(token, apiURL, sizeFormattedItem, getVisitorId())
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch(setSavingIndicator(false));
          if (updateReducer) dispatch(updateCartList(sizeFormattedItem));
          if (withUpdatedProduct) {
            const changedProduct = response.data.products?.find(
              product => sizeFormattedItem.productId === product.productId
            );
            if (!changedProduct) return response;
            let cartProducts = [...getState().cartReducer.cart];
            const productIndex = cartProducts.findIndex(
              product => sizeFormattedItem.productId === product.productId
            );
            if (productIndex !== -1) {
              cartProducts[productIndex] = changedProduct;
              dispatch(getCartList(cartProducts, false));
            }
          }
        }
        return response;
      })
      .catch(error => {
        dispatch(setSavingIndicator(false));

        return error;
      })
      .finally(() => {
        if (showLoader) dispatch(hideLoadingIndicator());
      });
  };
};

const wishListGeneralLoading = () => ({
  type: WISHLIST_GENERAL_LOADING
});

export const deleteWishListGeneralLoading = () => ({
  type: DELETE_WISHLIST_ITEM_LOADING
});

export const getCartAndWishlistGeneralData = () => async dispatch => {
  try {
    dispatch(wishListGeneralLoading());

    const { data } = await CartService.getCartAndWishlistGeneralData();

    dispatch(setCartAndWishlistGeneralData(data));
  } catch (error) {
    console.error(error);
  }
};

const setCartAndWishlistGeneralData = cartAndWishlistGeneralData => ({
  type: SET_CART_AND_WISHLIST_GENERAL,
  cartAndWishlistGeneralData
});

export const clearCartAndWishlistGeneralData = () => ({
  type: CLEAR_CART_AND_WISHLIST_GENERAL
});

export const handleAddToCart = (cartData, language) => {
  return async dispatch => {
    const response = await dispatch(saveToCart(cartData));
    const products = response.data?.products;

    if (products) {
      dispatch(getCartList(response.data.products, false));
    }

    // TODO: Add analytic after deciding what to add
    // AnalyticService.cart.trackAddToCart({
    //   item,
    //   cartData,
    //   authReducer,
    //   commonSettings,
    // });
    return response;
  };
};

export const getCartSummary =
  ({ deliveryType }: OrderSummaryRequestBody) =>
  async dispatch => {
    try {
      const response = await CartService.getCartSummary({
        deliveryType
      });

      dispatch({ type: GET_CART_SUMMARY, cartSummary: response });

      return response;
    } catch (error) {
      console.error(
        "The error happened on the Cart summary data fetching:",
        error
      );
      return error?.response;
    }

    return;
  };
