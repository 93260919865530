import "react-app-polyfill/stable";

import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { I18nextProvider } from "react-i18next";
import { loadableReady } from "@loadable/component";
import { Cookies } from "react-cookie";

import { googleAppId, sentry_DSN } from "./app/config/app";
import createStore from "./app/redux/store";
import { getPrevModeStorage } from "./app/util/browserStorage";
import i18next, {
  initI18next,
  initialI18nStore,
  initialLanguage
} from "./app/i18n";

import App from "./app";
import { PageInitialPropsProvider } from "./app/libs/pageDataBridge";
import { ContextStorage } from "./app/libs/contextStorage";

loadableReady().then(() => {
  const store = createStore({
    preloadedState: window.__PRELOADED_STATE__,
    language: initialLanguage
  });
  const cookies = new Cookies();
  ContextStorage.setClientStorageData({
    cookies,
    store
  });

  const isPreviewMode = getPrevModeStorage();

  initI18next();

  const Application = (
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={googleAppId}>
          <PageInitialPropsProvider
            initialProps={window.__PAGE_INITIAL_PROPS__}
          >
            <BrowserRouter>
              <App
                userAgent={navigator.userAgent}
                initialLanguage={initialLanguage}
                initialI18nStore={initialI18nStore}
              />
            </BrowserRouter>
          </PageInitialPropsProvider>
        </GoogleOAuthProvider>
      </Provider>
    </I18nextProvider>
  );

  Sentry.init({
    environment: process.env.REACT_APP_ENV || "development",
    dsn: sentry_DSN,
    beforeSend: function (event, hint) {
      if (hint?.originalException instanceof Event) {
        event!.extra!.isTrusted = hint.originalException.isTrusted;
        event!.extra!.detail = hint.originalException["detail"];
        event!.extra!.type = hint.originalException.type;
      }

      return event;
    }
  });

  const root = document.querySelector("#root");
  const isServerSideRenderedMode = root!.hasChildNodes() && !isPreviewMode;

  if (isServerSideRenderedMode) {
    hydrate(Application, root);
  } else {
    render(Application, root);
  }

  delete window.__PRELOADED_STATE__;
  delete window.__PAGE_INITIAL_PROPS__;
});
