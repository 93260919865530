import React, { FC } from "react";
import { FILTER_QUERY_PARAMS, FILTER_TYPES } from "../constants";
import { FilterSortMob } from "./filterSortMob";
import { Facet } from "../types";

type FilterProps = {
  filter: Facet;
};

const FiltersExtraTypeContainer: FC<FilterProps> = ({ filter }) => {
  const { type, ...restFilterData } = filter;

  switch (type) {
    case FILTER_TYPES.SINGLE_SELECT:
      if (restFilterData.queryParam === FILTER_QUERY_PARAMS.SORT) {
        return <FilterSortMob filter={filter} />;
      }
      break;

    default:
      return null;
  }
};

export default FiltersExtraTypeContainer;
