import { PageLoadStrategy, PageLoadStrategyDataArgument } from "./types";

export const handleLoadTask = async (
  task: PageLoadStrategy,
  data: PageLoadStrategyDataArgument
) => {
  try {
    return await task(data);
  } catch (error) {
    console.error("Page load common task error: ", error);
  }
};
