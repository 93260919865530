import React from "react";
import cn from "clsx";
import { useIsRTL, useLongLanguage } from "../../../hooks";
import HtmlParser from "../../html-parser";
import { DynamicBulletProps } from "./types";
import "./style.scss";

const DynamicBullet = ({ content, rowStyles }: DynamicBulletProps) => {
  const language = useLongLanguage();
  const isRTL = useIsRTL();

  const bulletContent = content.bullet[language];
  const className = content.bulletStyle || "style1";

  if (!bulletContent) {
    console.error("No content found for Dynamic bullet");
  }
  return (
    <div
      className={cn("dynamic_bullet_wrapper", className, {
        arabic: isRTL
      })}
      style={rowStyles}
    >
      {bulletContent?.map((content, index: number) => {
        return <HtmlParser key={index} html={content.value} />;
      })}
    </div>
  );
};

export default DynamicBullet;
