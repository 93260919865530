import { toast } from "react-toastify";
import { TOAST_TYPES } from "@/constants";

export const toastNotificationOpts = (title = "", message = "", time) => {
  return {
    title: title ? title : null,
    message: message,
    position: "tr",
    autoDismiss: time || 3
  };
};

export const toastMsg = (toastType, message, noTitle, time) => {
  const toastOptions = {
    autoClose: time || 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "toast_message"
  };

  if (toastType === TOAST_TYPES.SUCCESS) {
    toast.success(message, toastOptions);
  } else if (toastType === TOAST_TYPES.ERROR) {
    toast.error(message, { ...toastOptions, theme: "dark" });
  } else {
    toast(message, toastOptions);
  }
};
