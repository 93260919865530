import { useState } from "react";
import { FilterOption, GeneralFacet } from "../types";

const useSingleSelectFilter = (
  filter: Omit<GeneralFacet & { data: FilterOption[] }, "type" | "sortOrder">,
  onSelection: (...args) => void
) => {
  const { data: filterOptions, ...restFilterData } = filter;
  const [selectedOption, setSelectedOption] = useState<FilterOption>(null);
  const onFilterOptionClick = ({ option }, event) => {
    event.stopPropagation();

    if (!selectedOption || selectedOption.key !== option.key) {
      setSelectedOption(option);
      onSelection({
        ...restFilterData,
        selectedOptions: [option]
      });
    }
  };

  const clearSelectedOptions = () => {
    setSelectedOption(null);
    onSelection({
      ...restFilterData,
      selectedOptions: []
    });
  };

  return {
    filterOptions,
    selectedOption,
    onFilterOptionClick,
    clearSelectedOptions,
    setSelectedOption
  };
};

export default useSingleSelectFilter;
