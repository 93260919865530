import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  LOGGED_IN,
  LOGGED_OFF,
  LOGGED_OUT,
  OTP_METHOD_MOBILE,
  PROMOTION_FIELDS
} from "./constants";
import EVENTS from "./events";
import {
  getEcommerceData,
  getMenuTree,
  pushToDataLayer,
  sanitizeString
} from "./helper";
import {
  AddProductToCart,
  AddProductToWishlist,
  ErrorOccured,
  FormAbandonment,
  SelectProductSize,
  ShareProduct,
  SignIn,
  ViewProduct
} from "./type";
import { RemoveFromCart, ViewCart } from "../main/cart/types";
import {
  Accordion,
  AccountOptions,
  ChangeCountry,
  ChangeLanguage,
  ClickToAction,
  EmptySearchResult,
  FooterClick,
  FormErrors,
  NavTopClick,
  PaymentErrorProps,
  RecommendedProductSearch,
  Search,
  SelectBanner,
  SelectPromotion,
  SizeChart,
  SocialClick,
  ViewAllFaq,
  ViewBanner,
  ViewPromotion,
  ViewSearchResult
} from "../main/common/types";
import {
  ActionToAuthPage,
  AuthFormErrors,
  AuthMethod,
  EmailSignIn,
  SocialSignIn
} from "../main/authentication/types";
import { NavClick, RemoveFromWishlist, SelectItem } from "../types";
import { ImpressionsPayload, SortPayload } from "../main/plp/types";
import { OutofStock, PaymentMethodInfo } from "../main/pdp/type";
import {
  CancelOrderStart,
  CancelOrderSuccess,
  TrackOrder
} from "../main/myAccount/types";
import {
  AddShippingInfoProps,
  BeginCheckoutProps,
  AddPaymentInfoProps,
  ApplyVoucherProps,
  PurchaseEcommerceProps,
  RefundProps
} from "../main/checkout/types";

class GoogleAnalyticsServices {
  //Meta Page Event
  viewPage(data) {
    pushToDataLayer({
      event: EVENTS.viewPage,
      login_status: data.userLoggedIn ? LOGGED_IN : LOGGED_OFF,
      page_name: (data.title || "").toLowerCase().trim() || undefined,
      user_id: data.customerId,
      page_type: (data.type || "").toLowerCase()
    });
  }

  // PDP Events
  viewProduct(data: ViewProduct) {
    const { currency, payload, totalPrice } = getEcommerceData(data.product);
    pushToDataLayer({
      event: EVENTS.viewProduct,
      ecommerce: {
        currency,
        value: totalPrice,
        items: [payload]
      }
    });
  }
  addToCart(data: AddProductToCart) {
    const { item, cartData } = data;
    const { currency, payload, totalPrice } = getEcommerceData(
      {
        ...item,
        ...{ size: cartData?.size, quantity: cartData.quantity }
      },
      true
    );

    pushToDataLayer({
      event: EVENTS.addToCart,
      ecommerce: {
        value: totalPrice,
        currency: currency,
        items: [payload]
      }
    });
  }
  addToWishlist(data: AddProductToWishlist, isAfterCart = false) {
    const { item, wishlistData } = data;
    const { currency, payload, totalPrice } = getEcommerceData(
      item,
      isAfterCart
    );

    /*
     * If it's before adding to cart and If it's size is selected. */
    if (!isAfterCart && !isEmpty(wishlistData.size)) {
      payload["size"] = wishlistData.size?.toLowerCase();
    }

    pushToDataLayer({
      event: EVENTS.addToWishlist,
      ecommerce: {
        value: totalPrice,
        currency: currency,
        items: [payload]
      }
    });
  }
  imageInteraction(productSKU: number) {
    pushToDataLayer({
      event: EVENTS.productImagesInteraction,
      product_sku: productSKU?.toString()
    });
  }
  shareProduct(data: ShareProduct) {
    pushToDataLayer({
      event: EVENTS.shareProduct,
      share_by: data.shareBy,
      product_sku: data.productSKU?.toString()
    });
  }
  selectSize(data: SelectProductSize) {
    const { sizeData, item } = data;

    pushToDataLayer({
      event: EVENTS.selectSize,
      size: sizeData.size.size?.toLowerCase(),
      product_sku: item.id?.toString(),
      ...(!isEmpty(sizeData?.sizeType) && {
        size_region: sizeData?.sizeType.defaultValue.toLowerCase()
      })
    });
  }
  outOfStock(payload: OutofStock) {
    const { item } = payload;
    pushToDataLayer({
      event: EVENTS.outOfStock,
      size_region: get(
        item.sizes,
        "[0].sizeType.defaultValue",
        undefined
      )?.toLowerCase(),
      product_sku: item.id.toString(),
      size: get(item.sizes, "[0].variants", [])
        .filter(size => !!!size.sizeStock)
        .map(size => size.size)
        .join("|")
        ?.toLowerCase()
    });
  }
  paymentMethodInfo(data: PaymentMethodInfo) {
    pushToDataLayer({
      event: EVENTS.paymentMethodInfo,
      payment_method: data.method?.toLowerCase(),
      cta_name: "learn more"
    });
  }

  // Cart Page Events
  removeFromCart(data: RemoveFromCart, isAfterCart = false) {
    const { item } = data;
    const { currency, payload, totalPrice } = getEcommerceData(
      item,
      isAfterCart
    );
    pushToDataLayer({
      event: EVENTS.removeFromCart,
      ecommerce: {
        value: totalPrice,
        currency: currency,
        items: [payload]
      }
    });
  }
  viewCart(data: ViewCart) {
    const { items } = data;
    const { currency, payload, totalPrice } = getEcommerceData(items, true);
    pushToDataLayer({
      event: EVENTS.viewCart,
      ecommerce: {
        value: totalPrice,
        currency,
        items: payload
      }
    });
  }

  // Checkout Events

  beginCheckout(ecommerce: BeginCheckoutProps) {
    pushToDataLayer({
      event: EVENTS.beginCheckout,
      ecommerce
    });
  }

  addShippingInfo(ecommerce: AddShippingInfoProps) {
    pushToDataLayer({
      event: EVENTS.addShippingInfo,
      ecommerce
    });
  }

  continueAsGuest(login_status: string) {
    pushToDataLayer({
      event: EVENTS.continueAsGuest,
      login_status
    });
  }

  pinYourLocation(data: { location: string; status: string }) {
    pushToDataLayer({
      event: EVENTS.pinYourLocation,
      status: data.status,
      location: data.location
    });
  }

  addPaymentInfo(ecommerce: AddPaymentInfoProps) {
    pushToDataLayer({
      event: EVENTS.addPaymentInfo,
      ecommerce
    });
  }

  paymentError(data: PaymentErrorProps) {
    pushToDataLayer({
      event: EVENTS.paymentError,
      ...data
    });
  }

  applyVoucher(voucherData: ApplyVoucherProps) {
    pushToDataLayer({
      event: EVENTS.applyVoucher,
      ...voucherData
    });
  }

  purchase(data: PurchaseEcommerceProps) {
    const { payload } = getEcommerceData(data.items, true);

    pushToDataLayer({
      event: EVENTS.purchase,
      ecommerce: {
        currency: data.currency.toLowerCase(),
        value: data.value,
        transaction_id: data.transaction_id,
        shipping: data.shipping,
        items: payload,
        coupon: data.coupon,
        delivery_type: data.deliveryType.toLowerCase(),
        payment_method: data.paymentMethod.toLowerCase()
      }
    });
  }

  refund(ecommerce: RefundProps) {
    pushToDataLayer({ event: EVENTS.refund, ecommerce });
  }

  // PLP Events
  selectProduct(data: SelectItem) {
    const { payload } = getEcommerceData(data.item);
    const listHeading = (data.listHeading || "")?.toLowerCase();
    pushToDataLayer({
      event: EVENTS.selectItem,
      ecommerce: {
        items: [
          {
            ...payload,
            ...(!!listHeading.length && {
              item_list_name: listHeading,
              item_list_id: listHeading
            })
          }
        ]
      }
    });
  }
  productListImpressions(data: ImpressionsPayload) {
    const { currency, payload } = getEcommerceData(data.items);
    const listHeading = (data.listHeading || "")?.toLowerCase();
    const listingObject = {
      ...(!!data.listHeading.length && {
        item_list_name: listHeading,
        item_list_id: listHeading
      })
    };
    pushToDataLayer({
      event: EVENTS.viewItemList,
      ecommerce: {
        currency,
        ...listingObject,
        items:
          Array.isArray(payload) &&
          payload.map(product => ({
            ...product,
            ...listingObject
          }))
      }
    });
  }

  brandStock({ brand_name = undefined, brand_quantity = undefined }) {
    pushToDataLayer({
      event: EVENTS.brandStock,
      brand_name,
      brand_quantity
    });
  }

  // TODO: implementation
  filterAutoApplied(data: any) {
    pushToDataLayer({
      event: EVENTS.filterAutoApplied
    });
  }

  // TODO: implementation
  filter(data: any) {
    pushToDataLayer({
      event: EVENTS.filter,
      ...data
    });
  }

  sort(data: SortPayload) {
    pushToDataLayer({
      event: EVENTS.sort,
      ...data
    });
  }

  // Header Events
  viewMiniCart() {
    pushToDataLayer({
      event: EVENTS.viewMiniCart
    });
  }
  accountOptions(data: AccountOptions) {
    pushToDataLayer({
      event: EVENTS.accOptionSelected,
      option_selected: data.optionLabel?.toLowerCase()
    });
  }
  changeCountry(data: ChangeCountry) {
    pushToDataLayer({
      event: EVENTS.changeCountry,
      selected_country: data.country.toLowerCase()
    });
  }
  changeLanguage(data: ChangeLanguage) {
    pushToDataLayer({
      event: EVENTS.changeLanguage,
      selected_language: data.code
    });
  }
  navTopClick(data: NavTopClick) {
    pushToDataLayer({
      event: EVENTS.navTop,
      click_text: data.name.toLowerCase(),
      ...(data.url && { nav_link: data.url })
    });
  }
  navigationClick(data: NavClick) {
    pushToDataLayer({
      event: EVENTS.navClick,
      click_text: data.clickText.toLowerCase(),
      ...(data.tree && { nav_tree: data.tree }),
      ...(data.link && { nav_link: data.link })
    });
  }
  megaMenuClick(menuItem) {
    const headerTitle = menuItem.en_headerTitle || menuItem.headerTitle || "";
    this.navigationClick({
      clickText: headerTitle,
      link: menuItem.redirectionLink
    });
  }
  lvl1MenuClick(menuItem) {
    const headerTitle =
      menuItem.item.en_headerTitle || menuItem.item.headerTitle || "";
    const subTitle = menuItem.item.en_subTitle || menuItem.item.subTitle || "";
    const navTree = getMenuTree(headerTitle, subTitle);

    this.navigationClick({
      clickText: subTitle,
      link: menuItem.url,
      tree: navTree
    });
  }
  lvl2MenuClick(menuItem) {
    const headerTitle =
      menuItem.item.en_headerTitle || menuItem.item.headerTitle || "";
    const subTitle = menuItem.item.en_subTitle || menuItem.item.subTitle || "";
    const subTitle1 =
      menuItem.item.en_subTitle1 || menuItem.item.subTitle1 || "";
    const navTree = getMenuTree(headerTitle, subTitle1, subTitle);

    this.navigationClick({
      clickText: subTitle,
      link: menuItem.url,
      tree: navTree
    });
  }
  searchInteraction(type: string) {
    pushToDataLayer({
      event: EVENTS.searchInteraction,
      search_type: type
    });
  }
  inputSearchInteraction() {
    this.searchInteraction("text");
  }
  syteSearchInteraction() {
    this.searchInteraction("visual");
  }
  search(data: Search) {
    pushToDataLayer({
      event: EVENTS.search,
      ...(data.searchTerm && { search_term: data.searchTerm.toLowerCase() }),
      search_type: "text",
      search_category: data.searchCategory?.toLowerCase(),
      click_text: data.clickText,
      number_of_search_results: data.searchResultCount
    });
  }
  emptySearchResult(data: EmptySearchResult) {
    pushToDataLayer({
      event: EVENTS.emptySearch,
      search_term: data.searchText
    });
  }
  viewSearchResult(data: ViewSearchResult) {
    pushToDataLayer({
      event: EVENTS.viewSearchResult,
      ...(data.searchTerm && { search_term: data.searchTerm?.toLowerCase() }),
      search_type: data.searchType,
      search_category: data.searchCategory?.toLowerCase(),
      number_of_search_results: data.searchResultCount
    });
  }
  recommendedProductSearch(data: RecommendedProductSearch) {
    const {
      searchTerm,
      searchCategory,
      clickText,
      searchResultCount = 1
    } = data;
    this.search({
      searchTerm,
      searchCategory,
      clickText: clickText?.toLowerCase() || "",
      searchResultCount
    });
  }

  // Authentication Events
  forgotPassword() {
    pushToDataLayer({
      event: EVENTS.forgotPassword
    });
  }
  authenticationMethod(data: AuthMethod) {
    const event = !data.isLogin ? EVENTS.signUpMethod : EVENTS.signInMethod;
    pushToDataLayer({
      event,
      method: data.method
    });
  }
  actionToAuthPage(data: ActionToAuthPage) {
    const event = !data.isLogin ? EVENTS.actionToSignUp : EVENTS.actionToSignIn;
    pushToDataLayer({
      event
    });
  }
  signIn(data: SignIn) {
    pushToDataLayer({
      event: EVENTS.signIn,
      user_id: data.userId?.toString(),
      method: data.method,
      login_status: data.loginStatus ? LOGGED_IN : LOGGED_OFF,
      country_name: data.countryId,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName
    });
  }
  socialSignIn(data: SocialSignIn) {
    this.signIn({
      userId: data.responseData?.customerId,
      countryId: undefined,
      email: data.responseData?.email,
      firstName: data.responseData?.firstname,
      lastName: data.responseData?.lastname,
      method: data.loginFrom,
      loginStatus: data.responseData?.success
    });
  }
  emailSignIn(data: EmailSignIn) {
    this.signIn({
      userId: get(data.responseData, "userProfile.customerId", undefined),
      countryId: get(data.responseData, "userProfile.countryId", undefined),
      email: get(data.responseData, "userProfile.email", undefined),
      firstName: get(data.responseData, "userProfile.firstname", undefined),
      lastName: get(data.responseData, "userProfile.lastname", undefined),
      method: data.loginFrom,
      loginStatus: get(data.responseData, "success", false)
    });
  }
  signUp(data) {
    const { isSocialLogin, customerId, registerData } = data;
    const authData = {
      method: isSocialLogin ? get(registerData, "loginFrom", "") : "email",
      newsletter_subscription: get(registerData, "newsLetter", false).toString()
    };
    pushToDataLayer({
      event: EVENTS.signUp,
      login_status: isSocialLogin ? LOGGED_IN : LOGGED_OFF,
      // as with social-login, user will be loggedIn automatically
      user_id: `${customerId}` || undefined,
      ...authData
    });
  }
  loginError(data) {
    pushToDataLayer({
      event: EVENTS.loginError,
      method: data.method,
      login_error_message: sanitizeString(data.error)
    });
  }
  loginFormError(data: AuthFormErrors) {
    const errorMsgs = Object.values(data.errors);
    if (!isEmpty(errorMsgs)) {
      errorMsgs.forEach(error => {
        this.loginError({ method: "email", error });
      });
    }
  }
  signUpError(data) {
    pushToDataLayer({
      event: EVENTS.signUpError,
      method: data.method,
      signup_error_message: data.error
    });
  }
  signupFormError(data: AuthFormErrors) {
    const errorMsgs = Object.values(data.errors);
    if (!isEmpty(errorMsgs)) {
      errorMsgs.forEach(error => {
        this.signUpError({ method: "email", error });
      });
    }
  }

  // Application wide Form Events
  formStart(formName: string) {
    pushToDataLayer({
      event: EVENTS.formStart,
      form_name: formName.toLowerCase()
    });
  }
  formError(errors: FormErrors) {
    if (!isEmpty(errors)) {
      const fields = Object.keys(errors);
      fields.forEach(field => {
        this.errorOccured({
          name: field,
          message: errors[field]
        });
      });
    }
  }
  formComplete(formName: string) {
    pushToDataLayer({
      event: EVENTS.formComplete,
      form_name: formName.toLowerCase()
    });
  }
  errorOccured(error: ErrorOccured) {
    pushToDataLayer({
      event: EVENTS.errorOccured,
      error_name: error.name?.toLowerCase(),
      error_message: error.message?.toLowerCase()
    });
  }

  formAbandonment(data: FormAbandonment) {
    pushToDataLayer({
      event: EVENTS.formAbandonment,
      error_name: data.name?.toLowerCase(),
      form_field_name: data.inputName?.toLowerCase()
    });
  }

  // OTP Related Events
  requestOTP() {
    pushToDataLayer({
      event: EVENTS.requestOTP,
      method: OTP_METHOD_MOBILE // static
    });
  }
  resendOTP() {
    pushToDataLayer({
      event: EVENTS.resendOTP,
      method: OTP_METHOD_MOBILE // static
    });
  }
  validatedOTP() {
    pushToDataLayer({
      event: EVENTS.verifiedOTP,
      method: OTP_METHOD_MOBILE // static
    });
  }

  // Banner Related Events
  viewBanner(data: ViewBanner) {
    pushToDataLayer({
      event: EVENTS.viewBanner,
      banner_id: (data.bannerId || "")?.toLowerCase(),
      banner_name: (data.bannerTitle || "")?.toLowerCase()
    });
  }
  selectBanner(data: SelectBanner) {
    pushToDataLayer({
      event: EVENTS.selectBanner,
      banner_id: (data.bannerId || "")?.toLowerCase(),
      banner_name: (data.bannerTitle || "")?.toLowerCase()
    });
  }
  viewPromotion(data: ViewPromotion) {
    const payload = {};
    data.attributes.forEach(({ key, value }) => {
      if (PROMOTION_FIELDS.includes(key)) {
        payload[key] = value?.toLowerCase();
      }
    });
    pushToDataLayer({
      event: EVENTS.viewPromotion,
      ecommerce: {
        ...payload
      }
    });
  }
  selectPromotion(data: SelectPromotion) {
    const payload = {};
    data.attributes.forEach(({ key, value }) => {
      if (PROMOTION_FIELDS.includes(key)) {
        payload[key] = value?.toLowerCase();
      }
    });
    pushToDataLayer({
      event: EVENTS.selectPromotion,
      ecommerce: {
        ...payload
      }
    });
  }

  // My Accounts Event
  cancelOrderStart(data: CancelOrderStart) {
    pushToDataLayer({
      event: EVENTS.cancelOrderStart,
      order_id: data.orderId?.toLowerCase()
    });
  }
  cancelOrderSuccess(data: CancelOrderSuccess) {
    pushToDataLayer({
      event: EVENTS.cancelOrderSuccess,
      order_id: data.orderId?.toLowerCase()
    });
  }
  accountInfoSaved() {
    pushToDataLayer({
      event: EVENTS.accountInfoSaved
    });
  }
  trackOrder(data: TrackOrder) {
    pushToDataLayer({
      event: EVENTS.trackOrder,
      order_id: data.orderId?.toLowerCase()
    });
  }
  logout() {
    pushToDataLayer({
      event: EVENTS.logout,
      login_status: LOGGED_OUT
    });
  }

  // Footer related Events
  subscribeNewsletter() {
    pushToDataLayer({
      event: EVENTS.subscribeNewsletter
    });
  }
  footerClick(data: FooterClick) {
    pushToDataLayer({
      event: EVENTS.navFooter,
      nav_link: data.href,
      nav_tree: data.navTree?.toLowerCase()
    });
  }
  sizeChart(data: SizeChart) {
    pushToDataLayer({
      event: EVENTS.sizeChart,
      click_text: data.clickText?.toLowerCase(),
      size_chart_tree: data.tree?.toLowerCase()
    });
  }
  nearByStore(data) {
    pushToDataLayer({
      event: EVENTS.locateNearByStore,
      store_country: (
        data.shop?.en_country ||
        data.shop?.country ||
        data.shop?.countryName
      )?.toLowerCase(),
      store_city: data.shop?.city?.toLowerCase(),
      store_name: data.shop?.name?.toLowerCase()
    });
  }
  viewAllFaq(data: ViewAllFaq) {
    pushToDataLayer({
      event: EVENTS.viewAllFaq,
      faq_category: data.category
    });
  }

  /**
   * This event is common between PLP, PDP, and Wishlist Page
   */
  removeFromWishlist(data: RemoveFromWishlist) {
    pushToDataLayer({
      event: EVENTS.removeFromWishlist,
      item_id: data.wishListItem?.productId,
      item_name: get(data.item, "title.defaultValue", undefined)
    });
  }

  // CTA Events
  clickToAction(data: ClickToAction) {
    pushToDataLayer({
      event: EVENTS.clickToAction,
      cta_name: data.label.toLowerCase(),
      cta_type: data.type || "link"
    });
  }
  addAddressCTA() {
    pushToDataLayer({
      event: EVENTS.addNewAddress
    });
  }
  accordion(data: Accordion) {
    const { isActive, category, item } = data;
    pushToDataLayer({
      event: isActive ? EVENTS.accordionExpand : EVENTS.accordionCollapse,
      click_text:
        (
          item.en_longTitle ||
          item.longTitle ||
          item.en_title ||
          item.title
        )?.toLowerCase() || undefined,
      accordion_name: category?.toLowerCase()
    });
  }

  socialClick(data: SocialClick) {
    pushToDataLayer({
      event: EVENTS.socialClick,
      social_platform: data.socialPlatform
    });
  }

  trackAddressGuest = trackData => {
    const guestCustomerId = trackData?.customerId;
    const firstName = trackData?.firstName;
    const lastName = trackData?.lastName;
    const emailConsent = trackData?.emailConsent;
    const email = trackData?.email;

    pushToDataLayer({
      event: "guestAddress",
      guestDetails: {
        firstName,
        lastName,
        emailConsent,
        email,
        customerId: guestCustomerId
      }
    });
  };
}

const GoogleAnalytics = new GoogleAnalyticsServices();
export default GoogleAnalytics;
