import isEmpty from "lodash/isEmpty";
import { Facet, ProductsListingPageData } from "../types";

const useSelectedFilters = (
  productListingData: ProductsListingPageData
): Facet[] => {
  const selectedFilters = productListingData?.selectedFilters;
  if (isEmpty(selectedFilters)) return null;

  return selectedFilters;
};

export default useSelectedFilters;
