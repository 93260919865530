import ApiService from "./apiService";
import { webapiPost } from "../webapis/core";
import { BEConfig } from "../config/env";

class AlgoliaInsightsService extends ApiService {
  async trackProductClick({
    position,
    productId,
    queryID,
    algoliaIndex
  }: AlgoliaInsightsFullProductEventType): Promise<any> {
    const apiURL = `${this.getBaseUrl()}/product-click`;

    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      {
        position,
        productId: productId.toString(),
        queryID,
        algoliaIndex
      },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  async trackProductAddToCart({
    productId,
    queryID,
    algoliaIndex
  }: Omit<AlgoliaInsightsFullProductEventType, "position">): Promise<any> {
    const apiURL = `${this.getBaseUrl()}/add-to-cart`;

    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      {
        productId: productId.toString(),
        queryID,
        algoliaIndex
      },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  async trackProductDetailView({
    productId,
    queryID,
    algoliaIndex
  }: Omit<AlgoliaInsightsFullProductEventType, "position">): Promise<any> {
    const apiURL = `${this.getBaseUrl()}/product-detail-view`;

    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      {
        productId: productId.toString(),
        queryID,
        algoliaIndex
      },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  async trackProductPurchase({ productIdList }: { productIdList: string[] }) {
    const apiURL = `${this.getBaseUrl()}/product-purchase`;

    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      {
        productIdList
      },
      this.getVisitorId(),
      this.getLanguage()
    ).request;

    return response.data;
  }

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.productsApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}insights`;

    return url;
  }
}

type AlgoliaInsightsFullProductEventType = {
  position: number;
  productId: string | number;
  queryID?: string;
  algoliaIndex?: string;
};

const instance = new AlgoliaInsightsService();
export default instance;
