import React, { LegacyRef, useRef } from "react";
import cn from "clsx";
import { SaleWishList } from "./SaleWishList";
import { Product } from "@/services/productService/types";

type SaleWishlistButtonProps = {
  product: Product;
  addToTranslation: string;
};

const SaleWishlistButton = ({
  product,
  addToTranslation
}: SaleWishlistButtonProps) => {
  const iconRef: LegacyRef<HTMLDivElement> = useRef(null);

  const handleWishlistClick = () => {
    if (iconRef.current) {
      iconRef.current.click();
    }
  };

  return (
    <>
      <div className="mb-3" />
      <button
        className={cn("wishlist_button", {
          not_clickable: !product.pstock
        })}
        onClick={handleWishlistClick}
      >
        <SaleWishList product={product} iconRef={iconRef} />

        <p>{addToTranslation}</p>
      </button>
    </>
  );
};

export default SaleWishlistButton;
