import React, { FC, useCallback, useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import Headroom from "react-headroom";
import cn from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

import {
  DEFAULT_PAGE_LOADER_CONFIG,
  LANGUAGE_ROUTE_KEY
} from "../../constants";
import { getDeviceType } from "../../util";
import {
  getCountryList,
  showPageLoader
} from "../../redux/actions/common.action";
import {
  getUserCountryStorage,
  setUserCountryStorage
} from "../../util/browserStorage";
import CategoryMenu from "../categoryMenu";
import { Image } from "../image";
import Search from "../search";
import UserPreferences from "../userPreferences";
import UserPreferencesModal from "../modal/modal";
import ProfileButtons from "./profileButtons";
import { CartIcon } from "./profileButtons/cartPopup";
import { removeCountrySelectionDropdown } from "../../util";
import { headerBannerSectionsSelector } from "../../util/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import DynamicBannersWrapper from "../dynamic/dynamicBanner";
import { getOppositeLanguageUrl } from "../../util/routing";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import { useCountryShort, useIsRTL, useLanguage, useRouteSlug } from "@/hooks";
import { ContextStorage } from "@/libs/contextStorage";

import "./style.scss";

const Header: FC<{}> = () => {
  const { t, i18n } = useTranslation("userPreferences");
  const enTranslation = i18n.getFixedT("en", "userPreferences");
  const [openUserPreferenceModal, setOpenUserPreferenceModal] = useState(false);
  const [country, setCountry] = useState({});
  const [activeMenuElement, setActiveMenuElement] = useState(null);
  const countryData = useAppSelector(state => state.common.countryData);
  const ipCountryData = useAppSelector(state => state.common.ipCountryData);
  const settings = useAppSelector(state => state.common.settings);
  const isHeaderFixed = useAppSelector(state => state.common.isHeaderFixed);
  const megaMenuList = useAppSelector(
    state => state.megaMenuReducer.megaMenuList
  );
  const { headerBottomSections } = useAppSelector(headerBannerSectionsSelector);
  const isRTL = useIsRTL();
  const history = useHistory();
  const { pathname, search, hash } = useLocation();
  const routeSlug = useRouteSlug();
  const countryShortFromSlug = useCountryShort();
  const language = useLanguage();
  const oppositeLongLanguage = useIsRTL() ? "ENGLISH" : "عربى";
  const dispatch = useAppDispatch();
  const activeCountry = settings || country;
  const countryShortCode = activeCountry.countrySHORT;
  const countrySelection = activeCountry.countryCode;
  const currencySelection = activeCountry.currencyCode;

  const loadModal = () => {
    const setPreferenceModal = (countryData = []) => {
      let countryAE = {},
        selectedCountry = null,
        selectedIPCountry = null,
        cookieCountry = null;

      if (countryData) {
        countryData.forEach(_country => {
          if (countryShortFromSlug === _country.countrySHORT.toLowerCase()) {
            selectedCountry = _country;
          }
          if (_country.countrySHORT === "AE") {
            countryAE = _country;
          }
          if (ipCountryData?.countrySHORT === _country.countrySHORT) {
            selectedIPCountry = _country;
          }
          if (_country.countrySHORT === getUserCountryStorage()) {
            cookieCountry = _country;
          }
        });
      }

      const countryToStore =
        selectedCountry || cookieCountry || selectedIPCountry || countryAE;

      setUserCountryStorage(countryToStore.countrySHORT);
      setCountry(countryToStore);
    };

    countryData.length && setPreferenceModal(countryData);
  };

  useEffect(() => {
    if (countryData) {
      loadModal();
    }
  }, [countryData]);

  const openModal = e => {
    e?.stopPropagation();
    handleTopNavClick(`${countrySelection} | ${currencySelection}`);
    dispatch(getCountryList(language));
    setOpenUserPreferenceModal(true);
  };

  const closeModal = useCallback((e?) => {
    e?.stopPropagation();
    setOpenUserPreferenceModal(false);
  }, []);

  const handleMenuItemClick = (e, redirectionLink) => {
    e.stopPropagation();
    let currentPathname = `/${routeSlug}${redirectionLink}`;
    history.push(currentPathname);
  };

  const handleLangChange = () => {
    const { arabic, english } = LANGUAGE_ROUTE_KEY;
    dispatch(showPageLoader(DEFAULT_PAGE_LOADER_CONFIG));
    CommonAnalyticsEvent.changeLanguage({
      code: language === english ? arabic : english
    });
  };

  const handleTopNavClick = (name: string, url?: string) => {
    CommonAnalyticsEvent.navTopClick({
      name,
      url
    });
  };

  // TODO: use useUseSelector
  const deviceTypeSSR = get(
    ContextStorage.store.getState(),
    "common.deviceType",
    ""
  );
  const deviceType = deviceTypeSSR || getDeviceType();
  const logoImageUrls = settings.cdn?.logo;

  const fixedHeadroomStyle = {
    position: "fixed",
    zIndex: 100
  };

  return (
    <>
      <UserPreferencesModal
        modalClass={cn("generic_modal", "country_selection_modal", {
          arabic: isRTL
        })}
        showModal={openUserPreferenceModal}
        overlayClassName="countryOverlay"
        closeModal={closeModal}
        zIndex={150}
      >
        <button className="cross_btn" onClick={closeModal}>
          &times;
        </button>
        {openUserPreferenceModal && (
          <UserPreferences
            // @ts-ignore
            closeModal={closeModal}
          />
        )}
      </UserPreferencesModal>
      <div className={cn("header_wrap", { arabic: isRTL })}>
        <Headroom
          disableInlineStyles
          disable={isHeaderFixed}
          {...(isHeaderFixed && { style: fixedHeadroomStyle })}
        >
          <header>
            <section className="web_header">
              <section className="top_section">
                <div className="container padding0 position_relative">
                  <div className="rest_nav_top row">
                    <div className="header_logo_wrap padding0 align-left">
                      <Link
                        to={`/${routeSlug}/`}
                        onClick={() =>
                          handleTopNavClick("home", `/${routeSlug}/`)
                        }
                      >
                        <span className="current_logo">
                          <Image
                            src={logoImageUrls?.[deviceType]}
                            alt="logo"
                            id="main_header_logo"
                            showNewLogoDefaultImage
                          />
                        </span>
                      </Link>
                      {/* @ts-ignore */}
                      <Search className="d-none-mobile" />
                    </div>
                    <div className="header_right_section">
                      <div className="header_country_specific">
                        <button className="download_app">
                          <span />
                          <Link
                            to={`/${routeSlug}/app-download/`}
                            className="download_app"
                            onClick={() =>
                              handleTopNavClick(
                                enTranslation("downloadMobile"),
                                `/${routeSlug}/app-download/`
                              )
                            }
                          >
                            {t("downloadMobile")}
                          </Link>
                        </button>
                        {removeCountrySelectionDropdown(history) && (
                          <div className="currency_change" onClick={openModal}>
                            {countryShortCode && (
                              <span>
                                <CircleFlag
                                  countryCode={countryShortCode.toLowerCase()}
                                  width={18}
                                  className="country_image"
                                />
                              </span>
                            )}
                            {countrySelection && (
                              <p className="country_currency">
                                <span>{countrySelection}</span>
                                <span>{currencySelection}</span>
                              </p>
                            )}
                            <span className="arrow-down" />
                          </div>
                        )}
                        <a
                          className="language_change"
                          onClick={handleLangChange}
                          href={getOppositeLanguageUrl(
                            pathname + search + hash
                          )}
                        >
                          <span className="lang">{oppositeLongLanguage}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="bottom_section">
                <div className="container">
                  <div className="row">
                    <div
                      className="menuLeft position_static padding0 overflow-hidden"
                      style={{ height: "51px" }}
                    >
                      <ul className="navigation_menu">
                        {megaMenuList?.map((subMenu, index) => {
                          return (
                            <li
                              key={subMenu.uid || index}
                              className={cn(
                                "top-level-menu",
                                {
                                  new: !index,
                                  offer: subMenu.isSPS
                                },
                                {
                                  "active-li navigation_menu_element_hover":
                                    activeMenuElement === subMenu
                                }
                              )}
                              onMouseEnter={() => {
                                setActiveMenuElement(subMenu);
                              }}
                              onMouseLeave={() => {
                                setActiveMenuElement(null);
                              }}
                            >
                              <span
                                className="arrow-wrapper"
                                onClick={e => {
                                  handleMenuItemClick(
                                    e,
                                    subMenu.redirectionLink
                                  );
                                  CommonAnalyticsEvent.megaMenuClick(subMenu);
                                }}
                              >
                                <span className={cn(!index && "new_icon")} />
                                <span
                                  aria-label={index}
                                  id={`arrow-${index}`}
                                  className="arrow"
                                >
                                  <a
                                    href={`/${routeSlug}${subMenu.redirectionLink}`}
                                    onClick={e => {
                                      e.preventDefault();
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    {subMenu.headerTitle}
                                  </a>
                                </span>
                              </span>
                              <CategoryMenu menuList={subMenu} />
                            </li>
                          );
                        })}
                      </ul>
                      <div
                        className={cn("category-menu-overlay", {
                          active: !!activeMenuElement
                        })}
                      />
                    </div>
                    <div className="menuRight padding0">
                      <ProfileButtons CartPopup={CartIcon} />
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </header>
        </Headroom>
      </div>

      {headerBottomSections?.map((section, index) => (
        <DynamicBannersWrapper
          section={section}
          key={index}
          bannerPageTitle={section.sectionTitle}
        />
      ))}
    </>
  );
};

export default Header;
