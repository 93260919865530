import {
  SET_CART_SECTIONS,
  SET_CHECKOUT_SECTIONS,
  SET_HEADER_SECTIONS,
  SET_ORDER_SUCCESS_SECTIONS,
  SET_PDP_SECTIONS
} from "../constants/sections";
import {
  SECTION_CART_TYPES,
  SECTION_CART_TYPES_CMS,
  SECTION_CHECKOUT_TYPES,
  SECTION_CHECKOUT_TYPES_CMS,
  SECTION_HEADER_TYPES,
  SECTION_HEADER_TYPES_CMS,
  SECTION_ORDER_SUCCESS_TYPES_CMS,
  SECTION_ORDER_SUCCESS_TYPES,
  SECTION_PDP_TYPES,
  SECTION_PDP_TYPES_CMS,
  SECTION_SLUGS
} from "../../constants/sections";
import { SectionService } from "../../services";
import { _countryStoreSelection } from "../../util";
import { handleFetchError } from "../../util/errorHandler";
import { getSplittedSections } from "../../util/sections";
import { hideLoadingIndicator } from "./common.action";

const setHeaderBannerSections = sections => ({
  type: SET_HEADER_SECTIONS,
  sections
});

const setPDPBannerSections = sections => ({
  type: SET_PDP_SECTIONS,
  sections
});

const setCartBannerSections = sections => ({
  type: SET_CART_SECTIONS,
  sections
});

const setCheckoutBannerSections = sections => ({
  type: SET_CHECKOUT_SECTIONS,
  sections
});

const setOrderSuccessBannerSections = sections => ({
  type: SET_ORDER_SUCCESS_SECTIONS,
  sections
});

export const getBannerSections = sectionSlug => async dispatch => {
  try {
    const { data } = await SectionService.getBannerSections(sectionSlug);

    switch (sectionSlug) {
      case SECTION_SLUGS.HEADER:
        const splittedHeaderSections = getSplittedSections(
          data,
          SECTION_HEADER_TYPES_CMS,
          SECTION_HEADER_TYPES
        );

        dispatch(setHeaderBannerSections(splittedHeaderSections));
        break;
      case SECTION_SLUGS.PDP:
        const splittedPDPSections = getSplittedSections(
          data,
          SECTION_PDP_TYPES_CMS,
          SECTION_PDP_TYPES
        );
        dispatch(setPDPBannerSections(splittedPDPSections));
        break;
      case SECTION_SLUGS.CART:
        const splittedCartSections = getSplittedSections(
          data,
          SECTION_CART_TYPES_CMS,
          SECTION_CART_TYPES
        );

        dispatch(setCartBannerSections(splittedCartSections));
        break;

      case SECTION_SLUGS.CHECKOUT:
        const splittedCheckoutSections = getSplittedSections(
          data,
          SECTION_CHECKOUT_TYPES_CMS,
          SECTION_CHECKOUT_TYPES
        );

        dispatch(setCheckoutBannerSections(splittedCheckoutSections));
        break;

      case SECTION_SLUGS.ORDER_SUCCESS:
        const splittedOrderSuccessSections = getSplittedSections(
          data,
          SECTION_ORDER_SUCCESS_TYPES_CMS,
          SECTION_ORDER_SUCCESS_TYPES
        );

        dispatch(setOrderSuccessBannerSections(splittedOrderSuccessSections));
        break;
      default:
        break;
    }

    return data;
  } catch (error) {
    dispatch(hideLoadingIndicator());
    handleFetchError(error, dispatch);

    return error.response;
  }
};

export const handleSectionsTask = ({ store }, sectionSlug) =>
  store.dispatch(getBannerSections(sectionSlug));
