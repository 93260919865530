import React, { FC } from "react";
import "./style.scss";

const ProductOverlayTag: FC<{
  overlayLabel: string;
  overlayTagBgColor?: string;
  overlayTagLabelColor?: string;
}> = ({ overlayLabel, overlayTagBgColor, overlayTagLabelColor }) => {
  if (!overlayLabel) return null;

  return (
    <span
      className="product_tag product_overlay_tag"
      style={{
        backgroundColor: overlayTagBgColor ?? "none",
        color: overlayTagLabelColor ?? "none"
      }}
    >
      {overlayLabel}
    </span>
  );
};

export default ProductOverlayTag;
