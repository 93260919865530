import React, { useState } from "react";
import cn from "clsx";
import get from "lodash/get";
import { useIsRTL, useLongLanguage } from "../../../hooks";
import HtmlParser from "../../html-parser";
import { DynamicAccordionProps, HelpAccordionProps } from "./types";
import "./style.scss";

const DynamicAccordion = ({ accordion, rowStyles }: DynamicAccordionProps) => {
  const language = useLongLanguage();
  const isRTL = useIsRTL();
  const content = accordion[language];

  const header = get(accordion, ["headers", language, "header"]);
  if (!content) {
    console.error("No content found for Dynamic accordion");
  }
  return (
    <div
      className={cn("dynamic_accordion_wrapper", {
        arabic: isRTL
      })}
      style={rowStyles}
    >
      {header && <h4 className="header">{header}</h4>}

      <div>
        {content?.map((content, index: number) => {
          return (
            <HelpAccordion
              key={index}
              title={content.key}
              content={content.value}
            />
          );
        })}
      </div>
    </div>
  );
};

const HelpAccordion = ({ title, content }: HelpAccordionProps) => {
  const isRTL = useIsRTL();
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    return setIsOpen(prevData => !prevData);
  };

  return (
    <div
      className={cn("accordion", {
        arabic: isRTL
      })}
    >
      <div className="accordionCardTitle" onClick={toggleContent}>
        <span className="accordionHeading">
          <HtmlParser html={title} />
        </span>
        <span
          className={cn("toggleBanner", `${isOpen ? "iconClose" : "iconOpen"}`)}
        />
      </div>
      {isOpen && (
        <div className={"accordionContent"}>
          <HtmlParser html={content} />
        </div>
      )}
    </div>
  );
};

export default DynamicAccordion;
