import React, { FC, useCallback } from "react";
import InputRange from "react-input-range";
import { Range } from "react-input-range";

import { useAppSelector } from "@/redux/store";
import { isDecimalPlacesCountry } from "@/util/selectors";

import "react-input-range/lib/css/index.css";

type RangeSelectorProps = {
  changeRange: (value: Range) => void;
  changeRangeComplete?: (value: Range) => void;
  maxValue: number;
  minValue: number;
  value: Range | number;
};

const RangeSelector: FC<RangeSelectorProps> = ({
  changeRange,
  changeRangeComplete,
  maxValue,
  minValue,
  value
}) => {
  const isDecimal = useAppSelector(isDecimalPlacesCountry);

  const handleChange = useCallback(
    (value: Range) => {
      const newValue = isDecimal
        ? {
            min: +value.min.toFixed(2),
            max: +value.max.toFixed(2)
          }
        : value;
      const formatedValue =
        newValue.max > maxValue ? { ...newValue, max: maxValue } : newValue;
      changeRange(formatedValue);
    },
    [value]
  );

  return (
    <InputRange
      step={isDecimal ? 0.1 : 1}
      draggableTrack
      maxValue={maxValue}
      minValue={minValue}
      value={value}
      onChange={handleChange}
      onChangeComplete={changeRangeComplete}
    />
  );
};

export default RangeSelector;
