import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import FiltersExtraTypeContainer from "./filtersExtraTypeContainer";
import { Facet } from "../types";

const FiltersPanel: FC<{
  itemsTotal: number;
  filters: Facet[];
  onFiltersOpen: () => void;
  appliedFilterCount: number;
}> = ({ itemsTotal, filters, onFiltersOpen, appliedFilterCount }) => {
  const { t } = useTranslation("productListing");

  const extraTypesContent = [...filters]
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((filter, index) => {
      const { sortOrder: alreadyUsed, ...neededData } = filter;
      return (
        <FiltersExtraTypeContainer
          key={`${index}${filter.queryParam}`}
          filter={filter}
        />
      );
    });

  return (
    <div className="filter_types mobile_view_filters">
      <div className="product_counts">
        {itemsTotal} {t("item")}
      </div>
      <div className="sort_filter">
        {!isEmpty(filters) && (
          <>
            {extraTypesContent}
            <div className="filter_product" onClick={onFiltersOpen}>
              <h4 className="filter">
                {t("filterTitle")}
                {Boolean(appliedFilterCount) && (
                  <span className="filter_count">{appliedFilterCount}</span>
                )}
              </h4>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FiltersPanel;
