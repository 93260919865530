import {
  webapiGet,
  webapiPut,
  webapiPost,
  webapiAuthPost,
  webapiDelete,
  webapiGetWithParams
} from "./core";

export function fetchWebApi(
  accessToken,
  url,
  visitorId,
  language,
  countryId,
  storeId,
  defaultHeaders = false,
  isPrevMode = false,
  ipcountry
) {
  return webapiGet(
    accessToken,
    url,
    visitorId,
    language,
    countryId,
    storeId,
    defaultHeaders,
    isPrevMode,
    ipcountry
  ).request;
}
export function fetchWebApiWithParam(accessToken, url, bodyObject, visitorId) {
  return webapiGetWithParams(accessToken, url, bodyObject, visitorId).request;
}
export function putDashboardWebApi(
  accessToken,
  url,
  bodyObject,
  visitorId,
  language
) {
  return webapiPut(accessToken, url, bodyObject, visitorId, language).request;
}

export function postDashboardWebApi(
  accessToken,
  url,
  bodyObject,
  visitorId,
  language,
  defaultHeaders = false
) {
  return webapiPost(
    accessToken,
    url,
    bodyObject,
    visitorId,
    language,
    defaultHeaders
  ).request;
}

export function postAuthApi(url, bodyObject) {
  return webapiAuthPost(url, bodyObject).request;
}

export function deleteWebApi(accessToken, url, visitorId) {
  return webapiDelete(accessToken, url, visitorId).request;
}
