import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { TEMPLATE_HTML_KEYS } from "../../constants/html/templateHtmlKeys.constant";
import { templateHtmlParser } from "../../util/templateHtmlParser";
import { useRouteSlug } from "../../hooks";
// import { addCustomCountdown } from "../../util/custom-countdown";

const HtmlParser: React.FC<{ html: string }> = ({ html = "" }) => {
  const routeSlug = useRouteSlug();

  const replaceKeysMap = {
    [TEMPLATE_HTML_KEYS.COUNTRY_PREFIX]: routeSlug
  };

  useEffect(() => {
    // addCustomCountdown(html);
  }, [html]);

  return (
    <React.Fragment>
      {ReactHtmlParser(templateHtmlParser(html, replaceKeysMap))}
    </React.Fragment>
  );
};

export default React.memo(HtmlParser);
