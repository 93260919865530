import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import get from "lodash/get";
import qs from "query-string";
import moment from "moment";
import { getBaseUrl, isServer } from "..";
import { API_ERROR_MESSAGES } from "../../constants/api";

export const isItemAvailableInCountry = (item, countryId) =>
  Boolean(
    item &&
      item.available &&
      JSON.parse(item.available).find(c => c.countryId === countryId)
  );

export const getDeviceInfo = () => !isServer && get(window, "ua", "");

export const sleep = (ms: number) =>
  new Promise<void>(resolve => {
    const timerId = setTimeout(() => {
      resolve();

      clearTimeout(timerId);
    }, ms);
  });

export const isLinkStartsFromHTTPS = (link = "") => link.startsWith("https://");

export const isNil = value => Boolean(isUndefined(value) || isNull(value));

export const getRedirectionUrl = (isExternalLink, redirectionPath) =>
  isExternalLink ? { pathname: redirectionPath } : redirectionPath;

export const retryCall = ({ command, retry = 3, delay = 1000 }) => {
  let currentRetryCount = retry;

  const updatedCommand = async () => {
    try {
      currentRetryCount--;

      return await command();
    } catch (error) {
      if (!currentRetryCount) throw error;

      if (delay) await sleep(delay);

      return updatedCommand();
    }
  };

  return updatedCommand();
};

export const getDataAsObject = data =>
  typeof data === "object" ? data : JSON.parse(data);

export const truncateProductTitle = (title, characters) => {
  if (title && title.length > characters)
    return title.substring(0, characters) + "...";
  else {
    return title;
  }
};

export const isDateInPast = date => moment(date).isBefore(new Date());
export const isDateInFuture = date => moment(date).isAfter(new Date());

export const getReplacedPreviewHTML = (html = "", paymentCurrency = "AED") =>
  html && paymentCurrency && html.replace("{CURRENCY_CODE}", paymentCurrency);

export const isVideoLink = url =>
  /\.(mp4|webm|ogg|youtube\.com|vimeo\.com)/.test(url);

export const handleGoBack = (location, history, language, commonSettings) => {
  const { gclid } = qs.parse(location.search);
  const goHome = history.length <= 2 || gclid;
  if (goHome) {
    history.push(getBaseUrl({ language, commonSettings }) + "/");
  } else {
    history.goBack();
  }
};

export const checkResponseStatus = response => {
  const { response: nestedResponse } = response;

  if (response.message === API_ERROR_MESSAGES.NETWORK_ERROR) {
    return {
      isNetworkError: true,
      message: API_ERROR_MESSAGES.NETWORK_ERROR
    };
  } else if (nestedResponse?.data) {
    const statusCode = nestedResponse.data.statusCode;
    const message = nestedResponse.data?.message;

    switch (statusCode) {
      case 400:
      case 422:
        return { isToastMessage: true, isAlertMessage: true, message };

      default:
        return {};
    }
  }
  return {};
};

export const runSafely = async <T = any>(fn: () => T): Promise<T> => {
  try {
    return await fn();
  } catch {
    return undefined;
  }
};

export const getFormattedPhone = (fullPhone, mobileCountryCode) =>
  mobileCountryCode && fullPhone?.startsWith(mobileCountryCode)
    ? `${mobileCountryCode} ${fullPhone?.slice(
        mobileCountryCode.toString()?.length
      )}`
    : fullPhone;
