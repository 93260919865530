import axios from "axios";
import get from "lodash/get";
import { terminateSession } from "../redux/actions/authConfig.action";
import { hideErrorPage, showErrorPage } from "../redux/actions/common.action";
import { removeUserEmailStorage, removeUserStorage } from "./browserStorage";

import { isServer } from "./index";

export const handleFetchError = (error: Error, dispatch) => {
  const errorStatus = get(error, "response.status");

  if (dispatch && errorStatus && errorStatus >= 400) {
    dispatch(hideErrorPage());
    switch (errorStatus) {
      case 400:
      case 422:
        const errorMessage = get(error, "response.data.message");
        console.error(
          errorMessage
            ? `The call got ${errorStatus} code with a message: ${errorMessage}`
            : error
        );
        break;

      case 404:
        dispatch(showErrorPage("is404", true));
        break;

      case 409:
        if (!isServer) {
          removeUserStorage();
          removeUserEmailStorage();
        }

        dispatch(terminateSession());
        break;

      default:
        dispatch(showErrorPage("is503", true));
    }
  }

  if (axios.isCancel(error)) {
    console.error("Request cancelled:", error.message);
  } else {
    console.error(error);
  }
};

export const internalServerError = (res, dispatch) => {
  if (res.data && res.data.success === false && dispatch) {
    dispatch(hideErrorPage());
    dispatch(showErrorPage("is500", true));
  }
};

export const errorOnUserSide = errorCode =>
  errorCode && errorCode >= 400 && errorCode < 500;
