export const GET_CART_LIST = "GET_CART_LIST";
export const UPDATE_CART_LIST = "UPDATE_CART_LIST";
export const DELETE_CART_LIST = "DELETE_CART_LIST";
export const SET_SAVING_INDICATOR = "SET_SAVING_INDICATOR";
export const SET_CART_AND_WISHLIST_GENERAL = "SET_CART_AND_WISHLIST_GENERAL";
export const CLEAR_CART_AND_WISHLIST_GENERAL =
  "CLEAR_CART_AND_WISHLIST_GENERAL";
export const WISHLIST_GENERAL_LOADING = "WISHLIST_GENERAL_LOADING";
export const UPDATE_ITEM_QUANTITY = "UPDATE_ITEM_QUANTITY";
export const GET_CART_SUMMARY = "GET_CART_SUMMARY";
