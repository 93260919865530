import React, { FC } from "react";
import ModalBFL from "./modal";
import congratulationImg from "../../images/congratulation.svg";

const ThanksForSubscribingModal: FC<{
  openSubsModal: boolean;
  closeModal: () => void;
  message: string;
  messageHeading: string;
}> = ({ openSubsModal, closeModal, message, messageHeading }) => (
  <div>
    <ModalBFL
      overlayClassName="thankYouSubsOverlay"
      showModal={openSubsModal}
      modalClass="subscriptionModal"
      closeModal={closeModal}
    >
      <div className="close-btn">
        <span onClick={closeModal} className="cross-icon" />
      </div>
      <div className="thankYouSubscribe_modal">
        <img src={congratulationImg} alt="congrats" />
        <span className="modal-text">{messageHeading}</span>
        <p>{message}</p>
      </div>
    </ModalBFL>
  </div>
);

export default ThanksForSubscribingModal;
