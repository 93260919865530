import { useState, useEffect } from "react";
import { SWIPER_V11_URL } from "@/constants";
import { addScriptToBody } from "@/util/dom";

const useSwiperScript = () => {
  const [loadedScriptStatus, setLoadedScriptStatus] = useState(false);

  useEffect(() => {
    const loadSwiperScript = () => {
      addScriptToBody(SWIPER_V11_URL, () => setLoadedScriptStatus(true), true);
    };

    loadSwiperScript();
  }, []);

  return loadedScriptStatus;
};

export default useSwiperScript;
