export type Product = {
  id: number;
  storeId: number;
  captionImageURL: string;
  overlayTagLabel: TextAttributeField & {
    style?: { backgroundColor: string; textColor: string };
  };
  pstock: number;
  isReturn: boolean;
  promoType: string;
  countryId: number;
  isPriceAvailableInUserCountry: boolean;
  isAppOnlyPurchase: boolean;
  isAvailableInCountry: boolean;
  isMultisize: boolean;
  title: TextAttributeField;
  sizes: ProductSize[];
  allSizes: ProductSizeToShow[];
  modelSize?: string;
  brand: TextAttributeField & { id: number };
  parentCategory: TextAttributeField & { id: number };
  category: TextAttributeField & { id: number };
  subcategory: TextAttributeField & { id: number };
  images?: string[];
  url: string;
  highlights: {
    bullets: {
      label: string;
      values: {
        text: string;
        icon: string;
      }[];
    };
    attributes: ProductAttribute[];
    sizeShown?: {
      values: {
        text: string;
        icon: string;
      }[];
    };
    fit: {
      label: string;
      values: {
        text: string;
        icon: string;
      }[];
    };
  };
  price: {
    old: number;
    oldPriceLabel: string;
    current: number;
    currentPriceLabel: string;
    discountPercentage: number;
    currency: string;
    rrp: {
      price: number;
      priceLabel: string;
      savedPrice: number;
      savedPriceLabel: string;
      discountPercentage: number;
    };
  };
  relatedProducts?: Partial<Product>[];
  size?: string;
  sizeId?: number;
  sizeType?: string;
  sizeTypeKey?: string;
  isSizeSelectable: boolean;
  errors?: {
    longMessage: string;
    shortMessage: string;
    type: ProductErrorType;
  };
  warnings?: {
    longMessage: string;
    shortMessage: string;
    type: ProductWarningType;
  };
  modelProfile?: ProductModelDetails;
  displaySizes?: ProductSizeVariant[];
};

export type ProductModelDetails = {
  bust: number;
  height: number;
  hips: number;
  waist: number;
  imageUrl: string;
  modelId: string;
  modelSizeLabel: string;
  name: string;
};

export type ProductAttribute = {
  label: string;
  key: string;
  values: TextAttributeField[];
};

export type ProductSize = {
  sizeType: TextAttributeField;
  variants: ProductSizeVariant[];
};

export type ProductSizeToShow = {
  size: string;
  sizeId: number;
  sizeShortLabel: string;
  sizeStock: number;
  sizeSystem: string;
  sortOrder: number;
  upc: string;
};

export type ProductSizeVariant = {
  sizeId: number;
  size: string;
  sortOrder: number;
  sizeShortLabel?: string;
  sizeSystem?: string;
  upc: string;
  sizeStock: number;
};

export type LoyaltyPointsPayload = {
  productId: number;
  quantity: number;
  transactionPrice: number;
};

export enum ProductErrorType {
  OUT_OF_STOCK = "outOfStock",
  NOT_AVAILABLE_IN_COUNTRY = "notAvailable",
  GENERAL = "general"
}

export enum ProductWarningType {
  STOCK_REDUCED = "stockReduced",
  PRICE_UPDATED = "priceUpdated"
}
