import compact from "lodash/compact";
import { getProductUrl } from "..";
import { TabbyService } from "../../services";

const getUserData = (user, order) => {
  return order.continueAsGuest
    ? {
        email: order.guestEmail,
        name: `${order.address.firstname} ${order.address.lastname}`,
        phone: `+${order.address.phone}`
      }
    : {
        email: user.email,
        name: `${user.firstname} ${user.lastname}`,
        phone: `+${user.phone}`
      };
};

const getOrderHistory = async () => {
  const orders = await TabbyService.getOrders();

  if (!orders) return [];

  return orders.map(order => {
    const status = (() => {
      switch (order.status) {
        case "ORDER RECEIVED":
          return "new";
        case "HANDED TO SHIPPER":
        case "IN PROCESS":
          return "processing";
        case "ORDERED":
        case "DELIVERED":
          return "complete";
        case "CANCELLED":
        case "OUT FOR DELIVERY":
        case "FAILED":
        case "CANCEL":
        case "CANCEL_PENDING":
        case "CANCEL_SUCCESS":
        case "CANCEL_UNDER_MERCHANT":
        case "CANCEL_FAILED":
          return "canceled";
        case "RETURNED":
          return "refunded";
        default:
          return "unknown";
      }
    })();

    const paymentMethod = order.paymentMode.includes("COD") ? "cod" : "card";

    return {
      amount: order.paymentAmount,
      buyer: {
        name: order.shippingName,
        phone: `+${order.shippingMob}`
      },
      status,
      items: order.items.map(product => ({
        quantity: product.quantity,
        title: product.title,
        unit_price: `${product.transactionTotal}`,
        reference_id: `${product.pId}`,
        product_url: getProductUrl({ ...product, id: product.pId })
      })),
      payment_method: paymentMethod,
      purchased_at: order.createDate,
      shipping_address: {
        address: compact([
          order.shippingCountry,
          order.shippingState,
          order.shippingAddress
        ]).join(". "),
        city: order.shippingState,
        zip: order.shippingZip
      }
    };
  });
};

const getAddressData = address => {
  const { addressLine1, addressLine2, city, area, zipcode, country } = address;

  const cityInfo = compact([country, area, city]).join(", ");
  const addressLine = compact([addressLine1, addressLine2]).join(", ");
  const fullAddress = compact([cityInfo, addressLine]).join(". ");

  return {
    address: fullAddress,
    city,
    zip: zipcode
  };
};

export const getPaymentData = async ({
  order = {},
  deliveryCharges,
  orderBody,
  user,
  currency,
  language,
  createdOrderResponse,
  shippingAddress,
  orderItems,
  routeSlug
}) => {
  const amount = orderBody.transactionOrderTotal;
  const orderHistory = await getOrderHistory();
  const buyer = getUserData(user, order);

  return {
    payment: {
      amount,
      currency,
      buyer,
      buyer_history: {
        // TODO: calculate loyalty level
        loyalty_level: 10
        // TODO: set registration date
        // registered_since: "2019-10-05T17:45:17+00:00",
        // TODO: SET wishlist count
        // wishlist_count: 421
      },
      description: "BFL store order",
      order: {
        items: orderItems.map(product => ({
          description: product.brand?.label,
          product_url: `${document.location.origin}/${routeSlug}/${product.url}`,
          quantity: product.quantity,
          reference_id: `${product.id}`,
          title: product.title.label,
          unit_price: `${product.price.current}`
        })),
        shipping_amount: `${deliveryCharges}`,
        tax_amount: 0,
        reference_id: createdOrderResponse.orderId
      },
      shipping_address: getAddressData(shippingAddress),
      order_history: orderHistory
    },
    lang: language,
    merchant_code: TabbyService.getMerchantCode(),
    merchant_urls: TabbyService.getMerchantUrls(createdOrderResponse, orderBody)
  };
};
