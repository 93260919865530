import React, { FC, useEffect, useState } from "react";
import map from "lodash/map";
import { Image } from "@/components/image";
import GroupCategories from "./group-categories";
import { CarousalList, CategoryItem } from "./types";

const ListComponent: FC<{
  data: CategoryItem[];
  carousalList?: CarousalList[];
  header: string;
  handleClick: (item: CategoryItem) => void;
  backBtnClick: () => void;
  page3Title?: string;
  isLevel2?: boolean;
}> = ({
  data,
  carousalList,
  header,
  handleClick,
  backBtnClick,
  page3Title,
  isLevel2
}) => {
  const [noGroupData, setNoGroupData] = useState([]);
  const [groupData, setGroupData] = useState(null);

  useEffect(() => {
    if (isLevel2) {
      setData(data);
    } else {
      setNoGroupData(data);
      setGroupData({});
    }
  }, [data, isLevel2]);

  const setData = data => {
    const groupedData = data.reduce(
      (result, item) => {
        const key = item.groupBy;
        if (key) {
          result.grouped[key] = [...(result.grouped[key] || []), item];
        } else {
          result.noGroup.push(item);
        }
        return result;
      },
      { grouped: {}, noGroup: [] }
    );

    setGroupData(groupedData.grouped);
    setNoGroupData(groupedData.noGroup);
  };

  const groupDataKeys = groupData && Object.keys(groupData);

  return (
    <>
      <>
        <ul className="sub_categories_wrapper slide_layer">
          <h3>
            <span className="back_btn" onClick={backBtnClick} />
            {header}
          </h3>
          {isLevel2 &&
            map(noGroupData, item => (
              <li key={item.uid} onClick={() => handleClick(item)}>
                <span>{item.subTitle}</span>
                <span className="menu_img">
                  <Image src={item.imageUrl || ""} />
                </span>
              </li>
            ))}
        </ul>
        {!!groupDataKeys?.length &&
          groupDataKeys.map(key => (
            <GroupCategories
              key={key}
              title={key}
              dataList={groupData[key]}
              handleClick={handleClick}
            />
          ))}
      </>
      {!isLevel2 && (
        <GroupCategories
          title={page3Title}
          dataList={noGroupData}
          handleClick={handleClick}
        />
      )}
    </>
  );
};

export default ListComponent;
