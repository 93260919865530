import get from "lodash/get";
import set from "lodash/set";
import unset from "lodash/unset";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import {
  FOOTWEAR,
  QUICK_FILTER_DATA_MAP,
  QUICK_FILTER_DATA_MAP_KEYS,
  QUICK_FILTER_SHOES,
  TODDLERS_N_BABIES,
  QUICK_FILTER_KIDS,
  BABY_BOY_COLLECTION,
  BABY_GIRL_COLLECTION,
  TODDLERS,
  CLOTHING,
  CHILDREN,
  KIDS_FOOTWEAR,
  TODDLERS_N_BABY,
  MEGA_MENU_PARSING_FIELD,
  MEGA_MENU_CATEGORY_DATA_FIELD,
  KIDS,
  SHOP_ALL,
  TODDLERS_COLLECTION,
  SHOP_ALL_PREFIX
} from "../../constants/quickFilter";
import { getHierarchySearchArrayFromPath } from "../sizes";
import { LANGUAGE_ROUTE_KEY } from "../../constants";
import { includesSubString, isArray } from "..";
import {
  ARABIC_CLOTHING,
  ARABIC_SHOES
} from "../../constants/quickFilter/ar_quickFilter";

export const addDataFieldToPath = (
  pathArray,
  field = QUICK_FILTER_DATA_MAP[QUICK_FILTER_DATA_MAP_KEYS.STEP_DATA_FIELD]
) =>
  pathArray.reduce((acc, item) => {
    acc = [...acc, field, item];
    return acc;
  }, []);

export const prettifyToddlersPath = quickFilterStepPath => {
  const index = quickFilterStepPath.findIndex(
    item => item === TODDLERS_N_BABIES
  );
  const updatedPath = quickFilterStepPath.slice(0, index + 1);
  return updatedPath;
};

const getQuickFilterPathName = (baseURL, quickFilterStepPath) =>
  baseURL + quickFilterStepPath[quickFilterStepPath.length - 1];

export const getQuickFilterHistoryPushObject = (
  baseURL,
  quickFilterStepPath,
  selectedCategories,
  selectedSizes,
  language
) => {
  const size = `size=${selectedSizes.map(encodeURIComponent).join(",")}`;
  const pathname = getQuickFilterPathName(baseURL, quickFilterStepPath);
  const categories =
    selectedCategories &&
    `categories-2=${getHierarchySearchArrayFromPath(
      quickFilterStepPath,
      selectedCategories,
      language
    ).join(",")}`;

  const search = [categories, size].filter(Boolean).join("&");
  return {
    pathname,
    search
  };
};

export const getKidsQuickFilterHistoryPushObject = (
  baseURL,
  quickFilterStepPath,
  selectedCategories,
  selectedSizes,
  redirectUrl,
  language
) => {
  const size = `size=${selectedSizes.map(encodeURIComponent).join(",")}`;
  let categories = "";
  let occasion = "";
  const joinedPath = quickFilterStepPath.join("");
  if (
    includesSubString(joinedPath, QUICK_FILTER_SHOES) ||
    includesSubString(joinedPath, FOOTWEAR)
  ) {
    occasion = selectedCategories && `occasion=${selectedCategories.join(",")}`;
  } else {
    categories =
      selectedCategories &&
      `categories-2=${getHierarchySearchArrayFromPath(
        quickFilterStepPath,
        selectedCategories,
        language
      ).join(",")}`;
  }
  const [
    redirectWithoutSearch,
    searchFromRedirectURL
  ] = getSplittedUrlArrayBySearch(redirectUrl);
  const search = [categories, size, occasion, searchFromRedirectURL]
    .filter(Boolean)
    .join("&");
  const pathname = baseURL + redirectWithoutSearch;

  return {
    pathname,
    search
  };
};

export const getPrefixedQuickFilterPath = (
  filterStepPath,
  language = LANGUAGE_ROUTE_KEY.english
) =>
  SHOP_ALL[language][
    [...filterStepPath]
      .pop()
      .split("/")
      .filter(Boolean)
      .pop()
  ];

export const addAllItemsPrefix = (str, language = LANGUAGE_ROUTE_KEY.english) =>
  (SHOP_ALL_PREFIX[language] + str).toUpperCase();

export const setObjectKeyAsAdditionalField = (obj, filedName) => {
  const updatedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    updatedObj[key] = { [filedName]: key, [key]: value };
  }
  return updatedObj;
};

export const checkAnyIncludes = (str, substr, checkFn = includesSubString) => {
  if (!str || !substr) return false;

  if (Array.isArray(substr)) {
    const reg = new RegExp(substr.join("|").toUpperCase());
    return reg.test(str.toUpperCase());
  }
  return checkFn(str, substr);
};

export const getSplittedUrlArrayBySearch = url => url.split("?");

const createListOfCategories = list => {
  let colList = ["col1", "col2", "col3", "col4", "col5", "col6"];
  let singleList = [];
  colList.forEach(item => {
    if (!!isArray(list[item]) && !!list[item].length) {
      singleList = [...singleList, ...list[item]];
    }
  });
  return singleList;
};

const getFilterStepData = (
  dataArray,
  mapObjectFieldPath,
  dataFieldPath,
  mapObject = QUICK_FILTER_DATA_MAP
) => {
  const filters = get(mapObject, mapObjectFieldPath, []);
  const regStr = isArray(filters) ? filters.join("|") : filters;
  const data = dataArray.filter(item =>
    new RegExp(`(${regStr.toLowerCase()})$`).test(
      get(item, dataFieldPath, "")
        .toLowerCase()
        .trim()
    )
  );
  return data;
};

const getFilterDataWithCategory = (category, allowedChildrenCategories) => {
  const data = {};
  if (!category) return data;
  const { hoverImageUrl, imageUrl, redirectionLink, headerTitle } = category;
  const categoryTitle = category[MEGA_MENU_PARSING_FIELD];
  data[categoryTitle] = {
    headerTitle,
    hoverImageUrl,
    imageUrl,
    redirectionLink,
    children: allowedChildrenCategories.reduce((acc, allowedCategory) => {
      const data = getFilterStepData(
        createListOfCategories(category),
        allowedCategory,
        MEGA_MENU_CATEGORY_DATA_FIELD
      ).reduce((acc, item) => {
        const { subTitle, children, ...rest } = item;
        const title = item[MEGA_MENU_CATEGORY_DATA_FIELD];
        acc[title] = {
          subTitle,
          ...rest,
          children: children.reduce((acc, item) => {
            const title = item[MEGA_MENU_CATEGORY_DATA_FIELD];
            acc[title] = { ...item };
            return acc;
          }, {})
        };
        return acc;
      }, {});
      return { ...acc, ...data };
    }, {})
  };

  return data;
};

const handleSeparateToddlersClothesStructure = (
  clothesObject,
  language = LANGUAGE_ROUTE_KEY.english,
  toddlersArray = [BABY_BOY_COLLECTION, BABY_GIRL_COLLECTION]
) => {
  const { STEP_DATA_FIELD } = QUICK_FILTER_DATA_MAP_KEYS;

  return toddlersArray.reduce((acc, toddlerTitle) => {
    const redirectUrl =
      clothesObject[QUICK_FILTER_DATA_MAP[STEP_DATA_FIELD]][toddlerTitle]
        .redirectUrl;
    acc[redirectUrl] = {
      children: {},
      subTitle: clothesObject[QUICK_FILTER_DATA_MAP[STEP_DATA_FIELD]][
        toddlerTitle
      ].subTitle
        .replace(TODDLERS_COLLECTION[language], "")
        .trim(),
      redirectUrl
    };
    return acc;
  }, {});
};

const kidsMappingFunctionWithSplittedToddlers = (
  category,
  data,
  footWearPath,
  kidsShoes,
  kidsPath,
  clothingTitle,
  shoesTitle
) => {
  let levelToShift = cloneDeep(get(data, [...kidsPath, category]));

  const isToddlersCategory = category.toLowerCase().includes(TODDLERS);
  if (isToddlersCategory) {
    levelToShift = handleSeparateToddlersClothesStructure(levelToShift);
  }
  const footwearLevelPath = Object.keys(get(kidsShoes, footWearPath)).find(
    key => key.replace(TODDLERS_N_BABY, TODDLERS_N_BABIES).includes(category)
  );

  const levelToBeInjected = get(kidsShoes, [
    ...footWearPath,
    footwearLevelPath
  ]);
  unset(data, [...kidsPath, category, CHILDREN]);

  const shoesKey = get(
    levelToBeInjected,
    MEGA_MENU_CATEGORY_DATA_FIELD,
    QUICK_FILTER_SHOES
  );

  if (isToddlersCategory) {
    const toddlersArray = [BABY_BOY_COLLECTION, BABY_GIRL_COLLECTION];
    const toddlersClosingKey = `/${CLOTHING.toLowerCase()}/`; //because we don't have this data from the Megamenu just create some
    set(data, [...kidsPath, category, CHILDREN], {
      ...levelToShift
    });
    toddlersArray.forEach(toddlerTitle => {
      const childrenOfToddlerLevelPath = [
        ...kidsPath,
        category,
        CHILDREN,
        toddlerTitle,
        CHILDREN
      ];
      set(data, [...childrenOfToddlerLevelPath, shoesKey], {
        ...levelToBeInjected,
        subTitle: shoesTitle
      });

      set(data, [...childrenOfToddlerLevelPath, toddlersClosingKey], {
        children: [],
        redirectUrl: levelToShift[toddlerTitle].redirectUrl,
        subTitle: clothingTitle
      });
    });
  } else {
    const clothingKey = get(
      levelToShift,
      MEGA_MENU_CATEGORY_DATA_FIELD,
      CLOTHING
    );

    set(data, [...kidsPath, category, CHILDREN, shoesKey], {
      ...levelToBeInjected,
      subTitle: shoesTitle
    });
    set(data, [...kidsPath, category, CHILDREN, clothingKey], {
      ...levelToShift,
      subTitle: clothingTitle
    });
  }
};

const handleKidsStructure = (data, kidsShoes, language) => {
  const kidsPath = [KIDS, CHILDREN];
  const footWearPath = [...kidsPath, KIDS_FOOTWEAR, CHILDREN];
  const [clothingTitle, shoesTitle] =
    language === LANGUAGE_ROUTE_KEY.english
      ? [CLOTHING, QUICK_FILTER_SHOES]
      : [ARABIC_CLOTHING, ARABIC_SHOES];

  const dataUpdateFunction = category =>
    kidsMappingFunctionWithSplittedToddlers(
      category,
      data,
      footWearPath,
      kidsShoes,
      kidsPath,
      clothingTitle,
      shoesTitle
    );

  QUICK_FILTER_DATA_MAP[QUICK_FILTER_DATA_MAP_KEYS.KID_CATEGORY].forEach(
    dataUpdateFunction
  );
  return data;
};

export const getQuickFilterDataFromMegaMenu = (
  megaMenuData,
  language = LANGUAGE_ROUTE_KEY.english
) => {
  let data = {};
  const {
    MAIN_CATEGORY,
    PRODUCT_CATEGORY,
    KID_CATEGORY,
    STEP_DATA_FIELD,
    KID_SHOES_FIELD
  } = QUICK_FILTER_DATA_MAP_KEYS;
  const firstStepCategoriesData = getFilterStepData(
    megaMenuData,
    MAIN_CATEGORY,
    MEGA_MENU_PARSING_FIELD
  );
  const allowedChildrenCategories = [PRODUCT_CATEGORY, KID_CATEGORY];
  firstStepCategoriesData.forEach(category => {
    data = {
      ...data,
      ...getFilterDataWithCategory(category, allowedChildrenCategories)
    };
  });

  const kidsShoes = getFilterDataWithCategory(
    firstStepCategoriesData.filter(category =>
      category[MEGA_MENU_PARSING_FIELD].includes(
        QUICK_FILTER_KIDS.toLowerCase()
      )
    )[0],
    [KID_SHOES_FIELD]
  );

  data = handleKidsStructure(data, kidsShoes, language);
  return {
    [QUICK_FILTER_DATA_MAP[STEP_DATA_FIELD]]: data
  };
};

export const getStringifiedAdditionalInfoQuery = (
  redirectUrl,
  countryId,
  language,
  storeId
) =>
  queryString.stringify({
    url: redirectUrl.slice(1),
    countryId,
    language,
    storeId
  });

export const getTitle = dataObj =>
  (
    (!isEmpty(dataObj) && (dataObj.headerTitle || dataObj.subTitle)) ||
    ""
  ).trim();
