import { AxiosResponse } from "axios";

import { webapiPost, webapiGet } from "../../webapis/core";
import { postDashboardWebApi } from "../../webapis/apiResource";
import { BEConfig } from "../../config/env";

import ApiService from "../apiService";

import { LoyaltyPointsPayload, Product } from "./types";

class ProductService extends ApiService {
  public async getProductById(
    id: string,
    ipcountry: string
  ): Promise<{
    data: Product;
    meta: MetaData;
  }> {
    const response = (await webapiGet(
      this.getAccessToken(),
      `${this.getBaseUrl()}${BEConfig.productsApi.productsPageHandle}/${id}`,
      this.getVisitorId(),
      this.getLanguage(),
      null,
      null,
      null,
      null,
      ipcountry
    ).request) as AxiosResponse<{
      data: Product;
      meta: MetaData;
    }>;

    const product = this.formatProduct(response.data.data);

    return { meta: response.data.meta, data: product };
  }

  public getProducts(productsIds: string[] = []) {
    // TODO: return only the product object instead of Axios response
    return webapiPost(
      this.getAccessToken(),
      `${this.getBaseUrl()}${BEConfig.productsApi.productsPageHandle}${
        BEConfig.productsApi.productsById
      }`,
      productsIds,
      this.getVisitorId(),
      this.getLanguage()
    ).request;
  }

  public async getLoyaltyPointsByProducts(
    orderItems: LoyaltyPointsPayload[]
  ): Promise<number> {
    const response = (await postDashboardWebApi(
      this.getAccessToken(),
      `${this.getBaseUrl()}${BEConfig.productsApi.loyaltyPointsHandle}`,
      { orderItems },
      this.getVisitorId()
    )) as AxiosResponse<{
      success: boolean;
      message: string;
      loyaltyPoints: number;
    }>;

    return response.data?.loyaltyPoints;
  }

  public formatProduct(rawProduct): Product {
    const product = { ...rawProduct } as Product;

    if (rawProduct.sizes) {
      product.sizes = rawProduct.isMultisize
        ? rawProduct.sizes
        : [{ sizeType: {}, variants: rawProduct.sizes || [] }];
    }

    return product;
  }

  private getBaseUrl() {
    const {
      productsApi: { baseURL, protocol, port, versionInfo }
    } = BEConfig;

    return `${protocol}${baseURL}${port}${versionInfo}`;
  }
}

const instance = new ProductService();

export default instance;
