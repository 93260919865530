import React, { FC } from "react";
import isEmpty from "lodash/isEmpty";
import { FILTER_TYPES } from "../constants";
import FilterRangeMob from "./filterRangeMob";
import FilterMultiSelectMob from "./filterMultiSelectMob";
import FilterSizeMob from "./filterSizeMob";
import FilterCategoryMob from "./filterCategoryMob";
import {
  CategoryFacet,
  Facet,
  FilterType,
  GeneralFacet,
  MultiSelectFacet,
  PriceFacet,
  PriceFilterOption,
  SizeFacet
} from "../types";

type FilterProps = {
  filter: Omit<Facet, "sortOrder">;
  setToggledFilter: (
    filter: FilterType | PriceFilterOption | EmptyObject
  ) => void;
  toggledFilter: FilterType;
  onApplyButtonClick: (...args) => void;
};

const FilterContainer: FC<FilterProps> = ({
  filter,
  setToggledFilter,
  toggledFilter,
  onApplyButtonClick
}) => {
  if (isEmpty(filter?.data)) throw new Error();

  switch (filter.type) {
    case FILTER_TYPES.MULTI_SELECT:
      return (
        <FilterMultiSelectMob
          filter={filter as GeneralFacet & MultiSelectFacet}
          setToggledFilter={setToggledFilter}
          toggledFilter={toggledFilter}
        />
      );
    case FILTER_TYPES.CATEGORY:
      return (
        <FilterCategoryMob
          filter={filter as GeneralFacet & CategoryFacet}
          setToggledFilter={setToggledFilter}
          toggledFilter={toggledFilter}
          onApplyButtonClick={onApplyButtonClick}
        />
      );
    case FILTER_TYPES.PRICE:
      return (
        <FilterRangeMob
          filter={filter as GeneralFacet & PriceFacet}
          setToggledFilter={setToggledFilter}
          toggledFilter={toggledFilter}
        />
      );
    case FILTER_TYPES.SIZE:
      return (
        <FilterSizeMob
          filter={filter as GeneralFacet & SizeFacet}
          setToggledFilter={setToggledFilter}
          toggledFilter={toggledFilter}
        />
      );

    default:
      return null;
  }
};

export default FilterContainer;
