import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

type FilterWrapperElementProps = {
  iconClassName: string;
  filterTitle: string;
  onIconClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClearBtnClick: () => void;
  children: ReactNode;
  isHeaderVisible: boolean;
};

const FilterWrapperElement: FC<FilterWrapperElementProps> = ({
  iconClassName,
  onIconClick,
  filterTitle,
  onClearBtnClick,
  children,
  isHeaderVisible
}) => {
  const { t } = useTranslation("productListing");

  return (
    <>
      {isHeaderVisible && (
        <div className="mobile_filter_list_head">
          <div className="mobile_filter_icon_wrapper" onClick={onIconClick}>
            <span className={iconClassName} />
          </div>
          <em className="filterTitle">{filterTitle}</em>
          <span onClick={onClearBtnClick}>{t("clear")}</span>
        </div>
      )}

      {children}
    </>
  );
};

export default FilterWrapperElement;
