import React, { FC, useEffect, useRef, useState } from "react";
import cn from "clsx";
import get from "lodash/get";
import { Link } from "react-router-dom";
import {
  BULLET_TYPES,
  BANNER_ROW_TYPE,
  CONTROLLER_TYPES
} from "../../../constants";
import useSwiperScript from "@/hooks/useSwiperScript";
import { isMobile, isServer } from "../../../util";
import { useIsRTL, useLongLanguage, useRouteSlug } from "@/hooks";
import DynamicVideo from "../dynamic-video";
import { getRedirectionUrl, isLinkStartsFromHTTPS } from "../../../util/common";
import BannerLoaderImage from "../../banner/banner-loader-image";
import BannerIntersectionObserver from "../../banner/bannerIntersectionObserver";
import { BannerRow } from "@/routes/catalogPage/types";
import { Column } from "../dynamicColumns/types";
import loaderIcon from "../../../images/loading-icon.svg";
import "./style.scss";

const getElement = (el, className) =>
  !isServer && el && el.querySelector(className);

export const DynamicSlider: FC<{
  section: BannerRow | Column;
  bannerModuleName: string;
  bannerPageTitle: string;
}> = ({ section, bannerModuleName, bannerPageTitle }) => {
  const language = useLongLanguage();
  const routeSlug = useRouteSlug();
  const [imageLoading, setImageLoading] = useState(true);
  const imageLinkRef = useRef(null);
  const sliderRef = useRef(null);
  const { controllerStyles, indicatorStyles, id: rowId } = section;
  const swiperRef = useRef(null);
  const isArabic = useIsRTL();

  const nextButtonClass = `swiperBtnNext-${rowId}`;
  const prevButtonClass = `swiperBtnPrev-${rowId}`;

  const loadedScriptStatus = useSwiperScript();

  const activeBullet =
    !isServer &&
    sliderRef?.current?.querySelector(`.slider-bullet-active${section.id}`);

  const sliders = get(section, "imageList", []);

  const swiperAutoPlay = {
    autoplay: {
      delay: parseInt(section?.autoPlay)
    }
  };

  const setActiveBulletColor = () => {
    const activeBullet =
      !isServer &&
      sliderRef?.current?.querySelector(`.slider-bullet-active${section.id}`);

    if (activeBullet) {
      activeBullet.style.background = indicatorStyles.indicatorColor;
    }
  };

  useEffect(() => {
    onSlideChange();

    if (section.controllerType !== CONTROLLER_TYPES.NONE && !isMobile.any()) {
      setControllerStyles();
    }
  }, [activeBullet, sliders.length]);

  const setControllerStyles = () => {
    const nextButton = getElement(
      imageLinkRef.current,
      `.swiper-button-next.${nextButtonClass}`
    );
    const prevButton = getElement(
      imageLinkRef.current,
      `.swiper-button-prev.${prevButtonClass}`
    );

    if (nextButton && prevButton && controllerStyles) {
      nextButton.style.background = controllerStyles.backgroundControllerColor;
      prevButton.style.background = controllerStyles.backgroundControllerColor;
    }
  };

  const onSlideChange = () => {
    const allBullets =
      !isServer &&
      sliderRef?.current?.querySelectorAll(`.slider-bullet-${section.id}`);

    if (allBullets) {
      allBullets.forEach(bullet => {
        bullet.style.background = indicatorStyles.backgroundIndicatorColor;
      });
      setActiveBulletColor();
    }
  };

  const paginationForSwiper = {
    pagination: {
      el: ".swiper-pagination",
      renderBullet: (index, className) => {
        return `<span class='${className} ${section.indicatorType} slider-bullet-${section.id}'></span>`;
      },
      bulletActiveClass: `swiper-pagination-bullet-active slider-bullet-active${section.id}`,
      clickable: true
    },
    on: {
      slideChange: onSlideChange
    }
  };

  const navigation = {
    prevEl: `.swiper-button-prev.${prevButtonClass}`,
    nextEl: `.swiper-button-next.${nextButtonClass}`
  };

  const languageSpecifiedData = sliders.filter(el =>
    el.hasOwnProperty(language)
  );

  const slidersData = isMobile.any()
    ? sliders.filter(el => el[language]?.enabledOn?.includes("resp"))
    : sliders;

  const swiperParams = {
    ...(section.controllerType !== CONTROLLER_TYPES.NONE &&
      !isMobile.any() && { navigation }),
    ...(section.autoPlay && section.autoPlay !== "none" && swiperAutoPlay),
    ...(section.indicatorType &&
      section.indicatorType !== BULLET_TYPES.NONE &&
      paginationForSwiper),
    lazy: {
      loadPrevNext: true,
      enabled: true
    },
    loop: slidersData.length > 1
  };

  const isControlTypeNone = section.controllerType === CONTROLLER_TYPES.NONE;
  const isNotAvailable =
    isMobile.any() && !section?.enabledOn?.includes("resp");
  const isNavigationEnabled = !isControlTypeNone && sliders.length > 1;

  if (!languageSpecifiedData.length || !slidersData.length || isNotAvailable) {
    return null;
  }

  const sectionStyle = {
    ...section.styles,
    maxWidth: section.width?.value || "100%",
    margin: "0 auto"
  };

  const firstSlider = sliders[0][language];

  useEffect(() => {
    if (
      swiperRef.current &&
      slidersData.length &&
      (loadedScriptStatus || window.Swiper)
    ) {
      const swiper = new window.Swiper(swiperRef.current, swiperParams);
      isArabic && swiper?.changeLanguageDirection?.("rtl");
    }
  }, [swiperRef, slidersData, loadedScriptStatus, section.id]);

  return (
    <div
      style={{ maxWidth: firstSlider.width }}
      className="slider-wrapper"
      ref={sliderRef}
    >
      <div
        className="dynamic-slider-module"
        ref={imageLinkRef}
        style={sectionStyle}
      >
        <div
          style={{
            paddingBottom: `${firstSlider.ratio}%`,
            position: "relative"
          }}
        >
          <div className="swiper" ref={swiperRef} id={`swiper-${section.id}`}>
            <div className="swiper-wrapper">
              {slidersData.map((slider, index) => {
                const sliderData = slider[language];
                const redirectionLink = sliderData.redirectionLink;
                const isExternalLink =
                  redirectionLink && isLinkStartsFromHTTPS(redirectionLink);
                const redirectionPath = isExternalLink
                  ? redirectionLink
                  : `/${routeSlug}${redirectionLink}`;
                const linkTarget = isExternalLink ? "_blank" : "_self";
                const imageSrc = sliderData.imageURL;

                const dataForAnalytic = {
                  bannerModuleName: bannerModuleName?.toUpperCase(),
                  bannerRowName: section.title?.toUpperCase(),
                  index: index + 1,
                  imageUrl: sliderData.imageURL || sliderData.videoUrl,
                  pageName: bannerPageTitle,
                  imageListData: slider
                };

                if (slider.type === BANNER_ROW_TYPE.VIDEO) {
                  return (
                    <div
                      key={index}
                      className={cn("slider-wrapper swiper-slide")}
                    >
                      <DynamicVideo
                        styles={slider.styles}
                        data={sliderData}
                        linkTarget={linkTarget}
                        redirectionPath={redirectionPath}
                        index={index}
                        dataForAnalytic={dataForAnalytic}
                      />
                    </div>
                  );
                }

                return (
                  <div
                    key={index}
                    className={cn("slider-wrapper swiper-slide")}
                  >
                    <Link
                      to={getRedirectionUrl(isExternalLink, redirectionPath)}
                      target={linkTarget}
                      className={cn("link-wrapper", {
                        "disabled-link": !redirectionLink
                      })}
                      data-banner-link={redirectionLink}
                      data-banner-index={index}
                    >
                      {imageSrc && imageLoading && (
                        <BannerLoaderImage loaderIcon={loaderIcon} />
                      )}
                      <BannerIntersectionObserver data={dataForAnalytic}>
                        <img
                          src={imageSrc}
                          alt="banner-image"
                          className="banner-image"
                          onLoad={() => {
                            setImageLoading(false);
                          }}
                        />
                      </BannerIntersectionObserver>
                    </Link>
                  </div>
                );
              })}
            </div>
            {isNavigationEnabled && (
              <>
                <div
                  className={cn("swiper-button-prev", prevButtonClass)}
                ></div>
                <div
                  className={cn("swiper-button-next", nextButtonClass)}
                ></div>
              </>
            )}
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
