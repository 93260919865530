import { pushGaProductClick } from "../../util/analytics";

class CustomerAnalyticsService {
  trackLoyaltyProgramView = ({
    customerId,
    countryId,
    language,
    smilePoints,
    loyaltyMonetaryValue,
    currencyCode
  }) => {
    pushGaProductClick.pushGa({
      event: "loyaltyProgramViewed",
      customerId,
      countryId,
      language,
      smilePoints,
      loyaltyMonetaryValue,
      currencyCode
    });
  };

  trackLoyaltyRedeemButtonClick = ({
    customerId,
    countryId,
    language,
    smilePoints,
    loyaltyMonetaryValue,
    currencyCode
  }) => {
    pushGaProductClick.pushGa({
      event: "loyaltyProgramRedeemButtonClick",
      customerId,
      countryId,
      language,
      smilePoints,
      loyaltyMonetaryValue,
      currencyCode
    });
  };

  trackLoyaltyRedeemConfirmClick = ({
    customerId,
    countryId,
    language,
    smilePoints,
    selectedRedemptionPoints,
    selectedRedemptionTransactionAmount,
    loyaltyMonetaryValue,
    currencyCode
  }) => {
    pushGaProductClick.pushGa({
      event: "loyaltyProgramRedeemConfirmClick",
      customerId,
      countryId,
      language,
      smilePoints,
      selectedRedemptionPoints,
      selectedRedemptionTransactionAmount,
      loyaltyMonetaryValue,
      currencyCode
    });
  };

  trackMyWalletView = ({ customerId, countryId, language, myWalletAmount }) => {
    pushGaProductClick.pushGa({
      event: "myWalletViewed",
      customerId,
      countryId,
      language,
      myWalletAmount
    });
  };
}

export default CustomerAnalyticsService;
