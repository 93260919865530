import React, { FC } from "react";

import * as priceUtil from "../../../util/priceUtil";

import { Product } from "../../../services/productService";

const Discount: FC<{ product: Product }> = ({ product }) => {
  const percentage = priceUtil.getDiscountPercent(product);
  const percentageValue = `${percentage}%`;

  const showDiscount = product.price?.old && Boolean(percentage);
  if (!showDiscount) return null;

  return (
    <p className="item_discount_container">
      <span className="product-discount_view">-{percentageValue}</span>
    </p>
  );
};

export default Discount;
