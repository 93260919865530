import womenShoesIcon from "../../images/quick-filter/women-shoes.svg";
import womenClothingIcon from "../../images/quick-filter/women-clothing.svg";
import menShoesIcon from "../../images/quick-filter/men-shoes.svg";
import menClothingIcon from "../../images/quick-filter/men-clothing.svg";
import kidsShoesIcon from "../../images/quick-filter/kids-shoes.svg";
import kidsClothingIcon from "../../images/quick-filter/kids-clothing.svg";
import menIcon from "../../images/quick-filter/men.png";
import womenIcon from "../../images/quick-filter/women.png";
import kidsIcon from "../../images/quick-filter/kids.png";
import boysIcon from "../../images/quick-filter/boys.svg";
import girlsIcon from "../../images/quick-filter/girls.svg";
import toddlersIcon from "../../images/quick-filter/toddlers_babies.svg";
import babyBoyIcon from "../../images/quick-filter/baby-boy.svg";
import babyGirlIcon from "../../images/quick-filter/baby-girl.svg";
import {
  ARABIC_SHOES_FORMATS,
  ARABIC_ADULTS_CLOTHES_FORMATS,
  ARABIC_KIDS_CLOTHES_FORMATS,
  ARABIC_SHOP_ALL,
  ARABIC_SHOP_ALL_PREFIX,
  ARABIC_ALL,
  ARABIC_COLLECTION,
  ARABIC_GIRLS_IDEAL_FOR_PARAM,
  ARABIC_BOYS_IDEAL_FOR_PARAM,
  ARABIC_TODDLERS_HIERARCHY_FOOTWEAR
} from "./ar_quickFilter";

export const QUICK_FILTER_DATA_MAP_KEYS = {
  MAIN_CATEGORY: "MAIN_CATEGORY",
  KID_CATEGORY: "KID_CATEGORY",
  PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  SIZE_CATEGORY: "SIZE_CATEGORY",
  STEP_DATA_FIELD: "STEP_DATA_FIELD",
  KID_SHOES_FIELD: "KID_SHOES_FIELD"
};

//hardcoded params for quickFilters data parsing
export const TODDLERS_N_BABIES = "/kids/toddlers-and-babies/";
export const TODDLERS_N_BABY = "/kids/toddlers-babies/";
export const CLOTHING = "Clothing";
export const TODDLERS = "toddlers";
export const TODDLERS_N_BABIES_AMPERSAND = "/kids/Toddlers & Babies/";
const MEN = "/men-fashion/";
const WOMEN = "/women-fashion/";
export const KIDS = "/kids-fashion/";
export const QUICK_FILTER_KIDS = "Kids";
const BOYS = "/kids/boys/";
const GIRLS = "/kids/girls/";
export const QUICK_FILTER_SHOES = "Shoes";
export const CHILDREN = "children";
export const KIDS_FOOTWEAR = "/kids/footwear/";
export const FOOTWEAR = "/footwear/";
const GENERAL_SIZE = "General Size";
const WAIST_SIZE = "Waist Size";
const BAND_SIZE = "Band Size";
const CUP_SIZE = "Cup Size";
const SHOP_BY_AGE = "Shop By Age";
const INTERNATIONAL_SIZE = "International Size";
const SIZE_RANGE = "Size range";
const WAIST = "Waist";
const AGE = "Age";
const COLLECTION = "Collection";
export const BABY_BOY_COLLECTION = "/kids/toddlers-babies/baby-boy/";
export const BABY_GIRL_COLLECTION = "/kids/toddlers-babies/baby-girl/";
export const MEGA_MENU_PARSING_FIELD = "redirectionLink";
export const MEGA_MENU_CATEGORY_DATA_FIELD = "redirectUrl";
//------------------------------------------------------------

export const QUICK_FILTER_DATA_MAP = {
  [QUICK_FILTER_DATA_MAP_KEYS.MAIN_CATEGORY]: [MEN, WOMEN, KIDS],
  [QUICK_FILTER_DATA_MAP_KEYS.KID_CATEGORY]: [BOYS, GIRLS, TODDLERS_N_BABIES],
  [QUICK_FILTER_DATA_MAP_KEYS.PRODUCT_CATEGORY]: ["/clothing/", "/shoes/"],
  [QUICK_FILTER_DATA_MAP_KEYS.STEP_DATA_FIELD]: CHILDREN,
  [QUICK_FILTER_DATA_MAP_KEYS.KID_SHOES_FIELD]: KIDS_FOOTWEAR
};

export const ADULTS_CLOTHES_FORMATS = {
  ...ARABIC_ADULTS_CLOTHES_FORMATS,
  [GENERAL_SIZE]: [INTERNATIONAL_SIZE, SIZE_RANGE],
  [WAIST_SIZE]: [WAIST],
  [BAND_SIZE]: [BAND_SIZE],
  [CUP_SIZE]: [CUP_SIZE]
};
export const KIDS_CLOTHES_FORMATS = {
  ...ARABIC_KIDS_CLOTHES_FORMATS,
  [SHOP_BY_AGE]: [AGE]
};

export const SHOES_FORMATS = {
  ...ARABIC_SHOES_FORMATS,
  EU: ["EU"],
  US: ["US"],
  UK: ["UK"]
};

export const QUICK_FILTER_ALL_PRODUCTS = { en: "All", ar: ARABIC_ALL };

const ARABIC_STATIC_ICON_MAP = {
  رجال: menIcon,
  نساء: womenIcon,
  اطفال: kidsIcon,
  أولاد: kidsIcon
};

export const STATIC_ICON_MAP = {
  ...ARABIC_STATIC_ICON_MAP,
  Men: menIcon,
  Women: womenIcon,
  Kids: kidsIcon,
  "/kids-fashion//kids/boys/": boysIcon,
  "/kids-fashion//kids/girls/": girlsIcon,
  "/kids-fashion//kids/toddlers-and-babies/": toddlersIcon,
  "/men-fashion//men/shoes/": menShoesIcon,
  "/men-fashion//men/clothing/": menClothingIcon,
  "/women-fashion//women/shoes/": womenShoesIcon,
  "/women-fashion//women/clothing/": womenClothingIcon,
  "/kids-fashion//kids/boys//kids/boys/": kidsClothingIcon,
  "/kids-fashion//kids/boys//kids/boys/footwear/": kidsShoesIcon,
  "/kids-fashion//kids/girls//kids/girls/": kidsClothingIcon,
  "/kids-fashion//kids/girls//kids/girls/footwear/": kidsShoesIcon,
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy/": babyBoyIcon,
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-girl/": babyGirlIcon,
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy/,/kids/toddlers-babies/baby-girl/": kidsClothingIcon,
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy//clothing/": kidsClothingIcon,
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-girl//clothing/": kidsClothingIcon,
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy//kids/toddlers-babies/shoes/": kidsShoesIcon,
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-girl//kids/toddlers-babies/shoes/": kidsShoesIcon
};

export const STATIC_KIDS_STYLE_ICONS_PATH = {
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy/,/kids/toddlers-babies/baby-girl/":
    "images/quick-filter/toddlers-clothing",
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy//clothing/":
    "images/quick-filter/toddlers-clothing",
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-girl//clothing/":
    "images/quick-filter/toddlers-clothing",
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy//kids/toddlers-babies/shoes/":
    "images/quick-filter/toddlers-shoes",
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-girl//kids/toddlers-babies/shoes/":
    "images/quick-filter/toddlers-shoes",
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/shoes/":
    "images/quick-filter/toddlers-shoes",
  "/kids-fashion//kids/boys//kids/boys/footwear/":
    "images/quick-filter/boys-shoes",
  "/kids-fashion//kids/girls//kids/girls/footwear/":
    "images/quick-filter/girls-shoes"
};

export const SHOP_ALL_PREFIX = { en: "SHOP ALL ", ar: ARABIC_SHOP_ALL_PREFIX };

export const SHOP_ALL = {
  en: { clothing: "SHOP ALL CLOTHING", shoes: "SHOP ALL SHOES" },
  ar: ARABIC_SHOP_ALL
};

export const ITEMS_AMOUNT_FOR_BIG_ICONS = 7;

export const ADULTS_FLOW_STEPS_AMOUNT = 3;
export const KIDS_FLOW_STEPS_AMOUNT = 4;
export const TODDLERS_FLOW_STEPS_AMOUNT = 5;

export const CARD_SUBTITLE_MAP = {
  [MEN]: "yourLook",
  [WOMEN]: "delightful",
  [KIDS]: "trendyAndComfortable"
};

export const TODDLERS_COLLECTION = { en: COLLECTION, ar: ARABIC_COLLECTION };
export const TODDLERS_HIERARCHY_FOOTWEAR = {
  en: "Footwear",
  ar: ARABIC_TODDLERS_HIERARCHY_FOOTWEAR
};

export const TODDLERS_IDEAL_FOR_PARAM_BY_PATH = {
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-girl//kids/toddlers-babies/shoes/": {
    en: "Girls",
    ar: ARABIC_GIRLS_IDEAL_FOR_PARAM
  },
  "/kids-fashion//kids/toddlers-and-babies//kids/toddlers-babies/baby-boy//kids/toddlers-babies/shoes/": {
    en: "Boys",
    ar: ARABIC_BOYS_IDEAL_FOR_PARAM
  }
};

export const QUICK_FILTER_FLOWS = {
  adults: "adults",
  kids: "kids",
  toddlers: "toddlers"
};

export const STEPS_FOR_GA = {
  [QUICK_FILTER_FLOWS.adults]: [
    "Main_Category_Window", //men
    "Category_Window", //clothing
    "Sub_Category_Window", //shorts
    "Size_Window" //xl
  ],
  [QUICK_FILTER_FLOWS.kids]: [
    "Main_Category_Window", //kids
    "Main_Sub_Category_Window", //boys
    "Category_Window", //clothing
    "Sub_Category_Window", //shorts
    "Size_Window" //12Y
  ],
  [QUICK_FILTER_FLOWS.toddlers]: [
    "Main_Category_Window", //kids
    "Main_Sub_Category_Window", //toddlers and babies
    "Main_Sub_Category_Window", //baby-boy
    "Category_Window", //clothing
    "Sub_Category_Window", //shorts
    "Size_Window" // newborn
  ]
};
