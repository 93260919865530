import React, { FC, useContext, useEffect, useRef, useState } from "react";
import cn from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import { isWindows } from "react-device-detect";
import isEmpty from "lodash/isEmpty";
import FilterOptionItem from "./filterOptionItem";
import { SelectedFiltersContext } from "../webFilteringSection";
import FilterElement from "./filterElement";
import { getDeepSelectedCategory } from "@/utils";
import { CategoryFilterOption, GeneralFacet, CategoryFacet } from "../types";

type FilterCategoryProps = {
  filter: Omit<GeneralFacet & CategoryFacet, "sortOrder">;
};
type FilterCategoryOptionProps = {
  filterContent: CategoryFilterOption;
  onSelection: (...args) => void;
};

const FilterCategoryWeb: FC<FilterCategoryProps> = ({ filter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: filterOptions } = filter;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const {
    onSelection,
    updateSelectedFilters,
    selectedFilters,
    setSelectedFilters
  } = useContext(SelectedFiltersContext);

  const onFilterOptionClick = ({ option, isLeaf = false }, event) => {
    event?.stopPropagation();

    const permissionToClick =
      isLeaf ||
      !selectedOptions.length ||
      selectedOptions[0].key !== option.key;

    if (permissionToClick) {
      let options = [];

      if (isLeaf) {
        const filteredOptions = selectedOptions.filter(
          selectedOption => selectedOption.queryParam === option.queryParam
        );
        const allreadyAdded = filteredOptions.find(
          selectedOption => selectedOption.key === option.key
        );

        if (allreadyAdded) {
          options = filteredOptions.filter(item => item.key !== option.key);
        } else {
          options = [...filteredOptions, option];
        }
      }

      setSelectedOptions(isLeaf ? options : [option]);

      onSelection([
        ...(selectedOptions.length > 0
          ? [
              {
                type: filter.type,
                queryParam: selectedOptions[0].queryParam,
                defaultLabel: filter.defaultLabel,
                selectedOptions: []
              }
            ]
          : []),
        {
          type: filter.type,
          queryParam: option.queryParam,
          defaultLabel: filter.defaultLabel,
          selectedOptions: isLeaf ? options : [option]
        }
      ]);
    }
  };

  useEffect(() => {
    const selectedOption = getDeepSelectedCategory(filter);
    if (!isEmpty(selectedOption)) {
      const updatedFilter = selectedOption.reduce((acc, item) => {
        if (isEmpty(acc)) {
          return {
            type: filter.type,
            label: item.label,
            defaultLabel: filter.defaultLabel,
            queryParam: item.queryParam,
            selectedOptions: [item]
          };
        } else {
          acc.selectedOptions.push(item);
        }
        return acc;
      }, {} as any);

      updateSelectedFilters(updatedFilter);
    }
  }, []);

  useEffect(() => {
    const selectedOptionByResponse = getDeepSelectedCategory(filter);
    setSelectedOptions(selectedOptionByResponse);
    if (
      isEmpty(selectedOptionByResponse) &&
      selectedFilters[selectedOptions?.queryParam]
    ) {
      setSelectedFilters(currentFilters => {
        const { [selectedOptions.queryParam]: toRemove, ...newFilters } =
          currentFilters;
        return newFilters;
      });
    }
  }, [filter]);

  return (
    <FilterElement
      className={cn({
        items_selected: !!selectedOptions.length
      })}
      filterLabel={filter.label}
      filterWrapperClassName="categories mxw-300"
    >
      <Scrollbars
        autoHeight
        autoHeightMin={55}
        autoHeightMax={240}
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
        renderTrackVertical={props => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-vertical"
          />
        )}
        className={cn("filter_category_scrollbar", {
          windows_scrollbar: isWindows
        })}
      >
        <div>
          <ul>
            {filterOptions.map(option => (
              <RootFilterContent
                key={option.key}
                filterContent={option}
                onSelection={onFilterOptionClick}
              />
            ))}
          </ul>
        </div>
      </Scrollbars>
    </FilterElement>
  );
};

const LeafComponent: FC<FilterCategoryOptionProps> = ({
  filterContent,
  onSelection
}) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (filterContent.isSelected) {
      ref.current.scrollIntoView({ block: "end" });
    }
  }, []);

  return (
    <FilterOptionItem
      key={filterContent.key}
      option={filterContent}
      isSelected={filterContent.isSelected}
      onSelection={onSelection}
      containerRef={ref}
    />
  );
};

const ExpandableComponent: FC<FilterCategoryOptionProps> = ({
  filterContent,
  onSelection
}) => {
  const [isOpen, setIsOpen] = useState(filterContent.isSelected);
  const [selected, setSelected] = useState(false);
  const toggleOpen = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const onExtraLabelClick = ({ isLeaf }, event) => {
    onSelection(
      {
        option: {
          ...filterContent,
          selectedOptions: [filterContent]
        },
        isLeaf
      },
      event
    );
  };

  useEffect(() => {
    const selectionState =
      filterContent.isSelected &&
      filterContent.children.every(item => !item.isSelected);
    setSelected(selectionState);
  }, [filterContent]);

  return (
    <li>
      <h5
        className={cn("cursor_pointer", "category_name", "d-flex", {
          selected: filterContent.isSelected,
          expanded: isOpen
        })}
        onClick={toggleOpen}
      >
        <div className="expendable_icon" />
        {filterContent.label}
      </h5>
      <ul className={cn({ "d-none": !isOpen })}>
        {filterContent?.extraLabel && (
          <FilterOptionItem
            option={{
              key: filterContent.key,
              label: filterContent.extraLabel,
              defaultValue: filterContent.defaultValue,
              queryParam: filterContent.queryParam,
              isSelected: filterContent.isSelected
            }}
            isSelected={selected}
            onSelection={onExtraLabelClick}
          />
        )}
        {filterContent.children.map(option => (
          <RootFilterContent
            key={option.key}
            filterContent={option}
            onSelection={onSelection}
          />
        ))}
      </ul>
    </li>
  );
};

const RootFilterContent: FC<FilterCategoryOptionProps> = ({
  filterContent,
  onSelection
}) => {
  const isLeaf = isEmpty(filterContent.children);

  if (isLeaf) {
    return (
      <LeafComponent filterContent={filterContent} onSelection={onSelection} />
    );
  }
  return (
    <ExpandableComponent
      filterContent={filterContent}
      onSelection={onSelection}
    />
  );
};

export default FilterCategoryWeb;
