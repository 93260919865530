export const SESSION_TERMINATED = "SESSION_TERMINATED";
export const STORE_TOKEN_CHECKOUT_GUEST = "STORE_TOKEN_CHECKOUT_GUEST";
export const SET_PREV_PATH = "SET_PREV_PATH";
export const SHOW_PREVIEW = "SHOW_PREVIEW";
export const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS";
export const SET_ADDRESS_BOOK = "SAVE_ADDRESS_BOOK";
export const STORE_TOKEN = "STORE_TOKEN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const RESET_TOKEN_CHECKOUT_GUEST = "RESET_TOKEN_CHECKOUT_GUEST";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_EMAIL = "SET_EMAIL";
