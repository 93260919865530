export const getReorderedArray = (arr, itemsPerGroup) => {
  const result = [];
  for (let i = 0; i < itemsPerGroup; i++) {
    const reorderedArrayPerGroup = [];
    for (let j = i; j < arr.length; j += itemsPerGroup) {
      reorderedArrayPerGroup.push(arr[j]);
    }
    result.push(...reorderedArrayPerGroup);
  }
  return result;
};
