import React, { FC, ReactNode, useEffect } from "react";
import { compose } from "redux";
import cn from "clsx";

import { stormlyKey } from "../../config/app";
import {
  getStatesData,
  updateLanguageName
} from "../../redux/actions/common.action";
import withSyteAiFeeds from "../../hocs/withSyteAiFeeds";
import withDefaultLanguageHandling from "../../hocs/withDefaultLanguageHandling";
import StormlyService from "../../services/StormlyService";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useIsRTL, useLanguage } from "@/hooks";

const AppContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const storeLanguage = useAppSelector(store => store.common.language);
  const language = useLanguage();
  const countryId = useAppSelector(state => state.common.settings.countryId);
  const dispatch = useAppDispatch();
  const isRTL = useIsRTL();

  useEffect(() => {
    if (storeLanguage !== language) {
      dispatch(updateLanguageName(language));
    }
  }, []);

  useEffect(() => {
    countryId && dispatch(getStatesData(countryId, language));

    StormlyService.loadAnaliticsScript(stormlyKey);
  }, [countryId]);

  return (
    <div className={cn("parent-container", { arabic: isRTL })}>{children}</div>
  );
};
export default compose(
  withDefaultLanguageHandling,
  withSyteAiFeeds
  //@ts-ignore
)(AppContainer);
