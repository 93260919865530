import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect
} from "react";
import { InitialPropsPageContextData } from "./types";

export const pageInitialPropsContext =
  createContext<InitialPropsPageContextData>(null);

export const PageInitialPropsProvider = ({
  children,
  initialProps: serverInitialProps = null
}) => {
  const [initialProps, setInitialProps] = useState(serverInitialProps);

  const contextValue: InitialPropsPageContextData = useMemo(
    () => ({
      initialProps,
      setInitialProps
    }),
    [initialProps]
  );

  return (
    <pageInitialPropsContext.Provider value={contextValue}>
      {children}
    </pageInitialPropsContext.Provider>
  );
};

export const usePageInitialProps = <InitialProps = {},>() => {
  const context = useContext(
    pageInitialPropsContext
  ) as InitialPropsPageContextData<InitialProps>;

  useEffect(() => {
    return () => context.setInitialProps(null);
  }, []);

  return context;
};
