import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface SeoState {
  meta: Partial<MetaData>;
}

const initialState: SeoState = {
  meta: {}
};

const seoSlice = createSlice({
  name: "seo",
  initialState,
  reducers: {
    setMetaData(state, action: PayloadAction<MetaData>) {
      state.meta = action.payload;
    },
    removeMetaData(state) {
      state.meta = {};
    },
    setIsIndexable(state, action: PayloadAction<boolean>) {
      if (!state.meta) {
        state.meta = {};
      }
      state.meta.isIndexable = action.payload;
    }
  }
});

export default seoSlice;
