import { ContextStorage } from "@/libs/contextStorage";

import { BROWSER_STORAGE } from "../../redux/constants";

const addOneYear = date => {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + 1);
  return dateCopy;
};

export const getDefaultCookieOptions = () => ({
  path: "/",
  expires: new Date(addOneYear(Date.now()))
});

export const setCookie = (key, value) => {
  ContextStorage.cookies.set(key, value, {
    path: "/"
  });
};

export const getCookie = key => ContextStorage.cookies.get(key);

export const setUserStorage = (token, domain) => {
  ContextStorage.cookies.set(
    BROWSER_STORAGE.ACCESS_TOKEN,
    token,
    getDefaultCookieOptions()
  );
};

export const setCheckoutGuestToken = token => {
  ContextStorage.cookies.set(
    BROWSER_STORAGE.GUEST_CHECKOUT_TOKEN,
    token,
    getDefaultCookieOptions()
  );
};

export const getCheckoutGuestToken = () =>
  ContextStorage.cookies.get(BROWSER_STORAGE.GUEST_CHECKOUT_TOKEN);

export const removeCheckoutGuestToken = () => {
  ContextStorage.cookies.set(BROWSER_STORAGE.GUEST_CHECKOUT_TOKEN, "", {
    path: "/",
    maxAge: 0
  });
  ContextStorage.cookies.remove(BROWSER_STORAGE.GUEST_CHECKOUT_TOKEN);
};
export const setUserEmailStorage = email => {
  ContextStorage.cookies.set(
    BROWSER_STORAGE.USER_EMAIL,
    email,
    getDefaultCookieOptions()
  );
};

export const setGuestUserStorage = (token, domain) => {
  ContextStorage.cookies.set(
    BROWSER_STORAGE.GUEST_ACCESS_TOKEN,
    token,
    getDefaultCookieOptions()
  );
};

export const getUserStorage = () =>
  ContextStorage.cookies.get(BROWSER_STORAGE.ACCESS_TOKEN);
export const getUserEmail = () =>
  ContextStorage.cookies.get(BROWSER_STORAGE.USER_EMAIL);
export const getGuestUserStorage = () =>
  ContextStorage.cookies.get(BROWSER_STORAGE.GUEST_ACCESS_TOKEN);

export const removeUserStorage = () => {
  ContextStorage.cookies.set(BROWSER_STORAGE.ACCESS_TOKEN, "", {
    path: "/",
    maxAge: 0
  });
  ContextStorage.cookies.remove(BROWSER_STORAGE.ACCESS_TOKEN);
};

export const removeGuestUserStorage = () => {
  ContextStorage.cookies.set(BROWSER_STORAGE.GUEST_ACCESS_TOKEN, "", {
    path: "/",
    maxAge: 0
  });
  ContextStorage.cookies.remove(BROWSER_STORAGE.GUEST_ACCESS_TOKEN);
};

export const removeUserEmailStorage = () => {
  ContextStorage.cookies.set(BROWSER_STORAGE.USER_EMAIL, "", {
    path: "/",
    maxAge: 0
  });
  ContextStorage.cookies.remove(BROWSER_STORAGE.USER_EMAIL);
};

export const setPrevModeStorage = token => {
  if (token) {
    ContextStorage.cookies.set(BROWSER_STORAGE.PREV_MODE, token, {
      path: "/"
    });
  }
};

export const getPrevModeStorage = myCookies =>
  myCookies
    ? myCookies[BROWSER_STORAGE.PREV_MODE]
    : ContextStorage.cookies.get(BROWSER_STORAGE.PREV_MODE);

export const removePrevModeStorage = domain => {
  ContextStorage.cookies.set(BROWSER_STORAGE.PREV_MODE, "", {
    path: "/",
    maxAge: 0
  });
  ContextStorage.cookies.remove(BROWSER_STORAGE.PREV_MODE);
};

// TODO : domain should be come from ENV
export const setUserCountryStorage = token => {
  ContextStorage.cookies.set(
    BROWSER_STORAGE.USER_COUNTRY,
    token,
    getDefaultCookieOptions()
  );
  ContextStorage.cookies.set(
    BROWSER_STORAGE.USER_COUNTRY_SELECTION,
    token,
    getDefaultCookieOptions()
  );
};

export const getUserCountryStorage = () =>
  ContextStorage.cookies.get(BROWSER_STORAGE.USER_COUNTRY);

export const removeUserCountryStorage = domain => {
  ContextStorage.cookies.set(BROWSER_STORAGE.USER_COUNTRY, "", {
    path: "/",
    maxAge: 0
  });
  ContextStorage.cookies.remove(BROWSER_STORAGE.USER_COUNTRY);
  ContextStorage.cookies.set(BROWSER_STORAGE.USER_COUNTRY_SELECTION, "", {
    path: "/",
    maxAge: 0
  });
  ContextStorage.cookies.remove(BROWSER_STORAGE.USER_COUNTRY_SELECTION);
};
