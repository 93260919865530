import checkout from "@/routes/checkout/checkout";
import dynamicPageSlice from "./dynamicPage";
import searchSlice from "./search";
import seoSlice from "./seo";
import checkoutSlice from "./checkout";

const reducer = {
  [seoSlice.name]: seoSlice.reducer,
  [dynamicPageSlice.name]: dynamicPageSlice.reducer,
  [searchSlice.name]: searchSlice.reducer,
  [checkoutSlice.name]: checkoutSlice.reducer
};

export {
  reducer as default,
  seoSlice,
  dynamicPageSlice,
  searchSlice,
  checkoutSlice
};
