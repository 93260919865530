import Loadable from "@loadable/component";
import React from "react";

const Checkout = Loadable(
  () => import(/* webpackChunkName: "checkout_page" */ "./checkout")
);

export const personalIdContext = React.createContext(null);
export const moneyHashContext = React.createContext(null);

export default Checkout;
