export const LOGGED_IN = "logged in";
export const LOGGED_OFF = "logged off";
export const LOGGED_OUT = "logged out";

export const OTP_METHOD_MOBILE = "mobile";

export const EMAIL_LOGIN_METHOD = "email";
export const SEARCH_CATEGORY_DIRECT = "direct";

export const PROMOTION_FIELDS = [
  "creative_name",
  "creative_slot",
  "promotion_id",
  "promotion_name"
];
