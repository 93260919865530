import AEDImg from "../../images/UAE.png";
import BHDImg from "../../images/BHD.png";
import KWDImg from "../../images/KWD.png";
import OMRImg from "../../images/OMR.png";
import SARImg from "../../images/SAU.png";
import MLTImg from "../../images/MLT.png";
import LBNImg from "../../images/LBN.png";
import USAImg from "../../images/USA.png";
import EGYImg from "../../images/EGY.png";
import INDImg from "../../images/IND.png";
import JORImg from "../../images/JOR.png";
import PHLImg from "../../images/PHL.png";
import QATImg from "../../images/QAT.png";
import Visa from "../../images/visa.gif";
import Mastercard from "../../images/mastercard.gif";
import Cod from "../../images/cod.png";
import Amex from "../../images/amex.gif";
import VisaPay from "../../images/visa-pay.gif";
import ApplePay from "../../images/apple-pay.gif";

export const COUNTRY_SELECTION_MAP = {
  BHR: BHDImg,
  UAE: AEDImg,
  KWT: KWDImg,
  OMN: OMRImg,
  KSA: SARImg,
  MLT: MLTImg,
  LBN: LBNImg,
  USA: USAImg,
  EGY: EGYImg,
  IND: INDImg,
  JOR: JORImg,
  PHL: PHLImg,
  QAT: QATImg
};

export const COUNTRY_BASED_PAYMENT = {
  creditOrDebitCard: [Mastercard, Visa],
  applePay: ApplePay,
  visaPay: VisaPay,
  amex: Amex,
  cod: Cod,
  MASTERCARD: Mastercard,
  VISA: Visa,
  APPLE_PAY: ApplePay,
  COD: Cod
};
