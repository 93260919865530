import {
  CategoryFilter,
  CategoryFilterOption
} from "./routes/productListing/types";

export const getDeepSelectedCategory = (filter: CategoryFilter) => {
  const getDeepestSelectedOptions = (
    level: CategoryFilterOption
  ): CategoryFilterOption[] => {
    if (!level.isSelected) return [];

    const selectedChildren = (level.children || []).filter(
      child => child.isSelected
    );

    if (selectedChildren.length === 0) {
      return [level];
    }
    const deepestSelectedOptions: CategoryFilterOption[] = [];
    selectedChildren.forEach(child => {
      const selectedOptions = getDeepestSelectedOptions(child);
      deepestSelectedOptions.push(...selectedOptions);
    });

    return deepestSelectedOptions;
  };

  const deepestSelectedCategories: CategoryFilterOption[] = filter.data.flatMap(
    level => getDeepestSelectedOptions(level)
  );

  return deepestSelectedCategories;
};
