import React, { FC } from "react";
import cn from "clsx";
import { useSelector } from "react-redux";
import replace from "lodash/replace";
import { COUNTRY_NAME_SEO, PLP_CMS_DATA_SLUGS } from "../../../constants";
import { selectCountryCode } from "../../../util/selectors";
import HtmlParser from "../../html-parser";
import ShowMoreHtmlWrapper from "../dynamic-show-more-html-wrapper";
import { BannerRow } from "@/routes/catalogPage/types";
import { Column } from "../dynamicColumns/types";
import "./style.scss";

const DynamicHTMLBanner: FC<{
  content: BannerRow | Column;
  language: string;
  bannerSlug: string;
}> = ({ content, language, bannerSlug }) => {
  const html = content?.[language]?.html || content.html?.[language];

  if (!html) return null;

  const styles = {
    ...content.styles,
    maxWidth: content.width?.value || "100%",
    margin: `${content.styles?.marginTop}px auto ${content.styles?.marginBottom}px`
  };
  const isContainerWidth = content.width.type === "container-width";
  const bottomSection = bannerSlug === PLP_CMS_DATA_SLUGS.BOTTOM_SECTION;
  const countryCode = useSelector(selectCountryCode);

  const updatedHTML = replace(html, COUNTRY_NAME_SEO, countryCode);

  if (content.isShowMoreButtonVisible) {
    return (
      <ShowMoreHtmlWrapper
        showMoreContentHeight={content.showMoreContentHeight}
      >
        <div className="top_banner_wrapper" style={styles} data-id={bannerSlug}>
          <div className={cn("container", { top_container: !bottomSection })}>
            <HtmlParser html={updatedHTML} />
          </div>
        </div>
      </ShowMoreHtmlWrapper>
    );
  }

  return (
    <div className="top_banner_wrapper" style={styles} data-id={bannerSlug}>
      <div
        className={cn({
          top_container: !bottomSection,
          container: isContainerWidth
        })}
      >
        <HtmlParser html={updatedHTML} />
      </div>
    </div>
  );
};

export default DynamicHTMLBanner;
