import React, { FC } from "react";
import { useLanguage } from "@/hooks";
import CommonAnalyticsEvent from "@/services/analytics/main/common";
import { CustomBannerData } from "../types";
import "./index.scss";

type Props = {
  bannerData: CustomBannerData;
  generalData: any;
};

const FooterWhatsapp: FC<Props> = ({ bannerData, generalData }) => {
  const language = useLanguage();
  const iconUrl = bannerData.find(data => data.key === "iconUrl")?.value;
  const contact = bannerData.find(data => data.key === "contact")?.value;
  const infoText = bannerData.find(
    data => data.key === `${language}_infoText`
  )?.value;
  const link = bannerData.find(data => data.key === "link")?.value;
  const { styles } = generalData;

  const handleSocialClick = () => {
    CommonAnalyticsEvent.socialClick({
      socialPlatform: "whatsapp"
    });
  };

  return (
    <div className="footer_whatsapp" style={styles}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={link}
        className="footer_contact_wrapper"
        onClick={handleSocialClick}
      >
        <img
          src={iconUrl}
          className="contactWhatsapp"
          alt="whatsapp icon"
          loading="lazy"
        />
        <p className="phone direction-ltr">{contact}</p>
        <p className="message">{infoText}</p>
      </a>
    </div>
  );
};

export default FooterWhatsapp;
