import ApiService from "./apiService";

import { BEConfig } from "../config/env";

import { webapiGet, webapiPost } from "../webapis/core";
import { LANGUAGE_ROUTE_KEY_MAP } from "../constants";

class SectionService extends ApiService {
  async getBannerSections(sectionSlug) {
    const baseUrl = `${this.getBaseUrl()}${
      BEConfig.catalogApi.bannerSections
    }/${sectionSlug}`;
    const language = LANGUAGE_ROUTE_KEY_MAP[this.getLanguage()];

    try {
      const response = await webapiGet(
        this.getAccessToken(),
        baseUrl,
        this.getVisitorId(),
        language
      ).request;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  getBaseUrl() {
    const { protocol, baseURL, port, versionInfo } = BEConfig.catalogApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }

  async getDynamicSections(options) {
    const baseUrl = `${this.getBaseUrl()}${BEConfig.catalogApi.bannerSections}`;
    const language = LANGUAGE_ROUTE_KEY_MAP[this.getLanguage()];

    const response = await webapiPost(
      this.getAccessToken(),
      baseUrl,
      options,
      this.getVisitorId(),
      language
    ).request;

    return response.data;
  }
}

const instance = new SectionService();

export default instance;
