import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import cn from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import { isWindows } from "react-device-detect";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import { SelectedFiltersContext } from "../webFilteringSection";
import { useIsRTL } from "@/hooks";
import useSwiperScript from "@/hooks/useSwiperScript";
import useSelectedByQuery from "../hooks/useSelectedByQuery";
import FilterElement from "./filterElement";
import { SwiperOptions } from "swiper/types/swiper-options";

import {
  GeneralFacet,
  SingleSize,
  SizeFacet,
  SizeFilterOption
} from "../types";

const FilterSizesWeb: FC<{
  filter: GeneralFacet & SizeFacet;
}> = ({ filter }) => {
  const {
    onSelection,
    updateSelectedFilters,
    selectedFilters,
    setSelectedFilters
  } = useContext(SelectedFiltersContext);
  const isRTL = useIsRTL();
  const selectedByQuery = useSelectedByQuery();
  const [activeSizeGroup, setActiveSizeGroup] = useState(null);
  const [activeSizeStyle, setActiveSizeStyle] = useState(null);
  const [activeSizeFormat, setActiveSizeFormat] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState([]);

  useEffect(() => {
    const sizeGroup =
      (activeSizeGroup &&
        filter.data.find(sizeGroup => sizeGroup.key === activeSizeGroup.key)) ||
      filter.data[0];
    setActiveSizeGroup(sizeGroup);
    const sizeStyle = ((activeSizeStyle &&
      //@ts-ignore
      sizeGroup.children.find(
        sizeStyle => sizeStyle.key === activeSizeStyle.key
      )) ||
      sizeGroup.children[0]) as SizeFilterOption;
    setActiveSizeStyle(sizeStyle);
    const sizeFormat = sizeStyle.children[0];
    setActiveSizeFormat(sizeFormat);
  }, [activeSizeGroup, filter]);

  useEffect(() => {
    if (activeSizeGroup) {
      const sizeFormat = activeSizeStyle.children[0];
      setActiveSizeFormat(sizeFormat);
    }
  }, [activeSizeStyle]);

  useEffect(() => {
    const sizesByQuery = selectedByQuery[filter.queryParam]?.selectedOptions;

    if (!isEmpty(sizesByQuery)) {
      const allSizes = getAllSizes(filter);
      const selectedSizes = allSizes.filter(size => size.isSelected);
      updateSelectedFilters({
        type: filter.type,
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions: selectedSizes
      });
    }
  }, []);

  useEffect(() => {
    const allSizes = getAllSizes(filter);
    const selectedByResponse = allSizes.filter(size => size.isSelected);
    setSelectedSizes(selectedByResponse);
    if (isEmpty(selectedByResponse) && selectedFilters[filter.queryParam]) {
      setSelectedFilters(currentFilters => {
        const { [filter.queryParam]: toRemove, ...newFilters } = currentFilters;
        return newFilters;
      });
    }
  }, [filter]);

  const onSizeSelection = useCallback(
    (size, e) => {
      e.stopPropagation();
      let updatedSelectedSizes = [];
      if (selectedSizes.find(selectedSizes => selectedSizes.key === size.key)) {
        updatedSelectedSizes = selectedSizes.filter(
          selectedSize => selectedSize.key !== size.key
        );
      } else {
        updatedSelectedSizes = [...selectedSizes, size];
      }
      onSelection({
        ...filter,
        selectedOptions: updatedSelectedSizes
      });
      setSelectedSizes(updatedSelectedSizes);
    },
    [selectedSizes, filter, onSelection]
  );

  if (!filter) return null;

  const selectedSizesCount =
    selectedByQuery[filter.queryParam]?.selectedOptions.length;

  const swiperParams: SwiperOptions = {
    freeMode: true,
    grabCursor: true,
    slidesPerView: "auto",
    slidesPerGroup: 3,
    spaceBetween: 14,
    threshold: 8
  };

  return (
    <FilterElement
      className={cn("size_filter", {
        items_selected: !!selectedSizesCount
      })}
      filterLabel={filter.label}
      filterSelectedCount={selectedSizesCount}
      filterWrapperClassName="size_chart_wrapper padding0"
    >
      <SwipableLevel
        className="sizes_groups"
        list={filter.data}
        activeItem={activeSizeGroup}
        onClick={item => {
          setActiveSizeGroup(item);
        }}
        swiperParams={{ ...swiperParams, navigation: true, slidesPerGroup: 1 }}
        isNavigationEnabled
      />
      <SwipableLevel
        className="sizes_styles"
        list={activeSizeGroup?.children}
        activeItem={activeSizeStyle}
        onClick={item => {
          setActiveSizeStyle(item);
        }}
        swiperParams={swiperParams}
      />
      <Scrollbars
        autoHeight
        autoHeightMin={55}
        autoHeightMax={320}
        className={cn("sizes_formats_scrollbar", {
          windows_scrollbar: isWindows
        })}
        renderTrackVertical={({ ...props }) => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-vertical"
          />
        )}
      >
        <Level
          className="sizes_formats"
          list={activeSizeStyle?.children}
          activeItem={activeSizeFormat}
          forEachCallback={item => (
            <Level
              className="sizes_items"
              list={item.children}
              activeItem={selectedSizes}
              onClick={onSizeSelection}
            />
          )}
        />
      </Scrollbars>
    </FilterElement>
  );
};

const Level: FC<{
  className: string;
  list: SizeFilterOption[] | SizeFilterOption[] | SingleSize[];
  activeItem: SizeFilterOption | SizeFilterOption[];
  onClick?: (
    item: SizeFilterOption | SingleSize,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  forEachCallback?: (item: SizeFilterOption) => React.ReactNode;
}> = ({ className, list, activeItem, onClick, forEachCallback }) => {
  return (
    <div className={className}>
      {sortBy(list, ["sortOrder"]).map(
        (item: SizeFilterOption | SingleSize) => (
          <div
            key={item.key}
            className={cn("level_item", {
              active: checkIfActive(item, activeItem)
            })}
            {...(onClick && { onClick: e => onClick(item, e) })}
          >
            <span> {item.label}</span>
            {forEachCallback && forEachCallback(item as SizeFilterOption)}
          </div>
        )
      )}
    </div>
  );
};

const SwipableLevel: FC<{
  className: string;
  list: SizeFilterOption[];
  activeItem: SizeFilterOption | SizeFilterOption[];
  onClick?: (item: SizeFilterOption) => void;
  swiperParams: SwiperOptions;
  isNavigationEnabled?: boolean;
}> = ({
  className,
  list,
  activeItem,
  onClick,
  swiperParams,
  isNavigationEnabled = false
}) => {
  const [swiper, setSwiper] = useState(null);
  const [isNextButton, setIsNextButton] = useState(false);
  const [isPrevButton, setIsPrevButton] = useState(false);

  useEffect(() => {
    return () => onClick(list[0]);
  }, []);

  const swiperRef = useRef(null);

  const loadedScriptStatus = useSwiperScript();

  useEffect(() => {
    if (
      swiperRef.current &&
      list.length &&
      (loadedScriptStatus || window.Swiper)
    ) {
      const swiper = new window.Swiper(swiperRef.current, swiperParams);
      setSwiper(swiper);
      swiper.on("activeIndexChange", e => {
        setIsNextButton(!e.isEnd);
        setIsPrevButton(!e.isBeginning);
      });
      swiper.on("reachBeginning", e => {
        setIsPrevButton(false);
      });
      swiper.on("reachEnd", e => {
        setIsNextButton(false);
      });
      setIsNextButton(!swiper.isEnd);
      setIsPrevButton(!swiper.isBeginning);
    }
  }, [swiperRef, list, loadedScriptStatus]);

  return (
    <div className={className}>
      <div className="swiper" ref={swiperRef}>
        <div className="swiper-wrapper">
          {sortBy(list, ["sortOrder"]).map(item => (
            <div
              key={item.key}
              className={cn("level_item swiper-slide", {
                active: checkIfActive(item, activeItem)
              })}
              {...(onClick && { onClick: () => onClick(item) })}
            >
              <span> {item.label}</span>
            </div>
          ))}
        </div>
      </div>
      {isNavigationEnabled && swiper && (
        <>
          {isPrevButton && (
            <button
              className="swiper-button-prev"
              onClick={() => swiper.slidePrev()}
            ></button>
          )}
          {isNextButton && (
            <button
              className="swiper-button-next"
              onClick={() => swiper.slideNext()}
            ></button>
          )}
        </>
      )}
    </div>
  );
};

const checkIfActive = (item, activeItem) => {
  if (Array.isArray(activeItem)) {
    return activeItem.some(active => active.key === item.key);
  } else {
    return item.key === activeItem.key;
  }
};

export const getAllSizes = filter => {
  const getDeepestChildren = group =>
    group.children[0].children
      ? group.children.flatMap(child => getDeepestChildren(child))
      : group.children;

  const allSizes = filter.data.flatMap(sizeGroup =>
    getDeepestChildren(sizeGroup)
  );
  return allSizes;
};

export default FilterSizesWeb;
