import { useEffect, useState } from "react";
import { isServer } from "../util";

export default function useOnScrollHandling(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = isServer
    ? null
    : new IntersectionObserver(
        entries => {
          setIntersecting(entries[0].isIntersecting);
          if (!options?.constantTracking && entries[0].isIntersecting) {
            observer.disconnect();
          }
        },
        { threshold: options?.threshold || 0 }
      );

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref?.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const stopIntersecting = () => observer.disconnect();

  return { isIntersecting, stopIntersecting, observer };
}
