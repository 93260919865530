import React, { FC } from "react";
import cn from "clsx";

import "./style.scss";

interface Props extends React.HTMLAttributes<HTMLElement> {
  element?: keyof HTMLElementTagNameMap;
}

const ViewWrapper: FC<Props> = ({ element: Element = "div", ...rest }) => {
  return <Element {...rest} />;
};

const View: {
  desktop: FC<Props>;
  mobile: FC<Props>;
} = {
  desktop: ({ className, ...props }) => (
    <ViewWrapper {...props} className={cn("d-none-mobile", className)} />
  ),
  mobile: ({ className, ...props }) => (
    <ViewWrapper {...props} className={cn("d-none-desktop", className)} />
  )
};

export default View;
