import React, { FC } from "react";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import MenuList from "./menuList";
import { Menu } from "./categoryMenu";

const ListWrapper: FC<{ col: Menu[] }> = ({ col }) => (
  <div className="list-item">
    {map(col, (list, i) => {
      const isMarginClass =
        i + 1 < col.length && !isEmpty(get(col, `${[i + 1]}.children`));

      const listItem = {
        ...list,
        isMarginClass
      };

      return <MenuList key={i} listItem={listItem} />;
    })}
  </div>
);

export default ListWrapper;
