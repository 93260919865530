import React from "react";

const BounceLoader = ({ qty, size, color }) => (
  <div className="bounce">
    {Array.from({ length: qty }, (_, i) => (
      <div
        className={`bounce${i}`}
        style={{ width: size, height: size, backgroundColor: color }}
        key={i}
      />
    ))}
  </div>
);

export default BounceLoader;
