import React from "react";
import { useSelector } from "react-redux";

import { headerBannerSectionsSelector } from "../../../util/selectors";

import DynamicBannersWrapper from "../../dynamic/dynamicBanner";

import "./style.scss";

const ShopByStoreList = ({ closeMenu }: { closeMenu?: () => void }) => {
  const { headerShopByStoreSections } = useSelector(
    headerBannerSectionsSelector
  );

  if (!headerShopByStoreSections) return null;

  const handleCloseMenu = () => {
    closeMenu && closeMenu();
  };

  return (
    <div className="rest_nav_top top_brands_menu">
      <div className="store_list" onClick={handleCloseMenu}>
        {headerShopByStoreSections.map((row, key) => (
          <DynamicBannersWrapper
            section={row}
            key={key}
            bannerPageTitle={row.sectionTitle}
          />
        ))}
      </div>
    </div>
  );
};

export default ShopByStoreList;
