import React from "react";

import get from "lodash/get";
import hoistNonReactStatics from "hoist-non-react-statics";

const withLanguageFromUrl = Component => {
  const EnhancedComponent = props => {
    /**
     * match as router params, language - is set from common reducer
     */
    const { match, language } = props;
    const langCountryCode = get(match, "params.language", "");
    const currentLanguage = langCountryCode
      ? langCountryCode.split("-")[0]
      : language;

    return <Component {...props} currentLanguage={currentLanguage} />;
  };
  hoistNonReactStatics(EnhancedComponent, Component);
  return EnhancedComponent;
};

export default withLanguageFromUrl;
