import React, { FC, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";

import FilterContainer from "./filterContainer";
import { Facet } from "../types";
import "./style.scss";

const ProductListingFilters: FC<{ filters: Facet[] }> = ({ filters }) => {
  if (!filters) return null;

  const filtersToDisplay = useMemo(
    () =>
      filters
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(filter => {
          const { sortOrder: alreadyUsed, ...neededData } = filter;
          return (
            <ErrorBoundary key={filter.label} fallback={null}>
              <FilterContainer filter={neededData} />
            </ErrorBoundary>
          );
        }),
    [filters]
  );

  return (
    <div className="filter_wrapper">
      <div className="container padding0 listing_filter_web_view">
        <div className="filter_types">{filtersToDisplay}</div>
      </div>
    </div>
  );
};

export default ProductListingFilters;
