import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLongLanguage, useRouteSlug } from "@/hooks/";
import { CountDownContentType } from "../types";

const CountdownBanner: FC<{
  content: CountDownContentType;
  isCountdownOver: boolean;
}> = ({ content, isCountdownOver }) => {
  const [countDownImageData, setCountDownImageData] = useState(null);
  const language = useLongLanguage();
  const routeSlug = useRouteSlug();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setCountDownImageData(
      isCountdownOver ? content.afterCountDown : content.beforeCountDown
    );
  }, [isCountdownOver]);

  const imageStyles = {
    ...countDownImageData?.styles,
    zIndex: 1,
    cursor: "pointer"
  };
  const imageDataByLanguage = countDownImageData?.[language];
  const elementInitialStyles = {
    ...imageStyles,
    transition: imageStyles?.hover?.transition
  };

  const hoverStyle = isHovered ? { ...imageStyles?.hover } : {};
  const style = { ...elementInitialStyles, ...hoverStyle };

  return imageDataByLanguage?.imageURL ? (
    <Link
      to={`/${routeSlug}${imageDataByLanguage?.redirectionLink}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      id={content.id}
      style={style}
    >
      <img src={imageDataByLanguage?.imageURL} />
    </Link>
  ) : null;
};

export default CountdownBanner;
