import GoogleAnalytics from "../../googleAnalytics";
import { SelectItem } from "../../types";
import {
  AddProductToWishlist,
  FilterAutoAppliedPayload,
  FilterPayload,
  ImpressionsPayload,
  RemoveFromWishlist,
  SortPayload
} from "./types";

class PlpAnalyticsService {
  addToWishlist(payload: AddProductToWishlist) {
    GoogleAnalytics.addToWishlist(payload, true);
  }

  removeFromWishlist(payload: RemoveFromWishlist) {
    GoogleAnalytics.removeFromWishlist(payload);
  }

  select(payload: SelectItem) {
    GoogleAnalytics.selectProduct(payload);
  }

  impressions(payload: ImpressionsPayload) {
    GoogleAnalytics.productListImpressions(payload);
  }

  // TODO: implementation
  filterAutoApplied(payload: FilterAutoAppliedPayload) {
    GoogleAnalytics.filterAutoApplied(payload);
  }

  // TODO: implementation
  filter(payload: FilterPayload) {
    GoogleAnalytics.filter(payload);
  }

  sort(payload: SortPayload) {
    GoogleAnalytics.sort(payload);
  }

  brandStock(payload) {
    GoogleAnalytics.brandStock(payload);
  }
}

const PlpAnalytics = new PlpAnalyticsService();
export default PlpAnalytics;
