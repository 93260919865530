import React, { FC } from "react";

import { Product } from "../../../services/productService";

import { Image } from "../../image";
import ProductOverlayTag from "../../product/productOverlayTag";

import Discount from "./discount";

import "./style.scss";

const ContentImage: FC<{
  img: string;
  url: string;
  overlayLabel?: string;
  overlayTagBgColor?: string;
  overlayTagLabelColor?: string;
  lazyLoad?: boolean;
  showDefaultImage?: boolean;
  showDiscountSlab?: boolean;
  item?: Product;
}> = ({
  img,
  url,
  overlayLabel,
  overlayTagBgColor,
  overlayTagLabelColor,
  lazyLoad,
  showDefaultImage,
  showDiscountSlab = true,
  item
}) => {
  return (
    <div className="content_image">
      {img && url && (
        <Image
          src={`${url}${img}`}
          style={imageStyle}
          lazyLoad={lazyLoad}
          showDefaultImage={showDefaultImage}
        />
      )}
      {showDiscountSlab && item && <Discount product={item} />}

      <ProductOverlayTag
        overlayLabel={overlayLabel}
        overlayTagBgColor={overlayTagBgColor}
        overlayTagLabelColor={overlayTagLabelColor}
      />
    </div>
  );
};

const imageStyle = { width: "100% " };

export default ContentImage;
