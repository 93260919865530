import React, { FC, useMemo } from "react";
import { helmetJsonLdProp } from "react-schemaorg";
import { Helmet } from "react-helmet";
import cn from "clsx";

import BreadcrumbList from "@/components/breadcrumbsList";
import { useIsRTL, useRouteSlug } from "@/hooks";
import { getSEOStructuredBreadcrumbs } from "@/util/seo";
import { useAppSelector } from "@/redux/store";

const Breadcrumbs: FC<any> = ({ breadcrumbs }) => {
  const routeSlug = useRouteSlug();
  const isRTL = useIsRTL();
  const { meta } = useAppSelector(state => state.seo);

  const breadcrumbsList = useMemo(
    () =>
      breadcrumbs?.map((breadcrumb, index) => ({
        id: `${breadcrumb.value}-${index}`,
        name: breadcrumb.value,
        url: `/${routeSlug}${breadcrumb.link}`
      })),
    [breadcrumbs]
  );

  const seoStructuredBreadcrumbs = getSEOStructuredBreadcrumbs(
    breadcrumbs?.map(breadcrumb => ({
      name: breadcrumb.name,
      url: breadcrumb.url
    }))
  );

  return (
    <>
      {meta.isIndexable && (
        <Helmet script={[helmetJsonLdProp(seoStructuredBreadcrumbs)]} />
      )}
      <div className={cn("breadcrumb", { arabic: isRTL })}>
        <div className="container">
          <BreadcrumbList breadcrumbs={breadcrumbsList} />
        </div>
      </div>
    </>
  );
};

export default Breadcrumbs;
