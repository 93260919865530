import ApiService from "./apiService";

import { BEConfig } from "../config/env";

import { webapiPost } from "../webapis/core";

class AuthService extends ApiService {
  getRegistrationStatus = async registrationData => {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.getRegistrationDataStatus
    }`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      registrationData,
      this.getVisitorId(),
      this.getLanguage()
    ).request;
    return response.data;
  };

  startRegistration = async registrationData => {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.beginUserRegistration
    }`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      registrationData,
      this.getVisitorId(),
      this.getLanguage()
    ).request;
    return response.data;
  };

  deactivatePreviousAccounts = async ({ token = "", otp = "" }) => {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.deactivatePreviousAccounts
    }`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      { token, otp },
      this.getVisitorId(),
      this.getLanguage()
    ).request;
    return response.data;
  };

  completerRegistration = async ({ token = "", otp = "" }) => {
    const apiURL = `${this.getBaseUrl()}${
      BEConfig.customerApi.completeUserRegistration
    }`;
    const response = await webapiPost(
      this.getAccessToken(),
      apiURL,
      { token, otp },
      this.getVisitorId(),
      this.getLanguage()
    ).request;
    return response.data;
  };

  getBaseUrl() {
    const { baseURL, protocol, port, versionInfo } = BEConfig.customerApi;

    const url = `${protocol}${baseURL}${port}${versionInfo}`;

    return url;
  }
}

const instance = new AuthService();

export default instance;
