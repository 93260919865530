import { pushInfoToGa } from "../../util/analytics";

class BannerAnalyticService {
  pushGaBannerClick = banner => {
    const requestObj = {
      event: "catalogImpressionClick",
      ...banner,
      platform: "WEB"
    };
    pushInfoToGa(requestObj);
  };

  pushGaGTMProductImpressions = banner => {
    const requestObj = {
      event: "catalogImpressions",
      ...banner,
      platform: "WEB"
    };
    pushInfoToGa(requestObj);
  };

  pushStormlyBannerClick = (banner, variant, customerId) => {
    const stormlyAnalitic = window.analytics;
    const requestObj = {
      event: "stormlyImpressionClick",
      ...banner,
      platform: "WEB"
    };
    this.pushIdentifyDataToStormly(variant, customerId);

    stormlyAnalitic.track("click", requestObj);
  };

  pushStormlyBannerImpressions = (banner, variant, customerId) => {
    const stormlyAnalitic = window.analytics;

    const requestObj = {
      event: "stormlyImpressions",
      ...banner,
      platform: "WEB"
    };

    this.pushIdentifyDataToStormly(variant, customerId);

    stormlyAnalitic.track("scroll", requestObj);
  };

  pushIdentifyDataToStormly = (variant, customerId) => {
    const stormlyAnalitic = window.analytics;

    if (customerId) {
      stormlyAnalitic.identify(customerId.toString(), {
        experiments: { "user-variant": variant }
      });
    } else {
      stormlyAnalitic.identify({ experiments: { "user-variant": variant } });
    }
  };
}

export default BannerAnalyticService;
