import { fetchWebApi } from "../../webapis/apiResource";
import { GET_MEGA_MENU, RESET_MEGA_MENU } from "../constants";
import { BEConfig } from "../../config/env";
import { handleFetchError } from "../../util/errorHandler";
import { showLoadingIndicator, hideLoadingIndicator } from "./common.action";
import { getPrevModeStorage } from "../../util/browserStorage";

export const getMegaMenu = (content, language) => ({
  type: GET_MEGA_MENU,
  content,
  language
});

export const resetMegaMenu = () => ({
  type: RESET_MEGA_MENU
});

export const getMegaMenuData = (language, storeId, countryId, myCookies) => {
  if (language.includes("ar-") || language.includes("ar")) {
    language = "arabic";
  } else {
    language = "english";
  }
  const apiURL = `${BEConfig.catalogApi.protocol}${BEConfig.catalogApi.baseURL}${BEConfig.catalogApi.port}${BEConfig.catalogApi.versionInfo}${BEConfig.catalogApi.megaMenuHandle}/?storeId=${storeId}&countryId=${countryId}&language=${language}`;
  return dispatch => {
    return fetchWebApi(
      null,
      apiURL,
      null,
      null,
      null,
      null,
      null,
      getPrevModeStorage(myCookies)
    )
      .then(response => {
        if (response.status === 200 && response.data) {
          dispatch(getMegaMenu(response.data, language));
        }
        return response;
      })
      .catch(error => {
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};
