import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import cn from "clsx";
import { Range } from "react-input-range";
import { useTranslation } from "react-i18next";
import RangeSelector from "@/components/rangeSelector";
import { SelectedFiltersContext } from "../saleWebFilteringSection";
import useSelectedByResponse from "@/routes/productListing/hooks/useSelectedByResponse";
import FilterElement from "./filterElement";
import { GeneralFacet, PriceFacet } from "../types";

type FilterRangeProps = {
  filter: Omit<GeneralFacet & PriceFacet, "sortOrder">;
};

const FilterRangeWeb: FC<FilterRangeProps> = ({ filter }) => {
  const { t } = useTranslation("productListing");
  const [rangeValue, setRange] = useState<Range>(filter.data.selectedRange);

  const {
    onSelection,
    updateSelectedFilters,
    selectedFilters,
    setSelectedFilters
  } = useContext(SelectedFiltersContext);

  const selectedByResponse = useSelectedByResponse([filter.data])[0];

  const getArrayOfSelection = useCallback(
    (range: Range) => {
      const { minKey, maxKey } = getKeys(filter.queryParam);

      return [
        {
          label: filter.label,
          defaultLabel: filter.defaultLabel,
          type: filter.type,
          queryParam: minKey,
          selectedOptions: [{ key: range.min }]
        },
        {
          type: filter.type,
          label: filter.label,
          defaultLabel: filter.defaultLabel,
          queryParam: maxKey,
          selectedOptions: [{ key: range.max }]
        }
      ];
    },
    [filter]
  );

  useEffect(() => {
    if (selectedByResponse) {
      const selectedFilters = getArrayOfSelection(
        selectedByResponse.selectedRange
      );

      updateSelectedFilters(selectedFilters);
    }
  }, []);

  useEffect(() => {
    setRange(filter.data.selectedRange);
    const { minKey, maxKey } = getKeys(filter.queryParam);
    if (
      !selectedByResponse &&
      (selectedFilters[minKey] || selectedFilters[maxKey])
    ) {
      setSelectedFilters(currentFilters => {
        const { [minKey]: min, [maxKey]: max, ...newFilters } = currentFilters;
        return newFilters;
      });
    }
  }, [filter]);

  const onRangeComplete = useCallback(
    (newRange: Range) => {
      const selectedFilters = getArrayOfSelection(newRange);

      onSelection(selectedFilters);
    },
    [onSelection, getArrayOfSelection]
  );

  const rangeData = {
    min: filter.data.range.min,
    max: filter.data.range.max,
    selectedMin: filter.data.selectedRange.min,
    selectedMax: filter.data.selectedRange.max
  };

  return (
    <>
      <FilterElement
        filterLabel={`${filter.label} (${filter.data.currency} ${filter.data.selectedRange.min}-${filter.data.selectedRange.max})`}
      >
        <div className="range_container">
          <div className="range_dragger_container">
            <p>
              <span>{t("range")}</span>
              {`${filter.data.currency} ${rangeValue.min} - ${filter.data.currency} ${rangeValue.max}`}
            </p>
            <div className="range_dragger">
              <RangeSelector
                minValue={rangeData.min}
                maxValue={rangeData.max}
                value={rangeValue}
                changeRange={setRange as (value: Range) => void}
                changeRangeComplete={onRangeComplete}
              />
            </div>
          </div>
        </div>
      </FilterElement>
    </>
  );
};

const getKeys = (queryParam: string) => {
  const [minAttr, maxAttr] = queryParam.split("&");
  const [minKey] = minAttr.split("=");
  const [maxKey] = maxAttr.split("=");
  return { minKey, maxKey };
};

export default FilterRangeWeb;
