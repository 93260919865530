import React, { FC, useContext, useEffect, useState } from "react";
import cn from "clsx";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import useMultiselectFilter from "@/routes/productListing/hooks/useMultiselectFilter";
import useSelectedByResponse from "@/routes/productListing/hooks/useSelectedByResponse";
import { SelectedFiltersMobContext } from ".";
import FilterWrapperElement from "@/routes/productListing/productListingFiltersMob/filterWrapperElement";
import { ApplyButton } from "@/routes/productListing/productListingFiltersMob/applyButton";
import FilterOptionIcon from "@/routes/productListing/productListingFiltersWeb/filterOptionIcon";
import { FilterOption, FilterType, GeneralFacet } from "../types";

type FilterMultiSelectProps = {
  filter: Omit<GeneralFacet & { data: FilterOption[] }, "sortOrder">;
  setToggledFilter: (filter: FilterType | EmptyObject) => void;
  toggledFilter: FilterType;
};

const FilterMultiSelectMob: FC<FilterMultiSelectProps> = ({
  filter,
  setToggledFilter,
  toggledFilter
}) => {
  const { t } = useTranslation("productListing");
  const [searchValue, setSearchValue] = useState("");
  const { onSelection, updateSelectedFilters } = useContext(
    SelectedFiltersMobContext
  );
  const {
    filterOptions,
    selectedOptions,
    onFilterOptionClick,
    clearSelectedOptions,
    setSelectedOptions
  } = useMultiselectFilter(filter);
  const selectedByResponse = useSelectedByResponse(filterOptions);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
    if (selectedByResponse) {
      updateSelectedFilters({
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions: selectedByResponse
      });
    }
  }, []);

  useEffect(() => {
    setSelectedOptions(selectedByResponse);
  }, [filterOptions, toggledFilter]);

  const handleSearchType = (e: any) => {
    setSearchValue(e.target.value.toLocaleLowerCase());
  };

  const selectedOptionsCount = selectedOptions.length;

  const isCurrentToggledFilter =
    toggledFilter && toggledFilter.queryParam === filter.queryParam;

  const isSearchable = isCurrentToggledFilter && filter.search;

  const RightSideLabelContent = ({ option }) =>
    option.logoUrl ? (
      <img src={option.logoUrl} className="logo_image" alt="logo-image" />
    ) : (
      <span>{option.count}</span>
    );
  const labelFromSelectedFilters = selectedOptions
    .map(({ label }) => label)
    .join(", ");

  const onBackButtonClick = e => {
    e.stopPropagation();
    setToggledFilter({});
    setSelectedOptions(selectedByResponse);
  };

  const onFilterClearClickHandler = () => {
    searchValue && setSearchValue("");
    clearSelectedOptions();

    if (selectedByResponse.length) {
      onSelection({
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions: []
      });
    }
  };

  const onApplyBtnClick = async e => {
    e.stopPropagation();
    if (!isEqual(selectedOptions, selectedByResponse)) {
      await onSelection({
        label: filter.label,
        defaultLabel: filter.defaultLabel,
        queryParam: filter.queryParam,
        selectedOptions
      });
    }

    setToggledFilter({});
  };

  return (
    <h4
      className={cn(
        "filter_title",
        isCurrentToggledFilter ? "filter_open" : "filter_close_inner",
        {
          items_selected: selectedOptionsCount
        }
      )}
      onClick={() => setToggledFilter(filter)}
    >
      {filter?.label && (
        <span>
          {filter.label}
          {Boolean(selectedOptionsCount) && (
            <strong>{`${labelFromSelectedFilters}`}</strong>
          )}
        </span>
      )}

      {isCurrentToggledFilter && (
        <div
          className="filter_content openLeft"
          onClick={e => e.stopPropagation()}
        >
          <FilterWrapperElement
            iconClassName="mobile_back_icon"
            onIconClick={onBackButtonClick}
            filterTitle={filter.label}
            onClearBtnClick={onFilterClearClickHandler}
            isHeaderVisible={isCurrentToggledFilter}
          >
            {isSearchable && (
              <div className="search-wrapper">
                <input
                  type="text"
                  placeholder={filter.search.placeholder}
                  value={searchValue}
                  onChange={handleSearchType}
                  aria-label={`Search for ${filter.label}`}
                />
                {!searchValue && <span className="search_icon" />}
              </div>
            )}
            <Scrollbars
              autoHeight
              autoHeightMin={55}
              autoHeightMax={`calc(${screenHeight}px - ${
                isSearchable ? "160px" : "100px"
              })`}
            >
              <ul className="hierarchical_categories2_item">
                {filterOptions
                  .filter(option =>
                    option.label?.toLocaleLowerCase()?.includes(searchValue)
                  )
                  .map((option, idx) => {
                    return (
                      <li
                        className={cn(
                          {
                            checked: selectedOptions.some(
                              el => el.key === option.key
                            )
                          },
                          "filter_item_mobile"
                        )}
                        key={option.key}
                        onClick={e => {
                          e.stopPropagation();
                          onFilterOptionClick({ option }, e);
                        }}
                      >
                        {option.icon && <FilterOptionIcon icon={option.icon} />}
                        <span id={idx.toString()}>{option.label}</span>
                        <RightSideLabelContent option={option} />
                      </li>
                    );
                  })}
              </ul>
            </Scrollbars>
            <ApplyButton
              onButtonClick={onApplyBtnClick}
              buttonText={t("apply")}
            />
          </FilterWrapperElement>
        </div>
      )}
    </h4>
  );
};

export default FilterMultiSelectMob;
