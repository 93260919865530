import qs from "query-string";

import ApiService from "./apiService";
import ProductService, { Product } from "./productService";
import { BEConfig } from "../config/env";
import {
  fetchWebApi,
  putDashboardWebApi,
  deleteWebApi,
  postDashboardWebApi
} from "../webapis/apiResource";
import { AxiosResponse } from "axios";

class WishlistService extends ApiService {
  public async getWishlist({ page }: { page?: number }): Promise<WishlistData> {
    const response = (await fetchWebApi(
      this.getAccessToken(),
      `${this.getBaseUrl()}?${qs.stringify({ page: page || 1 })}`,
      this.getVisitorId(),
      this.getLanguage()
    )) as AxiosResponse<WishlistData>;

    response.data.wishlist = response.data.wishlist.map(
      product => ProductService.formatProduct(product) as WishlistProduct
    );

    return response.data;
  }

  public async saveToWishlist(
    data: {
      productId: number;
      size: string;
      sizeId: number;
      sizeType: string | number;
      _id?: string;
    },
    { page }: { page?: number }
  ): Promise<WishlistData | WishlistProduct> {
    const isPaginationFlow = page !== undefined;

    const response = (await putDashboardWebApi(
      this.getAccessToken(),
      `${this.getBaseUrl()}?${
        isPaginationFlow ? qs.stringify({ page: page || 1 }) : ""
      }`,
      data,
      this.getVisitorId()
    )) as AxiosResponse<WishlistData | WishlistProduct>;

    if (isPaginationFlow) {
      const wishlistData = response.data as WishlistData;

      wishlistData.wishlist = wishlistData.wishlist.map(
        product => ProductService.formatProduct(product) as WishlistProduct
      );

      return response.data as WishlistData;
    } else {
      const product = ProductService.formatProduct(
        response.data
      ) as WishlistProduct;

      return product;
    }
  }

  public async saveItemToWishlist(
    cartId: string,
    { page }: { page?: number }
  ): Promise<WishlistData | WishlistProduct> {
    const isPaginationFlow = page !== undefined;

    const {
      wishListApi: {
        baseURL,
        protocol,
        port,
        versionInfo,
        cartPageHandle,
        moveToWishlist
      }
    } = BEConfig;

    const url = `${protocol}${baseURL}${port}${versionInfo}${cartPageHandle}${moveToWishlist}`;

    const response = (await postDashboardWebApi(
      this.getAccessToken(),
      `${url}${isPaginationFlow ? qs.stringify({ page: page || 1 }) : ""}`,
      { cartId },
      this.getVisitorId()
    )) as AxiosResponse<WishlistData | WishlistProduct>;

    if (isPaginationFlow) {
      const wishlistData = response.data as WishlistData;

      wishlistData.wishlist = wishlistData.wishlist.map(
        product => ProductService.formatProduct(product) as WishlistProduct
      );

      return response.data as WishlistData;
    } else {
      const product = ProductService.formatProduct(
        response.data
      ) as WishlistProduct;

      return product;
    }
  }

  public async removeProductFromWishlist({
    productId,
    wishlistItemId,
    page
  }: {
    productId: number;
    wishlistItemId: string;
    page?: number;
  }) {
    const isPaginationFlow = page !== undefined;

    const response = (await deleteWebApi(
      this.getAccessToken(),
      `${this.getBaseUrl()}/${productId}/${wishlistItemId}?${
        isPaginationFlow ? qs.stringify({ page: page || 1 }) : ""
      }`,
      this.getVisitorId()
    )) as AxiosResponse<WishlistData | WishlistProduct>;

    if (isPaginationFlow) {
      const wishlistData = response.data as WishlistData;

      wishlistData.wishlist = wishlistData.wishlist.map(
        product => ProductService.formatProduct(product) as WishlistProduct
      );

      return response.data as WishlistData;
    } else {
      const product = ProductService.formatProduct(
        response.data
      ) as WishlistProduct;

      return product;
    }

    return response.data;
  }

  public async moveToCart(product: WishlistProductBaseData) {
    const {
      wishListApi: {
        baseURL,
        protocol,
        port,
        versionInfo,
        wishListPageHandle,
        moveToCart
      }
    } = BEConfig;

    const response = (await postDashboardWebApi(
      this.getAccessToken(),
      `${protocol}${baseURL}${port}${versionInfo}${wishListPageHandle}${moveToCart}`,
      product,
      this.getVisitorId()
    )) as AxiosResponse<
      | WishlistData
      | {
          code: number;
          message: string;
          data: {
            deletedItem: string;
          };
        }
    >;

    if ((response.data as WishlistData).wishlist) {
      (response.data as WishlistData).wishlist = (
        response.data as WishlistData
      ).wishlist.map(
        product => ProductService.formatProduct(product) as WishlistProduct
      );
    }

    return response.data;
  }

  private getBaseUrl() {
    const {
      wishListApi: { baseURL, protocol, port, versionInfo, wishListPageHandle }
    } = BEConfig;

    const url = `${protocol}${baseURL}${port}${versionInfo}${wishListPageHandle}`;

    return url;
  }
}

export type WishlistProductBaseData = {
  productId: number;
  quantity: number;
  size: string;
  sizeId: number;
  isMultisize: boolean;
  sizeType: string;
  sizeTypeKey: string;
  page?: number;
};

export type WishlistProduct = Product & {
  _id: string;
  productId: number;
};

export type Wishlist = WishlistProduct[];
export type WishlistPagination = {
  page: number;
  totalPages: number;
  totalProducts: number;
};

export type WishlistData = {
  pagination: WishlistPagination;
  wishlist: Wishlist;
};

const instance = new WishlistService();

export default instance;
